import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Contact from "../Pages/Contact";
import SignUp from "../Pages/SignUp";
import Work from "../Pages/Work";

import Login from "../Pages/Login";
import Meals from "../Pages/Meals";
import Plans from "../Pages/Plans";
import Home from "../Pages/Home";
import Header from "../Pages/Home/Header";
import Header2 from "../Pages/Home/Header2";
import Footer from "../Pages/Home/Footer";
import Footer2 from "../Pages/Home/Footer2";
import MealsTable from "../Pages/MealsTable";
import Edituser from "../Pages/editUser";
import Table from "../Pages/Table";
import CreateMember from "../Pages/CreateMember";
import UpdatePassword from "../Pages/Models/updatePasswordPopup.jsx";
import Cart from "../Pages/Cart";
import ContactUs from "../Pages/ContactUs";
import KitchenPartner from "../Pages/KitchenPartner/index.js";
import DeliveryPartner from "../Pages/DeliveryPartner";
import RetailPartner from "../Pages/RetailPartner";
import Checkout from "../Pages/Checkout/PaymentStep.jsx";

import AuthContext from "../Context/AuthContext";
import Profile from "../Pages/Profile";

import ABOUT_IMG from "../Assets/Banner/About.png";
import CONTACT_IMG from "../Assets/Banner/Contact.png";
import HOW_IMG from "../Assets/Banner/How.png";
import DELIVERY_IMG from "../Assets/Banner/Delivery.png";
import CHECKOUT_IMG from "../Assets/Banner/Checkout.png";
import ORDERSUMMARY_IMG from "../Assets/Banner/OrderSummary.png";
import MEAL_IMG from "../Assets/Banner/Meal.png";
import MEALM_IMG from "../Assets/Banner/MealM.png";
import Welcome from "../Pages/Home/Welcome";
import Welcome2 from "../Pages/Home/Welcome2";
import Welcome3 from "../Pages/Home/Welcome3";
import Welcome4 from "../Pages/Home/WelcomeNew";
import Checkoutm from "../Pages/MealsTable/Checkoutm";
import Cartm from "../Pages/Cart/Checkoutm";
import LoginPopup from "../Pages/Models/LoginPopup.jsx";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";

function Routes() {
  var matches = window.matchMedia("(max-width: 600px)");
  var matches1200 = window.matchMedia("(max-width: 1200px)");
  let history = useHistory();

  const [openLogin, setOpenLogin] = React.useState(true);

  return (
    <Typography>
      <Router>
        <Switch>
          {/* <Route path="/about">
            <Header2 title="About us" src={ABOUT_IMG} />
            <Contact />
            <Footer2 />
          </Route> */}
          {/* <Redirect from="/" to="/home" exact /> */}
          <Route path="/work">
            <Header2 title="HOW IT WORKS ?" src={HOW_IMG} order={true} />
            <Work />
            <Footer2 />
          </Route>
          <Route path="/SignUp">
            <Header2 title="Sign up" order={true} />
            <SignUp />
            <Footer2 />
          </Route>
          <Route path="/editUser">
            <Header2 title="EDIT USER" />
            <Edituser />
            <Footer2 />
          </Route>
          <Route path="/Meals">
            <Header2
              order={true}
              title={"HOW WE DELIVER  YOUR WEEKLY MEALS ?"}
              src={DELIVERY_IMG}
              size={6.6}
            />{" "}
            <Meals />
            <Footer2 />
          </Route>
          <Route path="/Profile">
            <Header2 title="My profile" />
            <Profile />
            <Footer2 />
          </Route>
          <Route path="/Privacy">
            <Header2 bannerShow={true} order={true} />
            <PrivacyPolicy />
            <Footer2 />
          </Route>

          <Route path="/plans">
            <Header2
              title={`OUR HEALTHY MEAL PLANS`}
              src={matches?.matches ? MEALM_IMG : MEAL_IMG}
              size={6}
            // order={!matches.matches}
            />
            <Plans /> <Footer2 />
          </Route>
          <Route path="/OrderSummary">
            <Header2
              title="Order summary"
              src={ORDERSUMMARY_IMG}
              bannerShow={matches.matches}
            />
            {matches1200.matches === true ? <Checkoutm /> : <MealsTable />}
            <Footer2 />
          </Route>
          <Route path="/Checkout">
            {/* <Header2 title="Checkout" /> */}
            <Checkout />
            {/* <Footer2 /> */}
          </Route>
          <Route path="/table">
            <Header /> <Table /> <Footer />
          </Route>
          <Route path="/add-member">
            <Header2 title="CREATE MEMBER" /> <CreateMember /> <Footer2 />
          </Route>
          <Route path="/kitchen_partner">
            <KitchenPartner />
          </Route>
          <Route path="/delivery_partner">
            <DeliveryPartner />
          </Route>
          <Route path="/retail_partner">
            <RetailPartner />
          </Route>

          <Route path="/reset-password">
            <Header2 title="home" size={4} order={true} />
            <Home />
            <UpdatePassword open={true} />
            <Footer2 />
          </Route>
          <Route path="/cart">
            <Header2
              title="Checkout"
              bannerShow={matches.matches}
              src={CHECKOUT_IMG}
            />
            {matches1200.matches === true ? <Cartm /> : <Cart />}
            <Footer2 />
          </Route>
          <Route path="/ContactUs">
            <Header2 order={true} title="Contact us" src={CONTACT_IMG} /> <ContactUs />{" "}
            <Footer2 />
          </Route>

          <Route path="/login">
            {matches.matches === true ? (
              <>
                <Header2 title="home" size={4} order={true} bannerShow={true} />
                <Login />
              </>
            ) : (
              <>
                <Header2 title="home" size={7} />
                <Home />
                <LoginPopup
                  open={openLogin}
                  handleClose={() => {
                    setOpenLogin(false);
                    history.push("/");
                  }}
                />{" "}
                <Footer2 />
              </>
            )}
          </Route>

          <Route path="/home">
            <Header2 order={true} title="home" size={7} />
            <Home />
            <Footer2 title={"home"} />
          </Route>
          <Route path="/">
            {/* {matches.matches === true ? <Welcome4 /> : <Welcome4 />} */}
            <Header2 order={true} title="home" size={7} />
            <Home />
            <Footer2 title={"home"} />

          </Route>
        </Switch>
      </Router>
    </Typography>
  );
}

export default Routes;
