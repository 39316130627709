import { Button, Grid, TextField, Typography } from "@mui/material";
import { borderRadius, lineHeight } from "@mui/system";
import React, { useState } from "react";
import { FONTS, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { CurrentUser } from "../../Services/API/Get";
import { EditUserQuery } from "../../Services/API/Put";
import { useHistory, Link } from "react-router-dom";
import ErrorPopup from "../Models/errorPopup.jsx"

function SignUp() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = React.useState("b");

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [officeCity, setofficeCity] = useState("");
  const [insurer, setInsurer] = useState("");
  const [policy, setPolicy] = useState("");
  const [entitlement, setentitlement] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");

  const [idisable, setidisable] = useState(true);

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState(false);
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState(false);
  const [errofficeNo, seterrofficeNo] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [errofficeAddress, seterrofficeAddress] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errofficeCity, seterrofficeCity] = useState(false);
  const [errinsurer, seterrInsurer] = useState(false);
  const [errpolicy, seterrPolicy] = useState(false);
  const [errentitlement, seterrentitlement] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "b") {
      setidisable(true);
      setInsurer("");
      setPolicy("");
      setentitlement("");
    } else {
      setidisable(false);
    }
  };

  const handleClose = () => {
    setOpenToast(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeNo === "") {
      seterrhomeNo(true);
    }
    if (mobile === "") {
      seterrmobile(true);
    }
    if (password !== "") {
      //   seterrPass(true)
      if (repassword === "") {
        seterrRePass("This Field is Requied");
      } else {
        seterrRePass("");
      }
    }

    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobile,
      email: email,
      homeNumber: homeNo,
      officeNumber: officeNo,
      homeAddress: homeAddress,
      officeAddress: officeAddress,
      homeCity: homeCity,
      officeCity: officeCity,
      insurer: insurer,
      policyNo: policy,
      entitlement: entitlement,
      password: password,
      oldPassword: repassword,
    };
    console.log("obj>>>>", obj);

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homeCity !== "" &&
      homeNo !== "" &&
      mobile !== "" &&
      (password !== "" ? repassword !== "" : firstName !== "")
    ) {
      EditUserQuery(obj)
        .then((res) => {
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Update Successful");
          getCurrentUser();

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          console.log("Error", error.response);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          sethomeAddress(cData?.homeAddress);
          sethomeCity(cData?.homeCity);
          setofficeAddress(cData?.officeAddress);
          setofficeCity(cData?.officeCity);
          setmobile(cData?.mobileNumber);
          sethomeNo(cData?.homeNumber);
          setofficeNo(cData?.officeNumber);
          setInsurer(cData?.insurer);
          setPolicy(cData?.policyNo);
          setentitlement(cData?.entitlement);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  const InputField = ({ title, id, value, error }) => {
    return (
      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
        <Typography
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              fontFamily: FONTS,
              color: COLORS.TextColor,
            }}
            gutterBottom
          >
            {title}
          </Typography>
          <input
            style={{
              fontFamily: FONTS,
              fontSize: 14,
              color: "#55555",
              padding: "4px 10px",
              outline: "none",
              border: "1px solid #666",
              borderRadius: 3,
            }}
          />
          {error && (
            <Typography
              variant="caption"
              color="red"
              style={{
                textAlign: "left",
                fontFamily: FONTS,
                margin: "5px 0px",
              }}
            >
              This Field is Requied
            </Typography>
          )}
        </Typography>
      </Grid>
    );
  };

  return (
    <React.Fragment>
       <ErrorPopup openToast={openToast} handleClose={setTimeout(() => {setOpenToast(false);}, 5000)} alertClass={alertClass} msg={msg} />

      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "50px 0px",
        }}
      >
        <Grid md={10} xs={11} style={{ width: "100%" }}>
          <Typography
        
            style={{
              fontFamily: FONTS_INTER,
              fontSize:"12pt",
              textAlign: "left",
              color: "white",
              background: COLORS.Primary,
              borderRadius: 4,
              padding: "8px 15px",
              // letterSpacing: "0.9px",
              fontWeight: "600",
            }}
          >
            PERSONAL DETAILS
          </Typography>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          >
            <Grid item md={5.5} xs={12} style={{ width: "100%" }}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    // variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    First Name
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                    value={firstName}
                  />
                  {errfirstName && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Last Name
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      setlasttName(e.target.value);
                      seterrlasttName(false);
                    }}
                    value={lastName}
                  />
                  {errlastName && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Email
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                    value={email}
                  />
                  {erremail && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                style={{ padding: 0, justifyContent: "space-between" }}
              >
                <Grid xs={7} style={{ padding: 0 }}>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        color: COLORS.TextColor,
                        fontSize: "8.5pt",
                        // letterSpacing: "1px",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                    >
                      Home Address *
                    </Typography>
                    <input
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: 14,
                        color: "#55555",
                        padding: "4px 10px",
                        outline: "none",
                        border: "1px solid ",
                        borderColor: COLORS.InputBorderColor,
                        borderRadius: 3,
                        height: "2rem",
                      }}
                      onChange={(e) => {
                        sethomeAddress(e.target.value);
                        seterrhomeAddress(false);
                      }}
                      value={homeAddress}
                    />
                    {errhomeAddress && (
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          margin: "5px 0px",
                          fontSize:"8pt",
                        }}
                      >
                        This Field is Requied
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid xs={4} style={{ padding: 0 }}>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        color: COLORS.TextColor,
                        fontSize: "8.5pt",
                        // letterSpacing: "1px",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                    >
                      City *
                    </Typography>
                    <input
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: 14,
                        color: "#55555",
                        padding: "4px 10px",
                        outline: "none",
                        border: "1px solid ",
                        borderColor: COLORS.InputBorderColor,
                        borderRadius: 3,
                        height: "2rem",
                      }}
                      onChange={(e) => {
                        sethomeCity(e.target.value);
                        seterrhomeCity(false);
                      }}
                      value={homeCity}
                    />
                    {errhomeCity && (
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          margin: "5px 0px",
                          fontSize:"8pt",
                        }}
                      >
                        This Field is Requied
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5.5} xs={12} style={{ width: "100%" }}>
              <Grid
                display="flex"
                style={{
                  padding: "15px 0px 0px 0px",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={7} style={{ padding: 0 }}>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        color: COLORS.TextColor,
                        fontSize: "8.5pt",
                        // letterSpacing: "1px",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                    >
                      Office Address *
                    </Typography>
                    <input
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: 14,
                        color: "#55555",
                        padding: "4px 10px",
                        outline: "none",
                        border: "1px solid ",
                        borderColor: COLORS.InputBorderColor,
                        borderRadius: 3,
                        height: "2rem",
                      }}
                      onChange={(e) => {
                        setofficeAddress(e.target.value);
                        seterrofficeAddress(false);
                      }}
                      value={officeAddress}
                    />
                    {errofficeAddress && (
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          margin: "5px 0px",
                          fontSize:"8pt",
                        }}
                      >
                        This Field is Requied
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid xs={4} style={{ padding: 0 }}>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        color: COLORS.TextColor,
                        fontSize: "8.5pt",
                        // letterSpacing: "1px",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                    >
                      City *
                    </Typography>
                    <input
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: 14,
                        color: "#55555",
                        padding: "4px 10px",
                        outline: "none",
                        border: "1px solid ",
                        borderColor: COLORS.InputBorderColor,
                        borderRadius: 3,
                        height: "2rem",
                      }}
                      onChange={(e) => {
                        setofficeCity(e.target.value);
                        seterrofficeCity(false);
                      }}
                      value={officeCity}
                    />
                    {errofficeCity && (
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          margin: "5px 0px",
                          fontSize:"8pt",
                        }}
                      >
                        This Field is Requied
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Mobile Number
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile(false);
                    }}
                    value={mobile}
                  />
                  {errmobile && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Home Number
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      sethomeNo(e.target.value);
                      seterrhomeNo(false);
                    }}
                    value={homeNo}
                  />
                  {errhomeNo && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Office Number
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => {
                      setofficeNo(e.target.value);
                      seterrofficeNo(false);
                    }}
                    value={officeNo}
                  />
                  {errofficeNo && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <Grid lg={6} md={8} sm={10} xs={12} style={{ width: "100%" }}>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.Primary,
                  textAlign: "left",
                  // letterSpacing: 1,
                  fontFamily: FONTS_INTER,
                  fontWeight:"500",
                }}
              >
                Do you have a life policy with Union Assurance or HNB Assurance
                ?
              </Typography>
              <Typography
                variant="caption"
                style={{
                  textAlign: "left",
                  float: "left",
                  fontFamily: FONTS_INTER,
                  color: COLORS.TextColor,
                  fontWeight:"500",
                }}
              >
                ( If "YES", please fill the below section and "check" your
                eligibility to get discounts. )
              </Typography>
            </Grid>
            <Grid
              md={3}
              sm={2}
              xs={12}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Radio
                  size="small"
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  style={{
                    color: COLORS.Primary,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ color: COLORS.Primary, fontFamily: FONTS_INTER, }}
                >
                  Yes
                </Typography>
                <Radio
                  size="small"
                  checked={selectedValue === "b"}
                  onChange={handleChange}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  style={{
                    color: COLORS.Primary,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    color: COLORS.Primary,

                    fontFamily: FONTS_INTER,
                  }}
                >
                  No
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <Grid item xs={12} md={3} lg={3} sm={5.5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Select your insurer
                  </Typography>
                  <input
                    disabled={idisable}
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => setInsurer(e.target.value)}
                    value={insurer}
                  />
                  {false && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} lg={2} sm={5.5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Enter Policy Number
                  </Typography>
                  <input
                    disabled={idisable}
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => setPolicy(e.target.value)}
                    value={policy}
                  />
                  {false && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              md={3}
              style={{ display: "flex", alignItems: "flex-end" }}
              // sx={{
              //   padding: {
              //     sm: 0,
              //     md: "35px 0px",
              //   },
              // }}
            >
              <Button
                disabled={idisable}
                size="large"
                variant="contained"
                // style={{
                //   background: "#808182",
                //   height: "34px",
                //   marginBottom: 14,
                // }}
                style={{
                  fontFamily: FONTS_INTER,
                  fontSize: 14,
                  color: "#55555",
                  padding: "20px 15px",
                  outline: "none",
                  border: "1px solid ",
                  borderColor: COLORS.InputBorderColor,
                  borderRadius: 3,
                  height: "2rem",
                  background: "#808182",

                  marginBottom: 14,
                  width: "fit-content",
                }}
              >
                CHECK ELIGIBLITY
              </Button>
            </Grid>
            <Grid item xs={12} lg={3} md={3}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Your entitlement
                  </Typography>
                  <input
                    disabled={idisable}
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    onChange={(e) => setentitlement(e.target.value)}
                    value={entitlement}
                  />
                  {false && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS,
                        margin: "5px 0px",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} md={5} lg={5} sm={5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Enter New Password
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      seterrPass(false);
                    }}
                    value={password}
                  />
                  {errPass && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} lg={5} sm={5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      // letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Enter Old Password
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    type="password"
                    onChange={(e) => {
                      setrePassword(e.target.value);
                      seterrRePass("");
                    }}
                    value={repassword}
                  />
                  {errRePass && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      {errRePass}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={2}
            md={3}
            style={{ display: "flex", alignItems: "flex-end",marginTop:"10px" }}
          >
            <Button
              size="small"
              variant="contained"
              color="warning"
              style={{
                height: "2rem",
                marginBottom: 19,
                padding: "20px 15px",
                fontSize: 14,
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SignUp;
