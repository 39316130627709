import { Grid, Typography } from "@mui/material";
import { fontFamily } from "@mui/system";
import React from "react";
import { FONTS, FONTS_ANOTON, FONTS_INTER, FONTS_RC } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CommentIcon from "@mui/icons-material/Comment";
import DeliverLabel from "../../Componets/DeliverLabel";
import { WEBSITE_CONTACT } from "../../config";
import STEPS from "../../Assets/steps.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";

import Steps1 from "../../Assets/steps/1.png";
import Steps2 from "../../Assets/steps/2.png";
import Steps3 from "../../Assets/steps/3.png";
import Steps4 from "../../Assets/steps/4.png";
import Steps5 from "../../Assets/steps/5.png";
import Steps6 from "../../Assets/steps/6.png";

import "./index.css"

function Meals() {
  const mySlider = React.useRef(null);

  const matches = useMediaQuery("(max-width:900px)");
  const matches2 = useMediaQuery("(max-width:600px)");
  const matches3 = useMediaQuery("(max-width:600px)");
  var matches4 = window.matchMedia("(max-width: 600px)");
  var matches340 = window.matchMedia("(max-width: 1340px)");

  const steArr = [
    Steps1, Steps2, Steps3, Steps4, Steps5, Steps6
  ]

  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: matches4 === true ? 1 : 1,
    slidesToScroll: matches4 === true ? 1 : 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };


  const TIMES_AND_FOODS = ({ meals, time }) => {
    return (
      <>
        <Grid
          xs={12}
          sm={9}
          style={{
            display: "flex",
            width: "100%",
            flexWrap: matches3 === true ? "wrap" : "nowrap",
            marginBottom: matches3 === true ? "5px" : "0px",
          }}
        >
          <Grid
            xs={5}
            sm={4}
            sx={{
              padding: {
                xs: "0px 2.5px 0px 0px",
                sm: "5px 2.5px 0px 5px",
              },
              display: "flex",
              flex: 1,
              width: "100%",
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: "black",
                padding: "8px 5px",
                color: "white",
                fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "10pt" : "1.3vW",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {meals}
            </Typography>
          </Grid>
          <Grid
            xs={7}
            sm={8}
            sx={{
              padding: {
                xs: "0px",
                sm: "5px 2.5px 0px 2.5px",
              },
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: "black",
                padding: matches3 === true ? "8px 0px" : "8px 0px 8px 15px",
                color: "white",
                fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "10pt" : "1.3vW",
                width: "100%",
              }}
            >
              {time}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={9}
          style={{
            display: "flex",
            width: "100%",
            flexWrap: matches3 === true ? "wrap" : "nowrap",
            marginBottom: matches3 === true ? "2.5px" : "0px",
          }}
        >
          <Grid
            xs={5}
            sm={4}
            sx={{
              padding: {
                xs: "0px 2.5px 0px 0px",
                sm: "2.5px 2.5px 0px 5px",
              },
              display: "flex",
              flex: 1,
              width: "100%",
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: COLORS.PrimaryBackground,
                padding: matches3 === true ? "8px 15px" : "8px 1.3vW",
                color: COLORS.Primary,
                // fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "12pt" : "1.3vW",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                width: "100%",

              }}
            >
              Tuesday 4 pm - 8 pm
            </Typography>
          </Grid>
          <Grid
            xs={7}
            sm={8}
            sx={{
              padding: {
                xs: "0px",
                sm: "2.5px 2.5px 0px 2.5px",
              },
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: COLORS.PrimaryBackground,
                padding: matches3 === true ? "8px 0px" : "8px 0px 8px 15px",
                color: COLORS.Primary,
                // fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "12pt" : "1.3vW",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                width: "100%",
              }}
            >
              Wednesday, Thursday & Friday meal packs
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={9}
          style={{
            display: "flex",
            width: "100%",
            flexWrap: matches3 === true ? "wrap" : "nowrap",
            marginBottom: matches3 === true ? "15px" : "0px",
          }}
        >
          <Grid
            xs={5}
            sm={4}
            sx={{
              padding: {
                xs: "0px 2.5px 0px 0px",
                sm: "2.5px 2.5px 0px 5px",
              },
              display: "flex",
              flex: 1,
              width: "100%",
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: COLORS.PrimaryBackground,
                padding: matches3 === true ? "8px 15px" : "8px 1.3vW",
                color: COLORS.Primary,
                // fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "12pt" : "1.3vW",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                width: "100%",

              }}
            >
              Friday 4 pm - 8 pm
            </Typography>
          </Grid>
          <Grid
            xs={7}
            sm={8}
            sx={{
              padding: {
                xs: "0px",
                sm: "2.5px 2.5px 0px 2.5px",
              },
            }}
          >
            <Typography
              variant={
                matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
              }
              style={{
                background: COLORS.PrimaryBackground,
                padding: matches3 === true ? "8px 0px" : "8px 0px 8px 15px",
                color: COLORS.Primary,
                // fontWeight: "bold",
                fontFamily: FONTS_RC,
                fontSize: "20.3px",
                fontSize: matches3 === true ? "12pt" : "1.3vW",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                width: "100%",
              }}
            >
              Saturday, Sunday, Monday & Tuesday meal packs
            </Typography>
          </Grid>
        </Grid>
      </>

    );
  };
  return (
    <>
      <Grid
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "10vh"
        }}
        sx={{
          margin: {
            sm: "30px 10vW",
            xs: "30px 0px"
          }
        }}
      >
        <Grid
          xs={11}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <Typography
            variant={
              matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
            }
            style={{
              color: COLORS.TextColor,
              marginBottom: "30px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
            }}
          >
            You can either collect or get delivered meal packs on every Tuesday and Friday of each week from our collection counter which is located at 8/11 Swift Way, Dandenong South VIC 3175.

          </Typography> */}
          {/* <Typography
            variant={
              matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
            }
            style={{
              color: COLORS.Primary,
              marginBottom: "30px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
            }}
          >
            Get your meal plans conveniently delivered to your doorstep with
            zero hassle. Freshness guaranteed. Our delivery agents will make
            sure your meals are delivered on time, anywhere you want.
          </Typography>
          <Typography
            variant={
              matches === true ? (matches2 === true ? "body2" : "body1") : "h6"
            }
            style={{
              color: COLORS.Primary,
              marginBottom: "30px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
            }}
          >
            We deliver freshly cooked meals pre-ordered by you right to your
            doorstep every day.
          </Typography> */}

          {/* <TIMES_AND_FOODS meals="Breakfast" time="Between 6.30 am & 9 am" />
          <TIMES_AND_FOODS meals="Lunch" time="Between 11 am & 2 pm" /> */}
          {/* <TIMES_AND_FOODS meals="Collection Date & Time" time="Type of Meal" /> */}



          {/* <Typography
            variant={"h6"}
            style={{
              // fontSize: "20px",
              padding: "0px",
              color: COLORS.Primary,
              fontFamily: FONTS_ANOTON,
              fontSize: "24pt",
              marginTop: "70px",
              width: "fit-content",
              textAlign: "left",
            }}
          >
           
            OPTION 1
          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TitleColor,
              marginTop: "0px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
           
            In-Store Pickup

          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
           
            At Ceylon Healthy Food, we understand that sometimes pickup is the more convenient option
            for our customers. That's why we're proud to offer in-store pickup for our customers. Our kitchen,
            located at 8/11 Swift Way, Dandenong South VIC 3175, is open for pickup between 4pm and 8pm on every
            Tuesday and Friday of each week.

          </Typography>
          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
           
            Simply provide your order number (found in your order confirmation email) to one of our
            friendly staff and collect your meal packs. No waiting in line, no delivery fees – just convenient
            and easy access to your nutritious meals.

          </Typography>
          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
          
            Sign up and create your weekly meal plan today and take advantage of the convenient option of in-store pickup.
          </Typography> */}


          {/* <Typography
            variant={"h6"}
            style={{
              // fontSize: "20px",
              padding: "0px",
              color: COLORS.Primary,
              fontFamily: FONTS_ANOTON,
              fontSize: "24pt",
              marginTop: "50px",
              width: "fit-content",
              textAlign: "left",
            }}
          >
          
            OPTION 2
          </Typography> */}

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TitleColor,
              marginTop: "0px",
              marginBottom: "0px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {/* If you require further clarifications please call our support team
            between 9 am and 5 pm all seven days. */}
            {/* Convenient Home Delivery */}

          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
            {/* If you require further clarifications please call our support team
            between 9 am and 5 pm all seven days. */}
            At Ceylon Healthy Food , we understand the importance of easy access to nutritious meals. That's why we're
            proud to offer home delivery for our valued customers. Simply select the delivery option
            during checkout and your order will be delivered to your doorstep on every Friday of each week.
          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
            {/* If you require further clarifications please call our support team
            between 9 am and 5 pm all seven days. */}
            Enjoy the convenience of having your meals delivered without the hassle of leaving your home. Our
            delivery service is reliable and efficient, ensuring that your meals arrive fresh and ready to enjoy.
            And with a low $5 fee per delivery, you can have the peace of mind knowing that your meals are being handled
            with care.

          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
            {/* If you require further clarifications please call our support team
            between 9 am and 5 pm all seven days. */}
            Don't miss out on this convenient and affordable delivery option. Create your weekly
            meal plan today and experience the convenience of home delivery.

          </Typography>

          <Typography
            variant={"body2"}
            style={{
              color: COLORS.TextColor,
              marginTop: "10px",
              marginBottom: "20px",
              fontFamily: FONTS_INTER,
              fontSize: matches3 === true ? "12pt" : "14pt",
              fontWeight: "500",
            }}
          >
            {/* If you require further clarifications please call our support team
            between 9 am and 5 pm all seven days. */}
            Note: Delivery is available only for selected suburbs. Our team is currently working to expand
            the delivery areas.

          </Typography>



          {/* <Typography

            sx={{
              display: {
                sm: "flex",
                xs: "none"
              }
            }}


          >

            <img
              className="mealsPlanImg"
              src={STEPS}
              alt="Steps"
              style={{
                width: "100%",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                // borderRadius: 18,
                borderRadius: 4,
                marginBottom: "3.5vw",
                cursor: "pointer",
              }}
            // height="250px"
            />


          </Typography>



          <div className="container33333"
            style={{
              display: matches4.matches === true ? "block" : "none"

            }}
          >

            <Slider ref={mySlider} {...settings2}>
              {steArr?.map((data, index) => {
                return (<div>

                  <Grid xs={12} >
                    <img
                      className="mealsPlanImg"
                      src={data}
                      alt="Steps"
                      style={{

                        objectFit: "cover",
                        width: "300px",
                        height: "600px",
                        marginTop: "10px",
                        // borderRadius: 18,

                        cursor: "pointer",
                      }}
                    // height="250px"
                    />

                  </Grid>
                </div>);
              })}


            </Slider>

            <Grid
              xs={12}
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
              sx={{
                display: {
                  sm: "none",
                  xs: "flex",
                },
              }}
            >
              <div
                style={{
                  background: COLORS.Primary,
                  display: "grid",
                  placeItems: "center",
                  padding: 6,
                  borderRadius: "50%",
                  marginRight: 5,
                }}
                onClick={() => {
                  mySlider?.current?.slickPrev();
                  // window.document.querySelector(
                  //   "#areas"
                  // ).scrollLeft -= 100;
                }}
              >
                <KeyboardArrowLeftIcon
                  style={{
                    fontSize: 24,
                    color: "white",
                  }}

                />
              </div>

              <div
                style={{
                  background: COLORS.Primary,
                  display: "grid",
                  placeItems: "center",
                  padding: 6,
                  borderRadius: "50%",
                }}
                onClick={() => {
                  // window.document.querySelector(
                  //   "#areas"
                  // ).scrollLeft += 100;
                  mySlider?.current?.slickNext();
                }}
              >
                <KeyboardArrowRightIcon
                  style={{
                    fontSize: 24,
                    color: "white",
                  }}
                />
              </div>
            </Grid>
          </div> */}


        </Grid>

      </Grid>
      <Grid xs={12}>
        <Grid
          xs={12}
          container
          spacing={0}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -50,
          }}
        >
          <DeliverLabel />
        </Grid>
      </Grid>
      {matches340.matches !== true ? <div style={{ marginBottom: "50vh" }}></div> : <></>}

    </>
  );
}

export default Meals;
