import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Grid,
  createTheme,
  Modal,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useHistory, Link } from "react-router-dom";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";

import { withStyles } from "@material-ui/core/styles";

import { FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";

import ErrorPopup from "./errorPopup.jsx";

import "./login.css";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { CurrentUser, getCities, getDistricts } from "../../Services/API/Get";
import { addDeliveryPartners } from "../../Services/API/Post";
import CloseIcon from "@mui/icons-material/Close";

function RPopup({ open, handleClose }) {
  var mat425 = window.matchMedia("(max-width: 600px)");
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "10.5pt",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: 500,
        outline: "none",

        height: "1.4rem",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        background: "rgba(232,232,232,0.5)",
      },
    },
  }));
  const classes = useStyles();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "750px",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "23px 10px",
    borderRadius: "4px",
    maxHeight: "99vh",
    overflowY: "scroll",
    minHeight: "573px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  const GreenCheckbox = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
      marginLeft: "0.5rem",
      padding: 0,
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const history = useHistory();
  const [lOpen, setlOpen] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState("");
  const [errofficeNo, seterrofficeNo] = useState("");
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [officeDistrictId, setofficeDistrictId] = useState(0);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);
  const [allOCities, setallOCities] = useState([]);
  const [allODistricts, setallODistricts] = useState([]);
  const [homepostCode, sethomepostCode] = useState("");
  const [officepostCode, setofficepostCode] = useState("");
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  // const [check1, setcheck1] = useState(0);
  // const [check2, setcheck2] = useState(0);
  // const [check3, setcheck3] = useState(0);
  let check1 = 0;
  let check2 = 0;
  let check3 = 0;

  const handleChangeHdist = (e, value) => {
    sethomeDistrict(value.name_en);
    seterrhomeDistrict(false);
    sethomeDistrictId(value.id);

    console.log("state>>", value.name_en);
  };
  const handleChangeHcity = (e, value) => {
    sethomeCity(value.name_en);
    sethomepostCode(value.postcode);
    seterrhomeCity(false);
    seterrhomepostCode(false);

    console.log("city>>", value.name_en);
  };

  const onSubmit2 = async (e) => {
    e.preventDefault();

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeNo === "") {
      seterrhomeNo("This Field is Requied");
    }
    if (mobile === "") {
      seterrmobile("This Field is Requied");
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    // if(password === ""){
    //   seterrPass(true)
    // }
    // if(repassword === ""){
    //   seterrRePass("This Field is Requied")
    // }
    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      contactName: firstName,
      district: homeDistrict,
      city: homeCity,
      email: email,
      dayTime_contactNo: mobile,
      driverlicence: check1 === true ? 1 : 0,
      motorinsurance: check2 === true ? 1 : 0,
      policyclearance: check3 === true ? 1 : 0,
      country: "Australia",
      postcode: homepostCode,
    };
    console.log("obj>>>>", obj);

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }
    if (!phoneRegex.test(homeNo)) {
      if (homeNo === "") {
        seterrhomeNo("This Field is Requied");
      } else {
        seterrhomeNo("Please Enter Valid number");
      }
    }
    if (officeNo !== "") {
      if (!phoneRegex.test(officeNo)) {
        seterrofficeNo("Please Enter Valid number");
      }
    }

    if (
      firstName !== "" &&
      //   lastName !== "" &&
      email !== "" &&
      //   homeAddress !== "" &&
      homeDistrict !== "" &&
      homepostCode !== "" &&
      homeCity !== "" &&
      //   homeNo !== "" &&
      mobile !== "" &&
      phoneRegex.test(mobile)
      //   phoneRegex.test(homeNo) &&
      //   ( officeNo !== "" ? phoneRegex.test(officeNo) : true)
    ) {
      addDeliveryPartners(obj)
        .then((res) => {
          handleClose(false);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Registered successfully");
          setfirstName("");
          setmobile("");
          setemail("");
          sethomeNo("");
          setofficeNo("");
          sethomeAddress("");
          sethomeCity("");
          sethomeDistrict("");
          sethomepostCode("");
          check1 = 0;
          check2 = 0;
          check3 = 0;
          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          console.log("Error", error.response);
          setOpenToast(true);
          setColor("red");
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    } else {
      //   scrollElement();
    }
  };

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res.data);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };
  const listOfficecities = () => {
    getCities(officeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallOCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };
  React.useEffect(() => {
    listDistricts();
  }, []);
  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);
  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listOfficecities();
    }
  }, [officeDistrictId]);

  return (
    <div>
      <LoadingSpinner open={lOpen} />
      <ErrorPopup
        openToast={openToast}
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Grid
              style={{
                width: "100%",
                justifyContent: "flex-end",
                padding: "0px 20px",
              }}
              sx={{
                display: {
                  sm: "none",
                  xs: "flex",
                },
              }}
            >
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // padding: "40px 0px",
              }}
            >
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <Avatar sx={{ m: 1, bgcolor: COLORS.Orange }}>
                  <PersonOutlineIcon 
                  />
                </Avatar> */}
                <Typography></Typography>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    fontSize: "18pt",
                    marginTop: "2%",
                  }}
                >
                  Registration Form
                </Typography>
                <Grid md={11} xs={12} style={{ width: "100%" }}>
                  <Typography
                    style={{
                      fontFamily: FONTS_INTER,
                      // fontWeight: "bold",
                      fontSize: "12pt",
                      display: "flex",
                      justifyContent: "center",
                      color: COLORS.TextColor,
                      textAlign: "center",
                      padding: "0px 10px",
                    }}
                  >
                    Please fill this form and submit. One of our team members
                    will contact you.
                  </Typography>
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // marginTop: "25px",
                    padding: "15px 40px",
                    background: "white",
                  }}
                >
                  <Grid
                    item
                    md={5.5}
                    xs={12}
                    style={{
                      width: "100%",
                      maxWidth: mat425.matches ? "100%" : "45%",
                    }}
                  >
                    <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          // variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Contact Name *
                        </Typography>
                        <input
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "10.5pt",
                            padding: "4px 10px",
                            outline: "none",
                            borderRadius: 3,
                            height: "2rem",
                            border: "none",
                            background: COLORS.GrayDark3,
                            color: COLORS.TextColor2,
                          }}
                          onChange={(e) => {
                            setfirstName(e.target.value);
                            seterrfirstName(false);
                          }}
                          value={firstName}
                        />
                        {errfirstName && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={12} style={{ margin: "15px 0px" }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          State *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            defaultValue={{ name_en: homeDistrict }}
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeHdist(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "310px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>

                        {errhomeDistrict && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      style={{ padding: 0, margin: "20px 0px" }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Suburb *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            defaultValue={{ name_en: homeCity }}
                            options={allHCities}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeHcity(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "310px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errhomeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      style={{ padding: 0, margin: "20px 0px" }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Postcode *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              width: mat425.matches === true ? "94.2%" : "94%",
                            }}
                            onChange={(e) => {
                              sethomepostCode(e.target.value);
                              seterrhomepostCode(false);
                            }}
                            value={homepostCode}
                          />
                        </div>
                        {errhomepostCode && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Day time contact number *
                        </Typography>
                        <input
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "10.5pt",
                            padding: "4px 10px",
                            outline: "none",
                            borderRadius: 3,
                            height: "2rem",
                            border: "none",
                            background: COLORS.GrayDark3,
                            color: COLORS.TextColor2,
                          }}
                          onChange={(e) => {
                            setmobile(e.target.value);
                            seterrmobile("");
                          }}
                          value={mobile}
                        />
                        {errmobile && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            {errmobile}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} style={{ margin: "20px 0px" }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Valid email address *
                        </Typography>
                        <input
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "10.5pt",
                            padding: "4px 10px",
                            outline: "none",
                            borderRadius: 3,
                            height: "2rem",
                            border: "none",
                            background: COLORS.GrayDark3,
                            color: COLORS.TextColor2,
                          }}
                          onChange={(e) => {
                            setemail(e.target.value);
                            seterremail(false);
                          }}
                          value={email}
                        />
                        {erremail && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={5.5}
                    xs={12}
                    style={{
                      width: "100%",
                      maxWidth: mat425.matches ? "100%" : "45%",
                    }}
                  >
                    <Grid md={12} xs={12} style={{ margin: "20px 0px" }}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              size="medium"
                              onChange={(e) => {
                                check1 = e.target.checked;
                                console.log("check 01 ", check1);
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: "10pt",
                                fontFamily: FONTS_INTER,
                                marginTop: "15px",
                                marginLeft: "5px",
                                color: COLORS.TextColor,
                              }}
                            >
                              I have a valid drivers license to drive in
                              Australia.
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              size="medium"
                              onChange={(e) => {
                                check2 = e.target.checked;
                                console.log("check 02 ", check2);
                                // if (e.target.checked === true) {
                                //   setcheck2(1);
                                // } else {
                                //   setcheck2(0);
                                // }
                              }}
                              // checked={this.state.checkedValues.includes(clients.orderOptions.createdAt)}
                              // defaultChecked={this.state.temp}

                              defaultChecked={false}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: "10pt",
                                fontFamily: FONTS_INTER,
                                marginTop: "15px",
                                marginLeft: "5px",
                                color: COLORS.TextColor,
                              }}
                            >
                              My vehicle has a valid motor insurance cover.
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              size="medium"
                              onChange={(e) => {
                                check3 = e.target.checked;
                                console.log("check 03 ", check3);
                              }}
                              // checked={this.state.checkedValues.includes(clients.orderOptions.createdAt)}
                              // defaultChecked={this.state.temp}

                              defaultChecked={false}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: "10pt",
                                fontFamily: FONTS_INTER,
                                marginTop: "15px",
                                marginLeft: "5px",
                                color: COLORS.TextColor,
                              }}
                            >
                              I am ready to provide police clearance check.
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid md={12} xs={12} style={{ margin: "30px 0px" }}>
                      <Box textAlign="center">
                        <Button
                          // fullWidth
                          variant="contained"
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "12pt",
                            color: "white",
                            background: COLORS.Primary,
                            borderRadius: 4,
                            width: "175px",
                            height: "40px",
                            border: "none",
                            marginTop: 10,
                            cursor: "pointer",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                          onClick={onSubmit2}
                        >
                          Submit Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
}

export default RPopup;
