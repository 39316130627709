import { Button, Grid, TextField, Typography } from "@mui/material";
import { borderRadius, lineHeight } from "@mui/system";
import React, { useState } from "react";
import { FONTS } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { RegisterUser } from "../../Services/API/Post";
import { useHistory, Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CurrentUser,getMemberbyId} from "../../Services/API/Get";


function SignUp() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = React.useState("b");

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [userId, setuserId] = useState("");
  const [members,setMembers] = useState([]);
  const [member, setMember] = useState("");


  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [officeCity, setofficeCity] = useState("");
  const [insurer, setInsurer] = useState("");
  const [policy, setPolicy] = useState("");
  const [entitlement, setentitlement] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");

  const [idisable, setidisable] = useState(true);

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState(false);
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState(false);
  const [errofficeNo, seterrofficeNo] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [errofficeAddress, seterrofficeAddress] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errofficeCity, seterrofficeCity] = useState(false);
  const [errinsurer, seterrInsurer] = useState(false);
  const [errpolicy, seterrPolicy] = useState(false);
  const [errentitlement, seterrentitlement] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");


  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: '#f44336',
      },
    },
  });

  const handleChange = (event) => {

    setSelectedValue(event.target.value);
    if (event.target.value === "b") {
      setidisable(true)
      setInsurer("")
      setPolicy("")
      setentitlement("")
    } else {
      setidisable(false)
    }

  };


  const handleClose = () => {
    setOpenToast(false);
  };

  const handleChangeMember = (e, value) => {
    console.log("<><>",value)
    // setMember(value.firstName)
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true)
    }
    if (lastName === "") {
      seterrlasttName(true)
    }
    if (email === "") {
      seterremail(true)
    }
    if (homeAddress === "") {
      seterrhomeAddress(true)
    }
    if (homeCity === "") {
      seterrhomeCity(true)
    }
    if (homeNo === "") {
      seterrhomeNo(true)
    }
    if (mobile === "") {
      seterrmobile(true)
    }
    if (password === "") {
      seterrPass(true)
    }
    if (repassword === "") {
      seterrRePass("This Field is Requied")
    }
    if (password != "" && repassword != "" && !(password === repassword)) {
      seterrRePass("Password Mismatch")
    }
    if (password != "" && repassword != "" && (password === repassword)) {
      seterrRePass("")
    }

    let obj = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobile,
      email: email,
      homeNumber: homeNo,
      officeNumber: officeNo,
      homeAddress: homeAddress,
      officeAddress: officeAddress,
      homeCity: homeCity,
      officeCity: officeCity,
      insurer: insurer,
      policyNo: policy,
      entitlement: entitlement,
      password: password
    }
    console.log("obj>>>>", obj)

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homeCity !== "" &&
      homeNo !== "" &&
      mobile !== "" &&
      password !== "" &&
      repassword !== "" &&
      (password === repassword)
    ) {
      RegisterUser(obj)
        .then((res) => {
          setOpenToast(true)
          setColor(COLORS.Primary)
          setAlertClass("success");
          setMsg("Register Successful")

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          console.log("Error", error.response)
          setOpenToast(true)
          setColor("red");
          setAlertClass("error");
          setMsg("Error SignUp")


        });

    }

  };

  const InputField = ({ title, id, value, error }) => {
    return (

      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>

        <Typography
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              fontFamily: FONTS,
              color: COLORS.TextColor,
            }}
            gutterBottom
          >
            {title}
          </Typography>
          <input
            style={{
              fontFamily: FONTS,
              fontSize: 14,
              color: "#55555",
              padding: "4px 10px",
              outline: "none",
              border: "1px solid #666",
              borderRadius: 3,
            }}

          />
          {error && (
            <Typography
              variant="caption"
              color="red"
              style={{
                textAlign: "left",
                fontFamily: FONTS,
                margin: "5px 0px",
              }}
            >
              This Field is Requied
            </Typography>
          )}
        </Typography>
      </Grid>
    );
  };


  const getMembers  = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          setuserId(cData?.id)
          getMemberbyId( cData.id)
          .then((res) => {
            console.log(res)
            setMembers(res?.data)        
          })
          .catch((error) => {
            console.log("error",error)
            
          });
          // setfirstName(cData?.firstName)
          // setlasttName(cData?.lastName)
          // setemail(cData?.email)
          // sethomeAddress(cData?.homeAddress)
          // sethomeCity(cData?.homeCity)
          // setofficeAddress(cData?.officeAddress)
          // setofficeCity(cData?.officeCity)
          // setmobile(cData?.mobileNumber)
          // sethomeNo(cData?.homeNumber)
          // setofficeNo(cData?.officeNumber)
          // setInsurer(cData?.insurer)
          // setPolicy(cData?.policyNo)
          // setentitlement(cData?.entitlement)

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
          console.log("error",error)
        console.log("current user error", error.response.data.message);
      });
     
  }

  React.useEffect(() => {
    getMembers()
  }, []);

  return (
    <React.Fragment>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 0px",
        }}
      >
        <Grid md={10} xs={11} style={{ width: "100%" }}>


          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <Grid md={7} sm={10} xs={12} lg={5} style={{ width: "100%" }}>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.Primary,
                  textAlign: "left",
                  letterSpacing: 1,
                  marginTop: 10,
                }}
              >
                Do you want to create meal plans for your family members ?
              </Typography>
              {/* <Typography
                variant="caption"
                style={{
                  textAlign: "left",
                  float: "left",
                  color: COLORS.TextColor,
                }}
              >
                (If "YES", please fill the below section and "check" your
                eligibility to get discounts. )
              </Typography> */}
            </Grid>
            <Grid
              md={5}
              sm={2}
              xs={12}
              lg={7}
              style={{ width: "100%", display: "flex", justifyContent: "left" }}
            >
              <div style={{ display: "flex", alignItems: "center", }}>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  style={{
                    color: COLORS.Primary,
                  }}
                />
                <Typography variant="body2" style={{ color: COLORS.Primary }}>
                  Yes
                </Typography>
                <Radio
                  checked={selectedValue === "b"}
                  onChange={handleChange}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  style={{
                    color: COLORS.Primary,
                  }}
                />
                <Typography variant="body2" style={{ color: COLORS.Primary }}>
                  No
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "stretch",
              marginTop: 0,
            }}
          >
            <Grid item xs={12} md={7} lg={5} sm={5.5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                  disabled={idisable}
                    id="country-select-demo"
                    sx={{ width: "100%" }}
                    options={members}
                    onChange={(event, newValue) => { handleChangeMember(event, newValue) }}
                    autoHighlight
                    getOptionLabel={(option) => option.firstName +" "+ option.lastName}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.firstName + " " + option.lastName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select your Family Member"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </ThemeProvider>

              </Grid>
            </Grid>
            <Grid
              md={2}
              sm={2}
              xs={6}
              lg={1}
              
            >
               <Box textAlign='left'>
              <Button
                disabled={idisable}
                size="small"
                variant="contained"
                style={{
                  background: "#808182",
                  height: "34px",
                  width: "120px",
                  marginTop:25,
                  marginLeft:10,
                  fontFamily: FONTS,
                  color: "yellow",
                  
                }}
              >
                SELECT PLAN
              </Button>
              </Box>
            </Grid>
            <Grid
              md={2}
              sm={2}
              xs={6}
              lg={1}
              
            >
         
                 <Box textAlign='left'>
                 <Link to="/add-member"
                 style={{
                  textDecorationLine: "none",
                  
                }}
              >
              <Button
                disabled={idisable}
                size="small"
                variant="contained"
                style={{
                  background: "#c1e1ec",
                  height: "34px",
                  width: "120px",
                  marginTop:25,
                  marginLeft:35,
                  fontFamily: FONTS,
                  color: "#808182",
                  
                }}
              >
                CREATE
              </Button>
              
              </Link>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5} md={1}>

              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>

                {/* <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS,
                      color: COLORS.TextColor,
                    }}
                    gutterBottom
                  >
                    Your entitlement
                  </Typography>
                  <input
                    disabled={idisable}
                    style={{
                      fontFamily: FONTS,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid #666",
                      borderRadius: 3,
                    }}
                    onChange={(e) =>
                      setentitlement(e.target.value)
                    }
                    value={entitlement}

                  />
                  {false && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS,
                        margin: "5px 0px",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography> */}
              </Grid>
            </Grid>
          </Grid>
{/* 
          <Grid
            item
            xs={12}
            lg={2}
            md={3}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Button
              size="small"
              variant="contained"
              color="warning"
              style={{ height: "34px", marginBottom: 14 }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SignUp;
