import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { COLORS } from "../../Constants/Colors";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Snackbar } from "@material-ui/core";
import { Alert, OutlinedInput } from "@mui/material";
import { Container } from "react-bootstrap";
import { FONTS_AC, FONTS_INTER } from "../../Constants";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { FormGroup, Form } from "reactstrap";
import { resetPassword } from "../../Services/API/Post";
import {
  Card,
  CardContent,
  Divider,
  getCardUtilityClass,
  Grid,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { getPackages } from "../../Services/API/Get";
import { packageToUser } from "../../Services/API/Put";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CARDIMAGE from "../../Assets/card.png";
import PaymentImages from "../../Assets/aa.png";
import InfoIcon from "@mui/icons-material/Info";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ErrorPopup from "./errorPopup.jsx"
export default function CardChoice({ open, handleClose, id }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const MONTHS = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const history = useHistory();
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const [expireYear, setExpireYear] = useState([]);

  const [cNumber, setCNumber] = useState("");
  const [cNumberErr, setCNumberErr] = useState(false);

  const [cName, setCName] = useState("");
  const [cNameErr, setCNameErr] = useState(false);

  const [mm, setMm] = useState("");
  const [mmErr, setMmErr] = useState(false);

  const [yy, setYy] = useState("");
  const [yyErr, setYyErr] = useState(false);

  const [cvv, setCvv] = useState("");
  const [cvvErr, setCvvErr] = useState(false);

  const [packages, setPackages] = useState([]);
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId) => {
    console.log("pkg id >>>", pkgId);

    const obj = {
      packageId: pkgId,
    };

    packageToUser(obj, id)
      .then((res) => {
        console.log(res.data);
        setOpenToast(true);
        setColor(COLORS.Primary);
        setAlertClass("success");
        setMsg("Package Assigned to User");
        setTimeout(() => {
          history.push("/plans");
        }, 2000);
      })
      .catch((error) => {
        console.log("error", error.response);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };
  useEffect(() => {
    const d = `${new Date().getFullYear()}`.split(0)[1];
    let newArray = [];
    for (let i = parseInt(d); i < parseInt(d) + 10; i++) {
      newArray.push(i);
    }
    console.log(newArray);
    setExpireYear(newArray);
  }, []);
  return (
    <>
    <ErrorPopup openToast={openToast} 
    // handleClose={openToast === true ? setTimeout(() => {setOpenToast(false);}, 5000) : false} 
    handleClose={()=> setOpenToast(false)}
    alertClass={alertClass} msg={msg} />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90vw",
              md: "90vw",
              lg: "75vw",
              sm: "70vw",
            },
            maxHeight: "98vh",
            // overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            sx={{
              // width: {
              //   xs: "95vh",
              //   md: "50px",
              // },
              padding: "30px",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={12}
                container
                spacing={2}
                // sx={{
                //   padding: {
                //     xs: 0,
                //     sm: "10px",
                //     md: "50px",
                //   },
                // }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                    display: "flex",
                    margin: "10px 0px ",
                    justifyContent: "space-between",
                  }}
                >
                  <div onClick={handleClose}>
                    <ArrowBackIcon />
                  </div>
                  <div
                    style={{
                      color: COLORS.Primary,
                      textAlign: "center",
                      fontSize: "13pt",
                      fontFamily: FONTS_INTER,
                      fontWeight: "bold",
                    }}
                    sx={{
                      flexWrap: "nowrap",
                    }}
                  >
                    Provide further information
                  </div>
                  <div></div>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                >
                  <img
                    src={CARDIMAGE}
                    style={{
                      width: "30px",
                    }}
                  />
                  {/* <CARDIMAGE/> */}
                  <div
                    style={{
                      fontSize: "13pt",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontWeight: "bold",
                      marginLeft: 10,
                    }}
                  >
                    Credit/Debit Card
                  </div>
                  <img
                    src={PaymentImages}
                    style={{
                      height: "16px",
                      marginLeft: 10,
                    }}
                  />
                </Grid>
                <Grid
                  xs={12}
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Grid
                    sm={6}
                    xs={12}
                    style={{ margin: "15px 0px", padding: "0px 5% 0px 0px" }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        // variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Card Number
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: 14,
                          color: "#55555",
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          borderColor: COLORS.InputBorderColor,
                          borderRadius: 3,
                          height: "2rem",
                        }}
                        onChange={(e) => {
                          setCNumber(e.target.value);
                          setCNumberErr(false);
                        }}
                        value={cNumber}
                      />
                      {cNumberErr && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    sm={6}
                    xs={12}
                    style={{ margin: "15px 0px", padding: "0px 5% 0px 0px" }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        // variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Cardholder Name
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: 14,
                          color: "#55555",
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          borderColor: COLORS.InputBorderColor,
                          borderRadius: 3,
                          height: "2rem",
                        }}
                        onChange={(e) => {
                          setCName(e.target.value);
                          setCNameErr(false);
                        }}
                        value={cName}
                      />
                      {cNameErr && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Grid
                    sm={6}
                    xs={12}
                    style={{
                      margin: "15px 0px",
                      padding: "0px 5% 0px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid sm={5.5} xs={12}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          // variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          MM
                        </Typography>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          // multiple
                          value={mm}
                          onChange={(e) => setMm(e.target.value)}
                          // input={<OutlinedInput label="Name" />}
                          // MenuProps={MenuProps}
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: 14,
                            color: "#55555",
                            // padding: "4px 10px",
                            outline: "none",
                            // border: "1px solid ",
                            borderColor: COLORS.InputBorderColor,
                            borderRadius: 3,
                            height: "2rem",
                          }}
                        >
                          {MONTHS.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <input
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: 14,
                            color: "#55555",
                            padding: "4px 10px",
                            outline: "none",
                            border: "1px solid ",
                            borderColor: COLORS.InputBorderColor,
                            borderRadius: 3,
                            height: "2rem",
                          }}
                          onChange={(e) => {
                            // setfirstName(e.target.value);
                            // seterrfirstName(false);
                          }}
                          // value={firstName}
                        /> */}
                        {mmErr && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Typography variant="body1">/</Typography>
                    <Grid sm={5.5} xs={12}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          // variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          YY
                        </Typography>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          // multiple
                          value={yy}
                          onChange={(e) => setYy(e.target.value)}
                          // input={<OutlinedInput label="Name" />}
                          // MenuProps={MenuProps}
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: 14,
                            color: "#55555",
                            // padding: "4px 10px",
                            outline: "none",
                            // border: "1px solid ",
                            borderColor: COLORS.InputBorderColor,
                            borderRadius: 3,
                            height: "2rem",
                          }}
                        >
                          {expireYear.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <input
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: 14,
                            color: "#55555",
                            padding: "4px 10px",
                            outline: "none",
                            border: "1px solid ",
                            borderColor: COLORS.InputBorderColor,
                            borderRadius: 3,
                            height: "2rem",
                          }}
                          onChange={(e) => {
                            // setfirstName(e.target.value);
                            // seterrfirstName(false);
                          }}
                          // value={firstName}
                        /> */}
                        {yyErr && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    sm={6}
                    xs={12}
                    style={{ margin: "15px 0px", padding: "0px 5% 0px 0px" }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        // variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        CVV
                      </Typography>
                      <input
                        type="number"
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: 14,
                          color: "#55555",
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          borderColor: COLORS.InputBorderColor,
                          borderRadius: 3,
                          height: "2rem",
                        }}
                        onChange={(e) => {
                          setCvv(e.target.value);
                          setCvvErr(false);
                        }}
                        // value={firstName}
                      />
                      {cvvErr && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Radio style={{ color: COLORS.Primary }} size="small" />
                  <Typography
                    style={{
                      fontSize: "13pt",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      // marginLeft: 10,
                    }}
                  >
                    Save card details
                  </Typography>
                </Grid>
                <Grid xs={12} style={{ display: "flex", alignItems: "center" }}>
                  {/* <Radio style={{ color: COLORS.Primary }} size="small" /> */}
                  <InfoIcon
                    style={{
                      color: COLORS.TextColor,
                      fontSize: "20px",
                      marginLeft: "8px",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "13pt",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      marginLeft: 10,
                    }}
                  >
                    Your order will be processed in USD
                  </Typography>
                </Grid>
                <Divider
                  style={{
                    width: "100%",
                    margin: "20px 0px",
                  }}
                />
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    padding: "20px 8px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <BeenhereIcon />
                    <Typography
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        color: COLORS.TitleColor,
                        marginLeft: 10,
                      }}
                    >
                      Your Payment information is safe with us
                    </Typography>
                  </div>
                  <Button
                    style={{
                      background: COLORS.Orange,
                      color: "white",
                      borderRadius: "30px",
                      padding: "8px 20px",
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                    }}
                  >
                    Save & Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
