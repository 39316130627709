import React from "react";
import IMG from "../../../Assets/sample.png";

import R from "../../../Assets/HOWITWORKS/R.jpg";
import D from "../../../Assets/HOWITWORKS/D.png";
import M from "../../../Assets/HOWITWORKS/M.jpg";
import P from "../../../Assets/HOWITWORKS/P.jpg";
import S from "../../../Assets/HOWITWORKS/G.jpg";

import { COLORS } from "../../../Constants/Colors";
import { Grid, Typography } from "@mui/material";
import { FONTS_AC, FONTS_INTER } from "../../../Constants";
import "./steps.css";

function Steps() {
  var matches = window.matchMedia("(max-width: 600px)");
  const LeftSide = ({ step, title, content, source }) => {
    return (
      <Grid
        container
        xs={10}
        sm={12}
        style={{
          background: "#E0F2F1",
          width: "100%",
          padding: "10px",
          margin: matches.matches ? 0:"30px 0px",
          display: "flex",
          flexDirection:matches.matches ?"column":"row",
          alignItems: "center",
          flexWrap: "nowrap",
          marginTop:10
        }}
     
      >
        <Grid
          item
          xs={12}
          sm={7}
          md={8.5}
          lg={8}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: matches?.matches ?"center":"flex-start",
            padding:matches?.matches ?"30px":"30px 50px",
          }}
        
        >
          <Typography
            variant="body2"
            style={{
              background: COLORS.Orange,
              color: "white",
              width: "fit-content",
              padding: "8px 18px",
              borderRadius: "20px",
              fontFamily: FONTS_INTER,
              fontSize: "16pt",
            }}
          >
            STEP {step}
          </Typography>
          <Typography
            variant="h6"
            style={{
             
              padding: "10px 0px",
              fontWeight: "bolder",
              color: COLORS.Primary,
              // textTransform: "inherit",
              fontFamily: FONTS_AC,
              fontSize: "22pt",
              letterSpacing: 2.5,
              textAlign:matches?.matches ?"center":"left"
            
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: "#666",
              fontFamily: FONTS_INTER,
              fontSize: "13pt",
              width: "90%",
              textAlign:matches?.matches ?"center":"left"
            
            }}
         
          >
            {content}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          sm={5}
          md={3.5}
          lg={4}
          style={{
            height: matches?.matches ?"100%":"262px"
          }}
        >
          <img
            src={source}
            style={{
              width: "100%",
              objectFit: "cover",
              padding: "0px ",
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const RightSide = ({ step, title, content, source }) => {
    return (
      <Grid
        container
        xs={10}
        sm={12}
        style={{
          width: "100%",
          padding: "10px",
          margin: matches.matches ?0:"30px 0px 93px",
          display: "flex",
          flexDirection:matches.matches ?"column":"row",
          alignItems: "center",
          flexWrap: "nowrap",
          marginTop:10,
          background: matches.matches ?COLORS.GrayDark3:"white"
        }}
        sx={{
         
        }}
      >
        <Grid
          item
          xs={10}
          sm={5}
          md={3.5}
          lg={4}
          style={{
            display:matches?.matches?"none":"block",
            height: matches?.matches ?"100%":"262px"
          }}
        >
          <img
            className="rightSideImgeSteps"
            src={source}
            style={{ width: "100%", objectFit: "cover", height: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8.5}
          lg={8}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems:matches?.matches ?"center":'flex-start',
            padding: matches?.matches ?"30px":"30px 82px",
          }}
         
        >
          <Typography
            variant="body2"
            style={{
              background: "#FF9201",
              color: "white",
              width: "fit-content",
              padding: "8px 18px",
              borderRadius: "20px",
              fontFamily: FONTS_INTER,
              fontSize: "16pt",
            }}
            
          >
            STEP {step}
          </Typography>
          <Typography
            variant="h6"
            style={{
           
              padding: "10px 0px",
              fontWeight: "bolder",
              color: COLORS.Primary,
              // textTransform: "capitalize",
              fontFamily: FONTS_AC,
              fontSize: "22pt",
              letterSpacing: 2.5,
              textAlign:matches?.matches ?"center":"left"
            }}
            
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              
              color: "#666",
              fontFamily: FONTS_INTER,
              fontSize: "13pt",
              textAlign:matches?.matches ?"center":"left"
            }}
           
          >
            {content}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          sm={5}
          md={3.5}
          lg={4}
          style={{
            display:matches?.matches ?"block":"none",
          }}
        >
          <img
            className="rightSideImgeSteps"
            src={source}
            style={{ width: "100%", objectFit: "contain", height: "100%" }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      md={9}
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px 0px",
      }}
    >
      <LeftSide
        step={1}
        title="Register"
        content=
    //     "
    // Choose your meal plan and select meals from our weekly
    // changing menu with over 50 options to choose from. Place your
    // order by our Wednesday 11:30PM cut off to start receiving
    // meals over the weekends."
    "Your account can keep your meals on track and your deliveries in line. By creating an account, you can gain access to our meal plans to select( create) the best one for you and your loved ones. "
        source={R}
      />
      <RightSide
        step={2}
        title="Select a Package"
        content=
//         "Choose your meal plan and select meals from our weekly
// changing menu with over 50 options to choose from. Place your
// order by our Wednesday 11:30PM cut off to start receiving
// meals over the weekends."
"Choose the ideal meal package for your week depending on your requirement. "
        source={S}
      />
      <LeftSide
        step={3}
        title="Create a Meal Plan"
        content=
    //     "
    // Choose your meal plan and select meals from our weekly
    // changing menu with over 50 options to choose from. Place your
    // order by our Wednesday 11:30PM cut off to start receiving
    // meals over the weekends."
    "Choose from our 3 meal plans available for  Weight Control, Cholesterol Control, and Diabetic Control."
        source={M}
      />
      <RightSide
        step={4}
        title="Pay"
        content=
//         "Choose your meal plan and select meals from our weekly
// changing menu with over 50 options to choose from. Place your
// order by our Wednesday 11:30PM cut off to start receiving
// meals over the weekends."
"We only accept online payments via our secure payment gateways. Your payment confirmation means your food is on the way!"
        source={P}
      />
      <LeftSide
        step={5}
        title="Get Delivered"
        content=
    //     "
    // Choose your meal plan and select meals from our weekly
    // changing menu with over 50 options to choose from. Place your
    // order by our Wednesday 11:30PM cut off to start receiving
    // meals over the weekends."
    "You can get delivered meal packs on every Friday of each week from our collection counter which is located at 8/11 Swift Way, Dandenong South VIC 3175."
        source={D}
      />
      {/* <RightSide
        step={6}
        title="To be decided"
        content="Choose your meal plan and select meals from our weekly
changing menu with over 50 options to choose from. Place your
order by our Wednesday 11:30PM cut off to start receiving
meals over the weekends."
      /> */}
    </Grid>
  );
}

export default Steps;
