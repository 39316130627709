import React, { useState, useRef } from "react";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
// import { Button, Grid, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
// import Divider from "@mui/material/Divider";

import { FONTS, FONTS_AC, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";

import { CurrentUser, getDistricts, getCities, getSignUpVerification, getPackages } from "../../Services/API/Get";
import { downPackageToUser, EditUserQuery, packageToMember, packageToUser, UpdateUserwithemail } from "../../Services/API/Put";
import ErrorPopup from "../Models/errorPopup.jsx";

import IconButton from "@material-ui/core/IconButton";
import { IMAGE_SERVER_URL_DEV2 } from "../../Constants/System/index.js";

import "./profile.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { upperFirst, lowerCase } from 'lodash';
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner";
import { RegisterUser, signUpVerification } from "../../Services/API/Post";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";

import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Box,
  Modal,
  TextField
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import ErrorIcon from "@mui/icons-material/Error";


function MainProfile({ mainProfile, setMainProfile, data }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxWidth: 300,
  };

  const GreenCheckbox = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
      marginLeft: "0.5rem",
      padding: 0,
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  var matches = window.matchMedia("(max-width: 600px)");
  var matches900 = window.matchMedia("(max-width: 900px)");
  var matches1200 = window.matchMedia("(max-width: 1200px)");

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.PrimaryBackground,
      },
      secondary: {
        main: COLORS.PrimaryBackground,
      },
    },
  });

  const [isStudent, setisStudent] = useState(false);
  let check1 = 0;

  const history = useHistory();
  const errScroll = useRef();
  const scrollElement = () => errScroll.current.scrollIntoView();

  const [selectedValue, setSelectedValue] = React.useState("b");
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [officeCity, setofficeCity] = useState("");
  const [insurer, setInsurer] = useState("");
  const [policy, setPolicy] = useState("");
  const [entitlement, setentitlement] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState("");
  const [errofficeNo, seterrofficeNo] = useState("");
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [errofficeAddress, seterrofficeAddress] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errofficeCity, seterrofficeCity] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");
  const [open, setOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [customer, setCustomer] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState(null);

  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeDistrictId, sethomeDistrictId] = useState(1);
  const [officeDistrictId, setofficeDistrictId] = useState(1);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [officeDistrict, setofficeDistrict] = useState("");
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);
  const [allOCities, setallOCities] = useState([]);
  const [allODistricts, setallODistricts] = useState([]);

  const [homepostCode, sethomepostCode] = useState("");
  const [officepostCode, setofficepostCode] = useState("");
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [lopen, setlopen] = React.useState(false);

  const [packages, setPackages] = useState([1]);
  const [productMealsIndex, setProductMealsIndex] = useState(0);
  const [openPackages, setopenPackages] = useState(false);
  const [downPkg, setdownPkg] = useState(false);

  const [studentId, setstudentId] = useState("");
  const [errstudentId, seterrstudentId] = useState(false);
  const [upPkg, setupPkg] = useState("");

  let obj1 = {};

  const [eDisable, seteDisable] = useState(true);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log("get all packages", res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId, meals) => {
    console.log("pkg id >>>", pkgId);



    const obj = {
      packageId: pkgId,
    };


    if (customer?.noofmeals < meals || customer?.packageId === null) {
      packageToUser(obj, userId, "update")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setOpen(false);
          setAlertClass("success");
          setMsg("Package Assigned to User");
          getCurrentUser();
          // setTimeout(() => {
          //   history.push("/plans");
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");

          setMsg(error.response.data.message);
        });

    } else {

      setdownPkg(true);
      setupPkg(pkgId);
      return;



    }




  };

  const PROUDCT_MEALS_CARD_R = ({ data, meals }) => {
    return (
      <Grid
        item
        // xs={12}
        // md={4}
        xs={4}
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "10px 20px",
        }}
      >
        <Card
          style={{
            minWidth: 265,
            maxWidth: 300,

            backgroundColor:
              (data.id === parseInt(customer.packageId))
                ? COLORS.GrayDark
                : COLORS.Primary,
          }}
        >
          <CardContent
            style={{
              padding: matches.matches ? "14px 18px" : "27px",
            }}
          >
            <Typography
              style={{
                fontSize: matches?.matches ? "28pt" : "30pt",
                color: COLORS.Orange2,
                fontWeight: 400,
                fontFamily: FONTS_AC,
                textTransform: "uppercase",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {data?.name}
            </Typography>
            <Typography
              component="div"
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "10px 0px 0px",
                // marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: matches.matches ? "98px" : 110,
                  height: matches.matches ? "98px" : 110,
                  borderRadius: "50%",
                  backgroundColor: "#e8eeee",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: COLORS.PrimaryText,
                    fontSize: matches.matches ? "38.2pt" : "41.2pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                    lineHeight: "40px",
                  }}
                >
                  {data.noofmeals}
                </Typography>
                <Typography
                  style={{
                    color: COLORS.TextColor,
                    fontSize: matches.matches ? "12.9pt" : "13.9pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "500",
                  }}
                >
                  MEALS
                </Typography>
              </div>
            </Typography>
            <Typography
              style={{
                // mb: 1.5,
                // mt: 3,
                color: "white",
                textAlign: "center",
                fontSize: "9.9pt",
                fontFamily: FONTS_INTER,
                margin: "20px 0px 10px",
                minHeight: "200px",
              }}
              color="text.secondary"
            >
              {data.description}
            </Typography>
            <Typography>
              <p
                style={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: matches.matches ? "29.9pt" : "32.9pt",
                  fontFamily: FONTS_AC,
                  margin: "10px 0px",
                }}
              >
                {" "}
                {data.price}
                {" AUS "}
              </p>
            </Typography>
            <Typography
              variant="body2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                disabled={
                  (data.id === parseInt(customer.packageId)
                  ) ? true
                    : false
                }
                style={{
                  background:
                    (data.id === parseInt(customer.packageId)
                    ) ? COLORS.GrayDark2
                      : COLORS.Orange,
                  fontSize: "13pt",
                  fontFamily: FONTS_INTER,
                  // background-color: rgb(255, 168, 20);
                  color: "white",
                  height: " 36px",
                  width: " 125px",

                  fontWeight: "bold",
                }}
                onClick={() => assignPackage(data.id, data.noofmeals)}
              >
                SELECT
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const getVerification = () => {

    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setlopen(true);
      getSignUpVerification(token)
        .then((res) => {
          UpdateUserwithemail(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);

              setOpenToast(true);
              setColor(COLORS.Primary);
              setAlertClass("success");
              setMsg("Update Successful");

              setTimeout(() => {
                history.push("/Profile");
                window.location.reload();
              }, 2000);

            })
            .catch((error) => {
              // setlopen(false);
              console.log("Error", error.response);
              setlopen(false);
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setlopen(false);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];

    console.log("target>>>", e.target.files[0])
    console.log("target>>>", e.target.files[0]?.size / 1000)

    if (e.target.files[0] !== null) {


      if (!((e.target.files[0]?.size / 1000) < 1024)) {
        setOpenToast(true);
        setColor(COLORS.Primary);
        setAlertClass("error");
        setMsg("Your file size  should be below 1 MB");
        return;
      }
    }


    let reader = new FileReader();
    reader.readAsDataURL(file);
    let fileInp = e.target.files[0];
    let baseURL;

    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;

      setImage(baseURL);
      setImage2("");
      console.log("image>>>>>", baseURL);
    };

  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "b") {
      setidisable(true);
      // setInsurer("");
      // setPolicy("");
      // setentitlement("");
    } else {
      setidisable(false);
    }
  };
  const handleClose = () => {
    setOpenToast(false);
  };

  const handleClosePackages = () => {
    setopenPackages(false);
  };

  const handleEdit = () => {
    if (eDisable === true) {
      seteDisable(false);
    } else if (eDisable === false) {
      seteDisable(true);
    }
  };

  const onSubmit = async (e) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    // if (homeNo === "") {
    //   seterrhomeNo(true);
    // }
    // if (mobile === "") {
    //   seterrmobile(true);
    // }
    if (password !== "") {
      //   seterrPass(true)
      if (repassword === "") {
        seterrRePass("This Field is Requied");
      } else {
        seterrRePass("");
      }
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }
    if (!phoneRegex.test(homeNo)) {
      if (homeNo === "") {
        seterrhomeNo("This Field is Requied");
      } else {
        seterrhomeNo("Please Enter Valid number");
      }
    }
    // if (officeNo !== "") {
    //   if (!phoneRegex.test(officeNo)) {
    //     seterrofficeNo("Please Enter Valid number");
    //   }
    // }

    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(firstName)),
      lastName: upperFirst(lowerCase(lastName)),
      mobileNumber: mobile,
      email: email,
      homeNumber: homeNo,
      officeNumber: officeNo === null ? "" : officeNo,
      homeAddress: homeAddress,
      officeAddress: officeAddress === null ? "" : officeAddress,
      homeCity: homeCity,
      officeCity: officeCity === null ? "" : officeCity,
      insurer: insurer,
      policyNo: policy,
      entitlement: entitlement,
      password: password,
      oldPassword: repassword,
      image: image,
      homeDistrict: homeDistrict,
      officeDistrict: officeDistrict === null ? "" : officeDistrict,
      homepostCode: homepostCode,
      officepostCode: officepostCode === null ? "" : officepostCode,
      isStudent: isStudent === true ? 1 : 0,
    };
    console.log("obj>>>>", obj);

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homeDistrict !== "" &&
      homepostCode !== "" &&
      homeCity !== "" &&
      // homeNo !== "" &&
      mobile !== "" &&
      (password !== "" ? repassword !== "" : firstName !== "") &&
      phoneRegex.test(mobile)
      // phoneRegex.test(homeNo) &&
      // (officeNo !== "" ? phoneRegex.test(officeNo) : true)
    ) {
      setlopen(true);
      if (email !== premail) {

        let obj2 = {
          data: {
            firstName: upperFirst(lowerCase(firstName)),
            lastName: upperFirst(lowerCase(lastName)),
            mobileNumber: mobile,
            email: email,
            homeNumber: homeNo,
            officeNumber: officeNo === null ? "" : officeNo,
            homeAddress: homeAddress,
            officeAddress: officeAddress === null ? "" : officeAddress,
            homeCity: homeCity,
            officeCity: officeCity === null ? "" : officeCity,
            insurer: insurer,
            policyNo: policy,
            entitlement: entitlement,
            password: password,
            oldPassword: repassword,
            image: image,
            homeDistrict: homeDistrict,
            officeDistrict: officeDistrict === null ? "" : officeDistrict,
            homepostCode: homepostCode,
            officepostCode: officepostCode === null ? "" : officepostCode,
            isStudent: isStudent === true ? 1 : 0,
            type: "profile",
          },
        };

        signUpVerification(obj2)
          .then((res) => {
            setlopen(false);
            // setuserId(res.data[0]);
            setOpenToast(true);
            setColor(COLORS.Primary);
            setAlertClass("success");
            setMsg(
              `The email verification link has been sent to ${email}. 
            Please click on the confirmation link to confirm your email. `
            );
            // setTimeout(() => {
            //   //  setOpen(true);
            //   history.push("/login");
            //   window.location.reload();
            // }, 2000);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            console.log("Error", error.response);
            setOpenToast(true);
            setColor(COLORS.Orange);
            setAlertClass("error");
            setMsg(error.response.data.message);
          });


      } else {
        EditUserQuery(obj)
          .then((res) => {
            setlopen(false);
            setOpenToast(true);
            setColor(COLORS.Primary);
            setAlertClass("success");
            setMsg("Update Successful");
            getCurrentUser();
            seteDisable(true);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            console.log("Error", error.response);
            setOpenToast(true);
            setColor(COLORS.Orange);
            setAlertClass("error");
            setMsg(error.response.data.message);
          })
      }
    } else {
      scrollElement();
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setpremail(cData?.email);
          sethomeAddress(cData?.homeAddress);
          sethomeCity(cData?.homeCity);
          setofficeAddress(cData?.officeAddress);
          setofficeCity(cData?.officeCity);
          setmobile(cData?.mobileNumber);
          sethomeNo(cData?.homeNumber);
          setofficeNo(cData?.officeNumber);
          setInsurer(cData?.insurer);
          setPolicy(cData?.policyNo);
          setentitlement(cData?.entitlement);
          setuserId(cData?.id);
          setImage2(cData?.image);
          sethomeDistrict(cData?.homeDistrict);
          setofficeDistrict(cData?.officeDistrict);
          sethomepostCode(cData?.homepostCode);
          setofficepostCode(cData?.officepostCode);
          setisStudent(cData?.isStudent === 1 ? true : false);

          setCustomer(cData);

          obj1 = { name_en: cData?.homeDistrict };

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listOfficecities = () => {
    getCities(officeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallOCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const useStyles = makeStyles((theme) => ({
    "@global": {
      '.MuiAutocomplete-option': {
        width: "100%"
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "10.5pt",

        color: eDisable === true ? COLORS.TextColor3 : COLORS.TextColor2,
        fontWeight: 400,
        outline: "none",

        height: "1.4rem",
      },
      '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
        opacity: 1,
        WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",

        background: eDisable !== true ? "transparent" : "rgba(236,236,236,0.6)",
      },
    },
  }));

  const classes = useStyles();


  React.useEffect(() => {
    getCurrentUser();
    listDistricts();
    getVerification();
    getPackage();
  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  React.useEffect(() => {
    if (officeDistrictId !== 0) {
      listOfficecities();
    }
  }, [officeDistrictId]);

  const handleChangeOdist = (e, value) => {
    if (value !== null) {

      setofficeDistrict(value.name_en);
      setofficeDistrictId(value.id);
    } else {
      setofficeDistrict("");
      setofficeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeOcity = (e, value) => {
    if (value !== null) {
      setofficeCity(value.name_en);
      setofficepostCode(value.postcode);
      seterrofficeCity(false);
    } else {
      setofficeCity("");
      setofficepostCode("");
    }

    console.log("city>>", value);
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      sethomeCity(value.name_en);
      sethomepostCode(value.postcode);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    console.log("city>>", value);
  };

  return (
    <div>
      <Modal
        open={downPkg}
        onClose={() => setdownPkg(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center",
                  }}
                >
                  Package Downgrade
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    Your package will be downgraded and the cart items will be removed.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 10,
                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    {/* For any clarifications please contact our Order Processing
                    Unit between 9 am and 5 pm through (03) 8794 8694 */}
                  </Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    // sx={{ mt: 5, mb: 2 }}
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                      marginTop: "40px"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      // setOpen(true);

                      downPackageToUser({ packageId: upPkg }, userId, "update")
                        .then((res) => {
                          console.log(res.data);
                          setOpenToast(true);
                          setColor(COLORS.Primary);
                          setOpen(false);
                          setAlertClass("success");
                          setMsg("Package Assigned to User");
                          getCurrentUser();
                          setdownPkg(false);
                          // setTimeout(() => {
                          //   history.push("/plans");
                          //   window.location.reload();
                          // }, 2000);
                        })
                        .catch((error) => {
                          console.log("error", error.response);
                          console.log("current package error", error.response.data.message);
                          setOpenToast(true);
                          setColor(COLORS.Orange);
                          setAlertClass("error");

                          setMsg(error.response.data.message);
                        });

                    }}
                  >
                    DOWNGRADE NOW
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={openPackages}
        onClose={handleClosePackages}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: matches?.matches ? "90vw" : "95vw",
            maxHeight: "98vh",
            overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              padding: matches?.matches ? "15px" : "30px",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: matches.matches ? "10px" : "0px",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={12}
                sm={11}
                container
                spacing={2}
                style={{
                  marginLeft: matches?.matches ? "0px" : "-16px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                    justifyContent: "flex-end",
                    display: matches?.matches ? "flex" : "none",
                  }}
                >
                  <div onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: matches.matches ? "22pt" : "26pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{
                    justifyContent: "center",
                    display: matches1200?.matches ? "none" : "flex",
                    width: "100%",
                  }}
                  // className="hideScrollBar"
                  sx={{
                    flexWrap: {
                      // sm: "nowrap",
                      xs: "nowrap",
                      // lg: "nowrap"
                    },
                    // flexDirection: {
                    //   xs: "column",
                    //   sm: "row",
                    // },
                    overflowX: "auto",

                  }}
                >
                  {/* -------------------- */}
                  {packages?.map((data) => {
                    let meals = null
                    if (data.id === parseInt(customer.packageId)) {
                      meals = data?.noofmeals;
                    }
                    return <PROUDCT_MEALS_CARD_R data={data} meals={meals} />;
                  })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                    display: matches1200?.matches ? "flex" : "none",
                  }}
                >
                  {packages
                    ?.filter((_, index) => index === productMealsIndex)
                    ?.map((data) => {
                      return <PROUDCT_MEALS_CARD_R data={data} />;
                    })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    // display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    display: matches1200?.matches ? "flex" : "none",
                  }}
                >
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      opacity: productMealsIndex > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      // window.document.querySelector("#areas").scrollLeft -= 100;
                      if (productMealsIndex > 0) {
                        setProductMealsIndex(productMealsIndex - 1);
                      }
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      opacity:
                        productMealsIndex < packages?.length - 1 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (productMealsIndex < packages?.length - 1) {
                        setProductMealsIndex(productMealsIndex + 1);
                      }
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <LoadingSpinner open={lopen} />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />{" "}
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          md={8}
          lg={6}
          xs={11}
          style={{ width: "100%", borderBottom: "1px solid white" }}
        >
          <Grid
            xs={12}
            style={{
              display: "flex",
              background: COLORS.Primary,
              padding: "10px 15px",
              alignItems: "center",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          >

            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {image2 !== null && image2 !== undefined ? (
                  <img
                    src={`${IMAGE_SERVER_URL_DEV2}${data.image}`}
                    style={{
                      width: 25,
                      height: 25,
                      padding: 2,
                      background: "white",
                      color: COLORS.Primary,
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  />
                ) : (
                  <PersonIcon
                    style={{
                      width: 25,
                      height: 25,
                      padding: 2,
                      background: "white",
                      color: COLORS.Primary,
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  />
                )}

                <Typography
                  style={{
                    fontSize: "12pt",
                    fontFamily: FONTS_INTER,
                    color: "white",
                  }}
                >
                  {name}
                </Typography>
              </div>
            </div>
            <div>
              {mainProfile === true ? (
                <div
                  onClick={() => {
                    setMainProfile(false);
                  }}
                >
                  <KeyboardArrowUpIcon
                    style={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setMainProfile(true);
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </div>
              )}
            </div>
          </Grid>
          {mainProfile === true && (

            <div
              style={{
                display: "flex",
                padding: 10,
                background: COLORS.GrayDark3,
                flexDirection: "column",
              }}
            >
              <Grid
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: 10,
                  background: "white",
                }}
                container
              >
                <Grid xs={12} container>

                  <Typography
                    style={{
                      display: "flex",
                      flex: 1,
                      // padding: "10px 15px",
                      alignItems: "center",
                    }}
                    sx={{
                      padding: {
                        xs: "10px 0px",
                        sm: "10px 15px",
                      },
                    }}
                  >

                    {/* {image !== "" ? (
                      <>
                        <input
                          disabled={eDisable}
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={onChangeAvatar}
                        />
                        <label htmlFor="icon-button-file">
                          <Tooltip arrow title="Change Avatar">
                            <IconButton
                              style={{
                                // width: 100,
                                // height: 100,
                                padding: 8,
                                borderRadius: "50%",
                                marginRight: "18px",
                              }}
                              disabled={eDisable}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              className="profile_img_p"
                            >
                              <img
                                src={image}
                                style={{
                                  // width: 75,
                                  // height: 75,
                                  padding: 8,
                                  background: COLORS.GrayDark5,
                                  color: "white",
                                  borderRadius: "50%",

                                  cursor: "pointer",
                                }}
                                className="profile_img"
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </>
                    ) : image2 !== null && image2 !== undefined ? (
                      <>
                        <input
                          disabled={eDisable}
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={onChangeAvatar}
                        />
                        <label htmlFor="icon-button-file">
                          <Tooltip arrow title="Change Avatar">
                            <IconButton
                              className="profile_img_p"
                              style={{
                                // width: 100,
                                // height: 100,
                                padding: 8,
                                borderRadius: "50%",
                                // marginRight: "18px",
                              }}
                              disabled={eDisable}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <img
                                className="profile_img"
                                src={`${IMAGE_SERVER_URL_DEV2}${image2}`}
                                style={{
                                  // width: 75,
                                  // height: 75,
                                  padding: 8,
                                  background: COLORS.GrayDark5,
                                  color: "white",
                                  borderRadius: "50%",

                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </>
                    ) : (
                      <>
                        <input
                          disabled={eDisable}
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={onChangeAvatar}
                        />
                        <label htmlFor="icon-button-file">
                          <Tooltip arrow title="Change Avatar">
                            <IconButton
                              style={{
                                // width: 100,
                                // height: 100,
                                padding: 8,
                                borderRadius: "50%",
                                marginRight: "18px",
                              }}
                              disabled={eDisable}
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              className="profile_img_p"
                            >
                              <PersonIcon
                                sx={{
                                  width: {
                                    xs: "50px",
                                    sm: "75px",
                                  },
                                  height: {
                                    xs: "50px",
                                    sm: "75px",
                                  },
                                }}
                                style={{
                                  // width: 75,
                                  // height: 75,
                                  padding: 8,
                                  background: COLORS.GrayDark5,
                                  color: "white",
                                  borderRadius: "50%",

                                  cursor: "pointer",
                                }}
                              // className="profile_img"
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </>
                    )} */}



                    {/* <img
                    src={AVATAR}
                    style={{
                      width: "80px",
                    }}
                  /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: FONTS_INTER,

                          fontWeight: "bold",
                          color: COLORS.Primary,
                          textAlign: "left",
                        }}
                        sx={{
                          fontSize: { xs: "12pt", sm: "17pt" },
                        }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: FONTS_INTER,
                          // fontSize: "12pt",
                          textAlign: "left",

                          color: COLORS.TextColor,
                        }}
                        sx={{
                          fontSize: { xs: "9pt", sm: "12pt" },
                        }}
                      >
                        Profile ID - {userId}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: FONTS_INTER,
                          // fontSize: "12pt",
                          textAlign: "left",

                          color: COLORS.TextColor,
                        }}
                        sx={{
                          fontSize: { xs: "9pt", sm: "12pt" },
                        }}
                      >
                        Username - {data?.email}
                      </Typography>
                    </div>
                  </Typography>
                  <Typography>



                    {eDisable === true ? (
                      <Tooltip arrow title="Edit">
                        <div>
                          <EditIcon
                            style={{
                              color: COLORS.TextColor,
                              padding: "10px 15px 0px 0px",
                              cursor: "pointer",
                            }}
                            onClick={handleEdit}
                            sx={{
                              fontSize: {
                                xs: "24px",
                                sm: "32px",
                              },
                            }}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip arrow title="Cancel Edit">
                        <div>
                          <EditIcon
                            style={{
                              color: COLORS.TextColor,
                              padding: "10px 15px 0px 0px",
                              cursor: "pointer",
                            }}
                            sx={{
                              fontSize: {
                                xs: "24px",
                                sm: "32px",
                              },
                            }}
                            onClick={handleEdit}
                          />
                        </div>
                      </Tooltip>

                    )}
                  </Typography>
                </Grid>
                <Grid xs={12} container>
                  {/* <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      // width: "90%",
                      // marginTop: 5,
                      fontSize: "7.5pt",
                      padding: "0px 10px",
                      color: COLORS.TextColor,
                    }}
                  >
                    {eDisable === false ? "Maximum upload file size : 1 MB" : ""}
                   
                  </Typography> */}





                </Grid>


              </Grid>


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Divider
                  style={{
                    width: "93%",
                  }}
                />
              </div>
              <Grid
                ref={errScroll}
                container
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // padding: 10,
                  background: "white",
                  // borderTop: `20px solid ${COLORS.GrayDark3}`,
                }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    width: "100%",
                    //   borderRight: `10px solid ${COLORS.GrayDark3}`,
                    padding: "15px 25px",
                  }}
                >
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        // variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        First Name *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setfirstName(e.target.value);
                          seterrfirstName(false);
                        }}
                        value={firstName}
                      />
                      {errfirstName && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Last Name *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setlasttName(e.target.value);
                          seterrlasttName(false);
                        }}
                        value={lastName}
                      />
                      {errlastName && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Email *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setemail(e.target.value);
                          seterremail(false);
                        }}
                        value={email}
                      />
                      {erremail && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Mobile Number *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setmobile(e.target.value);
                          seterrmobile(false);
                        }}
                        value={mobile}
                      />
                      {errmobile && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errmobile}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Address *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          sethomeAddress(e.target.value);
                          seterrhomeAddress(false);
                        }}
                        value={homeAddress}
                      />
                      {errhomeAddress && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    style={{
                      padding: 0,
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                    xs={12}
                  >
                    <Grid md={4} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          State *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <Autocomplete
                            disabled={eDisable}
                            classes={classes}
                            defaultValue={{ name_en: data.homeDistrict }}
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeHdist(event, newValue);
                            }}
                            renderOption={(option) => (
                              <Typography>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "375px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </Typography>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>

                        {errhomeDistrict && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Suburb *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <Autocomplete
                            disabled={eDisable}
                            classes={classes}
                            defaultValue={{ name_en: data.homeCity }}
                            options={allHCities}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeHcity(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "375px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errhomeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={3} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Postcode *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <input
                            disabled={eDisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color:
                                eDisable === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              width: matches.matches === true ? "93%" : matches900.matches === true ? "96%" : "84%",
                            }}
                            onChange={(e) => {
                              sethomepostCode(e.target.value);
                              seterrhomepostCode(false);
                            }}
                            value={homepostCode}
                          />
                        </div>
                        {errhomepostCode && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} style={{ margin: "30px 0px 0px", justifyContent: "space-between" }}>

                    <Grid item xs={8} sm={8} style={{ margin: "15px 0px", display: "flex", justifyContent: "left" }}>
                      {/* <FormControl>
                        <FormControlLabel
                          control={

                            <GreenCheckbox
                              disabled={eDisable}
                              checked={isStudent}
                              size="medium"
                              onChange={(e) => {
                                check1 = e.target.checked;
                                console.log("check 01 ", e.target.checked);
                                setisStudent(e.target.checked)
                              }}
                            />
                          }
                          label={
                            <Typography

                              style={{
                                fontSize: matches900.matches === true ? "9.5pt" : "0.9vW",
                                fontFamily: FONTS_INTER,
                                // marginTop: "15px",
                                marginLeft: "5px",
                                color: COLORS.TextColor,
                                textAlign: "left",
                              }}
                            >
                              Are you an International Student ?
                            </Typography>
                          }
                        />
                      </FormControl> */}
                      <Typography

                        style={{
                          fontSize: matches900.matches === true ? "10.5pt" : "1.1vW",
                          fontFamily: FONTS_INTER,
                          // marginTop: "15px",
                          marginLeft: "5px",
                          color: COLORS.Primary,
                          textAlign: "left",
                        }}
                      >
                        Are you an International Student ?
                      </Typography>
                    </Grid>

                    <Grid
                      // md={3}
                      sm={4}
                      xs={4}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        margin: "15px 0px",
                      }}
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "0px",
                        },
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          disabled={eDisable}
                          size="small"
                          checked={isStudent === true}
                          onChange={(e) => {
                            check1 = true;
                            console.log("check 01 ", true);
                            setisStudent(true)
                          }}
                          value={true}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,
                            fontFamily: FONTS_INTER,
                            marginRight: 5,
                          }}
                        >
                          Yes
                        </Typography>
                        <Radio
                          disabled={eDisable}
                          size="small"
                          checked={isStudent === false}
                          onChange={(e) => {
                            check1 = false;
                            console.log("check 01 ", false);
                            setisStudent(false)
                          }}
                          value={false}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "B" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,

                            fontFamily: FONTS_INTER,
                          }}
                        >
                          No
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ margin: "15px 0px", }}>

                      {
                        isStudent === true &&

                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Enter Student Id
                          </Typography>
                          <input
                            disabled={eDisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color: COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",

                              background: COLORS.GrayDark3,
                            }}
                            type="text"
                            onChange={(e) => {
                              setstudentId(e.target.value);
                              seterrstudentId(false);
                            }}
                            value={studentId}
                          />
                          {errstudentId && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>


                      }



                    </Grid>


                  </Grid>



                </Grid>



                {/* <Grid
                  item
                  md={6}
                  xs={12}
                  style={{
                    width: "100%",
                    // marginTop:"30px",
                    //   borderRight: `10px solid ${COLORS.GrayDark3}`,
                  }}
                  sx={{
                    padding: {
                      xs: "0px 25px",
                      sm: "15px 25px",
                    },
                    marginTop: {
                      lg: "30px",
                      md: "30px",
                    },
                  }}
                >
                  <Grid
                    md={12}
                    xs={12}
                    //  style={{ margin: "0px 0px" }}
                    sx={{
                      margin: {
                        lg: "0px 0px",
                        xs: "0px 0px",
                        md: "0px 0px",
                      },
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Office Address
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setofficeAddress(e.target.value);
                          seterrofficeAddress(false);
                        }}
                        value={officeAddress}
                      />
                      {errofficeAddress && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    style={{
                      // padding: "15px 0px 0px 0px",
                      justifyContent: "space-between",
                    }}
                    sx={{
                      padding: {
                        xs: "0px ",
                        sm: "15px 0px 0px 0px",
                      },
                      marginTop: {
                        xs: "15px",
                        lg: "0px",
                        md: "0px",
                      },
                    }}
                    xs={12}
                  >
                    <Grid md={4} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          State
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <Autocomplete
                            disabled={eDisable}
                            classes={classes}
                            defaultValue={{ name_en: data.officeDistrict }}
                            options={allODistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeOdist(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "375px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errofficeAddress && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Suburb
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <Autocomplete
                            disabled={eDisable}
                            classes={classes}
                            defaultValue={{ name_en: data.officeCity }}
                            options={allOCities}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, newValue) => {
                              handleChangeOcity(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "375px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errofficeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid md={3} xs={12} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Postcode
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "40px",
                            borderRadius: 3,
                            background:
                              eDisable === true
                                ? "transparent"
                                : COLORS.GrayDark3,
                          }}
                        >
                          <input
                            disabled={eDisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color:
                                eDisable === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              width: matches.matches === true ? "93%" : "85%",
                            }}
                            onChange={(e) => {
                              setofficepostCode(e.target.value);
                              // seterrmobile(false);
                            }}
                            value={officepostCode}
                          />
                        </div>
                        {errofficeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Mobile Number *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setmobile(e.target.value);
                          seterrmobile(false);
                        }}
                        value={mobile}
                      />
                      {errmobile && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errmobile}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Home Number *
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          sethomeNo(e.target.value);
                          seterrhomeNo(false);
                        }}
                        value={homeNo}
                      />
                      {errhomeNo && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errhomeNo}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Office Number
                      </Typography>
                      <input
                        disabled={eDisable}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color:
                            eDisable === true
                              ? COLORS.TextColor3
                              : COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                        }}
                        onChange={(e) => {
                          setofficeNo(e.target.value);
                          seterrofficeNo(false);
                        }}
                        value={officeNo}
                      />
                      {errofficeNo && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errofficeNo}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
              {false ?

                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 27px",
                    background: "white",
                    borderTop: `20px solid ${COLORS.GrayDark3}`,
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      marginTop: 30,
                    }}
                  >
                    <Grid lg={7} md={8} sm={10} xs={12} style={{ width: "100%" }}>
                      <Typography
                        variant="body1"
                        style={{
                          color: COLORS.Primary,
                          textAlign: "left",
                          // letterSpacing: 1,
                          fontFamily: FONTS_INTER,
                          fontWeight: "500",
                        }}
                      >
                        Do you have a life insurance policy ?
                        {/* with Union Assurance or HNB
                    Assurance ? */}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          float: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontWeight: "500",
                        }}
                      >
                        ( If "YES", please fill the below section and "check" your
                        eligibility to get discounts. )
                      </Typography>
                    </Grid>
                    <Grid
                      md={3}
                      sm={2}
                      xs={12}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                      sx={{
                        marginTop: {
                          xs: "15px",
                          sm: "0px",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          disabled={true}
                          size="small"
                          checked={selectedValue === "a"}
                          onChange={handleChange}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,
                            fontFamily: FONTS_INTER,
                            marginRight: 10,
                          }}
                        >
                          Yes
                        </Typography>
                        <Radio
                          disabled={true}
                          size="small"
                          checked={selectedValue === "b"}
                          onChange={handleChange}
                          value="b"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "B" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,

                            fontFamily: FONTS_INTER,
                          }}
                        >
                          No
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 30,
                    }}
                  >
                    <Grid item xs={12} md={3} lg={3} sm={5.5}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Select your insurer
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color:
                                eDisable === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setInsurer(e.target.value)}
                            value={insurer}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} sm={5.5}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Enter Policy Number
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color:
                                eDisable === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setPolicy(e.target.value)}
                            value={policy}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      md={3}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    // sx={{
                    //   padding: {
                    //     sm: 0,
                    //     md: "35px 0px",
                    //   },
                    // }}
                    >
                      <Button
                        disabled={idisable}
                        size="large"
                        variant="contained"
                        // style={{
                        //   background: "#808182",
                        //   height: "34px",
                        //   marginBottom: 14,
                        // }}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "12pt",
                          color: "white",
                          padding: "20px 15px",
                          outline: "none",
                          border: "none",
                          // borderColor: COLORS.InputBorderColor,
                          boxShadow: "none",
                          borderRadius: 6,
                          height: "2rem",
                          background: COLORS.Orange,
                          opacity: idisable === true ? 0.5 : 1,
                          marginBottom: 14,
                          // width: "fit-content",
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "fit-content",
                          },
                        }}
                      >
                        CHECK ELIGIBLITY
                      </Button>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Your entitlement
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              color:
                                eDisable === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor2,
                              padding: "4px 10px",
                              outline: "none",
                              border: "1px solid ",
                              // borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setentitlement(e.target.value)}
                            value={entitlement}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>



                : <></>}

              {eDisable === false ? (
                <>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 27px",
                      background: "white",
                      borderTop: `20px solid ${COLORS.GrayDark3}`,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >


                    <Grid item md={5.8} xs={12} style={{ margin: "15px 0px" }}>
                      {/* <Grid md={12} xs={12} style={{ margin: "15px 0px" }}> */}
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Enter New Password
                        </Typography>
                        <input
                          disabled={eDisable}
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "10.5pt",
                            color: COLORS.TextColor2,
                            padding: "4px 10px",
                            outline: "none",
                            border: "1px solid ",
                            // borderColor: COLORS.InputBorderColor,
                            borderRadius: 6,
                            height: "2rem",
                            border: "none",
                            background: COLORS.GrayDark3,
                          }}
                          type="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            seterrPass(false);
                          }}
                          value={password}
                        />
                        {errPass && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                      {/* </Grid> */}
                    </Grid>
                    <Grid item md={5.8} xs={12} style={{ margin: "15px 0px" }}>
                      {/* <Grid md={12} xs={12} style={{ margin: "15px 0px" }}> */}
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Enter old Password
                        </Typography>
                        <input
                          disabled={eDisable}
                          style={{
                            fontFamily: FONTS_INTER,
                            fontSize: "10.5pt",
                            color: COLORS.TextColor2,
                            padding: "4px 10px",
                            outline: "none",
                            border: "1px solid ",
                            // borderColor: COLORS.InputBorderColor,
                            borderRadius: 6,
                            height: "2rem",
                            border: "none",
                            background: COLORS.GrayDark3,
                          }}
                          type="password"
                          onChange={(e) => {
                            setrePassword(e.target.value);
                            seterrRePass("");
                          }}
                          value={repassword}
                        />
                        {errRePass && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            {errRePass}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "10px 27px",
                      background: "none",
                      borderTop: `20px solid ${COLORS.GrayDark3}`,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    sx={{
                      padding: {
                        sm: "10px 27px",
                        xs: "10px 25px",
                      },
                    }}
                  >
                    <Grid
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 30,
                        // padding: "10px 15px",
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          //  height: "34px",
                          marginBottom: 19,
                          // padding: "20px 15px",
                          // fontSize: "11pt",
                          background: COLORS.Orange,
                          boxShadow: "none",
                          color: "white",
                          // width: "146px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          paddingTop: "2px",
                        }}
                        sx={{
                          fontSize: {
                            xs: "12pt",
                            sm: "11pt",
                          },
                          height: {
                            xs: "34pt",
                            sm: "34px",
                          },
                          width: {
                            xs: "100%",
                            sm: "166px",
                            lg: "166px",
                            md: "166px",
                          },
                        }}
                        onClick={() => setopenPackages(true)}
                      >
                        Change Package
                      </Button>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 30,
                        // padding: "10px 15px",
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          //  height: "34px",
                          marginBottom: 19,
                          // padding: "20px 15px",
                          // fontSize: "11pt",
                          background: COLORS.Primary,
                          boxShadow: "none",
                          color: "white",
                          // width: "146px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          paddingTop: "2px",
                        }}
                        sx={{
                          fontSize: {
                            xs: "12pt",
                            sm: "11pt",
                          },
                          height: {
                            xs: "34pt",
                            sm: "34px",
                          },
                          width: {
                            xs: "100%",
                            sm: "166px",
                            lg: "166px",
                            md: "166px",
                          },
                        }}
                        onClick={onSubmit}
                      >
                        SAVE
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </div>


          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MainProfile;
