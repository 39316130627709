import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { COLORS } from "../../Constants/Colors";
import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { Container } from "react-bootstrap";
import { FONTS_INTER } from "../../Constants";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { FormGroup, Form } from 'reactstrap';
import { resetPassword } from "../../Services/API/Post";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useHistory, Link, BrowserRouter as Router, useLocation } from "react-router-dom";
import ErrorPopup from "./errorPopup.jsx"

export default function VerificationPopup({ open, handleClose }) {

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4, borderRadius: "4px",
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const history = useHistory();
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [email2, setEmail2] = useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [errPassword, setErrPassword] = React.useState("");

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let password1 = data.get('username')
    let password = data.get('password')

    if (password1 === "") {
      setErrEmail("This Field Required");
    }

    if (password1 !== "") {
      setErrEmail("");
    }

    if (password === "") {
      setErrPassword("This Field Required");
    }

    if (password !== "") {
      setErrPassword("");
    }

    if (!(password1 === password)) {
      setErrPassword("Password Mismatch")
    }
    // eslint-disable-next-line no-console
    console.log({
      password: password1,
    });

    let obj = {
      password: password1,
      token: query.get("token"),
    };

    if (password1 !== "" && password !== "" && password1 === password) {
      console.log("<<<<", obj)
      resetPassword(obj)
        .then((res) => {
          setOpenToast(true)
          setColor(COLORS.Primary)
          setAlertClass("success");
          setMsg("Password Changed Successfully")
          setTimeout(() => { history.push("/login"); window.location.reload(); }, 3000);
          // if (res.status) {
          //   localStorage.setItem(
          //     `${window.location.hostname}.login`,
          //     JSON.stringify(res)
          //   );
          // }

          // setEmail("");
          // setPassword("");

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error.response)
          // setColor("red");
          setAlertClass("error");
          setOpenToast(true)
          setColor("red")
          setMsg(error.response.data.message)

          // if (error.response.data.statusCode === 401) {
          //   setErrPassword(error.response.data.message);
          // } else {
          //   setErrEmail(error.response.data.errorMessage);
          // }
        });

    }




  };

  return (
    <>
      <ErrorPopup openToast={false}
        //  handleClose={openToast === true ? setTimeout(() => {setOpenToast(false);}, 5000) : false} 
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass} msg={msg} />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: COLORS.Orange }}>
                  <LockResetIcon />
                </Avatar>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    fontSize: "14pt",

                  }}>
                  Reset Password
                </Typography>
                <Form className="mb-3" onSubmit={handleSubmit}>
                  <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop: 20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>New Password *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.pddwrdColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <LockIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        // required
                        type="password"
                        name="username"
                        id="username"
                        placeholder="Enter New Password"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        // ref={passwordRef}
                        autoComplete="new-password"
                        onChange={(e) => {
                          // setPassword(e.target.value);
                          setErrEmail("")
                        }}
                      // onChange={this.onChangePassword}
                      // value={this.state.password}
                      />
                    </Paper>
                    <Box textAlign="right">
                      <div className="text-right">
                        <span
                          style={{
                            fontSize: "8pt",
                            color: "red",
                            fontFamily: FONTS_INTER,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {errEmail}
                        </span>
                      </div>
                    </Box>
                  </FormGroup>
                  {/* <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop:20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>New Password *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.emailColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                          <LockIcon style={{ fontSize: "13pt" }} />
                        </IconButton>
                      <input
                        // required
                        type="password"
                        name="username"
                        id="username"
                        placeholder="Enter New Password"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        autoComplete="new-password"
                        onChange={(e) =>
                          setErrEmail("")            
                          }
                        // onChange={this.onChangeEmail}
                        // value={email}
                      />
                      <Box textAlign='right'>
                      <div className="text-right">
                        <span style={{ fontSize: "8pt", color: "red",fontFamily: FONTS_INTER,}}>
                          {errEmail}
                        </span>
                      </div>
                      </Box>
                    </Paper>
                  </FormGroup> */}
                  <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop: 20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Re Enter New Password *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.pddwrdColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <LockIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        // required
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Re Enter password"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        // ref={passwordRef}
                        autoComplete="new-password"
                        onChange={(e) => {
                          // setPassword(e.target.value);
                          setErrPassword("");
                        }}
                      // onChange={this.onChangePassword}
                      // value={this.state.password}
                      />
                    </Paper>
                    <Box textAlign="right">
                      <div className="text-right">
                        <span
                          style={{
                            fontSize: "8pt",
                            color: "red",
                            fontFamily: FONTS_INTER,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {errPassword}
                        </span>
                      </div>
                    </Box>
                  </FormGroup>
                  {/* <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop:20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Re Enter New Password *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.emailColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <div>
                        <IconButton className="p-1 ml-2">
                          <LockIcon style={{ fontSize: "13pt" }} />
                        </IconButton>
                        <input
                          // required
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Re Enter password"
                          className="bg-white"
                          style={{
                            border: "none",
                            borderRadius: "2px",
                            width: "14.7rem",
                            fontSize: "9pt",
                            height: "2rem",
                            borderColor: "white",
                            fontFamily: FONTS_INTER,
                            background: COLORS.GrayDark3,
                          }}
                          autoComplete="new-password"
                          onChange={(e) =>
                            setErrPassword("")            
                            }
                          // onChange={this.onChangePassword}
                          // value={this.state.password}
                        />
                        <Box textAlign='right'>
                        <div className="text-right">
                        <span style={{ fontSize: "8pt", color: "red",fontFamily: FONTS_INTER,}}>
                          {errPassword}
                          </span>
                        </div>
                        </Box>
                      </div>
                    </Paper>
                  </FormGroup> */}
                  {openToast === true ?
                    <Box component="form" sx={{ mt: 3 }}>
                      <Typography
                        component="h5"
                        variant="h5"
                        style={{
                          fontSize: "10pt",
                          fontFamily: FONTS_INTER,
                          marginTop: 12,

                          color: color,
                          textAlign: "center",
                        }}
                      >
                        {msg}
                      </Typography>

                    </Box>
                    :
                    <></>}

                  <Box textAlign='center'>
                    <Button
                      type="submit"
                      // fullWidth
                      variant="contained"
                      sx={{ mt: 4, mb: 4 }}
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: "12pt",
                        color: "white",
                        background: COLORS.Primary,
                        borderRadius: 4,
                        width: "175px",
                        height: "35px",
                        border: "none",
                        marginTop: 60,
                      }}
                    >
                      CONFIRM
                    </Button>
                  </Box>

                </Form>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
}