import React, { useContext, useEffect } from "react";
import "../../App.css";

import { Link as RouterLink, BrowserRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,

  Menu,
  useMediaQuery,
  MenuItem,
  AppBar,
  Toolbar,
  Container,
  Typography,
  useTheme,
  
} from "@mui/material";

export default function Header({
  gohome,
  setGoHome,
  _activeTab,
  setMActiveTab,
  activeDevice,
  cart_item_qty,
  handleToaster,
}) {
  // xs, extra-small: 360px
  // sm, small: 600px
  // md, medium: 960px
  // lg, large: 1280px
  // xl, extra-large: 1920px
  const theme = useTheme();

  const mobile_sm = useMediaQuery(theme.breakpoints.down("xs"));
  const mobile_lg = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const largeDisplay = useMediaQuery(theme.breakpoints.down("xl"));

  const useStyles = makeStyles((theme) => ({
    gridRow: {
      width: "100vw",
    },
    TopBar: {
      height: "5.5vh",
      color: "white",
      width: "100vw",
    },
    AppBar: {
      paddingTop: "0.75vh",
      width: "100vw",
      height: "6.0vh",
      backgroundColor: "#97C83B",
      [theme.breakpoints.down("lg")]: {},
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#97C83B",
      },
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "#97C83B",
      },
    },
    ToolBar: {
      minHeight: "5.0vh",
      width: "100vw",
      verticalAlign: "baseline",
      paddingLeft: 0,
      [theme.breakpoints.down("sm")]: {
        height: "6.00vh",
      },
    },
    barContainer: {
      height: "4.25vh",
      display: "flex",
      width: "100%",
      maxWidth: "1920px",
      [theme.breakpoints.down("sm")]: {
        height: "6.00vh",
        marginRight: "0vw",
        paddingRight: "0vw",
      },
      [theme.breakpoints.down("xs")]: {
        height: "6.00vh",
        backgroundColor: "#97C83B",
      },
    },

    leftContainer: {
      float: "left",
      width: "50%",
      textAlign: "left",
      color: "white",
    },

    rightContainer: {
      float: "right",
      width: "50%",
      textAlign: "right",
      verticalAlign: "baseline",
      paddingRight: "4vw",
      color: "white",
      // [theme.breakpoints.down("sm")]: {
      //     textAlign:"left",
      //     paddingRight:'0vw',
      // },
      // [theme.breakpoints.down("xs")]: {
      //     textAlign:"left",
      //     paddingRight:'0vw',
      // },
    },

    toolbarContent: {
      color: "white",
      verticalAlign: "middle",
      // [theme.breakpoints.down("xs")]: {
      //     marginTop: theme.spacing(1),
      //     backgroundColor: "purple"
      // },
      // [theme.breakpoints.between("sm", "md")]: {
      //     marginTop: theme.spacing(3),
      //     backgroundColor: "blue"
      // },
      // "@media (min-width: 300px,max-width:600px)": {
      //     color:"red",
      // }

      [theme.breakpoints.down("lg")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.25vw",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "3.25vw",
      },
    },

    badge: {
      marginTop: "0.5vh",
      fontSize: "2.5vw",
      backgroundColor: "red",
      color: "white",
    },

    cartIcon_: {
      marginTop: "1vh",
      fontSize: "8vw",
    },

    bannerContainer: {
      left: "0",
      right: "0",
      height: "30vh",
      position: "relative",
      textAlign: "center",
      width: "100vw",
      color: "#97C83B",
      marginBottom: "2vh",
      [theme.breakpoints.down("lg")]: {
        height: "30vh",
      },
      [theme.breakpoints.down("md")]: {
        height: "22vh",
      },
      [theme.breakpoints.down("sm")]: {
        height: "15vh",
        marginBottom: "0",
      },
      [theme.breakpoints.down("xs")]: {
        height: "10vh",
        marginBottom: "0",
      },
    },

    LogoButton: {
      backgroundSize: "100% 100%",
      //   backgroundImage: `url(${bannerLogo})`,
    },

    banner: {
      position: "relative",
      height: "22vh",
      maxWidth: "100%",
      objectFit: "contain",
    },

    bannerText: {
      height: "6vh",
      position: "relative",
      fontSize: "3.0vw",
      textAlign: "center",
      color: "#0E6924",
      marginTop: "0",
    },

    home_gridRow_1: {
      textAlign: "right",
      paddingRight: "2vw",
    },
    home_gridRow_2: {
      textAlign: "left",
      paddingLeft: "2vw",
    },
    home_gridRow_3: {
      textAlign: "center",
    },
    home_bannerlogo: {
      position: "relative",
      marginTop: "6vh",
      marginBottom: "5vh",
      marginLeft: "35%",
      marginRight: "5%",
      height: "22vh",
      maxWidth: "100%",
      objectFit: "contain",
      [theme.breakpoints.down("lg")]: {
        marginTop: "6vh",
        height: "22vh",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "4vh",
        height: "22vh",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "4vh",
        height: "10vh",
        marginBottom: "0",
        marginLeft: "auto",
        marginRight: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "2vh",
        height: "6vh",
        marginBottom: "0",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    home_banner: {
      position: "relative",
      marginTop: "5vh",
      height: "19vh",
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "30%",
      objectFit: "contain",
      [theme.breakpoints.down("lg")]: {
        height: "19vh",
      },
      [theme.breakpoints.down("md")]: {
        height: "19vh",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "2vh",
        height: "10vh",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "2vh",
        marginLeft: "0",
        height: "4vh",
        marginRight: "0",
      },
    },

    home_bannerText: {
      height: "6vh",
      position: "relative",
      fontSize: "0",
      textAlign: "left",
      color: "#0E6924",
      marginTop: "-3vh",
      Left: "0",
      marginLeft: "1%",
      paddingTop: "0",
      [theme.breakpoints.down("xl")]: {
        fontSize: "12px",
        marginRight: "-5vw",
        marginTop: "-3vh",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "2.70vw",
        marginTop: "-3vh",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "2.80vw",
        marginTop: "-1vh",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.25vw",
        marginTop: "-1vh",
        color: "black",
        textAlign: "left",
        whiteSpace: "no-wrap",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "2.25vw",
        marginTop: "-1vh",
      },
    },
    mobile_menu: {
      marginTop: "2.5vh",
      marginLeft: "-5vw",
      height: "6vh",
    },

    mobile_menu_cart: {
      marginTop: "2vh",
      height: "6vh",
      marginRight: "1vw",
      [theme.breakpoints.down("sm")]: {
        marginRight: "1vw",
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: "1vw",
      },
    },
    MenuIcon: {
      fill: "#97C83B",
      fontSize: "2.5rem",
    },

    MenuButton: {
      padding: 0,
    },

    MenuPop: {
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "50%",
        paddingLeft: "0%",
        backgroundColor: "#0E6909",
      },
      marginTop: "2vh",
      marginLeft: "0vh",
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: 0,
      },
    },
    MenuItem: {
      justifyContent: "right",
      color: "white",
      backgroundColor: "#0E6909",
      borderBottom: "1px solid white",
      "&:hover": {
        backgroundColor: "#FFC004 !important",
        color: "black",
        borderBottom: "1px solid #FFC004",
      },
      width: "100%",
      marginRight: "10%",
      "& .MuiMenuItem-root": {
        minHeight: "4.5vh",
        height: "6.00vh",
        padding: "2vh 0 2vh 0",
      },
    },
    buttonCheckout: {
      marginTop: "2vw",
      marginLeft: "2vw",
      color: "#8FBD38",
      width: "4vw",
      height: "4vw",
    },
    MenuItemProfile: {},
  }));

  const classes = useStyles();
  const anchorRef = React.useRef(null);

  const [mobileMenu, setMobileMenu] = React.useState(null);

  const renderTopBar = (
    <div className={classes.TopBar}>
      <a id="top"></a>
      <AppBar className={classes.AppBar}>
        <Toolbar className={classes.ToolBar}>
          <Container className={classes.barContainer}>
            <div className={classes.leftContainer}>
              <Grid container spacing={0}>
                <Grid item xs={mobile_sm ? 2 : 1}>
                  {/* {mobile_sm && (
                    // <LocationOnIcon
                    //   style={{ fontSize: "large", top: "0", marginTop: "1vh" }}
                    // />
                  )} */}
                </Grid>
                <Grid item xs={mobile_sm ? 10 : 11}>
                  <Typography
                    align="left"
                    variant={"body1"}
                    className={classes.toolbarContent}
                  >
                    96a Murrumbeena Rd, Murrumbeena VIC 3163
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.rightContainer}>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="right"
              >
                <Grid
                  item
                  xs={mobile_sm ? 3 : 6}
                  style={{ margin: mobile_sm && "1.5vh 2vw 1vh -2vw" }}
                >
                  {/* {mobile_sm && <PhoneIcon style={{ fontSize: "4.00vw" }} />} */}
                </Grid>
                <Grid
                  item
                  xs={mobile_sm ? 9 : 6}
                  style={{ margin: mobile_sm && "1.5vh 0 auto 0" }}
                >
                  <Typography
                    style={{ fontSize: mobile_sm && "3.50vw" }}
                    align={mobile_sm ? "left" : "right"}
                    variant={"body1"}
                    className={classes.toolbarContent}
                  >
                    (03) 9569 0488
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );

  var menu_items = [
    { label: "Home", _activeTab: 0 },
    { label: "About Us", _activeTab: 1 },
    { label: "NDIS", _activeTab: 2 },
    { label: "Delivery Day", _activeTab: 3 },
    { label: "Order Now", _activeTab: 4 },
    // { label: "Downloads", _activeTab: 5 },
    { label: "Contact Us", _activeTab: 5 },
    { label: "Downloads", _activeTab: 6 },
    // {label:'A$',_activeTab:8},
    // {label:'PROFILE',_activeTab:9},
  ];

  useEffect(() => {
    console.log("mobileMenu", mobileMenu);
  }, [mobileMenu, setMobileMenu]);

  const logoRedirects = () => {
    console.log("changing to home");
    setGoHome(gohome ? false : true);
  };

  const handleClose = (activeTab) => {
    if (activeTab === 0) {
      setGoHome(gohome ? false : true);
    } else {
      if (activeTab === 7) {
        let current_order_suburb = JSON.parse(
          localStorage.getItem("current-order-suburb")
        );

        if (cart_item_qty.qty > 0) {
          if (current_order_suburb.minOrderQty === 68.8) {
            if (cart_item_qty.total >= 68.8) {
              if (current_order_suburb) {
                setMActiveTab(activeTab);
              } else {
                handleToaster("Please select your suburb first", "error");
              }
            } else {
              handleToaster("Cart value should exceed 6880.", "error");
            }
          } else {
            if (cart_item_qty.total >= current_order_suburb.minOrderQty) {
              if (current_order_suburb) {
                setMActiveTab(activeTab);
              } else {
                handleToaster("Please select your suburb first", "error");
              }
            } else {
              handleToaster(
                "we require a minimum order quantity of $" +
                  current_order_suburb.minOrderQty +
                  " for a delivery!",
                "error"
              );
            }
          }
        } else {
          handleToaster("Please add items to the cart", "error");
        }
      } else {
        setMActiveTab(activeTab);
      }
    }
    setMobileMenu(null);
  };

  const renderMobileMenu = (
    <BrowserRouter>
      <IconButton
        onClick={(e) => setMobileMenu(e.currentTarget)}
        className={classes.MenuButton}
        aria-controls="profile-menu"
        aria-haspopup="true"
      >
        {/* {mobileMenu !== null ? (
          <CloseIcon className={classes.MenuIcon} />
        ) : (
          <MenuIcon className={classes.MenuIcon} />
        )} */}
      </IconButton>
      <Menu
        id="profile-menu"
        open={Boolean(mobileMenu)}
        anchorEl={mobileMenu}
        onClose={() => setMobileMenu(null)}
        disableAutoFocusItem
        className={classes.MenuPop}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {menu_items.map((menu_item, index) => (
          <MenuItem
            key={index}
            value={index}
            className={classes.MenuItem}
            onClick={() => handleClose(menu_item._activeTab)}
          >
            {menu_item.label}
          </MenuItem>
        ))}
      </Menu>
    </BrowserRouter>
  );

  const renderBanner = (
    <Grid container spacing={0} className={classes.bannerContainer}>
      <Grid item xs={3} className={classes.home_gridRow}></Grid>
      <Grid item xs={8} className={classes.home_gridRow}>
        <div>
          <img src="" alt="sddsd" className={classes.home_banner} />
        </div>
        <div>
          <p className={classes.home_bannerText}>
            specialising in home-style nutritious meals
          </p>
        </div>
      </Grid>
      <Grid item xs={1} className={classes.home_gridRow}></Grid>
    </Grid>
  );

  const renderBanner2 = (
    <Grid container spacing={0} className={classes.bannerContainer}>
      <Grid item xs={3} className={classes.home_gridRow_1}>
        <div>
          <Button
            disableRipple
            onClick={() => {
              logoRedirects();
            }}
            style={{
              margin: 0,
              padding: 0,
              backgroundColor: "rgba(0,0,0,0.0)",
              color: "rgba(0,0,0,0.0)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.0)",
                color: "rgba(0,0,0,0.0)",
              },
            }}
          >
            <img src="" alt="sddsd" className={classes.home_bannerlogo} />
          </Button>
        </div>
      </Grid>
      <Grid item xs={6} className={classes.home_gridRow_2}>
        <div>
          <img src="" alt="sddsd" className={classes.home_banner} />
        </div>
        <div>
          <p style={{ fontSize: "2.53vw" }} className={classes.home_bannerText}>
            specialising in home-style nutritious meals
          </p>
        </div>
      </Grid>
      <Grid item xs={3} className={classes.home_gridRow_3}></Grid>
    </Grid>
  );

  return (
    <div id="header-root">
      <Grid container spacing={0} className={classes.gridColumn}>
        <Grid item xs={12} className={classes.gridRow}>
          <div className="item1">{renderTopBar}</div>
        </Grid>
        <Grid item xs={12} className={classes.gridRow}>
          <div className="item2">{renderBanner2}</div>
        </Grid>
      </Grid>
    </div>
  );
}
