import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FONTS_INTER } from "../Constants";
import { COLORS } from "../Constants/Colors";
import StepsContext from "../Context/StepsContext";

import "./componets.css";

const Steps = () => {
  const { steps } = useContext(StepsContext);
  // console.log("??????",steps)
  var matches = window.matchMedia("(max-width: 600px)");
  return (
    <Grid item xs={12} lg={9} md={10} style={{ padding: "10px 30px" }}>
      <Typography style={{ margin: "2% 0% 8% 0%", maxWidth: "90vw" }}>
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: "2",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{
              width: {
                sm: "100px",
                xs: "110px",
              },
            }}
          >
            <Typography
              id="num1"
              style={{
                fontFamily: FONTS_INTER,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                padding: "2%",
                textAlign: "center",
                border: steps > 1 ? "none" : `1px solid ${COLORS.Primary}`,
                color: steps > 1 ? "white" : COLORS.TextColor,
                backgroundColor: steps > 1 ? COLORS.Primary : "white",
              }}
              sx={{
                width: {
                  xs: "35px",
                  sm: "50px",
                },
                height: {
                  xs: "35px",
                  sm: "50px",
                },
                fontSize: {
                  xs: "9pt",
                  sm: "13pt",
                },
              }}
            >
              1
            </Typography>
            <Typography
              // variant="body2"
              style={{
                fontFamily: FONTS_INTER,
                color: COLORS.TextColor,
                position: "relative",
                top: 3,
                // fontWeight: "bold",
                fontSize:matches.matches === true ?"0.65rem":"13pt"
              }}
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "13pt",
                },
                minHeight: {
                  xs: "35px",
                },
              }}
            >
              Register
            </Typography>
          </Typography>

          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{
              width: {
                sm: "148px",
                xs: "110px",
              },
            }}
          >
            <Typography
              //   variant="h6"
              id="num2"
              style={{
                fontFamily: FONTS_INTER,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                borderRadius: "50%",
                padding: "2%",
                textAlign: "center",
                border: steps > 2 ? "none" : `1px solid ${COLORS.Primary}`,
                color: steps > 2 ? "white" : COLORS.TextColor,
                backgroundColor: steps > 2 ? COLORS.Primary : "white",
              }}
              sx={{
                width: {
                  xs: "35px",
                  sm: "50px",
                },
                height: {
                  xs: "35px",
                  sm: "50px",
                },
                fontSize: {
                  xs: "9pt",
                  sm: "13pt",
                },
              }}
            >
              2
            </Typography>
            <Typography
              // variant="body2"
              style={{
                fontFamily: FONTS_INTER,
                color: COLORS.TextColor,
                position: "relative",
                top: 3,
                fontSize:matches.matches === true ?"0.65rem":"13pt"
                // fontWeight: "bold",
              }}
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "13pt",
                },
                minHeight: {
                  xs: "35px",
                },
              }}
            >
              Select a Package
            </Typography>
          </Typography>

          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{
              width: {
                sm: "180px",
                xs: "110px",
              },
            }}
          >
            <Typography
              //   variant="h6"
              id="num3"
              style={{
                fontFamily: FONTS_INTER,

                display: "flex",

                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                borderRadius: "50%",
                padding: "2%",
                textAlign: "center",
                border: steps > 3 ? "none" : `1px solid ${COLORS.Primary}`,
                color: steps > 3 ? "white" : COLORS.TextColor,
                backgroundColor: steps > 3 ? COLORS.Primary : "white",
              }}
              sx={{
                width: {
                  xs: "35px",
                  sm: "50px",
                },
                height: {
                  xs: "35px",
                  sm: "50px",
                },
                fontSize: {
                  xs: "9pt",
                  sm: "13pt",
                },
              }}
            >
              3
            </Typography>
            <Typography
              // variant="body2"
              style={{
                fontFamily: FONTS_INTER,
                color: COLORS.TextColor,
                position: "relative",
                fontSize:matches.matches === true ?"0.65rem":"13pt",
                top: 3,
                // fontWeight: "bold",
              }}
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "13pt",
                },
                minHeight: {
                  xs: "35px",
                },
              }}
            >
              Create a Meal Plan
            </Typography>
          </Typography>

          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{
              width: {
                sm: "120px",
                xs: "110px",
              },
            }}
          >
            <Typography
              //   variant="h6"
              id="num4"
              style={{
                fontFamily: FONTS_INTER,

                display: "flex",

                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                borderRadius: "50%",
                padding: "2%",
                textAlign: "center",
                border: steps > 4 ? "none" : `1px solid ${COLORS.Primary}`,
                color: steps > 4 ? "white" : COLORS.TextColor,
                backgroundColor: steps > 4 ? COLORS.Primary : "white",
              }}
              sx={{
                width: {
                  xs: "35px",
                  sm: "50px",
                },
                height: {
                  xs: "35px",
                  sm: "50px",
                },
                fontSize: {
                  xs: "9pt",
                  sm: "13pt",
                },
              }}
            >
              4
            </Typography>
            <Typography
              // variant="body2"
              style={{
                fontFamily: FONTS_INTER,
                color: COLORS.TextColor,
                position: "relative",
                fontSize:matches.matches === true ?"0.65rem":"13pt",
                top: 3,
                // fontWeight: "bold",
              }}
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "13pt",
                },
                minHeight: {
                  xs: "35px",
                },
              }}
            >
              Pay
            </Typography>
          </Typography>
          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // width: "125px",
            }}
            sx={{
              width: {
                sm: "125px",
                xs: "126px",
              },
            }}
          >
            <Typography
              //   variant="h6"
              id="num4"
              style={{
                fontFamily: FONTS_INTER,
                // width: "50px",
                // height: "50px",
                // fontSize: "13pt",
                display: "flex",

                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                borderRadius: "50%",
                padding: "2%",
                textAlign: "center",
                border: steps > 5 ? "none" : `1px solid ${COLORS.Primary}`,
                color: steps > 5 ? "white" : COLORS.TextColor,
                backgroundColor: steps > 5 ? COLORS.Primary : "white",
              }}
              sx={{
                width: {
                  xs: "35px",
                  sm: "50px",
                },
                height: {
                  xs: "35px",
                  sm: "50px",
                },
                fontSize: {
                  xs: "9pt",
                  sm: "13pt",
                },
              }}
            >
              5
            </Typography>
            <Typography
              // variant="body2"
              style={{
                fontFamily: FONTS_INTER,
                color: COLORS.TextColor,
                position: "relative",
                fontSize:matches.matches === true ?"0.65rem":"13pt",
                top: 3,
                
                // fontWeight: "bold",
              }}
              sx={{
                fontSize: {
                  xs: "0.65rem",
                  sm: "13pt",
                },
                minHeight: {
                  xs: "35px",
                },
                marginRight:{
                  sm: "0.6vW"
                },
                whiteSpace:{
                  sm:"nowrap"
                }
              }}
            >
              Get Delivered
            </Typography>
          </Typography>
        </Typography>

        <hr
          className="stepHrlines"
          style={{
            // height: "4px",
            borderTop: `1px solid ${COLORS.Primary}`,
            maxWidth: "90%",
            // backgroundColor: COLORS.GrayDark,
            // borderColor: COLORS.GrayDark,
            zIndex: "1",
            // height:'1px',
            // background:"#b4b4b4"
          }}
        />
      </Typography>
    </Grid>
  );
};

export default Steps;
