import { Grid, TextField, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import {
  AU_WEBSITE,
  IMAGE_SERVER_URL_DEV,
  SL_WEBSITE,
} from "../../Constants/System";
import { getAllCities, getCompany, getLogo } from "../../Services/API/Get";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WELCOME_IMG from "../../Assets/Welcome/wel3.png";
import _LOGO_IMG from "../../Assets/www3.png";
import COMPANY from "../../Assets/company.png";
import POWER from "../../Assets/Welcome/power.png";
import { FONTS_INTER, FONTS_CHEWY, FONTS_AC, FONTS_RC } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ErrorPopup from "../Models/errorPopup";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { addSubscribe } from "../../Services/API/Post";
import FacebookIcon from '@mui/icons-material/Facebook';
import Video from "../../Assets/launch.mp4";
import Video2 from "../../Assets/launch2.mp4";

function Welcome2() {

  const useStyles = makeStyles((theme) => ({
    "@global": {
      '.MuiAutocomplete-option': {
        width: "100%"
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
        width: matches.matches === true ? "16.6vw" : "17vw",
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "1vw",

        color: COLORS.TextColor2,
        fontWeight: 400,
        outline: "none",
        borderRadius: "6",
        height: "4vh",
        // textAlign:"center",
        // marginLeft:"50%"
      },
      '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
        opacity: 1,
        // WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",

        background: "transparent",
      },
    },
  }));

  const nav = useHistory();
  var matches = window.matchMedia("(min-width: 1920px)");
  var mat425 = window.matchMedia("(max-width: 600px)");
  var mat920 = window.matchMedia("(min-width: 1920px)");
  const [country, setCountry] = useState(null);
  const [logo, setlogo] = useState("");
  const [company, setcompany] = React.useState("");
  const [Category, setCategory] = React.useState([]);
  const [userId, setuserId] = React.useState(null);
  const [email, setemail] = React.useState("");
  const [openRegForm, setOpenRegForm] = useState(false);
  const [openRegForm2, setOpenRegForm2] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const history = useHistory();
  const [cities, setallCities] = React.useState([]);

  const listCities = () => {
    getAllCities()
      .then((res) => {
        console.log("districts>>>", res.data);
        setallCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const companyGet = () => {
    getCompany()
      .then((res) => {
        console.log(res.data);
        setcompany(res?.data[res.data.length - 1]);
      })
      .catch((error) => {
        console.log("error", error.response);
        console.log("current package error", error.response.data.message);
      });
  };
  const logoGet = () => {
    getLogo()
      .then((res) => {
        console.log(res.data);
        setlogo(res.data[res.data.length - 1].image);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const postSubscribe = () => {
    const obj = {
      userId: userId,
      email: email,
      suburb: country,
    };

    console.log("sub>>>>>", obj);
    if (email !== "" && country !== null) {
      addSubscribe(obj)
        .then((res) => {
          setemail("");
          setCountry(null);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Successfully Subscribed");
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current user error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  React.useEffect(() => {
    logoGet();
    companyGet();
    listCities();
  }, []);
  const classes = useStyles();

  return (
    <>
      <ErrorPopup
        openToast={openToast}
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Grid
        style={{
          width: "100vw",
          minHeight: "100vh",
          background: "#014b7b",
          // background: `url(${WELCOME_IMG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid xs={12} container>
          <Grid
            sm={12}
            style={{
              // background: "white",
              height: "100vh",
              display: "flex",
              paddingBottom: "4vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >

            {/* <video width={mat425?.matches === true ? "100%" : "900"} controls loop autoplay> */}
            {/* <video width={mat425?.matches === true ? "100%" : mat920.matches === true ? "1200":"900"} className='VideoTag' autoPlay loop muted>
                <source src={mat425?.matches === true ? Video2 : Video} type='video/mp4' />
              </video> */}
            <video autoPlay loop muted playsInline preload="metadata" width={mat425?.matches === true ? "100%" : mat920.matches === true ? "1200" : "900"}>
              <source src={mat425?.matches === true ? Video2 : Video} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>

          </Grid>

        </Grid>
      </Grid>
    </>
  );
}

export default Welcome2;
