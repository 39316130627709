import {
  Button,
  Grid,
  // Link,
  MenuItem,
  Radio,
  Tooltip,
  Typography,
  Card,
  CardContent,
  TextField,
  // Autocomplete,
  createTheme,
  Popover,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { FONTS_AC, FONTS_ANOTON, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IMG from "../../Assets/sample.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
// import { Select } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CurrentUser,
  getMemberbyId,
  getPackages,
  getCart,
  getMemberCart,
} from "../../Services/API/Get";
import {
  packageToUser,
  packageToMember,
  CartAddress,
} from "../../Services/API/Put";
import _ from "lodash";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";
import Addmember from "../Models/addMemberPopup.jsx";
import ErrorPopup from "../Models/errorPopup.jsx";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner";
import { DeleteCart } from "../../Services/API/Delete";
import { ThemeProvider } from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import CartCount from "../../Componets/CartCount";
import CartContext from "../../Context/CartContext";
var moment = require("moment");

function Cartm() {
  const useSelect = makeStyles(() => ({
    root: {
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },
  }));
  const useStylesAutoComplete = makeStyles((theme) => ({
    "@global": {
      ".MuiAutocomplete-option": {
        width: "100%",
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
        width: "89vw",
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "12pt",

        color: COLORS.TextColor2,
        fontWeight: 400,
        outline: "none",
        borderRadius: "6",
        height: "1.4rem",
      },
      '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
        opacity: 1,
        // WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",

        background: "transparent",
      },
    },
  }));
  const classes = useStylesAutoComplete();
  const [open4, setOpen4] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [idisable, setidisable] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("No");
  const [listOf, setListOf] = useState([
    // {
    //   id: 1,
    //   status: false,
    // },
  ]);

  const [lopen, setlopen] = React.useState(false);
  const [lopen2, setlopen2] = React.useState(false);

  const [userId, setuserId] = useState(null);
  const [memberId, setmemberId] = useState(null);
  const [members, setMembers] = useState([
    // {
    //   firstName: "Add Member",
    //   lastName: "",
    // },
  ]);
  const [packages, setPackages] = useState([]);
  const [planType, setplanType] = useState("");
  const [cartDetails, setUCart] = useState([]);
  const [total, setTotal] = useState("");
  const [delivery] = useState(470);
  const [items, setitems] = useState("");
  const [meals, setMeals] = useState("");
  const [address, setAddress] = useState([]);
  const [name, setname] = useState("");
  const [productMealsIndex, setProductMealsIndex] = useState(0);
  const [customer, setCustomer] = useState([]);
  let mTot = 0;
  const [McartDetails, setMCart] = useState([]);
  const [expired, setExpired] = useState([]);
  const [mexpired, setmExpired] = useState([]);

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const history = useHistory();
  const [showContent, setShowContent] = useState(0);
  const [mexpireStatus, setmexpireStatus] = useState(false);
  const [currMember, setcurrMember] = useState("");
  const [Tmembers, setTMembers] = useState([]);


  const [msgDesc, setMsgDesc] = useState("");

  const [delType, setdelType] = useState("delivery");

  const { setCart, setMenuCategory, menuCategory, testRef } =
    React.useContext(CartContext);

  React.useEffect(() => {
    getMembers();
  }, [open4]);

  React.useEffect(() => {
    if (delType) {
      localStorage.setItem("delType", delType);
    }
  }, [delType])

  const calDelivery = () => {

    let allCart = []

    let delivery = 5

    let Friarr = []

    let Tuearr = []

    let friday, tuesday = ""

    console.log("ALALALLA", listOf)


    listOf?.map((d) => {

      d.productD?.map((d2) => {

        if (d2?.expire === 0)

          allCart.push(d2?.activeDate)

      });



    });

    console.log("POOOL", allCart)

    const datesArrNew = _.uniq(allCart)

    console.log("<><><><>", datesArrNew)

    datesArrNew?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(1, "days")

          break;

        case "Thu":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(2, "days")

          break;

        case "Fri":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(3, "days")

          break;


      }



    });


    // if (delType === "delivery") {

    //   if (Friarr.length > 0) {
    //     delivery += 5;
    //   }

    //   if (Tuearr.length > 0) {
    //     delivery += 5;
    //   }

    // }



    console.log("final del>>>>>", delivery)

    return delivery;


  }

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsgDesc("");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId, planType) => {
    const obj = {
      packageId: pkgId,
    };
    if (planType === "user") {
      packageToUser(obj, userId, "save")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Package Assigned to User");
          setTimeout(() => {
            history.push("/plans");
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    } else if (planType === "member") {
      packageToMember(obj, memberId, "save")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Package Assigned to Member");
          setTimeout(() => {
            history.push(`/plans?memberId=${memberId}`);
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "No") {
      setidisable(true);
    } else {
      setidisable(false);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const PROUDCT_MEALS_CARD = ({ data }) => {
    return (
      <Grid
        item
        xs={12}
        md={4}
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "10px 20px",
        }}
      >
        <Card
          style={{
            // minWidth: 240,
            // maxWidth: 300,
            // minWidth: 275,
            // maxWidth: 220,
            // backgroundColor: "#6caca7",
            backgroundColor: COLORS.Primary,
            width: "100%",
          }}
        >
          <CardContent
            style={{
              padding: "22px",
            }}
          >
            <Typography
              sx={{
                // fontSize: "30pt",
                fontSize: "26pt",
                color: COLORS.Orange2,
                fontWeight: 400,
                fontFamily: FONTS_AC,
                textTransform: "uppercase",
                justifyContent: "center",
                display: "flex",
              }}
              gutterBottom
            >
              {data?.name}
            </Typography>
            <Typography
              component="div"
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "10px 0px 0px",
                // marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: 90,
                  height: 90,
                  borderRadius: "50%",
                  backgroundColor: "#e8eeee",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: COLORS.PrimaryText,
                    // fontSize: "41.2pt",
                    fontSize: "38.2pt",

                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                    lineHeight: "40px",
                  }}
                >
                  {data.noofmeals}
                </Typography>
                <Typography
                  style={{
                    color: COLORS.TextColor,
                    // fontSize: "13.9pt",
                    fontSize: "10.9pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "500",
                  }}
                >
                  MEALS
                </Typography>
              </div>
            </Typography>
            <Typography
              style={{
                // mb: 1.5,
                // mt: 3,
                color: "white",
                textAlign: "center",
                fontSize: "12px",
                fontFamily: FONTS_INTER,
                margin: "20px 0px 10px",
                minHeight: 180,
              }}
              color="text.secondary"
            >
              {data.description}
            </Typography>
            <Typography>
              <p
                style={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  // fontSize: "32.9pt",
                  fontSize: "28.9pt",
                  fontFamily: FONTS_AC,
                  margin: "10px 0px",
                }}
              >
                {" "}
                {data.price}
                {" AUS"}
              </p>
            </Typography>
            <Typography
              variant="body2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                style={{
                  background: COLORS.Orange,
                  fontSize: "9.9pt",
                  fontFamily: FONTS_INTER,
                  color: "white",
                }}
                onClick={() => assignPackage(data.id, planType)}
              >
                SELECT
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const getMembers = () => {
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);
    if (getToken) {
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            console.log("$$$$$$$$", res.data);
            const cData = res.data[0];
            const obj = {
              id: cData.id,
              email: cData?.email,
              firstName: cData?.firstName,
              lastName: cData?.lastName,
              phone: cData?.mobileNumber,
            };

            setuserId(cData?.id);
            getMemberbyId(cData.id)
              .then((res) => {
                console.log(res);
                setTMembers(res?.data);
                const mArr = res.data?.map((d) => {
                  return {
                    ...d,
                  };
                });
                // const mm = mArr?.unshift({
                //   firstName: "Add Member",
                //   lastName: "",
                // });
                setMembers(mArr);
              })
              .catch((error) => {
                console.log("error", error);
              });

            localStorage.setItem(
              `${window.location.hostname}.profile`,
              JSON.stringify(obj)
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current user error", error.response.data.message);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const handleChangeMember = (e, value) => {
    setmemberId(value.id);
  };

  const CartGet = () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;
    let arrUser = [];
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);
    if (getToken) {
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];
            // arrUser.push(cData);
            setCustomer(cData);
            setname(cData.firstName + " " + cData.lastName);
            CartCount().then((res) => {
              setCart(res);
            });
            getCart({
              sortField: sortField,
              sort: sort,
              limit: limit,
              page: page,
              userId: cData?.id,
            })
              .then((res) => {
                setlopen(false);
                console.log("Cart >>>", res.data);
                var arrItems = res.data?.filter(function (el) {
                  return el.expire === 0;
                });

                let product = Object.assign({}, res.data[0]);
                product.usedMeals = arrItems?.length;
                product.type = "user";

                const update2 = res.data?.map((d) => {
                  return {
                    ...d,
                    deliveryDate: JSON.parse(d.productDetails)[0].deliveryDate,
                    Etype: "user",
                    isStudent: cData?.isStudent
                  };
                });

                var newArray = update2?.filter(function (el) {
                  return el.expire === 1;
                });

                var newArr2 = _.uniqBy(newArray, function (e) {
                  return e.deliveryDate;
                });

                newArr2.sort(dynamicSort("deliveryDate"));

                setExpired(newArr2);

                product.productD = update2;
                product.productExpire = newArr2;

                arrUser.push(product);
                const update = arrUser?.map((d) => {
                  return { ...d, status: false, id: d.cartId };
                });
                setListOf(update);
                // setListOf(update)
                setUCart(res.data);
                setTotal(res.data[0].price);
                setitems(res.data.length);
                setMeals(res.data[0].noofmeals);
                // Address = res.data
                setAddress(res.data);

                setShowContent(1);

                getMemberCart({
                  sortField: sortField,
                  sort: sort,
                  limit: limit,
                  page: page,
                  userId: cData?.id,
                })
                  .then((res) => {
                    setlopen(false);
                    console.log("Member Cart >>>", res.data);
                    setMCart(res.data);
                    // setTotal(res.data[0].price)
                    // setitems(res.data.length)
                    // setMeals(res.data[0].noofmeals)
                    // setAddress(res.data)
                    let newArr = [];
                    let mfilter = _.map(res.data, "members");
                    console.log("<<<<<<<", mfilter);

                    mfilter?.map((item, index) => {
                      var arrMItems = item?.filter(function (el) {
                        return el.expire === 0;
                      });

                      console.log("!@@!@@", item[0]);
                      let product = Object.assign({}, item[0]);
                      product.usedMeals = arrMItems.length;
                      product.type = "member";

                      const update2 = item?.map((d) => {
                        return {
                          ...d,
                          deliveryDate: JSON.parse(d.productDetails)[0]
                            .deliveryDate,
                          Etype: "member",

                        };
                      });

                      var newArray = update2?.filter(function (el) {
                        return el.expire === 1;
                      });

                      var newArr2 = _.uniqBy(newArray, function (e) {
                        return e.deliveryDate;
                      });

                      newArr2.sort(dynamicSort("deliveryDate"));

                      product.productD = update2;
                      product.productExpire = newArr2;

                      arrUser.push(product);
                    });

                    const update = arrUser?.map((d) => {
                      return { ...d, status: false, id: d.cartId };
                    });
                    console.log("$$$$$$$$", update);
                    setListOf(update);

                    mfilter?.map((item, index) => {
                      console.log("!@@!@@", item);

                      var newArray = item?.filter(function (el) {
                        return el.expire === 1;
                      });

                      const update2 = newArray?.map((d) => {
                        return {
                          ...d,
                          deliveryDate: JSON.parse(d.productDetails)[0]
                            .deliveryDate,
                        };
                      });

                      var newArr2 = _.uniqBy(update2, function (e) {
                        return e.deliveryDate;
                      });

                      console.log("+++++++", newArr2);

                      if (newArr2.length != 0) {
                        setmexpireStatus(true);
                      }

                      newArr.push(newArr2);
                    });

                    console.log("m expired > >>>>>", newArr);
                    setmExpired(newArr);
                  })
                  .catch((error) => {
                    setlopen(false);
                    console.log("error", error);
                    console.log(
                      "current package error",
                      error.response.data.message
                    );
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                  });
                window.scrollTo(0, 0);
              })

              .catch((error) => {
                setlopen(false);
                setCart(0);
                setShowContent(2);
                window.scrollTo(0, 0);
                console.log("error", error);
                console.log(
                  "current package error",
                  error.response.data.message
                );
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
              });
          }
        })
        .catch(() => {
          setlopen(false);
          setShowContent(2);
        });
    } else {
      setlopen(false);
      setShowContent(2);
    }
  };
  React.useEffect(() => {
    CartCount().then((res) => {
      setCart(res);
    });
  }, []);

  // {
  //   listOf?.map((row, index) => (mTot = parseFloat(row.price) + mTot));
  // }
  {
    mTot = customer?.isStudent === 1 ? listOf[0]?.price * 90 / 100 : listOf[0]?.price;
    Tmembers?.map((row, index) =>
      row.packageId !== null ?

        row?.isStudent === 1 ?
          (mTot = parseFloat(row.price * 90 / 100) + mTot)

          :


          (mTot = parseFloat(row.price) + mTot)

        : false
    );
  }
  const GET_EXPIRE_COMPONETS = ({ data }) => {
    let date = data?.deliveryDate;

    console.log("expire @#@#@#@#@#@", data);
    return (
      <Grid
        style={{
          display: "flex",
          // justifyContent: "space-between",
          padding: " 10px 0px",
          height: "55px",
          background: COLORS.GrayDark3,
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 10px 30px",
            height: "55px",
            background: COLORS.GrayDark3,
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "13pt",
              fontFamily: FONTS_INTER,
              color: COLORS.GrayDark2,
            }}
          >
            {moment(new Date(data?.deliveryDate)).format("DD.MM.YYYY")}
          </Typography>
          <Typography
            style={{
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            <div>
              <AddCircleIcon
                style={{ fontSize: 25, color: COLORS.Orange }}
                onClick={() => {
                  if (data.Etype === "user") {
                    history.push(`/plans?mealDay=${date}`);
                  } else if (data.Etype === "member") {
                    history.push(
                      `/plans?mealDay=${date}&memberId=${data.memberId}`
                    );
                  }
                }}
              />
            </div>
            {/* {`${d.price}.00`} */}
          </Typography>
        </div>
        <hr
          style={{
            borderBottom: `1px solid ${COLORS.Primary}`,
            width: "90%",
          }}
        />
      </Grid>
    );
  };
  const ADD_MORE = ({ data }) => {
    // let date = data?.deliveryDate;

    // console.log("expire @#@#@#@#@#@",data)
    return (
      <Grid
        style={{
          display: "flex",
          // justifyContent: "space-between",
          padding: " 10px 0px",
          height: "70px",
          background: COLORS.GrayDark3,
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: " 10px 30px",
            height: "55px",
            background: COLORS.GrayDark3,
            alignItems: "center",
          }}
        >
          {/* <Typography
            style={{
              fontSize: "13pt",
              fontFamily: FONTS_INTER,
              color: COLORS.GrayDark2,
            }}
          > */}
          {/* {moment(new Date(data?.deliveryDate)).format("DD.MM.YYYY")} */}

          <Button
            style={{
              background: COLORS.green,
              color: "white",
              padding: "5px 20px",
              borderRadius: "4px",
              width: "80%",
              // margin: "-30px 0px 15px 0px",
              fontFamily: FONTS_INTER,
              fontSize: "13.4pt",
              cursor: "pointer",
              // boxShadow: "0 2px 4px 0 #888888",
              display: "inline-flex",
              textTransform: "capitalize",
            }}
            onClick={() => {
              if (data.type === "user") {
                history.push(`/plans`);
              } else if (data.type === "member") {
                history.push(`/plans?&memberId=${data.memberId}`);
              }
            }}
          >
            <AddIcon fontSize="18px" /> <span>Add more</span>
          </Button>
          {/* </Typography> */}
          <Typography
            style={{
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            <div></div>
            {/* {`${d.price}.00`} */}
          </Typography>
        </div>
        <hr
          style={{
            borderBottom: `1px solid ${COLORS.Primary}`,
            width: "90%",
          }}
        />
      </Grid>
    );
  };
  React.useEffect(() => {
    getMembers();
    getPackage();
    CartGet();
  }, []);

  const Checkout = async () => {
    setlopen2(true);
    await CartGet();
    await getMembers();
    let result = "";
    var i = 0;

    if (!delType) {
      setlopen2(false);
      setOpenToast(true);
      setColor(COLORS.Orange);
      setAlertClass("error");
      setMsgDesc("");
      setMsg(
        "Please select collect option to continue checkout."
      );
      setMsgDesc("");
      return;

    }

    const nArr = listOf?.map((d, index) => {
      return {
        ...d,
      };
    });

    nArr.shift();



    setTimeout(() => {
      for (const row of listOf) {
        // console.log("KLKLKLKL", row.productD);

        if (i === 0) {
          for (const d of row.productD) {
            var arrItems = row.productD?.filter(function (el) {
              return el.expire === 0;
            });
            // console.log("#$#$#$#$#$",d)
            if (d.noofmeals - arrItems.length !== 0) {
              // console.log("GHGHGHGH", d.noofmeals - arrItems.length);
              result = false;
              if (result === false && expired.length > 0) {
                setlopen2(false);
                setOpenToast(true);
                setColor(COLORS.Orange);
                setAlertClass("error");
                setMsgDesc("");
                setMsg(
                  "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
                );
                return;
              } else if (result === false) {
                setlopen2(false);
                setOpenToast(true);
                setColor(COLORS.Orange);
                setAlertClass("error");
                setMsgDesc("Incomplete Meal Plan");
                setMsg(
                  `Please complete full weekly plan for ${d.firstName + " " + d.lastName
                  } prior to checkout`
                );
                return;
              }
            }
          }
        } else {
          for (const data of Tmembers) {
            if (data.packageId !== null) {
              if (nArr.length > 0) {
                const newArr1 = _.map(nArr, "memberId");

                if (!newArr1.includes(data.id)) {
                  setlopen2(false);
                  setOpenToast(true);
                  setColor(COLORS.Orange);
                  setAlertClass("error");
                  setMsgDesc("Incomplete Meal Plan")
                  setMsg(
                    `Please complete full weekly plan for ${data.firstName + " " + data.lastName
                    } prior to checkout`
                  );
                  return;
                }
              }
            }
          }

          // row.members?.map((d) => {
          for (const d of row.productD) {
            var arrItems = row.productD?.filter(function (el) {
              return el.expire === 0;
            });
            // console.log("#$#$#$#$#$",d)
            if (d.noofmeals - arrItems.length !== 0) {
              // console.log("GHGHGHGH", d.noofmeals - arrItems.length);
              result = false;
              if (result === false && mexpireStatus === true) {
                setlopen2(false);
                setOpenToast(true);
                setColor(COLORS.Orange);
                setAlertClass("error");
                setMsgDesc("");
                setMsg(
                  "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
                );
                return;
              } else if (result === false) {
                setlopen2(false);
                setOpenToast(true);
                setColor(COLORS.Orange);
                setAlertClass("error");
                setMsgDesc("Incomplete Meal Plan")
                setMsg(
                  `Please complete full weekly plan for ${d.firstName + " " + d.lastName
                  } prior to checkout`
                );
                return;
              }
            }
          }
        }
        // if(result = false){
        //   return;
        // }
        i++;
      }
      if (nArr.length === 0) {
        for (const data of Tmembers) {
          if (data.packageId !== null) {
            setlopen2(false);
            setOpenToast(true);
            setColor(COLORS.Orange);
            setAlertClass("error");
            setMsgDesc("Incomplete Meal Plan")
            setMsg(
              `Please complete full weekly plan for ${data.firstName + " " + data.lastName
              } prior to checkout`
            );
            return;
          }
        }
      }

      console.log("[][][][]][][]", result);

      // && mexpireStatus === false
      console.log("BOBOBO", result, expired.length, mexpireStatus);

      if (result !== false) {
        history.push("/checkout");
      }
      // else if(result === false && expired.length > 0) {
      //   setlopen2(false);
      //   setColor(COLORS.Orange);
      //   setAlertClass("error");
      //   setMsg(
      //     "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
      //   );
      // }else if(result === false && mexpireStatus !== false) {
      //   setlopen2(false);
      //   setColor(COLORS.Orange);
      //   setAlertClass("error");
      //   setMsg(
      //     "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
      //   );
      // }
    }, 0);

    // McartDetails?.map((row, index) => {
  };

  const MinCard = ({ data, index }) => {
    const [onUpdateAddress, setOnUpdateAddress] = useState(data?.homeAddress);
    const [onUpdateCity, setOnUpdateCity] = useState(data?.homeCity);
    const [edit, setEdit] = useState(true);
    const [myCartId, setMyCartId] = useState(null);
    const [myIndex, setMyIndex] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    let date = data?.deliveryDate;
    let dDate = new Date(date);

    // var nextDay = new Date(date);
    // nextDay.setDate(dDate.getDate() - 2);

    var nextDay = moment(date).subtract(2, "days");

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const onChangeAddress = (e, cartId, index, city) => {
      setOnUpdateAddress(e);
      let obj = {
        deliveryAddress: e,
        city: city,
      };

      CartAddress(obj, cartId)
        .then((res) => {
          setMyCartId(null);
          // CartGet();
          // CartGet();
          // setMCart(res.data);
          // setTotal(res.data[0].price)
          // setitems(res.data.length)
          // setMeals(res.data[0].noofmeals)
          // setAddress(res.data)
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });

      // cartDetails[index].homeAddress = e.target.value
      // address[index].homeAddress = e.target.value

      // this.state.dataList[index].owing = e.target.value
      // this.setState({
      //   owing: e.target.value
      // }, () => {
      //   console.log("owing  #######", this.state.dataList[index].owing)
      //   this.runSheetData(createdAt1, index1)
      // })
    };

    const onChangeRemove = () => {
      setlopen(true);
      DeleteCart(data.cartId)
        .then(() => {
          setMyCartId(null);
          CartGet();
        })
        .catch((error) => {
          setlopen(false);
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    };

    return (
      <Grid
        container
        xs={12}
        style={{
          padding: "5px 10px",
          display: "flex",
          flexDirection: "row",
          opacity: data?.expire === 1 ? 0.5 : 1,
        }}
      >
        <Grid
          xs={2.8}
          style={{
            marginRight: "10px",
          }}
        >
          <img
            src={`${IMAGE_SERVER_URL_DEV}${data.image}`}
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "6px",
            }}
          />
        </Grid>
        <Grid
          xs={8.5}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography
            style={{
              fontSize: "11pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
              fontWeight: "bold",
            }}
          >
            {data?.mealType}{" "}
            {data?.expire === 1 ? (
              <>
                -{" "}
                <Tooltip
                  style={{
                    fontSize: "9pt",
                  }}
                  arrow
                  title={
                    data.expire === 1
                      ? `Cutoff time of ordering this meal is 3pm on ${moment(
                        data?.expireDate
                      ).format("DD-MM-YYYY")}`
                      : ""
                  }
                >
                  <span
                    style={{ color: COLORS.Red, fontWeight: "400" }}
                    onClick={handleClick}
                  >
                    EXPIRED
                  </span>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      background: "#616161eb",
                      padding: "2px 5px",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    <span style={{ textAlign: "center" }}>
                      <span> Cutoff time of ordering </span>
                      <br />
                      <span>
                        this meal is 3pm on {moment(data?.expireDate).format("DD-MM-YYYY")}
                      </span>
                    </span>
                  </div>
                </Popover>
              </>
            ) : (
              <></>
            )}
          </Typography>
          <Typography style={{ display: "flex", marginBottom: "3px", flexDirection: "row" }}>
            <Typography
              style={{
                fontSize: "13pt",
                fontFamily: FONTS_INTER,
                color: COLORS.GrayDark2,
                // flex: 1,
              }}
            >
              {data?.productName}{" "}
            </Typography>
            <Typography>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background:
                    data.meatType === "Vegetable"
                      ? "#5dbd34"
                      : data.meatType === "Fish"
                        ? "#5271ff"
                        : data.meatType === "Chicken"
                          ? "#ffc004"
                          : "red",
                  fontSize: "11pt",
                  fontFamily: FONTS_INTER,
                  padding: "3px",
                  marginLeft: "8px",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {data.meatType === "Vegetable"
                  ? "V"
                  : data.meatType === "Fish"
                    ? "F"
                    : data.meatType === "Chicken"
                      ? "C"
                      : data.meatType === "Dried Fish"
                        ? "DF"
                        : data.meatType.charAt(0)}
              </div>

            </Typography>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                borderRadius: "8px",
                background: edit === false ? "white" : "transparent",
                padding: edit === false ? "5px 16px" : "0px 0px",
                width: "77%",
                marginBottom: edit === false ? "5px" : "0px",
              }}
            >
              {edit === false ? (
                <input
                  id="text"
                  //   disabled={edit}
                  // value={
                  //   "25/455, Real Inspire Polgasovita road Mattegoda Kottawa"
                  // }
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    // width: "9.2rem",ddd
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    // flex: 1,
                    fontFamily: FONTS_INTER,
                    background: edit === false ? "white" : COLORS.GrayDark3,
                    width: "100%",
                  }}
                  //   onChange={(e) => {
                  //     setAddress(e.target.value);

                  //   }}
                  onChange={(e) => {
                    // onChangeAddress(e, row.cartId, index);
                    setOnUpdateAddress(e.target.value);
                    setMyCartId(data.cartId);
                    setMyIndex(index);
                  }}
                  value={onUpdateAddress}
                  placeholder="Enter Delivery Address"
                />
              ) : (
                <Typography
                  style={{
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    flex: 1,
                    fontFamily: FONTS_INTER,
                  }}
                >
                  {`${onUpdateAddress}`}
                </Typography>
              )}
            </div>

            {/* {edit === true ? (
              <div
                onClick={() => setEdit(false)}
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
              >
                <Tooltip arrow title="Enable Address Field">
                  <EditIcon />
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setOnUpdateAddress(data?.homeAddress);
                  setMyCartId(null);
                  setEdit(true);
                }}
              >
                <Tooltip arrow title="Cancel">
                  <ClearIcon />
                </Tooltip>
              </div>
            )}
            {edit === false && myCartId !== null && (
              <div
                style={{
                  marginLeft: 10,
                }}
                onClick={() => {
                  onChangeAddress(onUpdateAddress, myCartId, myIndex);
                  setEdit(true);
                }}
              >
                <Tooltip arrow title="Save New Address">
                  <SaveIcon />
                </Tooltip>
              </div>
            )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                borderRadius: "8px",
                background: edit === false ? "white" : "transparent",
                padding: edit === false ? "5px 16px" : "0px 0px",
                width: "100%",
              }}
            >
              {edit === false ? (
                <input
                  id="text"
                  //   disabled={edit}
                  // value={
                  //   "25/455, Real Inspire Polgasovita road Mattegoda Kottawa"
                  // }
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    // width: "9.2rem",ddd
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    flex: 1,
                    fontFamily: FONTS_INTER,
                    background: edit === false ? "white" : COLORS.GrayDark3,
                    width: "100%",
                  }}
                  //   onChange={(e) => {
                  //     setAddress(e.target.value);

                  //   }}
                  onChange={(e) => {
                    // onChangeAddress(e, row.cartId, index);
                    setOnUpdateCity(e.target.value);
                    setMyCartId(data.cartId);
                    setMyIndex(index);
                  }}
                  value={onUpdateCity}
                  placeholder="Enter Delivery Address"
                />
              ) : (
                <Typography
                  style={{
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    flex: 1,
                    fontFamily: FONTS_INTER,
                  }}
                >
                  {/* 25/455, Real Inspire Polgasovita road Mattegoda Kottawa */}
                  {`${onUpdateCity}`}
                </Typography>
              )}
            </div>

            {edit === true ? (
              <div
                // onClick={() => setEdit(false)}

                // onClick={() => {
                //   if (data?.expire === 0) {
                //     setEdit(false);
                //   } else {
                //     setOpenToast(true);
                //     setColor(COLORS.Orange);
                //     setAlertClass("error");
                //     setMsg("Meal Has Expired");
                //   }
                // }}
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
              >
                <div>
                  <Tooltip arrow title="Remove">
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={
                        data.expire === 0
                          ? () => onChangeRemove()
                          : () => {
                            setOpenToast(true);
                            setColor(COLORS.Orange);
                            setAlertClass("error");
                            setMsgDesc("");
                            setMsg(
                              "The cut-off time for ordering this meal has been passed. "
                            );
                          }
                      }
                    />
                  </Tooltip>
                </div>
                {/* <Tooltip arrow title="Enable Address Field">
                  <EditIcon
                    style={{
                      opacity: data?.expire === 1 ? 0.2 : 1,
                    }}
                  />
                </Tooltip> */}
              </div>
            ) : (
              <div
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
                onClick={() => {
                  if (data?.expire === 0) {
                    setOnUpdateAddress(data?.homeAddress);
                    setOnUpdateCity(data?.homeCity);
                    setMyCartId(null);
                    setEdit(true);
                  } else {
                    setOpenToast(true);
                    setColor(COLORS.Orange);
                    setAlertClass("error");
                    setMsgDesc("");
                    setMsg("Meal Has Expired");
                  }
                }}
              >
                <Tooltip arrow title="Cancel">
                  <ClearIcon />
                </Tooltip>
              </div>
            )}
            {edit === false && myCartId !== null && (
              <div
                style={{
                  marginLeft: 10,
                }}
                onClick={() => {
                  onChangeAddress(
                    onUpdateAddress,
                    myCartId,
                    myIndex,
                    onUpdateCity
                  );
                  setEdit(true);
                }}
              >
                <Tooltip arrow title="Save New Address">
                  <SaveIcon />
                </Tooltip>
              </div>
            )}
          </div>
          {/* <Typography
            style={{
              fontSize: "12pt",
              color: "#A3A3A3",
              flex: 1,
              fontFamily: FONTS_INTER,
              textDecoration: data?.expire === 1 ? "line-through" : "none",
            }}
          >
           
            {`${data?.homeCity}`}
          </Typography> */}
        </Grid>
      </Grid>
    );
  };
  const handleClose = () => setOpen(false);
  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  const classes2 = useSelect();
  return (
    <>
      <LoadingSpinner open={showContent === 0 ? true : false} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95vw",
            // maxHeight: "95vh",
            maxHeight: "95%",
            overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            style={{
              background: "white",
              padding: "30px 10px",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={12}
                container
                spacing={2}
                style={{
                  paddingLeft: 0,
                  marginLeft: 0,
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <div onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: "22pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {packages
                    ?.filter((_, index) => index === productMealsIndex)
                    ?.map((data) => {
                      return <PROUDCT_MEALS_CARD data={data} />;
                    })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      opacity: productMealsIndex > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      // window.document.querySelector("#areas").scrollLeft -= 100;
                      if (productMealsIndex > 0) {
                        setProductMealsIndex(productMealsIndex - 1);
                      }
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      opacity:
                        productMealsIndex < packages?.length - 1 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (productMealsIndex < packages?.length - 1) {
                        setProductMealsIndex(productMealsIndex + 1);
                      }
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid
        xs={12}
        style={{
          display: showContent === 1 ? "block" : "none",
        }}
      >
        <Grid
          style={{
            padding: "25px 0px",
          }}
        >
          <Typography
            style={{
              fontSize: "30px",
              fontFamily: FONTS_ANOTON,
              fontWeight: "400",
              color: COLORS.TitleColor,
              textTransform: "uppercase",
            }}
          >
            My Meal Plan
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            {listOf.map((d, index) => {
              if (index === 0) {

                var newArr = d.productD.filter(expire => expire.expire === 0)

                var result = _.map(newArr, "deliveryDate");

                var result = result.sort(function (a, b) {
                  if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                  return -1;
                });

                const minDate = result.reduce((acc, date) => {
                  return acc && acc < date ? acc : date;
                }, "");
                const maxDate = result.reduce((acc, date) => {
                  return acc && acc > date ? acc : date;
                }, "");

                localStorage.setItem('deliveryDate', moment(minDate).format("DD.MM.YYYY"));
                // console.log("GHGHGH", maxDate);

                return (
                  <>
                    {newArr.length > 0 && (
                      <>
                        {/* for the period of
                        <br />
                       
                        {`${moment(minDate).format("DD.MM.YYYY")} - ${moment(
                          maxDate
                        ).format("DD.MM.YYYY")}`} */}
                      </>
                    )}
                  </>
                );
              }
            })}
          </Typography>
        </Grid>
        {listOf.map((d) => {
          var result = groupByKey(d.productD, "deliveryDate");

          let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));
          console.log("^^^^^^^^^^^", cItem);
          var cItem2 = cItem.sort(function (a, b) {
            if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
            return -1;
          });
          return (
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: COLORS.Primary,
                  padding: "5px 30px",
                  height: "55px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "15pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {`${d.firstName + " " + d.lastName}`}
                </Typography>
                <div>
                  {d.status === false ? (
                    <div
                      onClick={() => {
                        const up = listOf?.map((e) =>
                          e.id === d.id
                            ? { ...e, status: true }
                            : { ...e, status: false }
                        );
                        setListOf(up);
                      }}
                    >
                      <KeyboardArrowDownIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        const up = listOf?.map((e) =>
                          e.id === d.id
                            ? { ...e, status: false }
                            : { ...e, status: false }
                        );
                        setListOf(up);
                      }}
                    >
                      <KeyboardArrowUpIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              {d.status && (
                <>
                  <Grid
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      xs={3.9}
                      style={{
                        background: COLORS.GrayDark6,
                        color: "white",
                        fontSize: "12pt",
                        fontFamily: FONTS_INTER,
                        height: "41px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* 21 Entitled */}
                      {`${d.noofmeals} Entitled`}
                    </Grid>
                    <Grid
                      xs={3.9}
                      style={{
                        background: COLORS.Primary3,
                        color: "white",
                        fontSize: "12pt",
                        fontFamily: FONTS_INTER,
                        height: "41px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* 15 Used */}
                      {`${d.usedMeals} Used`}
                    </Grid>
                    <Grid
                      xs={3.9}
                      style={{
                        background: COLORS.Red,
                        color: "white",
                        fontSize: "12pt",
                        fontFamily: FONTS_INTER,
                        height: "41px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* 5 Unused */}
                      {`${d.noofmeals - d.usedMeals} Unused`}
                    </Grid>
                  </Grid>

                  {cItem2?.map((item) => {
                    let cartProducts = item[Object.keys(item)[0]];
                    console.log("!@!@!@!@####", item[Object.keys(item)[0]]);
                    var newArr = [];
                    cartProducts?.map((d) => {
                      if (d.mealType === "Dinner") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Breakfast") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Lunch") {
                        newArr.push(d);
                      }
                    });
                    return (
                      <Grid
                        style={{
                          background: COLORS.GrayDark3,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "13pt",
                            fontFamily: FONTS_INTER,
                            color: COLORS.GrayDark2,
                            textAlign: "center",
                            padding: "15px 0px",
                            opacity: newArr[0]?.expire === 1 ? 0.5 : 1,
                          }}
                        >
                          {/* 23.04.2022 */}
                          {moment(newArr[0]?.deliveryDate).format("DD.MM.YYYY")}
                        </Typography>
                        {newArr?.map((data, index) => {
                          return <MinCard data={data} index={index} />;
                        })}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <hr
                            style={{
                              width: "90%",
                              //   paddingBottom: "10px",
                              margin: 0,
                              marginTop: "20px",
                              opacity: newArr[0]?.expire === 1 ? 0.3 : 1,
                            }}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  {/* {d.productExpire?.map((data, index) => {
                    return <GET_EXPIRE_COMPONETS data={data} />;
                  })} */}

                  {/* {d.noofmeals - d.usedMeals !== 0 ||
                  d.productExpire.length > 0 ? (
                    <ADD_MORE data={d} />
                  ) : (
                    <></>
                  )} */}

                  {d.noofmeals - d.usedMeals !== 0 ? (
                    <ADD_MORE data={d} />
                  ) : (
                    <></>
                  )}
                </>
              )}

              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: " 36px 30px 26px",
                  height: "55px",
                  background: COLORS.GrayDark3,
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "15pt",
                    fontFamily: FONTS_INTER,
                    color: COLORS.Primary,
                  }}
                >
                  SUB TOTAL{" "} {d?.isStudent === 1 ? "( 10% Discount )" : ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: "15pt",
                    fontFamily: FONTS_INTER,
                    color: COLORS.Primary,
                  }}
                >
                  {/* 4500.00 */}

                  {`${parseFloat(d?.isStudent === 1 ? d.price * 90 / 100 : d.price).toFixed(2)}`}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        {Tmembers.map((d) => {
          const nArr = listOf?.map((d, index) => {
            return {
              ...d,
            };
          });

          nArr.shift();

          if (d.packageId !== null) {
            // var result = groupByKey(d.productD, "deliveryDate");

            // let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));
            // console.log("^^^^^^^^^^^", cItem);
            // var cItem2 = cItem.sort(function (a, b) {
            //   if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
            //   return -1;
            // });

            if (nArr.length > 0) {
              const newArr1 = _.map(nArr, "memberId");

              if (!newArr1.includes(d.id)) {
                return (
                  <Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: COLORS.Primary,
                        padding: "5px 30px",
                        height: "55px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "15pt",
                          fontFamily: FONTS_INTER,
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        {`${d.firstName + " " + d.lastName}`}
                      </Typography>
                    </Grid>

                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: " 36px 30px 26px",
                        height: "55px",
                        background: COLORS.GrayDark3,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "15pt",
                          fontFamily: FONTS_INTER,
                          color: COLORS.Primary,
                        }}
                      >
                        SUB TOTAL{" "} {d?.isStudent === 1 ? "( 10% Discount )" : ""}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "15pt",
                          fontFamily: FONTS_INTER,
                          color: COLORS.Primary,
                        }}
                      >
                        {/* 4500.00 */}
                        {`${parseFloat(d.price).toFixed(2)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
            } else {
              return (
                <Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      background: COLORS.Primary,
                      padding: "5px 30px",
                      height: "55px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "15pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {`${d.firstName + " " + d.lastName}`}
                    </Typography>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: " 36px 30px 26px",
                      height: "55px",
                      background: COLORS.GrayDark3,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "15pt",
                        fontFamily: FONTS_INTER,
                        color: COLORS.Primary,
                      }}
                    >
                      SUB TOTAL
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "15pt",
                        fontFamily: FONTS_INTER,
                        color: COLORS.Primary,
                      }}
                    >
                      {/* 4500.00 */}
                      {`${parseFloat(d?.isStudent === 1 ? d.price * 90 / 100 : d.price).toFixed(2)}`}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
          }
        })}
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 10px 30px",
            height: "55px",
            // background: COLORS.TitleColor,
            background: COLORS.GrayDark3,

            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            TOTAL
          </Typography>
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            {/* 4500.00 */}

            {`${parseFloat(mTot).toFixed(2)}`}
          </Typography>
        </Grid>



        {
          delType === "delivery" &&

          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: " 10px 30px",
              height: "55px",
              // background: COLORS.TitleColor,
              background: COLORS.GrayDark3,

              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "15pt",
                fontFamily: FONTS_INTER,
                color: COLORS.Primary,
              }}
            >
              DELIVERY
            </Typography>
            <Typography
              style={{
                fontSize: "15pt",
                fontFamily: FONTS_INTER,
                color: COLORS.Primary,
              }}
            >
              {/* 4500.00 */}

              {`${parseFloat(calDelivery()).toFixed(2)}`}
            </Typography>
          </Grid>

        }
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 10px 30px",
            height: "55px",
            // background: COLORS.TitleColor,
            background: COLORS.GrayDark3,

            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            GST 10%
          </Typography>
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
            }}
          >
            {/* 4500.00 */}

            {`${parseFloat((mTot + calDelivery()) * 10 / 100).toFixed(2)}`}
          </Typography>
        </Grid>


        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 10px 30px",
            height: "55px",
            background: COLORS.TitleColor,
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: "white",
            }}
          >
            FINAL TOTAL
          </Typography>
          <Typography
            style={{
              fontSize: "15pt",
              fontFamily: FONTS_INTER,
              color: "white",
            }}
          >
            {/* 4500.00 */}



            {`${parseFloat(((mTot + calDelivery()) * 110 / 100)).toFixed(2)}`}
          </Typography>
        </Grid>
        {customer.packageId !== null && customer.packageId !== undefined ? (
          <>
            {/* <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Grid xs={11}>
                <Typography
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "3vh",
                    alignItems: "center",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid lg={5.7} md={7.2} xs={12}>
                    <Typography
                      style={{
                        fontSize: "12.8pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "600",
                        color: COLORS.Primary,
                        textAlign: "left",
                      }}
                    >
                      How would you like to collect the order ?
                    </Typography>
                  </Grid>

                </Typography>

                <Typography
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "1.5vh",
                    alignItems: "center",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid
                    lg={5.3}
                    md={7}
                    xs={12}
                    sx={{
                      // paddingRight: { md: "18px" },
                      margin: {
                        xs: "15px 0px",
                      },
                      width: {
                        xs: "100%",
                        sm: "fit-content",
                      },
                      // marginRight: "10px",
                    }}
                  >
               
                    <div id="selectElementM" style={{ width: "100%" }}>
                      <Select
                        // disabled={idisable}
                        // classes={classes2}
                        fullWidth
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        // multiple
                        value={delType}
                        // onChange={handleChange}
                        // input={<OutlinedInput label="Name" />}
                        // MenuProps={MenuProps}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "flex-start",
                          background: COLORS.GrayDark3,
                          padding: "5px 0px",
                          borderRadius: "8px",
                          textAlign: "left"
                        }}
                        onChange={(event, newValue) => {
                          console.log("------------>", newValue.props.value);

                          setdelType(newValue.props.value)

                          // localStorage.setItem("delType", newValue.props.value)


                        }}
                        sx={{
                          "@global": {
                            "& .MuiSelect-select:focus": {
                              backgroundColor: "transparent",
                            },
                          },
                          root: {
                            "& .MuiInput-underline:before": {
                              borderBottom: "none",
                            },
                            "& .MuiSelect-select:focus": {
                              backgroundColor: "transparent",
                            },

                          },
                          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                            // height: 10,
                            border: "none",
                          },
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            width: "100%",
                            // padding: "5px 12px ",
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",

                          },
                          // "& .MuiList-root .MuiMenu-list": {
                          //   flexDirection: "column",
                          //   display: "flex",
                          //   alignItems: "flex-start",

                          // },
                          // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     borderColor: COLORS.PrimaryBackground,
                          //   },
                          // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     borderColor: COLORS.PrimaryBackground,
                          //   },
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "8px 0px",
                            backgroundColor: "transparent",
                          },
                          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                            minHeight: "35px"
                          }
                        }}
                      >
                        <MenuItem
                          key={0}
                          value={""}
                          // onClick={() => setOpen4(true)}
                          style={{
                            // marginBottom: 5,
                            padding: "0px 16px ",
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            minHeight: "35px"
                          }}
                          sx={{
                            "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                              minHeight: "35px"
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                           
                            <label>&nbsp;Please Select Collect Option</label>
                          </div>
                        </MenuItem>
                        <MenuItem
                          key={0}
                          value={"pick up"}
                          // onClick={() => setOpen4(true)}
                          style={{
                            // marginBottom: 5,
                            padding: "0px 16px ",
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            minHeight: "35px"
                          }}
                          sx={{
                            "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                              minHeight: "35px"
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                           
                            <label>&nbsp;In-store pick up</label>
                          </div>
                        </MenuItem>

                        <MenuItem
                          key={0}
                          value={"delivery"}
                          // onClick={() => setOpen4(true)}
                          style={{
                            // marginBottom: 5,
                            padding: "0px 16px ",
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            minHeight: "35px"
                          }}
                          sx={{
                            "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                              minHeight: "35px",
                              paddingRight: "20px"
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                           
                            <label>&nbsp;Delivery ($5 will be charged for each delivery)</label>
                          </div>
                        </MenuItem>

                      </Select>
                    </div>
                  </Grid>

                </Typography>
                {delType === "delivery" && (
                  <Typography
                    variant="caption"
                    // color="red"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      // margin: "5px 0px",
                      fontSize: "8pt",
                      marginLeft: "0vW",


                    }}
                  >
                    Delivery is available only for selected suburbs. Please check "Pick up & Delivery" page for more details.
                  </Typography>
                )}


              </Grid>
            </Grid> */}

            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "60px",
              }}
            >
              <Grid xs={11}>
                <Typography
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "2vh",
                    alignItems: "center",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid lg={5.7} md={7.2} xs={12}>
                    <Typography
                      style={{
                        fontSize: "12.8pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "600",
                        color: COLORS.Primary,
                        textAlign: "left",
                      }}
                    >
                      Do you want to create meal plans for your family members ?
                    </Typography>
                  </Grid>
                  <Grid
                    lg={6.3}
                    md={4.8}
                    xs={12}
                    sx={{
                      marginTop: {
                        xs: "10px",
                        sm: "0px",
                      },
                      width: {
                        xs: "100%",
                        sm: "fit-content",
                      },
                    }}
                  >
                    <Typography
                      style={{ display: "flex" }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          flex: 1,
                        }}
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                          },
                          alignItems: {
                            sm: "center",
                            xs: "flex-start",
                          },
                        }}
                      >
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "Yes"}
                            onChange={handleChange}
                            value="Yes"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            style={{
                              color: COLORS.Primary,
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            Yes
                          </Typography>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "No"}
                            onChange={handleChange}
                            value="No"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "B" }}
                            style={{
                              color: COLORS.Primary,
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            No
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                          },
                        }}
                      >
                        {/* <Link
                        to="/checkout"
                        style={{
                          textDecoration: "none",
                          color: "white",

                          cursor: "pointer",
                        }}
                      > */}
                        <Button
                          size="large"
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            // marginTop: 10,
                            padding: "3px 15px",
                            background: COLORS.Primary,
                            borderRadius: 4,
                            color: "white",
                          }}
                          variant="contained"
                          onClick={(e) => Checkout()}
                        >
                          CHECKOUT
                        </Button>
                        {/* </Link> */}
                      </Typography>
                    </Typography>
                  </Grid>
                </Typography>
                {idisable === false ? (
                  <Typography
                    style={{
                      marginBottom: "1vh",
                      display: "flex",
                      marginTop: "1.5vh",
                      alignItems: "center",
                    }}
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <Grid
                      lg={5.3}
                      md={7}
                      xs={12}
                      sx={{
                        // paddingRight: { md: "18px" },
                        margin: {
                          xs: "15px 0px",
                        },
                        width: {
                          xs: "100%",
                          sm: "fit-content",
                        },
                        // marginRight: "10px",
                      }}
                    >
                      {/* <div
                      style={{
                        marginLeft: 0,
                        height: "40px",
                        borderRadius: 6,
                        background: COLORS.GrayDark3,
                        width: "89vw",
                        padding: "0px 5px",
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <Autocomplete
                        options={members}
                        classes={classes}
                        // value={memberDropDown}
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName}`
                        }
                        onChange={(event, newValue) => {
                          console.log("on Chnage --->", newValue);
                          setcurrMember(newValue);
                          handleChangeMember(event, newValue);
                          // setMemberDropDown(newValue)
                        }}
                        renderOption={(option) => {
                          return (
                            <React.Fragment>
                              <p
                                style={{
                                  fontSize: "12pt",
                                  padding: "0",
                                  margin: "0",
                                  width: "",
                                  color: COLORS.TextColor,
                                  width: "233px",
                                  fontFamily: FONTS_INTER,
                                }}
                              >
                                {option.firstName === "Add Member" ? (
                                  <div
                                    onClick={() => setOpen4(true)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContetn: "flex-start",
                                      fontSize: "12pt",
                                      fontFamily: FONTS_INTER,
                                    }}
                                  >
                                    <AddCircleIcon
                                      style={{
                                        fontSize: 16,
                                        color: COLORS.Primary,
                                      }}
                                    />
                                    <div>&nbsp; Add Member</div>
                                  </div>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "12pt",
                                      fontFamily: FONTS_INTER,
                                    }}
                                  >{`${option.firstName} ${option.lastName}`}</span>
                                )}
                              </p>
                            </React.Fragment>
                          );
                        }}
                        ListboxProps={{
                          style: {
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            // width: matches.matches === true ?"16.6vw"  : "200px",
                            background: "white",
                            // width:"100%"
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div> */}
                      {/* <ThemeProvider theme={theme}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Australia", "SriLanka"]}
                onChange={(event, value) => {
                  // setCountry(value);
                }}
                sx={{
                  width: "90vw",
                  background: COLORS.GrayBackground,
                  borderRadius: "8px",
                  "& .MuiInputBase-input": {
                    height: 10,
                    border: "none",
                  },
                  //view
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.GrayBackground,
                  },

                  "&:hover .MuiOutlinedInput-input": {
                    color: COLORS.GrayBackground,
                  },
                  "&:hover .MuiInputLabel-root": {
                    color: COLORS.GrayBackground,
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: COLORS.GrayBackground,
                    },
                  ".MuiAutocomplete-root fieldset:hover": {
                    color: COLORS.GrayBackground,
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
                ListboxProps={{
                  style: {
                    maxHeight: "150px",
                  },
                }}
              />
            </ThemeProvider> */}
                      <div id="selectElementM" style={{ width: "100%" }}>
                        <Select
                          // disabled={idisable}
                          // classes={classes2}
                          fullWidth
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          // multiple
                          // value={personName}
                          // onChange={handleChange}
                          // input={<OutlinedInput label="Name" />}
                          // MenuProps={MenuProps}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          // multiple
                          value={currMember}

                          style={{
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "flex-start",
                            background: COLORS.GrayDark3,
                            padding: "5px 0px",
                            borderRadius: "8px",
                            textAlign: "left"
                          }}
                          onChange={(event, newValue) => {
                            console.log("------------>", newValue.props.value);
                            setcurrMember(newValue.props.value);

                            handleChangeMember(event, newValue.props.value);
                          }}
                          sx={{
                            "@global": {
                              "& .MuiSelect-select:focus": {
                                backgroundColor: "transparent",
                              },
                            },
                            root: {
                              "& .MuiInput-underline:before": {
                                borderBottom: "none",
                              },
                              "& .MuiSelect-select:focus": {
                                backgroundColor: "transparent",
                              },

                            },
                            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                              // height: 10,
                              border: "none",
                            },
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              width: "100%",
                              // padding: "5px 12px ",
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",

                            },
                            // "& .MuiList-root .MuiMenu-list": {
                            //   flexDirection: "column",
                            //   display: "flex",
                            //   alignItems: "flex-start",

                            // },
                            // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     borderColor: COLORS.PrimaryBackground,
                            //   },
                            // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     borderColor: COLORS.PrimaryBackground,
                            //   },
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              padding: "8px 0px",
                              backgroundColor: "transparent",
                            },
                            "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                              minHeight: "35px"
                            }
                          }}
                        >
                          <MenuItem
                            key={0}
                            value={""}
                            // onClick={() => setOpen4(true)}
                            style={{
                              padding: "6px 16px ",
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <label>Select Member
                              </label>
                            </div>
                          </MenuItem>
                          <MenuItem
                            key={0}
                            value={{ id: null }}
                            onClick={() => setOpen4(true)}
                            style={{
                              // marginBottom: 5,
                              padding: "0px 16px ",
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                              minHeight: "35px"
                            }}
                            sx={{
                              "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                                minHeight: "35px"
                              }
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AddCircleIcon
                                style={{ fontSize: 15, color: COLORS.Primary }}
                                onClick={() => setOpen4(true)}
                              />
                              <label>&nbsp; Add Member</label>
                            </div>
                          </MenuItem>
                          {members.map((name) => (
                            <MenuItem
                              key={name.id}
                              value={name}
                              style={{
                                // marginBottom: 5,
                                // padding: "6px 5px ",
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                padding: "0px",
                                textAlign: "left",
                                minHeight: "35px"
                              }}
                              sx={{
                                "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                                  minHeight: "35px"
                                }
                              }}

                            // style={getStyles(name, personName, theme)}
                            >
                              <div style={{ padding: "0px 16px" }}>
                                {name.firstName + " " + name.lastName}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Grid>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          disabled={idisable}
                          size="small"
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            background: COLORS.Primary,
                            // color: COLORS.yellow,
                            color: "white",
                            borderRadius: 4,
                            padding: "6px 25px",
                          }}
                          variant="contained"
                          sx={{
                            padding: "4px 25px",
                            marginRight: {
                              xs: "0px",
                              sm: "10px",
                            },
                            width: {
                              xs: "100%",
                              sm: "fit-content",
                            },
                            marginTop: {
                              xs: "10px",
                              sm: "0px",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (memberId !== null) {
                              if (currMember.packageId !== null) {
                                setTimeout(() => {
                                  history.push(
                                    `/plans?memberId=${currMember.id}`
                                  );
                                }, 0);
                              } else {
                                setOpen(true);
                                setplanType("member");
                              }
                            } else {
                              setOpenToast(true);
                              setColor(COLORS.Orange);
                              setAlertClass("error");
                              setMsgDesc("");
                              setMsg("Select a member first.");
                            }
                          }}
                        >
                          {currMember === "" || currMember === null
                            ? "SELECT A PACKAGE FOR THE MEMBER AND CREATE MEAL PLANS"
                            : currMember.packageId !== null &&
                              currMember.packageId !== undefined
                              ? "CREATE MEAL PLAN"
                              : "SELECT A PACKAGE FOR THE MEMBER AND CREATE MEAL PLANS"}
                          {/* Select a package & Create */}
                        </Button>
                      </div>
                    </div>
                  </Typography>
                ) : (
                  <></>
                )}
                <Typography
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  {/* <Link
                  to="/checkout"
                  style={{
                    textDecoration: "none",
                    color: "white",

                    cursor: "pointer",
                    width: "100%",
                  }}
                > */}
                  <Button
                    size="large"
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      // marginTop: 10,
                      padding: "6px 14px",
                      background: COLORS.Orange,
                      width: "100%",
                      color: "white",

                      borderRadius: 4,
                    }}
                    variant="contained"
                    color="success"
                    onClick={(e) => Checkout()}
                  >
                    CHECKOUT
                  </Button>
                  {/* </Link> */}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      {showContent === 0 ? (
        <Typography
          style={{
            height: "80vh",
          }}
        ></Typography>
      ) : (
        <></>
      )}

      {showContent === 2 ? (
        <Grid
          xs={12}
          style={{
            minHeight: "40vh",
            display: "flex",
            justifyContent: "center",
            padding: "8%",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "20pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Red,
              fontWeight: "bold",
            }}
          >
            "Oops ! Your meal plan is empty"
          </Typography>
          <Typography
            style={{
              fontSize: "12pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
              fontWeight: "bold",
            }}
          >
            Looks like you haven't added anything to your meal plan yet
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      <Addmember open={open4} handleClose={() => setOpen4(false)} />
      <LoadingSpinner open={lopen} />
      <LoadingSpinner open={lopen2} />
      <ErrorPopup
        msgDesc={msgDesc}
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
    </>
  );
}

export default Cartm;
