import React, { useEffect } from "react";
import { CurrentUser, getCart,getMemberCart } from "../Services/API/Get";
import _ from "lodash";

const CartCount = async () => {
  const myCountOfCart = async () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;

    let Count = 0;
    const response = await CurrentUser();
    if (response.statusCode === 200) {
      const cData = response.data[0];

      const update = await getCart({
        sortField: sortField,
        sort: sort,
        limit: limit,
        page: page,
        userId: cData?.id,
      });
      console.log("BOBOBO",update)
      if (update.statusCode === 200) {
        var arrItems = update.data?.filter(function (el) {
          return el.expire === 0;
        });
        console.log("Cart count >>>", arrItems.length);
        Count = Count + arrItems.length;
        // return Count;
      }


      await getMemberCart({
        sortField: sortField,
        sort: sort,
        limit: limit,
        page: page,
        userId: cData?.id,
      })
      .then((res) => {
        console.log("BOBOBO222",res)
        if (res.statusCode === 200) {
          for (const row of res.data) {
           
            console.log("KLKLKLKL", row.members);

            var arrItems = row.members?.filter(function (el) {
              return el.expire === 0;
            });

            Count = Count + arrItems.length
      
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        return Count;
      });
    
    }
    console.log("Cart count >>>", Count);
    return Count;

    //    { .then((res) => {
    //       if (res.statusCode === 200) {

    //         //  { .then((res) => {
    //         //     console.log("Cart count >>>", res.data.length);
    //         //   })
    //         //   .catch((error) => {
    //         //     console.log("error", error);
    //         //     console.log("current package error", error.response.data.message);
    //         //     // setOpenToast(true);
    //         //     // setColor(COLORS.Orange);
    //         //     // setAlertClass("error");
    //         //     // setMsg(error.response.data.message);
    //         //   });}
    //       }
    //     });}
  };

  return await myCountOfCart();
};

export default CartCount;
