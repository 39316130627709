import React, { useEffect, useMemo, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Modal } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function LoadingSpinner({open}) {
    const [close, setClose] = useState(false);

    const theme = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: {
          main: '#f44336',
        },
      },
    });

  return (
    <Modal
    open={open}
    onClose={() =>
        setClose(false)
        }
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ThemeProvider theme={theme}>
    {/* <h3
      style={{
        color: "white",
      }}
    >
      Saving..
    </h3> */}



    <div style={{ outline: 'none'}}>
      <CircularProgress />
    </div>
    </ThemeProvider>
  </Modal>
  );
}