import React, { useState, useRef } from "react";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert, Divider } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import {
  Grid,
  Box,
  createTheme,
  Modal,
  Typography,
  Button,
  Tooltip,
  TextField,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { FONTS, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import { CurrentUser, getCities, getDistricts } from "../../Services/API/Get";
import { AddMember } from "../../Services/API/Post";
import ErrorPopup from "../Models/errorPopup.jsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import { upperFirst, lowerCase } from 'lodash';
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";

function CreateMember({ open, handleClose }) {
  const history = useHistory();
  var matches = window.matchMedia("(max-width: 600px)");
  var matches900 = window.matchMedia("(max-width: 900px)");

  const GreenCheckbox = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
      marginLeft: "0.5rem",
      padding: 0,
    },
  })((props) => <Checkbox color="default" {...props} />);

  let check1 = 0;
  const [isStudent, setisStudent] = useState(false);


  const [selectedValue, setSelectedValue] = React.useState("b");
  const errScroll = useRef();
  const scrollElement = () => errScroll.current.scrollIntoView();

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [userId, setuserId] = useState("");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [officeCity, setofficeCity] = useState("");
  const [insurer, setInsurer] = useState("");
  const [policy, setPolicy] = useState("");
  const [entitlement, setentitlement] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");

  const [idisable, setidisable] = useState(true);
  const [closePopup, setClosepopup] = useState(false);

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState("");
  const [errofficeNo, seterrofficeNo] = useState("");
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [errofficeAddress, seterrofficeAddress] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errofficeCity, seterrofficeCity] = useState(false);
  const [errinsurer, seterrInsurer] = useState(false);
  const [errpolicy, seterrPolicy] = useState(false);
  const [errentitlement, seterrentitlement] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");
  const [image, setImage] = useState("");

  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeDistrictId, sethomeDistrictId] = useState(1);
  const [officeDistrictId, setofficeDistrictId] = useState(1);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [officeDistrict, setofficeDistrict] = useState("");
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);
  const [allOCities, setallOCities] = useState([]);
  const [allODistricts, setallODistricts] = useState([]);

  const [homepostCode, sethomepostCode] = useState("");
  const [officepostCode, setofficepostCode] = useState("");
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [lopen, setlopen] = React.useState(false);

  const [studentId, setstudentId] = useState("");
  const [errstudentId, seterrstudentId] = useState(false);

  const useStyles = makeStyles((theme) => ({
    "@global": {
      '.MuiAutocomplete-option': {
        width: "100%"
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "10.5pt",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: 500,
        outline: "none",

        height: "1.4rem",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        background: "rgba(232,232,232,0.5)",
      },
    },
  }));

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "95vw",
      md: "75vw",
      lg: "55vw",
      sm: "70vw",
    },
    maxHeight: {
      xs: "80vh",
      sm: "88vh",
    },
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: {
      sm: "32px",
      xs: "16px 8px",
    },
    borderRadius: "4px",
  };

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];


    if (e.target.files[0] !== null) {


      if (!((e.target.files[0]?.size / 1000) < 1024)) {
        setOpenToast(true);
        setColor(COLORS.Primary);
        setAlertClass("error");
        setMsg("Your file size  should be below 1 MB");
        return;
      }
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    let fileInp = e.target.files[0];
    let baseURL;

    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;

      setImage(baseURL);
      console.log("image>>>>>", baseURL);
    };
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "b") {
      setidisable(true);
      setInsurer("");
      setPolicy("");
      setentitlement("");
    } else {
      setidisable(false);
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const onSubmit = async (e) => {

    e.preventDefault();

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    // if (homeNo === "") {
    //   seterrhomeNo("This Field is Requied");
    // }
    if (mobile === "") {
      seterrmobile("This Field is Requied");
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    // if(password === ""){
    //   seterrPass(true)
    // }
    // if(repassword === ""){
    //   seterrRePass("This Field is Requied")
    // }
    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(firstName)),
      lastName: upperFirst(lowerCase(lastName)),
      mobileNumber: mobile,
      email: email,
      homeNumber: homeNo,
      officeNumber: officeNo,
      homeAddress: homeAddress,
      officeAddress: officeAddress,
      homeCity: homeCity,
      officeCity: officeCity,
      insurer: insurer,
      policyNo: policy,
      entitlement: entitlement,
      userId: userId,
      password: password,
      image: image,
      homeDistrict: homeDistrict,
      officeDistrict: officeDistrict,
      homepostCode: homepostCode,
      officepostCode: officepostCode,
      isStudent: isStudent === true ? 1 : 0,
    };
    console.log("obj>>>>", obj);

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }
    // if (!phoneRegex.test(homeNo)) {
    //   if (homeNo === "") {
    //     seterrhomeNo("This Field is Requied");
    //   } else {
    //     seterrhomeNo("Please Enter Valid number");
    //   }
    // }
    // if (officeNo !== "") {
    //   if (!phoneRegex.test(officeNo)) {
    //     seterrofficeNo("Please Enter Valid number");
    //   }
    // }

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homepostCode !== "" &&
      homeDistrict !== "" &&
      homeCity !== "" &&
      // homeNo !== "" &&
      mobile !== "" &&
      phoneRegex.test(mobile)
      // phoneRegex.test(homeNo) &&
      // (officeNo !== "" ? phoneRegex.test(officeNo) : true)
    ) {
      setlopen(true);
      AddMember(obj)
        .then((res) => {
          setlopen(false);
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Member added successfully");
          setfirstName("");
          setlasttName("");
          setmobile("");
          setemail("");
          sethomeNo("");
          setofficeNo("");
          sethomeAddress("");
          setofficeAddress("");
          sethomeCity("");
          setofficeCity("");
          setInsurer("");
          setPolicy("");
          setentitlement("");
          setImage("");
          sethomeDistrict("");
          setofficeDistrict("");
          sethomepostCode("");
          setofficepostCode("");

          getCurrentUser();
          listDistricts();
          setisStudent(false);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          setOpenToast(true);
          setColor("red");
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    } else {
      scrollElement();
    }
  };
  const onSubmit2 = async (e) => {

    e.preventDefault();

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    // if (homeNo === "") {
    //   seterrhomeNo("This Field is Requied");
    // }
    if (mobile === "") {
      seterrmobile("This Field is Requied");
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    // if(password === ""){
    //   seterrPass(true)
    // }
    // if(repassword === ""){
    //   seterrRePass("This Field is Requied")
    // }
    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(firstName)),
      lastName: upperFirst(lowerCase(lastName)),
      mobileNumber: mobile,
      email: email,
      homeNumber: homeNo,
      officeNumber: officeNo,
      homeAddress: homeAddress,
      officeAddress: officeAddress,
      homeCity: homeCity,
      officeCity: officeCity,
      insurer: insurer,
      policyNo: policy,
      entitlement: entitlement,
      userId: userId,
      image: image,
      homeDistrict: homeDistrict,
      officeDistrict: officeDistrict,
      homepostCode: homepostCode,
      officepostCode: officepostCode,
      isStudent: isStudent === true ? 1 : 0,
      // password:password
    };
    console.log("obj>>>>", obj);

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }
    // if (!phoneRegex.test(homeNo)) {
    //   if (homeNo === "") {
    //     seterrhomeNo("This Field is Requied");
    //   } else {
    //     seterrhomeNo("Please Enter Valid number");
    //   }
    // }
    // if (officeNo !== "") {
    //   if (!phoneRegex.test(officeNo)) {
    //     seterrofficeNo("Please Enter Valid number");
    //   }
    // }

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homepostCode !== "" &&
      homeDistrict !== "" &&
      homeCity !== "" &&
      // homeNo !== "" &&
      mobile !== "" &&
      phoneRegex.test(mobile)
      // phoneRegex.test(homeNo) &&
      // (officeNo !== "" ? phoneRegex.test(officeNo) : true)
    ) {
      setlopen(true);
      AddMember(obj)
        .then((res) => {
          setlopen(false);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("You have successfully added a member. ");
          setTimeout(() => {
            handleClose(false);

            setfirstName("");
            setlasttName("");
            setmobile("");
            setemail("");
            sethomeNo("");
            setofficeNo("");
            sethomeAddress("");
            setofficeAddress("");
            sethomeCity("");
            setofficeCity("");
            setInsurer("");
            setPolicy("");
            setentitlement("");
            setImage("");
            sethomeDistrict("");
            setofficeDistrict("");
            sethomepostCode("");
            setofficepostCode("");
            setisStudent(false);
          }, 0);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          setOpenToast(true);
          setColor("red");
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    } else {
      scrollElement();
    }
  };
  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };

          setuserId(cData?.id);
          // setfirstName(cData?.firstName)
          // setlasttName(cData?.lastName)
          setemail(cData?.email)
          sethomeAddress(cData?.homeAddress)
          sethomeDistrict(cData?.homeDistrict)
          sethomeCity(cData?.homeCity)
          sethomepostCode(cData?.homepostCode)
          // setofficeAddress(cData?.officeAddress)
          // setofficeDistrict(cData?.officeDistrict)
          // setofficeCity(cData?.officeCity)
          // setofficepostCode(cData?.officepostCode)
          setmobile(cData?.mobileNumber)
          // sethomeNo(cData?.homeNumber)
          // setofficeNo(cData?.officeNumber)
          // setInsurer(cData?.insurer)
          // setPolicy(cData?.policyNo)
          // setentitlement(cData?.entitlement)

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listOfficecities = () => {
    getCities(officeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallOCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
    listDistricts();
  }, [open]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  React.useEffect(() => {
    if (officeDistrictId !== 0) {
      listOfficecities();
    }
  }, [officeDistrictId]);

  const handleChangeOdist = (e, value) => {
    if (value !== null) {

      setofficeDistrict(value.name_en);
      setofficeDistrictId(value.id);
    } else {
      setofficeDistrict("");
      setofficeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeOcity = (e, value) => {
    if (value !== null) {
      setofficeCity(value.name_en);
      setofficepostCode(value.postcode);
      seterrofficeCity(false);
    } else {
      setofficeCity("");
      setofficepostCode("");
    }

    console.log("city>>", value);
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      sethomeCity(value.name_en);
      sethomepostCode(value.postcode);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    console.log("city>>", value);
  };

  const InputField = ({ title, id, value, error }) => {
    return (
      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
        <Typography
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              fontFamily: FONTS,
              color: COLORS.TextColor,
            }}
            gutterBottom
          >
            {title}
          </Typography>
          <input
            style={{
              fontFamily: FONTS,
              fontSize: 14,
              color: "#55555",
              padding: "4px 10px",
              outline: "none",
              border: "1px solid #666",
              borderRadius: 3,
            }}
          />
          {error && (
            <Typography
              variant="caption"
              color="red"
              style={{
                textAlign: "left",
                fontFamily: FONTS,
                margin: "5px 0px",
              }}
            >
              This Field is Requied
            </Typography>
          )}
        </Typography>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <LoadingSpinner open={lopen} />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleCloseToast}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // padding: "40px 0px",
              }}
            >
              <Grid md={11.2} xs={12} style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontSize: "12pt",
                    textAlign: "left",
                    color: "white",
                    background: COLORS.Primary,
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    padding: "12px 15px 12px 15px",
                    // letterSpacing: "0.9px",
                    fontWeight: "600",
                    padding: "10px 15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PersonIcon
                        style={{
                          width: 45,
                          height: 45,
                          padding: 2,
                          background: "white",
                          color: COLORS.Primary,
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          color: "white",
                        }}
                      >
                        PERSONAL DETAILS
                      </Typography>
                    </div>
                  </div>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    padding: "17px 20px",
                    background: COLORS.GrayDark3,
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    ref={errScroll}
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      padding: 0,
                      background: "white",
                    }}
                  >
                    {/* <Grid xs={12} container>
                      <Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: "10px 15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {image !== "" ? (
                          <>
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onChangeAvatar}
                            />
                            <label htmlFor="icon-button-file">
                              <Tooltip arrow title="Upload Avatar">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <img
                                    src={image}
                                    style={{
                                      width: 75,
                                      height: 75,
                                      padding: 8,
                                      background: COLORS.GrayDark5,
                                      color: "white",
                                      borderRadius: "50%",
                                      marginRight: "18px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onChangeAvatar}
                            />
                            <label htmlFor="icon-button-file">
                              <Tooltip arrow title="Upload Avatar">
                                <IconButton
                                  style={{
                                    width: 100,
                                    height: 100,
                                    padding: 8,
                                    borderRadius: "50%",
                                    marginRight: "18px",
                                  }}
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <PersonIcon
                                    style={{
                                      width: 75,
                                      height: 75,
                                      padding: 8,
                                      background: COLORS.GrayDark5,
                                      color: "white",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>
                          </>
                        )}
                        <Typography
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            color: COLORS.GrayDark6,
                          }}
                        >
                          Upload your profile picture
                        </Typography>
                      </div>
                    </div>
                    </Typography>
                    </Grid>
                    <Grid xs={12} container>
                <Typography
              gutterBottom
              style={{
                textAlign: "left",
                // width: "90%",
                // marginTop: 5,
                fontSize: "7.5pt",
                padding:"0px 10px",
                color: COLORS.TextColor,
              }}
            >
              Maximum upload file size : 1 MB
            </Typography>
          
                
  

               
                </Grid> */}
                  </Grid>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      background: "white",
                    }}
                  >
                    <Divider
                      style={{
                        width: "93%",
                      }}
                    />
                  </div> */}
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginTop: "25px",
                      padding: "15px 25px",
                      background: "white",
                    }}
                  >
                    <Grid item md={12} xs={12} style={{ width: "100%" }}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            // variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            First Name *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setfirstName(e.target.value);
                              seterrfirstName(false);
                            }}
                            value={firstName}
                          />
                          {errfirstName && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Last Name *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setlasttName(e.target.value);
                              seterrlasttName(false);
                            }}
                            value={lastName}
                          />
                          {errlastName && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Email *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setemail(e.target.value);
                              seterremail(false);
                            }}
                            value={email}
                          />
                          {erremail && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Mobile Number *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setmobile(e.target.value);
                              seterrmobile("");
                            }}
                            value={mobile}
                          />
                          {errmobile && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              {errmobile}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Home Address *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              sethomeAddress(e.target.value);
                              seterrhomeAddress(false);
                            }}
                            value={homeAddress}
                          />
                          {errhomeAddress && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        display="flex"
                        style={{ padding: "0px 0px 20px 0px", justifyContent: "space-between" }}
                        xs={12}
                      >
                        <Grid xs={12} md={4} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              State *
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <Autocomplete
                                classes={classes}
                                defaultValue={{ name_en: homeDistrict }}
                                value={{ name_en: homeDistrict }}
                                options={allHDistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, newValue) => {
                                  handleChangeHdist(event, newValue);
                                }}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "9pt",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                    >
                                      {option.name_en}
                                    </p>
                                  </React.Fragment>
                                )}
                                ListboxProps={{
                                  style: {
                                    fontSize: "10.5pt",
                                    fontFamily: FONTS_INTER,
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>

                            {errhomeDistrict && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={4} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                marginTop: {
                                  xs: "15px",
                                  lg: "0px",
                                  md: "0px",
                                },
                              }}
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              Suburb *
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <Autocomplete
                                classes={classes}
                                defaultValue={{ name_en: homeCity }}
                                value={{ name_en: homeCity }}
                                options={allHCities}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, newValue) => {
                                  handleChangeHcity(event, newValue);
                                }}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "9pt",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                    >
                                      {option.name_en}
                                    </p>
                                  </React.Fragment>
                                )}
                                ListboxProps={{
                                  style: {
                                    fontSize: "10.5pt",
                                    fontFamily: FONTS_INTER,
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                            {errhomeCity && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={3} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                marginTop: {
                                  xs: "15px",
                                  lg: "0px",
                                  md: "0px",
                                },
                              }}
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              Postcode *
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <input
                                style={{
                                  fontFamily: FONTS_INTER,
                                  fontSize: "10.5pt",
                                  padding: "4px 10px",
                                  outline: "none",
                                  borderRadius: 3,
                                  height: "2rem",
                                  border: "none",
                                  background: COLORS.GrayDark3,
                                  color: COLORS.TextColor2,
                                  width: matches.matches === true ? "93%" : matches900.matches === true ? "97%" : "84%",
                                }}
                                onChange={(e) => {
                                  sethomepostCode(e.target.value);
                                  seterrhomepostCode(false);
                                }}
                                value={homepostCode}
                              />
                            </div>
                            {errhomepostCode && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        display="flex"
                        style={{ justifyContent: "space-between" }}
                        xs={12}
                      >

                        <Grid xs={8} sm={8} style={{
                          margin: "15px 0px", display: "flex",
                          flexDirection: "column", justifyContent: "left"
                        }}>

                          {/* <FormControl>
                          <FormControlLabel
                            control={

                              <GreenCheckbox
                                checked={isStudent}
                                size="medium"
                                onChange={(e) => {
                                  check1 = e.target.checked;
                                  console.log("check 01 ", e.target.checked);
                                  setisStudent(e.target.checked)
                                }}
                              />
                            }
                            label={
                              <Typography

                                style={{
                                  fontSize: matches900.matches === true ? "9.5pt" : "0.9vW",
                                  fontFamily: FONTS_INTER,
                                  // marginTop: "15px",
                                  marginLeft: "5px",
                                  color: COLORS.TextColor,
                                  textAlign: "left",
                                }}
                              >
                                Is the member an International Student ?
                              </Typography>
                            }
                          />
                        </FormControl> */}

                          <Typography

                            style={{
                              fontSize: matches900.matches === true ? "10.5pt" : "1.1vW",
                              fontFamily: FONTS_INTER,
                              // marginTop: "15px",
                              marginLeft: "5px",
                              color: COLORS.Primary,
                              textAlign: "left",
                            }}
                          >
                            Is the member an International Student ?
                          </Typography>


                        </Grid>

                        <Grid
                          item
                          // md={3}
                          sm={4}
                          xs={4}
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            margin: "15px 0px",
                          }}
                          sx={{
                            marginTop: {
                              xs: "10px",
                              sm: "0px",
                            },
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Radio

                              size="small"
                              checked={isStudent === true}
                              onChange={(e) => {
                                check1 = true;
                                console.log("check 01 ", true);
                                setisStudent(true)
                              }}
                              value={true}
                              name="radio-buttons"
                              inputProps={{ "aria-label": "A" }}
                              style={{
                                color: COLORS.Primary,
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{
                                color: COLORS.Primary,
                                fontFamily: FONTS_INTER,
                                marginRight: 5,
                              }}
                            >
                              Yes
                            </Typography>
                            <Radio

                              size="small"
                              checked={isStudent === false}
                              onChange={(e) => {
                                check1 = false;
                                console.log("check 01 ", false);
                                setisStudent(false)
                              }}
                              value={false}
                              name="radio-buttons"
                              inputProps={{ "aria-label": "B" }}
                              style={{
                                color: COLORS.Primary,
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{
                                color: COLORS.Primary,

                                fontFamily: FONTS_INTER,
                              }}
                            >
                              No
                            </Typography>
                          </div>
                        </Grid>

                      </Grid>


                      <Grid item xs={12} md={12} style={{ margin: "15px 0px", }}>

                        {
                          isStudent &&

                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              Enter Student Id
                            </Typography>
                            <input

                              style={{
                                fontFamily: FONTS_INTER,
                                fontSize: "10.5pt",
                                color: COLORS.TextColor2,
                                padding: "4px 10px",
                                outline: "none",
                                border: "1px solid ",
                                // borderColor: COLORS.InputBorderColor,
                                borderRadius: 6,
                                height: "2rem",
                                border: "none",

                                background: COLORS.GrayDark3,
                              }}
                              type="text"
                              onChange={(e) => {
                                setstudentId(e.target.value);
                                seterrstudentId(false);
                              }}
                              value={studentId}
                            />
                            {errstudentId && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>


                        }



                      </Grid>
                    </Grid>
                    {/* <Grid
                      item
                      md={5.5}
                      xs={12}
                      style={{ width: "100%", marginTop: "15px" }}
                    >
                      <Grid md={12} xs={12} style={{ margin: "0px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Office Address
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setofficeAddress(e.target.value);
                              seterrofficeAddress(false);
                            }}
                            value={officeAddress}
                          />
                          {errofficeAddress && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        display="flex"
                        style={{
                          // padding: "15px 0px 0px 0px",
                          justifyContent: "space-between",
                        }}
                        sx={{
                          padding: {
                            xs: "0px ",
                            sm: "15px 0px 0px 0px",
                          },
                          marginTop: {
                            xs: "15px",
                            lg: "0px",
                            md: "0px",
                          },
                        }}
                        xs={12}
                      >
                        <Grid xs={12} md={4} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              State
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <Autocomplete
                                classes={classes}
                                defaultValue={{ name_en: officeDistrict }}
                                value={{ name_en: officeDistrict }}
                                options={allODistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, newValue) => {
                                  handleChangeOdist(event, newValue);
                                }}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "9pt",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                    >
                                      {option.name_en}
                                    </p>
                                  </React.Fragment>
                                )}
                                ListboxProps={{
                                  style: {
                                    fontSize: "10.5pt",
                                    fontFamily: FONTS_INTER,
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                            {errofficeAddress && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={4} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                marginTop: {
                                  xs: "15px",
                                  lg: "0px",
                                  md: "0px",
                                },
                              }}
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              Suburb
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <Autocomplete
                                classes={classes}
                                defaultValue={{ name_en: officeCity }}
                                value={{ name_en: officeCity }}
                                options={allOCities}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, newValue) => {
                                  handleChangeOcity(event, newValue);
                                }}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "9pt",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                    >
                                      {option.name_en}
                                    </p>
                                  </React.Fragment>
                                )}
                                ListboxProps={{
                                  style: {
                                    fontSize: "10.5pt",
                                    fontFamily: FONTS_INTER,
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                            {errofficeCity && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={3} style={{ padding: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                marginTop: {
                                  xs: "15px",
                                  lg: "0px",
                                  md: "0px",
                                },
                              }}
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                fontSize: "8.5pt",
                                // letterSpacing: "1px",
                                fontWeight: "bold",
                              }}
                              gutterBottom
                            >
                              Postcode
                            </Typography>
                            <div
                              style={{
                                marginLeft: 0,
                                height: "2rem",
                                borderRadius: 3,
                              }}
                            >
                              <input
                                style={{
                                  fontFamily: FONTS_INTER,
                                  fontSize: "10.5pt",
                                  padding: "4px 10px",
                                  outline: "none",
                                  borderRadius: 3,
                                  height: "2rem",
                                  border: "none",
                                  background: COLORS.GrayDark3,
                                  color: COLORS.TextColor2,
                                  width: matches.matches === true ? "93%" : "85%",
                                }}
                                onChange={(e) => {
                                  setofficepostCode(e.target.value);
                                  // seterrmobile("");
                                }}
                                value={officepostCode}
                              />
                              
                            </div>
                            {errofficeCity && (
                              <Typography
                                variant="caption"
                                color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  margin: "5px 0px",
                                  fontSize: "8pt",
                                }}
                              >
                                This Field is Requied
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Mobile Number *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setmobile(e.target.value);
                              seterrmobile("");
                            }}
                            value={mobile}
                          />
                          {errmobile && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              {errmobile}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Home Number *
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              sethomeNo(e.target.value);
                              seterrhomeNo("");
                            }}
                            value={homeNo}
                          />
                          {errhomeNo && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              {errhomeNo}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                          >
                            Office Number
                          </Typography>
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                            }}
                            onChange={(e) => {
                              setofficeNo(e.target.value);
                              seterrofficeNo("");
                            }}
                            value={officeNo}
                          />
                          {errofficeNo && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                                fontSize: "8pt",
                              }}
                            >
                              {errofficeNo}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid> */}
                  </Grid>
                  {false ?

                    <div
                      style={{
                        marginTop: 20,
                        background: "white",
                        padding: "15px 25px",
                      }}
                    >
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          marginTop: 30,
                        }}
                      >
                        <Grid
                          lg={9}
                          md={9}
                          sm={10}
                          xs={12}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              color: COLORS.Primary,
                              textAlign: "left",
                              // letterSpacing: 1,
                              fontFamily: FONTS_INTER,
                              fontWeight: "400",
                              fontSize: "13pt",
                            }}
                          >
                            Do you have a life insurance policy ?
                            {/* with Union Assurance or HNB
                        Assurance ? */}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              textAlign: "left",
                              float: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontWeight: "400",
                              fontSize: "10pt",
                            }}
                          >
                            ( If <b>"YES"</b>, please fill the below section and
                            "check" your eligibility to get discounts. )
                          </Typography>
                        </Grid>
                        <Grid
                          md={3}
                          sm={2}
                          xs={12}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            style={{ display: "flex" }}
                            sx={{
                              flexDirection: {
                                xs: "column",
                                sm: "row",
                              },

                              alignItems: {
                                xs: "flex-start",
                                sm: "center",
                              },
                              marginTop: {
                                xs: "10px",
                                sm: "0px",
                              },
                            }}
                          >
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: 10,
                              }}
                              sx={{
                                marginTop: {
                                  xs: "10px",
                                  sm: "0px",
                                },
                              }}
                            >
                              <Radio
                                size="small"
                                checked={selectedValue === "a"}
                                onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                style={{
                                  color: COLORS.Primary,
                                }}
                              />
                              <Typography
                                variant="body2"
                                style={{
                                  color: COLORS.Primary,
                                  fontFamily: FONTS_INTER,
                                }}
                              >
                                Yes
                              </Typography>
                            </Typography>
                            <Typography
                              style={{ display: "flex", alignItems: "center" }}
                              sx={{
                                marginTop: {
                                  xs: "10px",
                                  sm: "0px",
                                },
                              }}
                            >
                              <Radio
                                size="small"
                                checked={selectedValue === "b"}
                                onChange={handleChange}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "B" }}
                                style={{
                                  color: COLORS.Primary,
                                }}
                              />
                              <Typography
                                variant="body2"
                                style={{
                                  color: COLORS.Primary,

                                  fontFamily: FONTS_INTER,
                                }}
                              >
                                No
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 30,
                        }}
                      >
                        <Grid item xs={12} md={3} lg={3} sm={5.5}>
                          <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                            <Typography
                              component="div"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  color: COLORS.TextColor,
                                  fontSize: "8.5pt",
                                  // letterSpacing: "1px",
                                  fontWeight: "bold",
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                gutterBottom
                              >
                                Select your insurer
                              </Typography>
                              <input
                                disabled={idisable}
                                style={{
                                  fontFamily: FONTS_INTER,
                                  fontSize: "10.5pt",
                                  padding: "4px 10px",
                                  outline: "none",
                                  borderRadius: 3,
                                  height: "2rem",
                                  border: "none",
                                  background: COLORS.GrayDark3,
                                  color: COLORS.TextColor2,
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                onChange={(e) => setInsurer(e.target.value)}
                                value={insurer}
                              />
                              {false && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 0px",
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} sm={5.5}>
                          <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                            <Typography
                              component="div"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  color: COLORS.TextColor,
                                  fontSize: "8.5pt",
                                  letterSpacing: "1px",
                                  fontWeight: "bold",
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                gutterBottom
                              >
                                Enter Policy Number
                              </Typography>
                              <input
                                disabled={idisable}
                                style={{
                                  fontFamily: FONTS_INTER,
                                  fontSize: "10.5pt",
                                  padding: "4px 10px",
                                  outline: "none",
                                  borderRadius: 3,
                                  height: "2rem",
                                  border: "none",
                                  background: COLORS.GrayDark3,
                                  color: COLORS.TextColor2,
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                onChange={(e) => setPolicy(e.target.value)}
                                value={policy}
                              />
                              {false && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 0px",
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={3}
                          md={3}
                          style={{ display: "flex", alignItems: "flex-end" }}
                        // sx={{
                        //   padding: {
                        //     sm: 0,
                        //     md: "35px 0px",
                        //   },
                        // }}
                        >
                          <Button
                            disabled={idisable}
                            size="large"
                            variant="contained"
                            // style={{
                            //   background: "#808182",
                            //   height: "34px",
                            //   marginBottom: 14,
                            // }}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "12pt",
                              color: "white",
                              padding: "20px 15px",
                              outline: "none",
                              border: "1px solid ",
                              borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              background: COLORS.Orange2,
                              opacity: idisable === true ? 0.5 : 1,
                              marginBottom: 14,
                              boxShadow: "none",
                              // width: "fit-content",
                            }}
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "fit-content",
                              },
                            }}
                          >
                            CHECK ELIGIBLITY
                          </Button>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3}>
                          <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                            <Typography
                              component="div"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  color: COLORS.TextColor,
                                  fontSize: "8.5pt",
                                  letterSpacing: "1px",
                                  fontWeight: "bold",
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                gutterBottom
                              >
                                Your entitlement
                              </Typography>
                              <input
                                disabled={idisable}
                                style={{
                                  fontFamily: FONTS_INTER,
                                  fontSize: "10.5pt",
                                  padding: "4px 10px",
                                  outline: "none",
                                  borderRadius: 3,
                                  height: "2rem",
                                  border: "none",
                                  background: COLORS.GrayDark3,
                                  color: COLORS.TextColor2,
                                  opacity: idisable === true ? 0.5 : 1,
                                }}
                                onChange={(e) => setentitlement(e.target.value)}
                                value={entitlement}
                              />
                              {false && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS,
                                    margin: "5px 0px",
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    : <></>}

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                      float: "right",
                    }}
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        // height: "34px",
                        marginBottom: 19,
                        // padding: "20px 15px",
                        // fontSize: "11pt",
                        background: COLORS.Primary,
                        boxShadow: "none",
                        color: "white",
                        // width: "146px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        paddingTop: "2px",
                      }}
                      sx={{
                        fontSize: {
                          xs: "12pt",
                          sm: "11pt",
                        },
                        height: {
                          xs: "34pt",
                          sm: "34px",
                        },
                        width: {
                          xs: "100%",
                          lg: "146px",
                          md: "146px",
                        },
                      }}
                      onClick={onSubmit2}
                    >
                      Save
                    </Button>

                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        // height: "34px",
                        marginBottom: 19,
                        // padding: "20px 15px",
                        // fontSize: "11pt",
                        background: COLORS.Orange2,
                        color: COLORS.GrayDark2,
                        boxShadow: "none",

                        fontWeight: "bold",
                        borderRadius: "5px",
                        paddingTop: "4px",
                        marginLeft: "10px",
                      }}
                      sx={{
                        fontSize: {
                          xs: "12pt",
                          sm: "11pt",
                        },
                        height: {
                          xs: "34pt",
                          sm: "34px",
                        },
                        width: {
                          xs: "100%",
                          lg: "146px",
                          md: "146px",
                        },
                      }}
                      onClick={onSubmit}
                    >
                      Save & Add
                    </Button>
                  </Grid>
                </div>
                {/* <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} md={5} lg={5} sm={5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Enter Password
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      seterrPass(false);
                    }}
                    value={password}
                  />
                  {errPass && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      This Field is Requied
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} lg={5} sm={5}>
              <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      color: COLORS.TextColor,
                      fontSize: "8.5pt",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Re Enter Password
                  </Typography>
                  <input
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: 14,
                      color: "#55555",
                      padding: "4px 10px",
                      outline: "none",
                      border: "1px solid ",
                      borderColor: COLORS.InputBorderColor,
                      borderRadius: 3,
                      height: "2rem",
                    }}
                    type="password"
                    onChange={(e) => {
                      setrePassword(e.target.value);
                      seterrRePass("");
                    }}
                    value={repassword}
                  />
                  {errRePass && (
                    <Typography
                      variant="caption"
                      color="red"
                      style={{
                        textAlign: "left",
                        fontFamily: FONTS_INTER,
                        margin: "5px 0px",
                        fontSize:"8pt",
                      }}
                    >
                      {errRePass}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
              </Grid>
            </Grid>
          </ThemeProvider>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default CreateMember;
