import { Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../../Constants/Colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { borderLeft } from "@mui/system";
import { FONTS_AC, FONTS_INTER } from "../../../Constants";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
function Faq() {
  const errScroll = useRef();

  const history = useHistory();


  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const [tabsSwitch, setTabSwitch] = useState([
    {
      id: 1,
      title:
        "Does Ceylon Healthy Food cater for food allergies and/or specific dietary requirements?",
      status: false,
      content:
        "NO. Our meal plans are regularized for the standard population, and we do not customize meals based on a person’s special requirements. You will need to check the menus and ingredients before you order food. ",
    },
    {
      id: 2,
      title: "Should I pay for my registration?",
      status: false,
      content:
        "No. You can create your account for FREE. But before start creating your weekly meal plan should purchase a package.",
    },
    {
      id: 3,
      title: "Is there any cut-off time to confirm the order? ",
      status: false,
      content:
        "Yes, there is a cut-off time for confirming orders. In this case, the cut-off time is Wednesday at 3pm. Orders must be confirmed before this time in order to be processed.",
    },
    {
      id: 4,
      title: "Will I get a refund if I cancel my weekly plan? ",
      status: false,
      content:
        "No. Due to the nature of our business, you cannot cancel your order. As soon as you place the order it is confirmed by our kitchen and will be locked in the inventory. ",
    },
    // {
    //   id: 5,
    //   title: "Can I change the delivery address once I placed the order? ",
    //   status: false,
    //   content:
    //     "No. Once your order is confirmed, you cannot change the delivery address. We have given full flexibility to change the delivery address till the last moment of the order finalization to avoid your inconvenience. ",
    // },
    {
      id: 6,
      title: "Can I request a Diet History report? ",
      status: false,
      content:
        "YES. You can request a diet history report and it will cost AUD 50 per report. ",
    },
    // {
    //   id: 7,
    //   title: "Will I get a notification upon order delivery? ",
    //   status: false,
    //   content:
    //     "Yes. The delivery partner will ring you upon arrival and will leave the order on the doorstep. ",
    // },
    // {
    //   id: 8,
    //   title: "Should I pay extra for delivery? ",
    //   status: false,
    //   content: "No. Your package includes FREE delivery fees. ",
    // },
  ]);

  useEffect(() => {
    let pQuery = query.get("refund");

    console.log("KKKDDDd", pQuery)

    if (pQuery === "true") {

      var newArr = [...tabsSwitch]

      console.log("MMMAAA<<<<<", newArr)

      newArr[3].status = true;

      setTabSwitch(newArr);

      errScroll.current.scrollIntoView({ block: 'center', behavior: 'smooth' });

      history.push("/work")

    }


  }, [query.get("refund")])


  const TABS = ({ title, content, tab }) => {
    // const [toggle, setToggle] = useState(tab);
    var matches = window.matchMedia("(max-width: 600px)");
    return (
      <Grid container xs={12}>
        <Grid
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "10px 20px 20px 40px",
            background: COLORS.PrimaryBackground,
            alignItems: "center",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flex: 1,
              color: COLORS.Primary,
              fontWeight: "500",
              textAlign: "left",
              fontFamily: FONTS_INTER,
              fontSize: matches.matches === true ? "13pt" : "16pt",
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => {


              if (tab?.status === true) {

                const up = tabsSwitch?.map((c) =>
                  c.id === tab.id
                    ? { ...c, status: false }
                    : { ...c, status: false }
                );
                setTabSwitch(up);

              } else {

                const up = tabsSwitch?.map((c) =>
                  c.id === tab.id
                    ? { ...c, status: true }
                    : { ...c, status: false }
                );
                setTabSwitch(up);

              }


            }}
          >
            {tab?.status === true && <KeyboardArrowUpIcon />}
            {tab?.status === false && <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
        {tab?.status === true && (
          <Grid
            xs={12}
            md={12}
            style={{
              padding: "10px 20px 20px 40px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Typography
              style={{
                textAlign: "justify",
                color: COLORS.TextColor,
                fontFamily: FONTS_INTER,
                fontSize: "13pt",
              }}
            >
              {content}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <Grid
      ref={errScroll}
      xs={10}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px 0px",
      }}
    >
      <Typography
        style={{
          color: COLORS.Primary,
          fontWeight: "600",
          marginBottom: "35px",
          fontFamily: FONTS_AC,
          fontSize: "36px",
        }}
      >
        FREQUENTLY ASKED QUESTIONS (FAQ)
      </Typography>
      <Grid

        container
        xs={12}
        style={{
          border: "1px solid #ccc",
          borderBottom: "none",
          borderRadius: "3px",
        }}
      >
        {tabsSwitch?.map((d) => {
          return <TABS title={d?.title} content={d.content} tab={d} />;
        })}
      </Grid>
    </Grid>
  );
}

export default Faq;
