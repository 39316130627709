import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FONTS, FONTS_AC, FONTS_ANOTON, FONTS_INTER, FONTS_RC } from "../Constants";
import { COLORS } from "../Constants/Colors";
import { getAllCities } from "../Services/API/Get";

const sub = [
  {
    state: "VIC",
    suburb: [
      "3805 - Narre Warren",
      "3805 - Narre Warren South",
      "3976 - Hampton Park",
      "3975 - Lynbrook",
      "3977 - Cranbourne",
      "3978 - Clyde North",
      "3977 - Cranbourne West",
      "3978 - Clyde",
      "3803 - Hallam",
    ],
  },
  {
    state: "NSW",
    suburb: [],
  },

  {
    state: "SA",
    suburb: [],
  },
  {
    state: "QLD",
    suburb: [],
  },
  {
    state: "WA",
    suburb: [],
  },
]

function DeliverLabel() {
  var matches = window.matchMedia("(max-width: 1340px)");
  var matches2 = window.matchMedia("(max-width: 600px)");



  const [myIndex, setMyIndex] = useState(0);
  const [myIndex2, setMyIndex2] = useState(0);
  const [newArray, setNewArray] = useState();
  const [suburb, setSuburb] = useState([
    {
      state: "VIC",
      suburb: [
        "3805 - Narre Warren",
        "3805 - Narre Warren South",
        "3976 - Hampton Park",
        "3975 - Lynbrook",
        "3977 - Cranbourne",
        "3978 - Clyde North",
        "3977 - Cranbourne West",
        "3978 - Clyde",
        "3803 - Hallam",
      ],
    },
    {
      state: "NSW",
      suburb: [],
    },

    {
      state: "SA",
      suburb: [],
    },
    {
      state: "QLD",
      suburb: [],
    },
    {
      state: "WA",
      suburb: [],
    },
  ]);
  const [allSuburb, setAllSuburb] = useState({
    state: "VIC",
    suburb: [
      "3805 - Narre Warren",
      "3805 - Narre Warren South",
      "3976 - Hampton Park",
      "3975 - Lynbrook",
      "3977 - Cranbourne",
      "3978 - Clyde North",
      "3977 - Cranbourne West",
      "3978 - Clyde",
      "3803 - Hallam",
    ],
  }
  );

  const getSuburbs = () => {
    getAllCities()
      .then((res) => {
        console.log("res sub>>>", res.data)

        let newArr = []

        //  const sub = `${d.postcode+" - "+d.name_en}`

        const update2 = res.data?.map((d) => {

          const sub = `${d.postcode + " - " + d.name_en}`

          newArr.push(sub);

        });

        console.log("newArr>>>", newArr)

        const update3 = sub?.map((d) => {

          console.log("QWQWW", d)
          //  console.log("QQQ<<", allSuburb)

          if (d.state === "VIC") {
            d.suburb = newArr;
            setAllSuburb({
              name: "VIC",
              suburb: newArr,
            })


            // allSuburb.suburb = newArr;
          }
          return { ...d }

        });

        console.log("PO>>", update3)

        setSuburb(update3);


        console.log("TYUUU", suburb)

        const prod = update3?.filter((s) => s.state === "VIC");
        setAllSuburb(prod[0]);
        const io = _.chunk(prod[0]?.suburb, 10);
        const qwer = _.chunk(io, 1);

        //  const io = _.chunk(newArr, 5);
        // const qwer = _.chunk(io, 1);

        setNewArray(qwer);

      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  }
  useEffect(() => {
    getSuburbs();

  }, []);
  useEffect(() => {
    const io = _.chunk(allSuburb?.suburb, 10);
    const qwer = _.chunk(io, 1);

    setNewArray(qwer);

  }, []);
  return (
    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", }}>
      <Grid
        xs={12}
        lg={9}
        md={9}
        container
        spacing={0}
        style={{
          padding: "50px 0px",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "530",

              color: COLORS.TitleColor,
              fontWeight: "400",

              // fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: FONTS_ANOTON,
              fontSize: matches2.matches ? "26pt" : "36pt",
              lineHeight: 1.2,
              margin: matches.matches ? "20px 22px" : "20px 5px",
            }}
          >
            We deliver to most of the south eastern suburbs{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {suburb?.map((i, index) => {


            return (
              <Typography
                style={{
                  fontFamily: FONTS_RC,
                  fontSize: "18pt",
                  color: COLORS.Primary,
                  display: "flex",
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    color:
                      allSuburb?.state === i.state
                        ? COLORS.Orange
                        : COLORS.Primary,
                  }}
                  onClick={() => {
                    const prod = suburb?.filter((s) => s.state === i.state);
                    setAllSuburb(prod[0]);
                    const io = _.chunk(prod[0]?.suburb, 10);
                    const qwer = _.chunk(io, 1);
                    console.log("new--------->", qwer);
                    // const io = _.chunk(prod[0]?.suburb, 5);
                    // const qwer = _.chunk(io, 1);

                    setNewArray(qwer);
                  }}
                >
                  {i.state}
                </span>{" "}
                {suburb?.length !== index + 1 && (
                  <span
                    style={{ padding: "0px 10px", color: COLORS.TitleColor5 }}
                  >
                    |
                  </span>
                )}
              </Typography>
            );
          })}
        </Grid>
        {/* mobile */}
        <Grid
        xs={12}
          container
          style={{
            display: matches?.matches ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          {newArray?.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: COLORS.TitleColor4,
                  textAlign: "left",
                  fontFamily: FONTS_INTER,
                  fontSize: "17pt",
                }}
              >
                Operation will start soon
              </Typography>
            </Grid>
          ) : (
            newArray
              ?.filter((_, r) => r === myIndex)
              ?.map((q, index) => {
                return (
                  <>
                    {q?.map((d, index) => {
                      return (
                        <Grid xs={7} container
                          style={
                            {
                              display: "flex",
                              justifyContent: "center"

                            }
                          }
                        >
                          <Grid item xs={10}


                          >
                            {d?.map((y) => {
                              return (
                                <Typography
                                  style={{
                                    color: COLORS.TitleColor4,
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    fontSize: "13pt",
                                    padding: matches?.matches
                                      ? "1px 10px"
                                      : "1px 5px",
                                      whiteSpace:"nowrap"
                                  }}
                                >
                                  {y}
                                </Typography>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                );
              })
          )}
        </Grid>

        {/* desktop */}
        {allSuburb?.suburb?.length === 0 ? (
          <Grid
            id="areas"
            xs={12}
            container
            className="hideScrollBar"
            style={{
              padding: "13pt ",

              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap",
              overflowX: matches.matches ? "scroll" : "unset",
              maxHeight: matches?.matches ? "" : "170px",
              display: matches?.matches ? "none" : "flex",
            }}
          >
            <Typography
              style={{
                color: COLORS.TitleColor4,
                textAlign: "left",
                fontFamily: FONTS_INTER,
                padding: matches?.matches ? "1px 10px" : "1px 5px",
                fontSize: "17pt",
              }}
            >
              Operation will start soon
            </Typography>
          </Grid>
        ) : (
          <Grid
            id="areas"
            // xs={11}
            container
            className="hideScrollBar"
            style={{
              padding: "13pt ",

              // flexDirection: "row",
              alignItems: "center",
              // justifyContent: "flex-start",
              // width: "100%",
              // flexWrap: "wrap",
              overflowX: matches.matches ? "scroll" : "unset",
              maxHeight: matches?.matches ? "" : "147px",
              display: matches?.matches ? "none" : "flex",
            }}
          // sx={{
          //   overflowX: {
          //     xs: "scroll",
          //   },
          //   maxHeight: {
          //     xs: "",
          //     sm: "147px",
          //   },
          //   display: {
          //     xs: "none",
          //     sm: "flex",
          //   },
          // }}
          >
            {" "}
            {allSuburb?.state === "VIC" ? (
              <>
                {myIndex2 === 0 ?

                  <Grid
                    container
                    xs={12}
                    style={{ display: "flex", justifyContent: "center",marginLeft:"3.5vW" }}
                  >
                    <Grid item xs={3} >
                      {allSuburb.suburb.slice(0, 15).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      {allSuburb.suburb.slice(15, 30).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      {allSuburb.suburb.slice(30, 45).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap"
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: "2%" }}>
                      {allSuburb.suburb.slice(45, 60).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                  :
                  <Grid
                    container
                    xs={12}
                    style={{ display: "flex", justifyContent: "center",marginLeft:"3.5vW" }}
                  >
                    <Grid item xs={3} >
                      {allSuburb.suburb.slice(60, 75).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3} style={{ maxWidth: "27%" }}>
                      {allSuburb.suburb.slice(75, 90).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      {allSuburb.suburb.slice(90, 105).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap"
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: "2%" }}>
                      {allSuburb.suburb.slice(105, 120).map((v) => {
                        return (
                          <Typography
                            style={{
                              color: COLORS.TitleColor4,
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              padding: matches?.matches ? "1px 10px" : "1px 5px",
                              fontSize: matches?.matches ? "15pt" : "13pt",
                              whiteSpace: "nowrap",
                            }}
                          // sx={{
                          //   padding: {
                          //     xs: "1px 10px",
                          //     sm: "1px 5px",
                          //   },
                          //   fontSize: {
                          //     sm: "13pt",
                          //     xs: "15pt",
                          //   },
                          // }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>



                }



                <Grid container xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      marginTop: "2%",
                      opacity: myIndex2 === 0 ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (myIndex2 !== 0) {

                        setMyIndex2(myIndex2 - 1)
                        console.log("inex>>>", myIndex2)
                      }
                      // if (myIndex - 1 > -1) {
                      //   setMyIndex(myIndex - 1);
                      // }
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      marginTop: "2%",
                      opacity: myIndex2 === 1 ? 0.5 : 1,
                    }}
                    onClick={() => {

                      if (myIndex2 < 1) {

                        setMyIndex2(myIndex2 + 1)
                        console.log("inex>>>", myIndex2)
                      }
                      // if (myIndex + 1 < newArray?.length) {
                      //   console.log("------>", newArray?.length);
                      //   console.log("------<", myIndex);
                      //   setMyIndex(myIndex + 1);
                      // }
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>

                </Grid>


              </>

            ) : (
              allSuburb?.suburb.map((y) => {
                return (
                  <Typography
                    style={{
                      color: COLORS.TitleColor4,
                      textAlign: "left",
                      fontFamily: FONTS_INTER,
                      padding: matches?.matches ? "1px 10px" : "1px 5px",
                      fontSize: matches?.matches ? "15pt" : "13pt",
                    }}
                  // sx={{
                  //   padding: {
                  //     xs: "1px 10px",
                  //     sm: "1px 5px",
                  //   },
                  //   fontSize: {
                  //     sm: "13pt",
                  //     xs: "15pt",
                  //   },
                  // }}
                  >
                    {y}
                  </Typography>
                );
              })
            )}

          </Grid>
        )}



        {newArray?.length !== 0 && (
          <Grid
            xs={12}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              display: matches?.matches ? "flex" : "none",
            }}
          // sx={{
          //   display: {
          //     sm: "none",
          //     xs: "flex",
          //   },
          // }}
          >
            <div
              style={{
                background: COLORS.Primary,
                display: "grid",
                placeItems: "center",
                padding: 6,
                borderRadius: "50%",
                marginRight: 5,
                opacity: myIndex === 0 ? 0.5 : 1,
              }}
              onClick={() => {
                if (myIndex - 1 > -1) {
                  setMyIndex(myIndex - 1);
                }
              }}
            >
              <KeyboardArrowLeftIcon
                style={{
                  fontSize: 24,
                  color: "white",
                }}
              />
            </div>

            <div
              style={{
                background: COLORS.Primary,
                display: "grid",
                placeItems: "center",
                padding: 6,
                borderRadius: "50%",
                opacity: myIndex === 1 ? 0.5 : 1,
              }}
              onClick={() => {
                if (myIndex + 1 < newArray?.length) {
                  console.log("------>", newArray?.length);
                  console.log("------<", myIndex);
                  setMyIndex(myIndex + 1);
                }
              }}
            >
              <KeyboardArrowRightIcon
                style={{
                  fontSize: 24,
                  color: "white",
                }}
              />
            </div>
          </Grid>
        )}

      </Grid>
    </Grid>
  );
}

export default DeliverLabel;
