import { Grid } from "@mui/material";
import React from "react";
import { COLORS } from "../../Constants/Colors";

function Footer() {
  return (
    <Grid
      container
      xs={12}
      spacing={0}
      style={{ display: "flex", justifyContent: "center",  background: COLORS.Primary, }}
    >
      <Grid
        xs={11}
        style={{
          background: COLORS.Primary,
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <div className="row">
              <div style={{ textAlign: "left", padding: "0px 50px 0px 50px" }}>
                <h4>CONTACT US</h4>
                <div>
                  <p>96a Murrumbeena Rd, Murrumbeena VIC, 3163</p>
                  <p>(03) 9569 0488</p>
                  <p>meals@katrinaskitchen.com.au</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ textAlign: "left", padding: "0px 50px 0px 50px" }}>
              <h4>MENUS</h4>
              <>
                <p>Diabetes</p>
                <p>Cholesterol</p>
                <p>Weight Loss</p>
                <p>Muscle Gain</p>
              </>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ textAlign: "left", padding: "0px 50px 0px 50px" }}>
              <h4>OPENING HOURS</h4>
              <p>Mon - Sun 9 am - 5 pm</p>
              <p>Public Holidays OPEN</p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div
                  style={{ textAlign: "left", padding: "0px 50px 0px 50px" }}
                >
                  <h4>USEFUL LINKS</h4>
                  <p>Menu and Price List</p>
                  <p>Privacy policy</p>
                  <p>Refund policy</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item xs={9}></Grid>

            <Grid item xs={3}>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  FB
                </Grid>
                <Grid item xs={3}>
                  LN
                </Grid>
                <Grid item xs={3}>
                  INS
                </Grid>
                <Grid item xs={3}>
                  TWT
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ background: COLORS.Orange, color: "white" }}>
        <div>
          <div>
            <h4>
              Copyright © 2021 Katrina Kitchen | Website Design - InfinityCs
            </h4>
            <p
              styles={{
                fontWeight: "normal",
                padding: "0.5vh auto 0.5vh auto",
              }}
            >
              Copyright © 2021 Katrina Kitchen | <br /> Website Design -
              InfinityCs
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default Footer;
