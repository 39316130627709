import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import "./checkout.css";
import Paypal from "../../Assets/aa.png";
// import PayMethod from "../../../assets/payment-method.png";
import { OrderSave } from "../../Services/API/Post";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import jwtDecode from "jwt-decode";
import {
  getOrderDetails,
  // getBillInfo,
  // getShipInfo,
  getProductById,
  CurrentUser,
  getEmailTemplate,
  getCart,
  getMemberCart,
} from "../../Services/API/Get";
import { EMAIL_TAMPLETE } from "./email";
import moment from "moment";
import { IMAGE_SERVER_URL_DEV, CURRENCY } from "../../Constants/System";
import _ from "lodash";
import { COLORS } from "../../Constants/Colors";
import {
  Button,
  Card,
  CardContent,
  Divider,
  getCardUtilityClass,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import { FONTS_INTER } from "../../Constants";
import CardChoice from "../../Pages/Models/CardChoice";
import ErrorPopup from "../Models/errorPopup.jsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";
import StepsContext from "../../Context/StepsContext";
import CartContext from "../../Context/CartContext";
import CartCount from "../../Componets/CartCount";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { EMAIL_TAMPLETE2 } from "./email2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxWidth: 300,
};
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.Primary,
    },
    secondary: {
      main: "#f44336",
    },
  },
});

class PaymentStep extends React.Component {
  static contextType = StepsContext;

  constructor(props) {
    super(props);
    this.state = {
      radioBtn: {
        paypal: false,
        delivery: true,
      },
      count: 0,
      open_toast: false,
      open_toast2: false,
      alertClass: "success",
      msg: "",
      color: COLORS.Primary,
      billing: "",
      shipping: "",
      product: "",
      customer: "",
      currency: "",
      subject: "",
      description: "",
      cartDetails: [],
      McartDetails: [],
      openCard: false,
      lOpen: false,
      iUsers: [],
      url: false,
    };
    this.ClickPaypal = this.ClickPaypal.bind(this);
    this.ClickCashonDelivery = this.ClickCashonDelivery.bind(this);
    this.PlaceOrder = this.PlaceOrder.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getCurrency = this.getCurrency.bind(this);
  }

  async getCurrency() {
    this.setState({
      currency: await CURRENCY(),
    });
  }
  ClickPaypal(e) {
    this.setState(
      {
        radioBtn: {
          paypal: true,
          delivery: false,
        },
      },
      () => {
        this.setState(
          {
            openCard: true,
          },
          () => console.log(this.state.openCard)
        );
      }
    );
  }
  ClickCashonDelivery(e) {
    this.setState({
      radioBtn: {
        paypal: false,
        delivery: true,
      },
    });
  }
  handleClose() {
    this.setState({
      open_toast: false,
    });
  }
  componentDidMount() {
    // this.getCustomer();
    // this.getBilling();
    // this.getShipping();
    // this.getProduct(789)
    // this.getCurrency();
    // this.getEmail();
    this.getProduct();
    this.setState({ lOpen: true });
    // setTimeout(() => {
    //   this.PlaceOrder();
    // }, 4000);
  }

  getProduct() {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;

    let users = [];

    CurrentUser().then((res) => {
      if (res.statusCode === 200) {
        const cData = res.data[0];

        this.setState(
          {
            customer: res.data[0],
          },
          () => {
            console.log("cust info>>", this.state.customer);


          }
        );

        // setname(cData.firstName + " " + cData.lastName);

        getCart({
          sortField: sortField,
          sort: sort,
          limit: limit,
          page: page,
          userId: cData?.id,
        })
          .then((res) => {
            console.log("Cart >>>", res.data);

            var newArray = res.data?.filter(function (el) {
              return el.expire === 0;
            });

            let order = Object.assign({}, newArray[0]);
            order.cItems = newArray;
            console.log("ZXZX", order);
            users.push(order);

            this.setState(
              {
                cartDetails: newArray,
              },
              () => console.log("<><><><>", this.state.cartDetails)
            );
            // setCart(res.data);
            // setTotal(res.data[0].price);
            // setitems(res.data.length);
            // setMeals(res.data[0].noofmeals);
            getMemberCart({
              sortField: sortField,
              sort: sort,
              limit: limit,
              page: page,
              userId: cData?.id,
            })
              .then((res) => {
                console.log("Member Cart >>>", _.map(res.data, "members"));

                let newArr = [];

                _.map(res.data, "members")?.map((item, index) => {
                  var newArray = item?.filter(function (el) {
                    return el.expire === 0;
                  });

                  console.log("+++++++", newArray);
                  if (newArray.length > 0) {
                    newArr.push(newArray);
                  }
                });

                let mfilter = _.map(res.data, "members");
                // console.log("ASASA", _.map(res.data, "members"))

                newArr?.map((item, index) => {
                  console.log("!@@!@@", item[0]);
                  let product = Object.assign({}, item[0]);
                  // product.usedMeals = item.length;
                  // product.type = "member";

                  const update2 = item?.map((d) => {
                    return {
                      ...d,
                      // deliveryDate: JSON.parse(d.productDetails)[0]
                      //   .deliveryDate,Etype:"member",
                    };
                  });

                  product.cItems = update2;

                  users.push(product);
                });

                this.setState(
                  {
                    McartDetails: newArr,
                    iUsers: users,
                  },
                  () => {
                    console.log("<><><><>", this.state.McartDetails);
                    this.PlaceOrder();
                  }
                );
                // setMCart(res.data);
                // setTotal(res.data[0].price)
                // setitems(res.data.length)
                // setMeals(res.data[0].noofmeals)
                // setAddress(res.data)
              })
              .catch((error) => {
                console.log("error", error);
                console.log(
                  "current package error",
                  error.response.data.message
                );
                this.setState({
                  iUsers: users
                }, () => { this.PlaceOrder(); });
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
              });
          })
          .catch((error) => {
            console.log("error", error);
            console.log("current package error", error.response.data.message);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
    });
  }
  getCustomer() {
    console.log("Customer information>>>>>>>>>>>>>>>>>");
    CurrentUser()
      .then((res) => {
        this.setState(
          {
            customer: res.data[0],
          },
          () => {
            console.log("cust info>>", this.state.customer);
            this.getProduct();

          }
        );
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ customer: "" });
      });
  }

  // getShipping() {
  //   console.log("Shipping information>>>>>>>>>>>>>>>>>");
  //   getShipInfo()
  //     .then((res) => {
  //       this.setState(
  //         {
  //           shipping: res.data[0],
  //         },
  //         () => {
  //           console.log("Ship info>>", this.state.shipping);
  //         }
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       this.setState({ shipping: "" });
  //     });
  // }
  // getBilling() {
  //   console.log("Billing information>>>>>>>>>>>>>>>>>");
  //   getBillInfo()
  //     .then((res) => {
  //       this.setState(
  //         {
  //           billing: res.data[0],
  //         },
  //         () => {
  //           console.log("Bill info>>", this.state.billing);
  //         }
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       this.setState({ billing: "" });
  //     });
  // }
  getEmail() {
    console.log("Email information>>>>>>>>>>>>>>>>>");
    getEmailTemplate()
      .then((res) => {
        this.setState(
          {
            subject: res.data[0].subject,
            description: res.data[0].description,
          },
          () => {
            console.log(
              "Email info>>",
              this.state.subject,
              this.state.description
            );
          }
        );
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ billing: "" });
      });
  }
  // getProduct(id) {
  //   getProductById(id)
  //     .then((res) => {
  //       this.setState({
  //         product: res.data[0]
  //       }, () => {
  //         console.log("product info>>", this.state.product)
  //       }
  //       )
  //     })
  //     .catch((error) => {
  //       console.log(error.response)
  //       this.setState({ shipping: "" })
  //     });
  // }

  async PlaceOrder() {
    const { setSteps } = this.context;

    let newArray = [];
    let Subtotal = 0;
    let orderMyNo = 10000;
    let d = moment().format("lll");

    let isStudent = this.state.customer.isStudent === 1 ? true : false;

    if (this.state.cartDetails.length !== 0) {
      await this.state.McartDetails?.map((item) => {
        Subtotal = Subtotal + parseFloat(item[0].isStudent === 1 ? item[0].price * 90 / 100 : item[0].price);
      });
      Subtotal = Subtotal + parseFloat(this.state.cartDetails[0].isStudent === 1 ? this.state.cartDetails[0].price * 90 / 100 : this.state.cartDetails[0].price);
      // this.setState({ lOpen: true });
    } else {
      this.setState({
        color: COLORS.Primary,
        alertClass: "alert",
        msg: "Your Cart List Empty",
        open_toast2: true,
      });
      return true;
    }
    await getOrderDetails()
      .then((res) => {
        orderMyNo = parseInt(res.data[res.data.length - 1]?.orderNo) + 1;
        console.log("order no", orderMyNo);
      })
      .catch((error) => {
        console.error("order history error", error.response);
      });

    console.log("$%$%%%%", this.state.iUsers);

    console.log("BOBOBO", parseFloat((Subtotal * 110) / 100).toFixed(2));

    const emailTem = await EMAIL_TAMPLETE({
      customerName: this.state.customer.firstName,
      invoiceNo: `${orderMyNo}`.trim().toString(),
      pdfName: `${this.state.customer.firstName}_${`${orderMyNo}`
        .trim()
        .toString()}`,
    });

    const emailTem2 = await EMAIL_TAMPLETE2({
      customerName: `${this.state.customer.firstName} ${this.state.customer.lastName}`,
      address: this.state.customer.homeAddress,
      address2: `${this.state.customer?.homeCity} - ${this.state.customer.homepostCode}`,
      date: moment(new Date()).format("l"),
      invoiceNo: `${orderMyNo}`.trim().toString(),
      subTotal: Subtotal,
      tax: 0,
      total: Subtotal,
      MESSAGE: this.state.description,
      cartArray: this.state.cartDetails,
      MemeberList: this.state.McartDetails,
      iUsers: this.state.iUsers,
    });
    console.log("------>", emailTem2);

    const getToken = localStorage.getItem(`${window.location.hostname}.login`);
    if (getToken) {
      const { data } = JSON?.parse(getToken);
      const { id, firstName, email } = jwtDecode(data);
      if (
        this.state.radioBtn.paypal === true ||
        this.state.radioBtn.delivery === true
      ) {
        let item = this.state.cartDetails[0];
        let newItemList = this.state.cartDetails;

        //   for(i = 0; i < newItemList?.length; i++){
        //     newItemList[i].productPrice = newItemList[i]['discountedprice'];
        //     delete newItemList[i].discountedprice;
        // }
        newItemList?.map((item, index) => {
          newItemList[index].productD = JSON.parse(item.productDetails);
          newItemList[index].productD[0].productPrice = item?.isStudent === 1 ? item.price * 90 / 100 : item.price;
          newItemList[index].images = JSON.parse(item.productDetails)[0].image;
          newItemList[index].productD[0].meatType = item.meatType;
          newItemList[index].productD[0].deliveryAddress = item.homeAddress;
          newItemList[index].productD[0].city = item.homeCity;
          newItemList[index].productD[0].mainId = item.mainId;
          // newItemList[index].productDetails.qty = item.qty;
          // newItemList[index].productDetails.productPrice = item.discountedprice;
          // newItemList[index].productDetails.id = item.productId;

          // newItemList[index].productDetails = this.state.product;
          // newItemList[index].qty = item.qtyOrderd;
          // newItemList[index].id = item.productId;
          // delete newItemList[index].discountedprice;
          // delete newItemList[index].qtyOrderd;
          // delete newItemList[index].images;
        });

        // let productDetails = _.map(newItemList, "productDetails")

        // this.props.cartDetails?.map((item) => {
        // console.log("New Item >>", newItemList);

        const shippingcost = 0;

        const obj = {
          // productId: item?.productId,
          orderNo: `${orderMyNo}`.trim().toString(),
          invoiceNo: `${orderMyNo}`.trim().toString(),
          userId: id,
          userName: firstName,
          email: email,
          qty: JSON.parse(item.productDetails)[0].qty,
          // productName: item?.productName,
          itemTotal: Subtotal,
          homeAddress: this.state.customer.homeAddress,
          officeAddress: this.state.customer.officeAddress,
          deliveryAddress: this.state.customer.homeAddress,
          homeCity: this.state.customer.homeCity,
          officeCity: this.state.customer.officeCity,
          // productPrice: item?.discountedprice,
          shippingCost: shippingcost,
          amountPayable: parseFloat((Subtotal * 110) / 100).toFixed(2),
          payment: this.state.radioBtn.paypal === true ? "Card" : "Cash",
          date: new Date(),
          productDetails: _.map(newItemList, "productD"),
          image: _.map(newItemList, "images"),
          customerDetails: {
            customer: this.state.customer,
            // billing: this.state.billing,
            // shipping: this.state.shipping,
          },
          firstName: this.state.customer.firstName,
          lastName: this.state.customer.lastName,
          phoneNumber: this.state.customer.mobileNumber,

          emailBody: emailTem,
          pdfName: `${this.state.customer.firstName}_${`${orderMyNo}`
            .trim()
            .toString()}`,
          emailTemplate: emailTem2,
          subject: this.state.subject,
          deliveryDate: new Date(),
          member: 0,
          homeDistrict: this.state.customer.homeDistrict,
          currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          postCode: this.state.customer.homepostCode,
        };

        newArray.push(obj);

        await this.state.McartDetails?.map((item, index) => {
          let Mcart = item;
          Mcart?.map((item, index) => {
            Mcart[index].productD = JSON.parse(item.productDetails);
            Mcart[index].productD[0].productPrice = item?.isStudent === 1 ? item.price * 90 / 100 : item.price;
            Mcart[index].images = JSON.parse(item.productDetails)[0].image;
            Mcart[index].productD[0].meatType = item.meatType;
            Mcart[index].productD[0].deliveryAddress = item.homeAddress;
            Mcart[index].productD[0].city = item.homeCity;
            Mcart[index].productD[0].mainId = item.mainId;
          });
          const obj = {
            // productId: item?.productId,
            orderNo: `${orderMyNo}`.trim().toString(),
            invoiceNo: `${orderMyNo}`.trim().toString(),
            userId: item[0].memberId,
            userName: item[0].firstName,
            email: item[0].email,
            qty: JSON.parse(item[0].productDetails)[0].qty,
            // productName: item?.productName,
            itemTotal: Subtotal,
            homeAddress: item[0].address,
            officeAddress: item[0].officeAddress,
            deliveryAddress: item[0].homeAddress,
            homeCity: item[0].homeCity,
            officeCity: item[0].officeCity,
            // productPrice: item?.discountedprice,
            shippingCost: shippingcost,
            amountPayable: parseFloat((Subtotal * 110) / 100).toFixed(2),
            payment: this.state.radioBtn.paypal === true ? "Card" : "Cash",
            date: new Date(),
            productDetails: _.map(Mcart, "productD"),
            image: _.map(Mcart, "images"),
            customerDetails: {
              customer: JSON.parse(item[0].customerDetails),
            },
            firstName: item[0].firstName,
            lastName: item[0].lastName,
            phoneNumber: item[0].mobileNumber,
            subject: this.state.subject,
            deliveryDate: new Date(),
            member: 1,
            homeDistrict: item[0].homeDistrict,
            postCode: item[0].homepostCode,
          };

          newArray.push(obj);
        });

        let obj2 = {
          userDetails: newArray,
        };




        //DELIVERY DATES FOR SUCCESS POP UP

        var datesArr = []

        newArray?.map((item, index) => {

          item?.productDetails.map((item2, index) => {

            datesArr.push(item2[0])

          });

        });

        const datesArrNew = _.uniq(_.map(datesArr, "deliveryDate"))

        console.log("<><><><>", datesArrNew)

        let delivery = 5

        let Friarr = []

        let Tuearr = []

        let friday, tuesday = ""

        datesArrNew?.map((item2, index) => {

          let day = moment(item2).format("ddd")

          // switch (day) {
          //   case "Sat":

          //     Friarr.push(item2);

          //     friday = moment(item2).subtract(1, "days")

          //     break;

          //   case "Sun":

          //     Friarr.push(item2);

          //     friday = moment(item2).subtract(2, "days")

          //     break;

          //   case "Mon":

          //     Friarr.push(item2);

          //     friday = moment(item2).subtract(3, "days")

          //     break;

          //   case "Tue":

          //     Friarr.push(item2);

          //     friday = moment(item2).subtract(4, "days")

          //     break;

          //   case "Wed":

          //     Tuearr.push(item2);

          //     tuesday = moment(item2).subtract(1, "days")

          //     break;

          //   case "Thu":

          //     Tuearr.push(item2);

          //     tuesday = moment(item2).subtract(2, "days")

          //     break;

          //   case "Fri":

          //     Tuearr.push(item2);

          //     tuesday = moment(item2).subtract(3, "days")

          //     break;


          // }

          switch (day) {
            case "Sat":

              Friarr.push(item2);

              friday = moment(item2).subtract(1, "days")

              break;

            case "Sun":

              Friarr.push(item2);

              friday = moment(item2).subtract(2, "days")

              break;

            case "Mon":

              Friarr.push(item2);

              friday = moment(item2).subtract(3, "days")

              break;

            case "Tue":

              Friarr.push(item2);

              friday = moment(item2).subtract(4, "days")

              break;

            case "Wed":

              Friarr.push(item2);

              friday = moment(item2).subtract(5, "days")

              break;

            case "Thu":

              Friarr.push(item2);

              friday = moment(item2).subtract(6, "days")

              break;

            case "Fri":

              Friarr.push(item2);

              friday = moment(item2)

              break;


          }



        });

        let delType = localStorage.getItem("delType");


        // if (delType === "delivery") {

        //   if (Friarr.length > 0) {
        //     delivery += 5;
        //   }

        //   if (Tuearr.length > 0) {
        //     delivery += 5;
        //   }

        // }

        obj2.delCharges = delivery
        obj2.discount = isStudent ? 10 : 0
        obj2.orderType = delType

        console.log("obj>>>>>", obj2)

        localStorage.setItem("orderDates", JSON.stringify(datesArrNew));

        await OrderSave(obj2)
          .then((res) => {
            // setSteps(6);
            // localStorage.setItem("order", true);

            // this.setState({ url: res.data });
            localStorage.setItem("orderDates", JSON.stringify(datesArrNew));
            if (res.data !== undefined) {
              window.location.href = res.data;
            } else {
              this.props.history.push("/cart");
            }
          })
          .catch((error) => {
            console.log("Error", error.response);
            this.setState({
              color: COLORS.Primary,
              alertClass: "alert",
              msg: error.response.data.message,
              open_toast2: true,
              lOpen: false,
            });
          });
      }
    }
  }
  render() {
    // if (this.state.url !== false) {
    //   if (this.state.url === undefined) {
    //     this.props.history.push("/cart");
    //   } else {
    //     window.location.href = this.state.url;
    //   }

    //   // window.open(this.state.url, "_blank");

    //   // return <Redirect to={this.state.url} />;
    // }
    return (
      <>
        <LoadingSpinner open={this.state.lOpen} />
        <Modal
          open={this.state.open_toast}
          onClose={() => {
            this.setState({ open_toast: false });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                  />
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{
                      fontSize: "14pt",
                      fontFamily: FONTS_INTER,
                      fontWeight: "bold",
                      marginTop: 7,
                    }}
                  >
                    Order Successful
                  </Typography>
                  <Box component="form" sx={{ mt: 1 }}>
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{
                        fontSize: "10px",
                        fontFamily: FONTS_INTER,
                        marginTop: 7,

                        color: COLORS.Primary,
                        textAlign: "center",
                      }}
                    >
                      Thank you for completing your weekly meal plan with us. Your meal plan will be delivered between 5pm and 8.30pm every day. Please check your order summary for more details.
                    </Typography>

                    <Typography
                      component="h5"
                      variant="h5"
                      style={{
                        fontSize: "10pt",
                        fontFamily: FONTS_INTER,
                        marginTop: 7,

                        color: COLORS.Primary,
                        textAlign: "center",
                      }}
                    >
                      <p>
                        Breakfast 6am - 9pm <br />
                        Lunch 11 a.m - 1.30
                        <br />
                        Dinner 5.30 p.m - 8.30 p.m
                      </p>
                    </Typography>

                    <Typography
                      component="body1"
                      variant="h5"
                      style={{
                        fontSize: "8pt",
                        fontFamily: FONTS_INTER,
                        marginTop: 7,

                        color: COLORS.Primary,
                        textAlign: "center",
                        fontStyle: "italic",
                      }}
                    >
                      <p>
                        (Delivery times can be vary depending on the road
                        traffic conditions.)
                      </p>
                    </Typography>

                    {/* <Typography
                      component="h5"
                      variant="h5"
                      style={{
                        fontSize: "10pt",
                        fontFamily: FONTS_INTER,
                        marginTop: 7,

                        color: COLORS.Primary,
                        textAlign: "center",
                      }}
                    >
                      <p>
                        For any clarifications please contact our Order
                        Processing Unit between 9 am and 5 pm through 0112 222
                        222
                      </p>
                    </Typography> */}
                  </Box>
                </Box>
              </Container>
            </ThemeProvider>
          </Box>
        </Modal>
        <CardChoice
          open={this.state.openCard}
          handleClose={() =>
            this.setState({
              openCard: false,
            })
          }
        />

        <Snackbar
          open={false}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.handleClose}
        >
          <Alert
            variant="filled"
            style={{
              backgroundColor: this.state.color,
              color: "white",
              fontWeight: "bold",
            }}
            onClose={this.handleClose}
            severity={this.state.alertClass}
          >
            {this.state.msg}
          </Alert>
        </Snackbar>
        <ErrorPopup
          openToast={this.state.open_toast2}
          handleClose={
            this.state.open_toast2 === true
              ? setTimeout(() => {
                this.setState({ open_toast2: false });
              }, 5000)
              : this.state.open_toast === true
                ? setTimeout(() => {
                  this.setState({ open_toast: false });
                }, 5000)
                : false
          }
          alertClass={this.state.alertClass}
          msg={this.state.msg}
        />
      </>
    );
  }
}

export default withRouter(PaymentStep);
