import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Background from "./Background";

import { Row, Col, Container } from "react-bootstrap";
import { Button, Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPhone,
  faEnvelope,
  faStore,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "./contact.css";
import { Link } from "react-router-dom";
import { postContacts } from "../../Services/API/Post";
import { getCompany } from "../../Services/API/Get";
import { COLORS } from "../../Constants/Colors";
import { FONTS_INTER } from "../../Constants";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={12} defaultCenter={{ lat: 6.9326, lng: 79.8439 }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: 6.9326, lng: 79.8439 }} />
      )}
    </GoogleMap>
  ))
);

const styles = (theme) => ({
  carthr: {
    borderTop: "0.2px solid #B6B6B6",
    color: "black",
    backgroundColor: "#E9EBED",
    [theme.breakpoints.down("sm")]: {
      borderTop: "0.1px solid black",
      // marginTop:0,
      // marginBottom:0
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {},
  },

  multilineColor: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },

    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
    width: "90%",
    height: "auto",
    color: "black",
    fontSize: "9pt",
    marginLeft: 10,
    // border:"1px solid black"
  },
});

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.postContactsApi = this.postContactsApi.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeContact = this.onChangeContact.bind(this);
    this.onChangeMemberNo = this.onChangeMemberNo.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeWebSite = this.onChangeWebSite.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);

    this.getCompany = this.getCompany.bind(this);

    this.state = {
      data: [],
      name: "",
      email: "",
      memberNo: "",
      contact: "",
      webSite: "",
      message: "",
      toaster: false,
      msg: "",
      company: [],
      coverImage: "",
      errname: false,
      errmNo: false,
      errcontact: false,
      erremail: false,
      errMsg: false,
    };
  }
  componentDidMount() {
    this.getCompany();
  }
  getCompany() {
    getCompany()
      .then((res) => {
        this.setState({
          company: res?.data[res.data.length - 1],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeName(e) {
    console.log("name");
    this.setState({
      name: e.target.value,
      errname: "",
    });
  }

  onChangeContact(e) {
    console.log("name");
    this.setState({
      contact: e.target.value,
      errcontact: "",
    });
  }

  onChangeMemberNo(e) {
    console.log("name");
    this.setState({
      memberNo: e.target.value,
      errmNo: "",
    });
  }

  onChangeEmail(e) {
    console.log("email");
    this.setState({
      email: e.target.value,
      erremail: "",
    });
  }

  onChangeWebSite(e) {
    console.log("web");
    this.setState({
      webSite: e.target.value,
    });
  }

  onChangeMessage(e) {
    console.log("msg");
    this.setState({
      message: e.target.value,
      errMsg: "",
    });
  }

  postContactsApi(e) {
    e.preventDefault();

    const obj = {
      name: this.state.name,
      email: this.state.email,
      contactNumber: this.state.contact,
      memberNumber: this.state.memberNo,
      message: this.state.message,
    };
    console.log("obj", obj);

    if (!obj.name) {
      this.setState({ errname: true })
    }
    if (!obj.email) {
      this.setState({ erremail: true })
    }
    if (!obj.contactNumber) {
      this.setState({ errcontact: true })
    }
    // if (!obj.memberNumber) {
    //   this.setState({ errmNo: true })
    // }
    if (!obj.message) {
      this.setState({ errMsg: true })
    }

    if (
      obj.name &&
      obj.contactNumber &&
      obj.email &&
      // obj.memberNumber &&
      obj.message
    ) {

      postContacts(obj)
        .then((res) => {
          console.log("res", res);
          // alert("Submited..!!")
          this.setState(
            {
              name: "",
              email: "",
              contact: "",
              memberNo: "",
              webSite: "",
              message: "",
              toaster: true,
              msg: "Your enquiry has been successfully submitted",
            }
            //   () => {
            //     setTimeout(this.setState({toaster: false}),4000);
            //   }
          );
        })
        .catch((error) => {
          console.log("error", error);

          this.setState({});
        });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {/* <Container> */}
        <Row>
          <Col>
            <Grid
              container
              xs={12}
              style={{
                padding: "40px 0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "",
                  paddingTop: 50,
                  paddingBottom: 50,
                  paddingLeft: 80,
                  paddingRight: 80,
                }}
              >
                <Background>
                  <MyMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `450px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </Background>

                
              </Grid> */}
              {/* 
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "",
                  paddingLeft: 80,
                  paddingRight: 80,
                  paddingBottom: 50,
                }}
              >
                <Background>
                  <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} className="contactDetails">
                      <div>
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{
                            width: 22,
                            height: 22,
                          }}
                          color={COLORS.Primary}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "#1f1f1f",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          lineHeight: 1.1,
                          marginTop: 10,
                          marginTop: 10,
                        }}
                      >
                        Tel
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          linkeHeight: "24px",
                          color: "#555555",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          fontWeight: "400",
                          marginTop: 10,
                        }}
                      >
                        {this.state.company.phoneNumber}
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      className="contactDetails"
                      // style={{
                      //   borderRight: "0.2px solid #DAD9D9",
                      // }}
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{
                            width: 22,
                            height: 22,
                          }}
                          color={COLORS.Primary}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "#1f1f1f",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          marginTop: 10,
                          lineHeight: 1.1,
                        }}
                      >
                        Mail
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          linkeHeight: "24px",
                          color: "#555555",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          fontWeight: "400",
                          marginTop: 10,
                        }}
                      >
                        {this.state.company.email}
                      </div>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <div>
                        <FontAwesomeIcon
                          icon={faStore}
                          style={{
                            width: 22,
                            height: 22,
                          }}
                          color={COLORS.Primary}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "#1f1f1f",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          marginTop: 10,
                          lineHeight: 1.1,
                        }}
                      >
                        Open
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          linkeHeight: "24px",
                          color: "#555555",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto',sans-serif",
                          fontWeight: "400",
                          marginTop: 10,
                        }}
                      >
                        Mon – Sat: 9:00 – 18:00
                      </div>
                    </Grid>
                  </Grid>
                </Background>
              </Grid> */}
              <Grid item xs={11} lg={12} >
                <Grid>

                  <Container fluid style={{ padding: 0 }}>
                    {/* <Row

                    > */}

                    <Grid container xs={12} lg={12}>

                      <Grid item
                        xs={12}

                        lg={5}

                        sx={{
                          padding: {
                            sm: "0px 0px 0px 40px",
                            md: "0px 0px 0px 50px",
                            lg: "0px 0px 0px 100px",
                          }
                        }}
                      >
                        <Typography
                          component="div"
                          sx={{
                            textAlign: {
                              xs: "center",
                              sm: "center",
                            },
                          }}
                          style={{
                            marginBottom: 30,
                          }}
                        >
                          <h2
                            style={{
                              // fontSize: "18.5px",
                              // lineHeight: "32px",
                              // letterSpacing: "1px",
                              textTransform: "capitalize",
                              marginTop: 0,
                              fontFamily: FONTS_INTER,
                              color: COLORS.Primary,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            sx={{
                              fontSize: {
                                sm: "36pt",
                                xs: "30pt",
                              },
                            }}
                          >
                            Send Enquiry
                            {/* <div
                        style={{
                          height: 2,
                          width: "60px",
                          background: COLORS.Primary,
                        }}
                      /> */}
                          </h2>
                        </Typography>

                        <Grid
                          item
                          xs={11.7}

                          style={{
                            backgroundColor: "",
                            paddingBottom: 20,
                          }}
                          sx={{
                            padding: {
                              sm: "0px 5vW"
                            }
                          }}
                        >
                          <Grid
                            container
                            spacing={5}
                            sx={{
                              "&.MuiGrid-root >.MuiGrid-item": {
                                paddingTop: "20px",
                              },
                            }}
                          >
                            <Grid item lg={12} xs={11}>
                              <input
                                onChange={this.onChangeName}
                                value={this.state.name}
                                type="name"
                                name="name"
                                id="name"
                                placeholder="Name"
                                className="bg-white"
                                style={{
                                  border: "none",
                                  borderRadius: "6px",
                                  width: "100%",
                                  fontSize: "16px",
                                  marginLeft: 5,
                                  background: COLORS.GrayDark3,
                                  padding: "8px 15px",
                                  minHeight: "2rem",
                                  outline: "none",
                                  fontFamily: FONTS_INTER,
                                }}
                              />
                              {this.state.errname && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 5px",
                                    fontSize: "8pt",
                                    display: "flex",
                                    justifyContent: "left"
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Grid>
                            {/* <Grid item lg={12} xs={11}>
                              <input
                                onChange={this.onChangeMemberNo}
                                value={this.state.memberNo}
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Member number (Optional)"
                                className="bg-white"
                                style={{
                                  border: "none",
                                  borderRadius: "6px",
                                  width: "100%",
                                  fontSize: "16px",
                                  marginLeft: 5,
                                  background: COLORS.GrayDark3,
                                  padding: "8px 15px",
                                  minHeight: "2rem",
                                  outline: "none",
                                  fontFamily: FONTS_INTER,
                                }}
                              />
                              {this.state.errmNo && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 5px",
                                    fontSize: "8pt",
                                    display: "flex",
                                    justifyContent: "left"
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Grid> */}
                            <Grid item lg={12} xs={11}>
                              <input
                                onChange={this.onChangeContact}
                                value={this.state.contact}
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Contact number"
                                className="bg-white"
                                style={{
                                  border: "none",
                                  borderRadius: "6px",
                                  width: "100%",
                                  fontSize: "16px",
                                  marginLeft: 5,
                                  background: COLORS.GrayDark3,
                                  padding: "8px 15px",
                                  minHeight: "2rem",
                                  outline: "none",
                                  fontFamily: FONTS_INTER,
                                }}
                              />
                              {this.state.errcontact && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 5px",
                                    fontSize: "8pt",
                                    display: "flex",
                                    justifyContent: "left"
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              xs={11}

                            // style={{
                            //   borderRight: "0.2px solid #DAD9D9",
                            // }}
                            >
                              <input
                                onChange={this.onChangeEmail}
                                value={this.state.email}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email"
                                className="bg-white"
                                style={{
                                  border: "none",
                                  borderRadius: "6px",
                                  width: "100%",
                                  fontSize: "16px",
                                  marginLeft: 5,
                                  background: COLORS.GrayDark3,
                                  padding: "8px 15px",
                                  minHeight: "2rem",
                                  outline: "none",
                                  fontFamily: FONTS_INTER,
                                }}
                              />
                              {this.state.erremail && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  style={{
                                    textAlign: "left",
                                    fontFamily: FONTS_INTER,
                                    margin: "5px 5px",
                                    fontSize: "8pt",
                                    display: "flex",
                                    justifyContent: "left"
                                  }}
                                >
                                  This Field is Requied
                                </Typography>
                              )}
                            </Grid>
                            {/* <Grid item lg={4} xs={11}>
                            <input
                              onChange={this.onChangeWebSite}
                              value={this.state.webSite}
                              type="url"
                              name="url"
                              id="url"
                              placeholder="Website"
                              className="bg-white"
                              style={{
                                border: "1px solid #eeeeee",
                                borderRadius: "0px",
                                width: "100%",
                                fontSize: "14px",
                                marginLeft: 5,
                                background: "#fff",
                                padding: "8px 15px",
                                outline: "none",
                                minHeight: "40px",
                                fontFamily: "'Roboto',sans-serif",
                                borderRadius: "3px",
                              }}
                            />
                          </Grid> */}
                          </Grid>

                        </Grid>


                        <Grid lg={11.7} xs={12} style={{ marginBottom: 30 }}
                          sx={{
                            padding: {
                              sm: "0px 13.5vW 0px 5vW",
                              // md: "0px 13vW 0px 5vW",
                              lg: "0px 5vW"
                            }
                          }}
                        >
                          {" "}
                          <textarea
                            className="messageContactField"
                            onChange={this.onChangeMessage}
                            value={this.state.message}
                            //   rows="3"
                            //   cols="30"
                            required
                            type="text"
                            name="message"
                            id="message"
                            placeholder="Type your message"
                            style={{
                              border: "none",
                              borderRadius: "6px",
                              // width: "100%",
                              fontSize: "16px",
                              marginLeft: 5,
                              background: COLORS.GrayDark3,
                              padding: "8px 15px",
                              minHeight: "8rem",
                              outline: "none",
                              fontFamily: FONTS_INTER,
                            }}
                          />
                          {this.state.errMsg && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 5px",
                                fontSize: "8pt",
                                display: "flex",
                                justifyContent: "left"
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Grid>
                        <Grid md={11.7} style={{ marginBottom: "20px" }}
                          sx={{
                            padding: {
                              sm: "0px 13.5vW 0px 5vW",
                              // md: "0px 13vW 0px 5vW",
                              lg: "0px 5vW"
                            }
                          }}
                        >
                          {this.state.toaster && (
                            <label
                              style={{
                                color: "green",
                                // textTransform: "capitalize",
                                fontWeight: "500",
                                fontFamily: "Inter,sans-serif",
                                letterSpacing: "0.6px",
                                fontSize: "1em",
                              }}
                            >
                              {this.state.msg}
                            </label>
                          )}
                        </Grid>

                        <Grid md={12}
                          sx={{
                            padding: {
                              sm: "0px 13vW 0px 5vW",
                              // md: "0px 13vW 0px 5vW",
                              lg: "0px 5vW"
                            }
                          }}
                        >
                          {/* <Button
                          onClick={this.postContactsApi}
                          style={{ background: "#635bff", outline: "none" }}
                        >
                          {" "}
                          Submit
                        </Button> */}

                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "15.5px",
                              color: "white",
                              padding: "20px 40px",
                              outline: "none",
                              border: "1px solid ",
                              borderColor: COLORS.InputBorderColor,
                              borderRadius: 6,
                              height: "2rem",
                              background: COLORS.Orange,
                              boxShadow: "none",
                            }}
                            sx={{
                              width: {
                                xs: "98%",
                                sm: "105%",
                              },
                              marginLeft: {
                                xs: "5px",
                                sm: "5px",
                              },

                            }}
                            onClick={this.postContactsApi}
                          >
                            Send
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item
                        xs={12}
                        lg={6}

                        sx={{
                          padding: {
                            sm: "6.5vW 0px 0px 10px",
                            lg: "2.5vW 0px 0px 10px",
                            xs: "80px 0px"
                          }
                        }}
                      >
                        <Grid container xs={12} lg={12} style={{ display: "flex", justifyContent: "flex-start" }}
                          sx={{
                            padding: {
                              // sm: "6.5vW 0px 0px 10px",
                              // lg: "2.5vW 0px 0px 10px",
                              sm: "0px 80px",
                              lg: "0px"
                            }
                          }}

                        >

                          <Grid xs={12} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Typography style={{ padding: "0px 20px 0px 0px" }} >
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                style={{
                                  width: 22,
                                  height: 22,
                                  border: `2px solid ${COLORS.Primary}`,
                                  padding: 10,
                                  borderRadius: "50%",
                                }}
                                color={COLORS.Primary}
                              />

                            </Typography>
                            <Typography style={{ display: "flex", flexDirection: "column", justifyContent: "left", textAlign: "left" }}>

                              <Typography
                                style={{
                                  fontSize: "14pt",
                                  color: COLORS.TitleColor,
                                  fontFamily: FONTS_INTER,
                                  fontWeight: "bold",
                                  marginTop: 10,
                                }}
                              >

                                Kitchen

                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "13pt",
                                  color: COLORS.TextColor,
                                  fontFamily: FONTS_INTER,
                                  // fontWeight: "bold",
                                  marginTop: 10,
                                }}
                              >


                                8/11 Swift Way,<br />
                                Dandenong South VIC <br />3175


                              </Typography>
                            </Typography>



                          </Grid>
                          <Grid xs={12} lg={6} style={{ display: "flex", justifyContent: "flex-start" }}
                            // sx={{
                            //   padding: {
                            //     xs: "10px 0px 0px 65px",
                            //     sm: "0px 0px"
                            //   }
                            // }}
                            sx={{
                              padding: {
                                // sm: "6.5vW 0px 0px 10px",
                                // lg: "2.5vW 0px 0px 10px",
                                xs: "10px 0px 0px 65px",
                                sm: "20px 60px",
                                lg: "0px"
                              }
                            }}
                          >

                            <Typography style={{ display: "flex", flexDirection: "column", justifyContent: "left", textAlign: "left" }}>

                              <Typography
                                style={{
                                  fontSize: "14pt",
                                  color: COLORS.TitleColor,
                                  fontFamily: FONTS_INTER,
                                  fontWeight: "bold",
                                  marginTop: 5,
                                }}
                              >

                                Office

                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "13pt",
                                  color: COLORS.TextColor,
                                  fontFamily: FONTS_INTER,
                                  // fontWeight: "bold",
                                  marginTop: 10,
                                }}
                              >

                                3/26-28 Verdun Dr, <br />Narre Warren VIC <br />3805

                              </Typography>
                            </Typography>



                          </Grid>


                        </Grid>

                        <Grid xs={12} style={{ display: "flex", justifyContent: "flex-start" }}
                        
                          sx={{
                            padding: {
                              // sm: "6.5vW 0px 0px 10px",
                              // lg: "2.5vW 0px 0px 10px",
                              xs: "40px 0px",
                              sm: "50px 80px",
                              lg: "30px 0px"
                            }
                          }}
                        >

                          <div style={{ padding: "0px 20px 0px 0px" }}>
                            <FontAwesomeIcon
                              icon={faPhone}
                              style={{
                                width: 22,
                                height: 22,
                                border: `2px solid ${COLORS.Primary}`,
                                padding: 10,
                                borderRadius: "50%",
                              }}
                              color={COLORS.Primary}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: "14pt",
                              color: COLORS.TextColor,
                              fontFamily: FONTS_INTER,
                              // fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {/* {this.state.company.phoneNumber} */}
                            (03) 8794 8694
                          </div>
                        </Grid>
                        <Grid xs={12} style={{ display: "flex", justifyContent: "flex-start" }} 
                         sx={{
                          padding: {
                            // sm: "6.5vW 0px 0px 10px",
                            // lg: "2.5vW 0px 0px 10px",
                            xs: "0px 0px",
                            sm: "0px 80px",
                            lg: "10px 0px"
                          }
                        }}
                        >

                          <div style={{ padding: "0px 20px 0px 0px" }}>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              style={{
                                width: 22,
                                height: 22,
                                border: `2px solid ${COLORS.Primary}`,
                                padding: 10,
                                borderRadius: "50%",
                              }}
                              color={COLORS.Primary}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: "14pt",
                              color: COLORS.TextColor,
                              fontFamily: FONTS_INTER,
                              // fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {this.state.company.email}
                          </div>
                        </Grid>

                      </Grid>



                    </Grid>





                    {/* </Row> */}

                    {/* <Grid
                      container
                      xs={12}
                      sx={{
                        padding: {
                          xs: "60px 0px 20px",
                          sm: "50px 0px",
                        },
                      }}
                    >
                      <Grid item lg={6} xs={12} className="contactDetails">
                        <div>
                          <FontAwesomeIcon
                            icon={faPhone}
                            style={{
                              width: 22,
                              height: 22,
                              border: `2px solid ${COLORS.Primary}`,
                              padding: 10,
                              borderRadius: "50%",
                            }}
                            color={COLORS.Primary}
                          />
                        </div>

                        <div
                          style={{
                            fontSize: "15pt",
                            color: COLORS.TitleColor,
                            fontFamily: FONTS_INTER,
                            fontWeight: "bold",
                            marginTop: 10,
                          }}
                        >
                        
                          (03) 8794 8694
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xs={12}
                        sx={{
                          marginTop: {
                            xs: "30px",
                            sm: "0px",
                          },
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{
                              width: 22,
                              height: 22,
                              border: `2px solid ${COLORS.Primary}`,
                              padding: 10,
                              borderRadius: "50%",
                            }}
                            color={COLORS.Primary}
                          />
                        </div>

                        <div
                          style={{
                            fontSize: "15pt",
                            color: COLORS.TitleColor,
                            fontFamily: FONTS_INTER,
                            fontWeight: "bold",
                            marginTop: 10,
                          }}
                        >
                          {this.state.company.email}
                        </div>
                      </Grid>
                    </Grid> */}



                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </Col>
        </Row>
        <Grid
          container
          xs={12}

          sx={{
            padding: {
              xs: "60px 0px 0px 0px",
              lg: "50px 0px 0px 0px",
            },
          }}
        >
          <Grid item lg={6} xs={12}
            style={{
              position: "relative",
              display: "block",
              zIndex: 1,
              width: "100%",
            }}
          >
            <iframe
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=3/26-28 Verdun Drive, Narre Warren, VIC 3805.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              className="contact-page-google-map__one"
              allowFullScreen
              style={{
                position: "relative",
                display: "block",
                width: "100%",
                height: "600px",
                border: "none"
              }}
            ></iframe>
          </Grid>
          <Grid item lg={6} xs={12}
            style={{
              position: "relative",
              display: "block",
              zIndex: 1,
              width: "100%",
            }}
            sx={{
              marginTop: {
                xs: "20px",
                lg: "0px",
              },
            }}
          >
            <iframe
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=8/11 Swift Way, Dandenong South VIC 3175&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              className="contact-page-google-map__one"
              allowFullScreen
              style={{
                position: "relative",
                display: "block",
                width: "100%",
                height: "600px",
                border: "none"
              }}
            ></iframe>
          </Grid>
        </Grid>
        {/* </Container> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Contacts);
