import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
function Background({ children }) {
  return (
    <Grid
      container
      style={{
        justifyContent: "center",
      }}
    >
      <Grid item sm={1} xs={0}></Grid>
      <Grid item sm={10} xs={12}>
        {children}
      </Grid>
      <Grid item sm={1} xs={0}></Grid>

      {/* <Row
        style={{
          display: "grid",
          placeItems: "center",
          background: "pink",
        }}
      >
        <Col
          xxl={12}
          xl={11}
          lg={12}
          md={12}
          style={{
            padding: "0 2vw ",
            background: "green",
            width: "100%",
          }}
        >
          {children}
        </Col>
      </Row> */}
    </Grid>
  );
}

export default Background;
