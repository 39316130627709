import {
  Button,
  Card,
  CardContent,
  Divider,
  getCardUtilityClass,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useHistory, Link } from "react-router-dom";
import { FONTS, FONTS_AC, FONTS_ANOTON, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CurrentUser,
  getMemberbyId,
  getPackages,
  getCart,
  getMemberCart,
} from "../../Services/API/Get";
import {
  packageToUser,
  packageToMember,
  CartAddress,
} from "../../Services/API/Put";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";

import Addmember from "../Models/addMemberPopup.jsx";
import ErrorPopup from "../Models/errorPopup.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import { EMAIL_TAMPLETE } from "../Checkout/email";
import "./cart.css";
import { DeleteCart } from "../../Services/API/Delete";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import { getAllCities } from "../../Services/API/Get";
import CartCount from "../../Componets/CartCount";
import CartContext from "../../Context/CartContext";

var moment = require("moment");

function MealsTable() {
  var matches = window.matchMedia("(max-width: 600px)");
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgDesc, setMsgDesc] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  // const [openCard, setOpenCard] = useState(true);

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("No");
  const [showContent, setShowContent] = useState(0);

  const [userId, setuserId] = useState(null);
  const [memberId, setmemberId] = useState(null);
  const [members, setMembers] = useState([
    // {
    //   firstName: "Add Member",
    //   lastName: "",
    // },
  ]);
  const [listOf, setListOf] = useState([]);
  const [packages, setPackages] = useState([]);
  const [planType, setplanType] = useState("");
  const [cartDetails, setUCart] = useState([]);
  const [total, setTotal] = useState("");
  const [delivery] = useState(470);
  const [items, setitems] = useState("");
  const [meals, setMeals] = useState("");
  const [address, setAddress] = useState([]);
  const [name, setname] = useState("");
  const [productMealsIndex, setProductMealsIndex] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [idisable, setidisable] = useState(true);
  let mTot = 0;
  const [McartDetails, setMCart] = useState([]);
  const [expired, setExpired] = useState([]);
  const [mexpired, setmExpired] = useState([]);
  const [mexpireStatus, setmexpireStatus] = useState(false);
  const [lopen, setlopen] = React.useState(false);
  const [lopen2, setlopen2] = React.useState(false);

  const [allHCities, setallHCities] = useState([]);

  const { setCart, scrollToElement3, scrollToElement } =
    React.useContext(CartContext);

  const [currMember, setcurrMember] = useState("");
  const [Tmembers, setTMembers] = useState([]);

  const [delType, setdelType] = useState("delivery");



  const history = useHistory();
  const useStylesAutoComplete = makeStyles((theme) => ({
    "@global": {
      ".MuiAutocomplete-option": {
        width: "100%",
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
        width: matches.matches === true ? "16.6vw" : "265px",
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "12pt",

        color: COLORS.TextColor2,
        fontWeight: 400,
        outline: "none",
        borderRadius: "6",
        height: "1.4rem",
      },
      '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
        opacity: 1,
        // WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
        fontWeight: "400",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",

        background: "transparent",
      },
    },
  }));
  const useStyles = makeStyles((theme) => ({
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {

      border: "none",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      width: "100%",
      height: "5px",
      // padding: "5px 12px ",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      borderColor: "white",
    },
    "& .MuiList-root .MuiMenu-list ": {
      flexDirection: "column",
      display: "flex",
      alignItems: "flex-start",
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "white",
      border: "none",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "white",
      border: "none",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "1px 12px",
      borderColor: "white",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
    {
      display: "flex",
    },
    "& .MuiPaper-root": {
      minWidth: "300px",
      left: "100px",

    },
  }));
  const classes = useStylesAutoComplete();
  const classes2 = useStyles()


  useEffect(() => {
    if (delType) {
      localStorage.setItem("delType", delType);
    }
  }, [delType])


  const handleChange = (event) => {
    const di = window.document.querySelector("#selectElemement")
    console.log("--->", di)


    if (di) {
      const dd = window.document.querySelector("fieldset")
      dd.style.border = "none"


      const ds = window.document.querySelector("#selectElemement").querySelector("div");
      const dut = ds.childNodes[0].style.padding = "4px 10px";





    }
    setSelectedValue(event.target.value);
    if (event.target.value === "No") {
      setidisable(true);

    } else {
      setidisable(false);
    }


  };
  const theme2 = createTheme({
    palette: {
      primary: {
        main: COLORS.PrimaryBackground,
      },
      secondary: {
        main: COLORS.PrimaryBackground,
      },
    },

  });
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxWidth: 300,
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.GrayDark9,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "11pt",
      color: COLORS.TextColor,
      fontFamily: FONTS,
      borderBottom: "none",
    },
  }));
  const StyledTableCell2 = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: "13pt",
      color: COLORS.GrayDark2,
      fontFamily: FONTS_INTER,
      fontWeight: "600",
      padding: "16px 8px",
    },
  }));
  const StyledTableCell3 = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: "11pt",
      color: COLORS.GrayDark2,
      fontFamily: FONTS_INTER,
      borderBottom: "none",
      padding: 8,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    color: COLORS.TextColor,
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const calDelivery = () => {

    let allCart = []

    let delivery = 5

    let Friarr = []

    let Tuearr = []

    let friday, tuesday = ""

    console.log("ALALALLA", cartDetails)

    cartDetails?.map((d) => {

      console.log(">AAAAAAa", d[Object.keys(d)[0]][0].activeDate)

      if (d[Object.keys(d)[0]][0].expire === 0)

        allCart.push(d[Object.keys(d)[0]][0].activeDate)

    });


    McartDetails?.map((d) => {

      d.members?.map((d2) => {

        if (d2.expire === 0)

          allCart.push(d2.activeDate)

      });



    });

    console.log("POOOL", allCart)

    const datesArrNew = _.uniq(allCart)

    console.log("<><><><>", datesArrNew)

    datesArrNew?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(1, "days")

          break;

        case "Thu":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(2, "days")

          break;

        case "Fri":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(3, "days")

          break;


      }



    });


    // if (delType === "delivery") {

    //   if (Friarr.length > 0) {
    //     delivery += 5;
    //   }

    //   if (Tuearr.length > 0) {
    //     delivery += 5;
    //   }

    // }



    console.log("final del>>>>>", delivery)

    return delivery;


  }



  const getMembers = () => {
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);
    if (getToken) {
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];
            const obj = {
              id: cData.id,
              email: cData?.email,
              firstName: cData?.firstName,
              lastName: cData?.lastName,
              phone: cData?.mobileNumber,
            };
            setuserId(cData?.id);
            getMemberbyId(cData.id)
              .then((res) => {
                console.log("members responseII", res?.data);
                setTMembers(res?.data);
                const mArr = res.data?.map((d) => {
                  return {
                    ...d,
                  };
                });
                // const mm = mArr?.unshift({
                //   firstName: "Add Member",
                //   lastName: "",
                // });
                setMembers(mArr);
              })
              .catch((error) => {
                console.log("error", error);
              });

            localStorage.setItem(
              `${window.location.hostname}.profile`,
              JSON.stringify(obj)
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current user error", error.response.data.message);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };
  const handleChangeMember = (e, value) => {
    setmemberId(value.id);
  };
  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsgDesc("");
        setMsg(error.response.data.message);
      });
  };
  const assignPackage = (pkgId, planType) => {
    console.log("<><><><>", memberId)
    const obj = {
      packageId: pkgId,
    };
    if (planType === "user") {
      packageToUser(obj, userId, "save")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Package selected successfully!");
          setTimeout(() => {
            history.push("/plans");
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    } else if (planType === "member") {
      packageToMember(obj, memberId, "save")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Package selected successfully!");
          setTimeout(() => {
            history.push(`/plans?memberId=${memberId}`);
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  const CartGet = () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;
    let arrUser = [];
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);
    if (getToken) {
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];
            setCustomer(cData);
            setname(cData.firstName + " " + cData.lastName);
            console.log("oooooooooooooooooo", res);
            CartCount().then((res) => {
              setCart(res);
            });
            getCart({
              sortField: sortField,
              sort: sort,
              limit: limit,
              page: page,
              userId: cData?.id,
            })
              .then((res) => {
                setlopen(false);
                console.log("Cart >>>", res.data);

                const uCartItems = res.data?.map((d) => {
                  return {
                    ...d,
                    deliveryDate: JSON.parse(d.productDetails)[0].deliveryDate,
                  };
                });

                var result = groupByKey(uCartItems, "deliveryDate");

                let cItem = Object.entries(result).map(([k, v]) => ({
                  [k]: v,
                }));

                var cItem2 = cItem.sort(function (a, b) {
                  if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                  return -1;
                });

                var arrItems = res.data?.filter(function (el) {
                  return el.expire === 0;
                });

                let product = Object.assign({}, res.data[0]);
                product.usedMeals = arrItems?.length;
                product.type = "user";

                const update3 = res.data?.map((d) => {
                  return {
                    ...d,
                    deliveryDate: JSON.parse(d.productDetails)[0].deliveryDate,
                    Etype: "user",
                  };
                });

                var newArray = update3?.filter(function (el) {
                  return el.expire === 1;
                });

                var newArr2 = _.uniqBy(newArray, function (e) {
                  return e.deliveryDate;
                });

                newArr2.sort(dynamicSort("deliveryDate"));

                product.productD = update3;
                product.productExpire = newArr2;

                arrUser.push(product);
                const update5 = arrUser?.map((d) => {
                  return { ...d, status: false, id: d.cartId };
                });
                setListOf(update5);

                console.log("--------_>", cItem2);
                setUCart(cItem2);
                setTotal(cData?.isStudent === 1 ? res.data[0].price * 90 / 100 : res.data[0].price);
                setitems(arrItems.length);
                setMeals(res.data[0].noofmeals);
                // Address = res.data
                setAddress(res.data);

                var newArray = res.data?.filter(function (el) {
                  return el.expire === 1;
                });

                const update2 = newArray?.map((d) => {
                  return {
                    ...d,
                    deliveryDate: JSON.parse(d.productDetails)[0].deliveryDate,
                  };
                });

                var newArr2 = _.uniqBy(update2, function (e) {
                  return e.deliveryDate;
                });

                newArr2.sort(dynamicSort("deliveryDate"));

                console.log("expired > >>>>>", newArr2);
                setExpired(newArr2);
                setShowContent(1);
              })
              .catch((error) => {
                setlopen(false);
                setUCart([]);
                setTotal("");
                setitems("");
                setMeals("");
                // Address = res.data
                setAddress([]);
                setExpired([]);
                setShowContent(2);
                console.log("error", error);
                console.log(
                  "current package error",
                  error.response?.data.message
                );
                setCart(0);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
              });

            getMemberCart({
              sortField: sortField,
              sort: sort,
              limit: limit,
              page: page,
              userId: cData?.id,
            })
              .then((res) => {
                setlopen(false);
                console.log("Member Cart >>>", res.data);
                setMCart(res.data);
                // setTotal(res.data[0].price)
                // setitems(res.data.length)
                // setMeals(res.data[0].noofmeals)
                // setAddress(res.data)
                let newArr = [];
                let mfilter = _.map(res.data, "members");
                console.log("<<<<<<<", mfilter);

                mfilter?.map((item, index) => {
                  console.log("!@@!@@", item);

                  var newArray = item?.filter(function (el) {
                    return el.expire === 1;
                  });

                  const update2 = newArray?.map((d) => {
                    return {
                      ...d,
                      deliveryDate: JSON.parse(d.productDetails)[0]
                        .deliveryDate,
                    };
                  });

                  var newArr2 = _.uniqBy(update2, function (e) {
                    return e.deliveryDate;
                  });

                  newArr2.sort(dynamicSort("deliveryDate"));

                  console.log("+++++++", newArr2);

                  if (newArr2.length != 0) {
                    setmexpireStatus(true);
                  }

                  newArr.push(newArr2);
                });

                console.log("m expired > >>>>>", newArr);
                setmExpired(newArr);
              })
              .catch((error) => {
                setlopen(false);
                setMCart([]);
                console.log("error", error);
                console.log(
                  "current package error",
                  error.response?.data.message
                );
              });
          }
        })
        .catch((error) => {
          setlopen(false);
          setShowContent(2);
        });
    } else {
      setlopen(false);
      setShowContent(2);
    }
  };

  // const onChangeAddress = (e, cartId, index) => {
  //   let cartId1 = cartId;
  //   let index1 = index;

  //   console.log("edit address>>>>", e.target.value, cartId, index);
  //   const upD = address?.map((d) =>
  //     d.cartId === cartId
  //       ? {
  //           ...d,
  //           homeAddress: e.target.value,
  //         }
  //       : { ...d, homeAddress: cartDetails[index]?.homeAddress }
  //   );
  //   setAddress(upD);

  //   // Address = upD

  //   let obj = {
  //     deliveryAddress: address[index].homeAddress,
  //   };

  //   CartAddress(obj, cartId)
  //     .then((res) => {
  //       console.log("Up Cart >>>", res.data);
  //       // CartGet();
  //       // setMCart(res.data);
  //       // setTotal(res.data[0].price)
  //       // setitems(res.data.length)
  //       // setMeals(res.data[0].noofmeals)
  //       // setAddress(res.data)
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       console.log("current package error", error.response.data.message);
  //       // setOpenToast(true);
  //       // setColor(COLORS.Orange);
  //       // setAlertClass("error");
  //       // setMsg(error.response.data.message);
  //     });

  //   // cartDetails[index].homeAddress = e.target.value
  //   // address[index].homeAddress = e.target.value

  //   // this.state.dataList[index].owing = e.target.value
  //   // this.setState({
  //   //   owing: e.target.value
  //   // }, () => {
  //   //   console.log("owing  #######", this.state.dataList[index].owing)
  //   //   this.runSheetData(createdAt1, index1)
  //   // })
  // };

  const listHomecities = () => {
    getAllCities()
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };
  React.useEffect(() => {
    getMembers();
    getPackage();
    CartGet();
    listHomecities();

  }, []);
  React.useEffect(() => {
    getMembers();
  }, [open4]);
  React.useEffect(() => {
    console.log("BOBO");
    CartCount().then((res) => {
      setCart(res);
    });
  }, []);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose = () => setOpen(false);

  // {
  //   _.map(McartDetails, "members")?.map(
  //     (row, index) => (mTot = parseFloat(row[0].price) + mTot)
  //   );
  // }

  {
    _.map(Tmembers)?.map((row, index) =>
      row.packageId !== null ?

        row.isStudent === 1 ?
          (mTot = parseFloat(row.price * 90 / 100) + mTot)
          :
          (mTot = parseFloat(row.price) + mTot)
        :
        false
    );
  }

  const OnTableRow = ({ row, index }) => {
    let date = row?.deliveryDate;
    let dDate = new Date(date);

    // var nextDay = new Date(date);
    // nextDay.setDate(dDate.getDate() - 2);
    var nextDay = moment(date).subtract(2, "days")

    const [onUpdateAddress, setOnUpdateAddress] = useState(row?.homeAddress);
    const [onUpdateCity, setOnUpdateCity] = useState(row?.homeCity);
    const [edit, setEdit] = useState(true);
    const [myCartId, setMyCartId] = useState(null);
    const [myIndex, setMyIndex] = useState(0);
    const onChangeAddress = (address, cartId, index, city) => {
      let obj = {
        deliveryAddress: address,
        city: city,
      };

      CartAddress(obj, cartId)
        .then((res) => {
          setMyCartId(null);
          CartGet();
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    };

    const onChangeRemove = () => {
      setlopen(true);
      DeleteCart(row.cartId)
        .then(() => {
          setMyCartId(null);
          CartGet();
        })
        .catch((error) => {
          setlopen(false);
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    };
    return (
      <StyledTableRow
        key={`${row.cartId}`}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            // textDecoration: row.expire === 1 ? "line-through" : "none",
            padding: "5px 0px 5px 0px",
            width: "20%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          component="th"
          scope="row"
        >
          {/* Wednesday, 22nd Feb */}
          {index === 0 ? (
            <div
              style={{
                background: "white",
                padding: "8px 8px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                // marginTop: 5,
              }}
            >
              {moment(dDate).format("dddd, DD MMM")}
            </div>
          ) : (
            <></>
          )}
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            padding: "5px 0px 5px 0px",
            // textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "10%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.mealType}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            // textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "18%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.productName}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            // textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "10%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.meatType}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "0px 0px 0px 0px",
            // textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "18%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="left"
        >
          {/* {row.homeAddress} */}
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              background: "white",
              padding: "6px 16px 5px",
              // marginTop: 5,
            }}
          >
            <input
              id="text"
              disabled={edit}
              style={{
                border: "none",
                borderRadius: "0px",
                // width: "9.2rem",ddd
                fontSize: "11pt",
                color: "#8A8A8A",
                flex: 1,
                padding: "8px 8px ",
                background: edit === false ? "#F3F3F3" : "white",
                width: "90%",
                borderRadius: "3px",
              }}
              //   onChange={(e) => {
              //     setAddress(e.target.value);

              //   }}
              onChange={(e) => {
                // onChangeAddress(e, row.cartId, index);
                setOnUpdateAddress(e.target.value);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              value={onUpdateAddress}
              placeholder="Enter Delivery Address"
            />
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "0px",

            width: "12%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="left"
        >
          {/* {row.homeAddress} */}
          <div
            style={{
              marginLeft: 0,
              height: "2rem",
              borderRadius: 3,
            }}
          >
            <input
              id="text"
              disabled={edit}
              style={{
                border: "none",
                borderRadius: "0px",
                // width: "9.2rem",ddd
                fontSize: "11pt",
                color: "#8A8A8A",
                flex: 1,
                padding: "8px 8px ",
                background: edit === false ? "#F3F3F3" : "white",
                width: "60%",
                borderRadius: "3px",
              }}
              //   onChange={(e) => {
              //     setAddress(e.target.value);

              //   }}
              onChange={(e) => {
                // onChangeAddress(e, row.cartId, index);
                setOnUpdateAddress(e.target.value);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              value={onUpdateCity}
              placeholder="Enter Delivery City"
            />
            {/* <Autocomplete
              disabled={edit}
              classes={classes}
              defaultValue={{ name_en: onUpdateCity }}
              options={allHCities}
              getOptionLabel={(option) => option.name_en}
              onChange={(event, newValue) => {
                // handleChangeHdist(event, newValue);
                setOnUpdateCity(newValue.name_en);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <p
                    style={{
                      fontSize: "9pt",
                      padding: "0",
                      margin: "0",
                      width: "230px",
                    }}
                  >
                    {option.name_en}
                  </p>
                </React.Fragment>
              )}
              ListboxProps={{
                style: {
                  fontSize: "10.5pt",
                  fontFamily: FONTS_INTER,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            /> */}
          </div>
          {/* <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              padding: "6px 16px 5px",
              // marginTop: 5,
              background: "white",
              // borderTopRightRadius: "8px",
              // borderBottomRightRadius: "8px",
            }}
          >
            <input
              id="text"
              disabled={edit}
              style={{
                border: "none",
                borderRadius: "0px",
                // width: "9.2rem",ddd
                fontSize: "11pt",
                color: "#8A8A8A",
                flex: 1,
                padding: "8px 8px",
                background: edit === false ? COLORS.GrayBackground2 : "white",
              }}
              //   onChange={(e) => {
              //     setAddress(e.target.value);

              //   }}
              onChange={(e) => {
                // onChangeAddress(e, row.cartId, index);
                setOnUpdateCity(e.target.value);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              value={onUpdateCity}
              placeholder="Enter Delivery City"
            />
          </div> */}
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            display: "flex",
            padding: "0px 0px 0px 0px",
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            width: "5%",
            opacity: row.expire === 1 ? 0.5 : 1,
          }}
          align="right"
        >
          <div>
            <Tooltip arrow title="Remove">
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={
                  row.expire === 0
                    ? () => onChangeRemove()
                    : () => {
                      setOpenToast(true);
                      setColor(COLORS.Orange);
                      setAlertClass("error");
                      setMsgDesc("");
                      setMsg(
                        "The cut-off time for ordering this meal has been passed. "
                      );
                    }
                }
              />
            </Tooltip>
          </div>

          {edit === true ? (
            <div
              // onClick={() => {
              //   if (row.expire === 0) {
              //     setEdit(false);
              //   } else {
              //     setOpenToast(true);
              //     setColor(COLORS.Orange);
              //     setAlertClass("error");
              //     setMsg(
              //       "The cut-off time for ordering this meal has been passed. "
              //     );
              //   }
              // }}

              style={{
                background: "white",
                width: "100%",
                padding: "3px 10px 4px 0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                curosr: "pointer",
              }}
            >
              {/* <Tooltip arrow title="Enable Address Field">
                <EditIcon />
              </Tooltip> */}
            </div>
          ) : (
            <div
              style={{
                background: "white",
                width: "100%",
                padding: "3px 10px 4px 0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
              onClick={() => {
                setOnUpdateAddress(row?.homeAddress);
                setMyCartId(null);
                setEdit(true);
              }}
            >
              <Tooltip arrow title="Cancel">
                <ClearIcon />
              </Tooltip>
            </div>
          )}
          {edit === false && myCartId !== null && (
            <div
              onClick={() => {
                onChangeAddress(
                  onUpdateAddress,
                  myCartId,
                  myIndex,
                  onUpdateCity
                );
              }}
            >
              <Tooltip arrow title="Save New Address">
                <SaveIcon />
              </Tooltip>
            </div>
          )}
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            width: "7%",
            // textDecoration: row.expire === 1 ? "line-through" : "none",
          }}
          align="left"
        >
          {row.expire === 1 && (
            <Tooltip
              style={{
                fontSize: "9pt",
                textAlign: "center"
              }}
              arrow
              title={
                <span style={{ textAlign: "center" }}>
                  <span> Cutoff time of ordering </span>
                  <br />
                  <span>
                    this meal is 3pm on  {moment(row?.expireDate).format("DD-MM-YYYY")}
                  </span>
                </span>
              }
            >
              <div
                style={{
                  background: "white",
                  padding: "8px 8px",
                  // marginTop: 5,
                  color: COLORS.Red2,
                  fontFamily: "bold",
                  fontSize: "11pt",
                  fontFamily: FONTS_INTER,
                  // fontWeight: "500",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                {row.expire === 1 ? `Expired` : ``}
              </div>
            </Tooltip>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  const OnTableRow2 = ({ row, index, type }) => {
    // console.log("@@@@@@@",row)
    let date = JSON.parse(row?.productDetails)[0]?.deliveryDate;
    let dDate = new Date(date);

    dDate.setDate(dDate.getDate() + 7);

    const [onUpdateAddress, setOnUpdateAddress] = useState(
      // address[index]?.homeAddress
      row?.homeAddress
    );
    const [edit, setEdit] = useState(true);
    const [myCartId, setMyCartId] = useState(null);
    const [myIndex, setMyIndex] = useState(0);
    const onChangeAddress = (e, cartId, index) => {
      let obj = {
        deliveryAddress: e,
      };

      CartAddress(obj, cartId)
        .then((res) => {
          setMyCartId(null);
          CartGet();
          // CartGet();
          // setMCart(res.data);
          // setTotal(res.data[0].price)
          // setitems(res.data.length)
          // setMeals(res.data[0].noofmeals)
          // setAddress(res.data)
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });

      // cartDetails[index].homeAddress = e.target.value
      // address[index].homeAddress = e.target.value

      // this.state.dataList[index].owing = e.target.value
      // this.setState({
      //   owing: e.target.value
      // }, () => {
      //   console.log("owing  #######", this.state.dataList[index].owing)
      //   this.runSheetData(createdAt1, index1)
      // })
    };

    return (
      <StyledTableRow key={`${row.cartId}`}>
        <StyledTableCell
          style={{ fontSize: "11pt", fontFamily: FONTS_INTER }}
          component="th"
          scope="row"
        >
          {moment(dDate).format("dddd, DD MMM")}
          <Box textAlign="right">
            <Button
              style={{
                background: COLORS.Orange,
                color: "white",
                padding: "0px 20px",
                borderRadius: "20px",
                width: "fit-content",
                margin: "-30px 0px 15px 0px",
                fontFamily: FONTS_AC,
                fontSize: "13.4pt",
                cursor: "pointer",
                boxShadow: "0 2px 4px 0 #888888",
                display: "inline-flex",
                textTransform: "capitalize",
              }}
              onClick={() => {
                if (type === "user") {
                  history.push(`/plans?mealDay=${date}`);
                } else if (type === "member") {
                  history.push(
                    `/plans?mealDay=${date}&memberId=${row.memberId}`
                  );
                }
              }}
            >
              +
            </Button>
          </Box>
        </StyledTableCell>
        <StyledTableCell
          style={{ fontSize: "11pt", fontFamily: FONTS_INTER, display: "flex" }}
          align="right"
        ></StyledTableCell>
      </StyledTableRow>
    );
  };
  const OnTableRow3 = ({ row, index, type }) => {
    return (
      <Grid
        sm={12}
        style={{
          margin: "20px 0px 35px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box textAlign="left">
          <Button
            style={{
              background: COLORS.green,
              color: "white",
              padding: "4px 45px",
              borderRadius: "8px",
              width: "fit-content",
              // margin: "-30px 0px 15px 0px",
              fontFamily: FONTS_INTER,
              fontSize: "14pt",
              cursor: "pointer",
              boxShadow: "none",
              display: "inline-flex",
              textTransform: "capitalize",
            }}
            onClick={() => {
              if (type === "user") {
                history.push(`/plans`);
                // scrollToElement3()
                setTimeout(() => {
                  scrollToElement();

                }, [1000]);
              } else if (type === "member") {
                history.push(`/plans?&memberId=${row.memberId}`);
                // scrollToElement3();
                setTimeout(() => {
                  scrollToElement();

                }, [1000]);
              }
            }}
          >
            Add more
          </Button>
        </Box>
      </Grid>
      // <StyledTableRow>
      //   <StyledTableCell
      //     style={{ fontSize: "11pt", fontFamily: FONTS_INTER }}
      //     component="th"
      //     scope="row"
      //   >
      //     {/* {moment(dDate).format("dddd, DD MMM")} */}
      //     <Box textAlign="left">
      //       <Button
      //         style={{
      //           background: COLORS.green,
      //           color: "white",
      //           padding: "4px 45px",
      //           borderRadius: "8px",
      //           width: "fit-content",
      //           // margin: "-30px 0px 15px 0px",
      //           fontFamily: FONTS_INTER,
      //           fontSize: "14pt",
      //           cursor: "pointer",
      //           boxShadow: "none",
      //           display: "inline-flex",
      //           textTransform: "capitalize",
      //         }}
      //         onClick={() => {
      //           if (type === "user") {
      //             history.push(`/plans`);
      //           } else if (type === "member") {
      //             history.push(`/plans?&memberId=${row.memberId}`);
      //           }
      //         }}
      //       >
      //         Add more
      //       </Button>
      //     </Box>
      //   </StyledTableCell>
      //   <StyledTableCell
      //     style={{ fontSize: "11pt", fontFamily: FONTS_INTER, display: "flex" }}
      //     align="right"
      //   ></StyledTableCell>
      // </StyledTableRow>
    );
  };
  const PROUDCT_MEALS_CARD = ({ data }) => {
    return (
      <Grid
        item
        xs={12}
        md={4}
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "10px 20px",
        }}
      >
        <Card
          style={{
            minWidth: 265,
            maxWidth: 300,

            backgroundColor: COLORS.Primary,
          }}
        >
          <CardContent
            style={{
              padding: "27px",
            }}
          >
            <Typography
              sx={{
                fontSize: "30pt",
                color: COLORS.Orange2,
                fontWeight: 400,
                fontFamily: FONTS_AC,
                textTransform: "uppercase",
                justifyContent: "center",
                display: "flex",
              }}
              gutterBottom
            >
              {data?.name}
            </Typography>
            <Typography
              component="div"
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "10px 0px 0px",
                // marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: 110,
                  height: 110,
                  borderRadius: "50%",
                  backgroundColor: "#e8eeee",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: COLORS.PrimaryText,
                    fontSize: "41.2pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                    lineHeight: "40px",
                  }}
                >
                  {data.noofmeals}
                </Typography>
                <Typography
                  style={{
                    color: COLORS.TextColor,
                    fontSize: "13.9pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "500",
                  }}
                >
                  MEALS
                </Typography>
              </div>
            </Typography>
            <Typography
               style={{
                // mb: 1.5,
                // mt: 3,
                color: "white",
                textAlign: "center",
                fontSize: "9.9pt",
                fontFamily: FONTS_INTER,
                margin: "20px 0px 10px",
                minHeight: "200px",
              }}
              color="text.secondary"
            >
              {data.description}
            </Typography>
            <Typography>
              <p
                style={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: "32.9pt",
                  fontFamily: FONTS_AC,
                  margin: "10px 0px",
                }}
              >
                {" "}
                {data.price}
                {" AUS "}
              </p>
            </Typography>
            <Typography
              variant="body2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                style={{
                  background: COLORS.Orange,
                  fontSize: "13pt",
                  fontFamily: FONTS_INTER,
                  // background-color: rgb(255, 168, 20);
                  color: "white",
                  height: " 36px",
                  width: " 125px",

                  fontWeight: "bold",
                }}
                onClick={() => assignPackage(data.id, planType)}
              >
                SELECT
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const Checkout = async () => {
    setlopen2(true);
    await CartGet();
    await getMembers();

    let result = "";

    // if (!delType) {
    //   setlopen2(false);
    //   setOpenToast(true);
    //   setColor(COLORS.Orange);
    //   setAlertClass("error");
    //   setMsgDesc("");
    //   setMsg(
    //     "Please select collect option to continue checkout."
    //   );
    //   setMsgDesc("");
    //   return;

    // }

    setTimeout(() => {
      if (meals - items !== 0 && expired.length !== 0) {
        setlopen2(false);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsgDesc("");
        setMsg(
          "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
        );
        setMsgDesc("");
        return;
      } else if (meals - items !== 0) {
        setlopen2(false);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsgDesc("Incomplete Meal Plan");
        setMsg(
          `Please complete full weekly plan for ${name} prior to checkout`
        );
        return;
      }

      for (const data of Tmembers) {
        if (data.packageId !== null) {
          if (McartDetails.length > 0) {
            console.log("KLKLKLKL", McartDetails);

            const newArr1 = _.map(McartDetails, "memberId");

            if (!newArr1.includes(data.id.toString())) {
              console.log("hello1");

              setlopen2(false);
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsgDesc("Incomplete Meal Plan");
              setMsg(
                `Please complete full weekly plan for ${data.firstName + " " + data.lastName
                } prior to checkout`
              );
              return;
            }
          } else {
            console.log("hello2");
            setlopen2(false);
            setOpenToast(true);
            setColor(COLORS.Orange);
            setAlertClass("error");
            setMsgDesc("Incomplete Meal Plan");
            setMsg(
              `Please complete full weekly plan for ${data.firstName + " " + data.lastName
              } prior to checkout`
            );
            return;
          }
        }
      }

      // McartDetails?.map((row, index) => {
      for (const row of McartDetails) {
        // console.log("KLKLKLKL", row.members);

        // row.members?.map((d) => {
        for (const d of row.members) {
          var arrItems = row.members?.filter(function (el) {
            return el.expire === 0;
          });
          // console.log("#$#$#$#$#$",d)
          if (d.noofmeals - arrItems.length !== 0) {
            // console.log("GHGHGHGH", d.noofmeals - arrItems.length);
            result = false;
            // console.log("BOBOBOBO", meals - items, result);
            // if (meals - items !== 0 || result === false) {
            //   setlopen(false);
            //   setOpenToast(true);
            //   setColor(COLORS.Orange);
            //   setAlertClass("error");
            //   setMsg("Incomplete Meal Plans");
            //   return;
            // }
            if (result === false && mexpireStatus === true) {
              setlopen2(false);
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsgDesc("");
              setMsg(
                "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
              );
              return;
            } else if (result === false) {
              console.log("hello");
              setlopen2(false);
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsgDesc("Incomplete Meal Plan");
              setMsg(
                `Please complete full weekly plan for ${d.firstName + " " + d.lastName
                } prior to checkout`
              );
              return;
            }
          }
        }
        // if(result = false){
        //   return;
        // }
      }

      console.log("[][][][]][][]", result);

      console.log("status>>>>>>>", expired.length, mexpireStatus);

      if (
        meals - items === 0 &&
        result !== false
        // expired.length === 0 &&
        // mexpireStatus === false
      ) {
        history.push("/checkout");
      }
      // else if(meals - items !== 0 && expired.length !== 0){
      //   setlopen2(false);
      //   setOpenToast(true);
      //   setColor(COLORS.Orange);
      //   setAlertClass("error");
      //   setMsg(
      //     "Cutoff time for ordering some meals has been expired. Please add new meals before checkout."
      //   );
      // }else if(meals - items !== 0){
      //   setlopen2(false);
      //   setOpenToast(true);
      //   setColor(COLORS.Orange);
      //   setAlertClass("error");
      //   setMsg(
      //     `Incomplete Meal Plan for member ${name}`
      //   );
      // }
    }, 0);
  };

  return (
    <>
      <LoadingSpinner open={showContent === 0 ? true : false} />
      <LoadingSpinner open={lopen} />
      <LoadingSpinner open={lopen2} />
      <Addmember open={open4} handleClose={() => setOpen4(false)} />
      <ErrorPopup
        openToast={openToast}
        handleClose={() => setOpenToast(false)}
        msgDesc={msgDesc}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //       setOpenToast(false);
        //     }, 5000)
        //     : false
        // }
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Grid
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 0px",
        }}
      >

        {items !== 0 &&
          <>

          </>

        }
        <Grid md={10} xs={11}>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              padding: "10px 0px",
              fontWeight: "400",
              color: COLORS.Primary,
              textTransform: "uppercase",
              fontFamily: FONTS_ANOTON,
              fontSize: "36pt",
            }}
          >
            My Meal Plans
          </Typography>
          <Typography
            style={{
              fontSize: "14pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
              marginBottom: 30,
            }}
          >
            {listOf.map((d, index) => {
              if (index === 0) {

                var newArr = d.productD.filter(expire => expire.expire === 0)

                var result = _.map(newArr, "deliveryDate");

                var result = result.sort(function (a, b) {
                  if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                  return -1;
                });

                // const minDate = result.reduce((acc, date) => {
                //   return acc && new Date(acc) < new Date(date) ? acc : date;
                // }, "");
                // const maxDate = result.reduce((acc, date) => {
                //   return acc && new Date(acc) > new Date(date) ? acc : date;
                // }, "");

                const minDate = result.reduce((acc, date) => {
                  return acc && acc < date ? acc : date;
                }, "");
                const maxDate = result.reduce((acc, date) => {
                  return acc && acc > date ? acc : date;
                }, "");

                localStorage.setItem('deliveryDate', moment(minDate).format("DD.MM.YYYY"));
                // console.log("GHGHGH", maxDate);

                return (
                  <>
                    {newArr.length > 0 && (
                      <>
                        {/* for the period of
                        <br />
                       
                        {`${moment(minDate).format("DD.MM.YYYY")} - ${moment(
                          maxDate
                        ).format("DD.MM.YYYY")}`} */}

                      </>
                    )
                    }

                  </>
                );
              }
            })}
          </Typography>
          <Typography
            style={{
              display: "flex",
              flexWrap: "wrap",
              visibility: showContent === 1 ? "visible" : "hidden",
            }}
          >
            <Typography
              style={{ display: "flex", flexWrap: "nowrap" }}
              sx={{
                width: { xs: "100%", sm: "fit-content" },
              }}
            ></Typography>
            <Typography
              style={{
                display: "flex",

                background: COLORS.GrayDark3,
                color: COLORS.Primary,
                // width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                flex: 1,
              }}
              sx={{
                margin: {
                  sm: "0px 10px 5px 0px",
                  xs: "5px",
                },
                whiteSpace: {
                  xs: "wrap",
                  sm: "nowrap",
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                padding: { xs: "10px 5px", sm: "5px 10px" },
                width: {
                  xs: "100%",
                  sm: "187px",
                },
                height: { xs: "fit-content", sm: "47px" },
                maxWidth: {
                  xs: "100%",
                  sm: "187px",
                },
                maxHeight: { xs: "fit-content", sm: "47px" },
              }}
            >
              <Typography
                style={{
                  fontSize: "22pt",
                  fontFamily: FONTS_AC,
                  fontWeight: "600",
                }}
                sx={{
                  marginRight: { sm: "10px", xs: "0" },
                }}
              >
                {meals}
              </Typography>
              <Typography
                style={{
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,
                }}
              >
                Assigned Meals
              </Typography>
            </Typography>
            <Typography
              style={{
                display: "flex",

                background: COLORS.GrayDark3,
                color: "white",
                // width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                flex: 1,
              }}
              sx={{
                margin: {
                  sm: "0px 10px 5px 0px",
                  xs: "5px",
                },
                whiteSpace: {
                  xs: "wrap",
                  sm: "nowrap",
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                padding: { xs: "10px 5px", sm: "5px 10px" },
                width: {
                  xs: "100%",
                  sm: "187px",
                },
                height: { xs: "fit-content", sm: "47px" },
                maxWidth: {
                  xs: "100%",
                  sm: "187px",
                },
                maxHeight: { xs: "fit-content", sm: "47px" },
              }}
            >
              <Typography
                style={{
                  fontSize: "22pt",
                  fontFamily: FONTS_AC,
                  fontWeight: "600",
                  marginRight: 10,
                  color: COLORS.Orange4,
                }}
                sx={{
                  marginRight: { sm: "10px", xs: "0" },
                }}
              >
                {items}
              </Typography>
              <Typography
                style={{
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,
                  color: COLORS.Orange3,
                }}
                sx={{
                  padding: {
                    xs: "0px 10px",
                    sm: "0px",
                  },
                }}
              >
                Used Meals
              </Typography>
            </Typography>
            <Typography
              style={{
                display: "flex",

                background: COLORS.GrayDark3,
                color: COLORS.Red2,
                // width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                flex: 1,
              }}
              sx={{
                margin: {
                  sm: "0px 10px 5px 0px",
                  xs: "5px",
                },
                whiteSpace: {
                  xs: "wrap",
                  sm: "nowrap",
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                padding: { xs: "10px 5px", sm: "5px 10px" },
                width: {
                  xs: "100%",
                  sm: "187px",
                },
                height: { xs: "fit-content", sm: "47px" },
                maxWidth: {
                  xs: "100%",
                  sm: "187px",
                },
                maxHeight: { xs: "fit-content", sm: "47px" },
              }}
            >
              <Typography
                style={{
                  fontSize: "22pt",
                  fontFamily: FONTS_AC,
                  fontWeight: "600",
                }}
                sx={{
                  marginRight: { sm: "10px", xs: "0" },
                }}
              >
                {meals - items}
              </Typography>
              <Typography
                style={{
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,
                }}
              >
                Available Meals
              </Typography>
            </Typography>
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                padding: "0px 20px",
                fontWeight: "300",
                color: "#000",
                // textTransform: "capitalize",
                fontFamily: FONTS_INTER,
                fontSize: "14pt",
                marginBottom: 0,
                marginTop: 30,
              }}
            >
              Meal plan for <span style={{ fontWeight: "bold" }}> {name}</span>
            </Typography>
          </Typography>
          {showContent === 2 ? (
            <>
              <Typography
                style={{
                  fontSize: "24pt",
                  fontFamily: FONTS_INTER,
                  color: COLORS.Red,
                  fontWeight: "bold",
                }}
              >
                "Oops ! Your meal plan is empty"
              </Typography>
              <Typography
                style={{
                  fontSize: "16pt",
                  fontFamily: FONTS_INTER,
                  color: COLORS.Primary,
                  fontWeight: "bold",
                }}
              >
                Looks like you haven't added anything to your meal plan yet
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Grid
            xs={12}
            style={{
              visibility: showContent === 1 ? "visible" : "hidden",
            }}
          >
            <TableContainer
              // component={Paper}
              style={{
                // background: "#fff",
                marginBottom: 50,
                background: COLORS.GrayDark3,
              }}
            >
              <Table
                sx={{ minWidth: 800, background: COLORS.GrayDark3s }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "20%",
                        padding: "16px 16px",
                      }}
                      align="left"
                    >
                      Delivery Day 
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "10%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Type
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "18%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Meal
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "10%",
                        padding: "16px 8px ",
                      }}
                      align="left"
                    >
                      Option
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "17%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Delivery Address
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "12%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      City
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "5%",
                      }}
                      align="right"
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "%",
                      }}
                      align="right"
                    ></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cartDetails?.map((item) => {
                    let cartProducts = item[Object.keys(item)[0]];
                    var newArr = [];
                    cartProducts?.map((d) => {
                      if (d.mealType === "Dinner") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Breakfast") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Lunch") {
                        newArr.push(d);
                      }
                    });
                    return (
                      <StyledTableRow
                        style={{
                          background: COLORS.GrayDark3,
                        }}
                      >
                        <StyledTableCell
                          colSpan={8}
                          style={{
                            fontSize: "11pt",
                            fontFamily: FONTS_INTER,

                            padding: "0px 4px 0px 4px",
                          }}
                          component="th"
                          scope="row"
                        >
                          <div
                            style={{
                              background: "white",

                              borderRadius: 8,
                              marginTop: 12,
                              width: "99%",
                              marginLeft: "6px",
                            }}
                          >
                            {newArr?.map((row, index) => (
                              <>
                                <OnTableRow row={row} index={index} />
                              </>
                            ))}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}

                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        fontSize: "11pt",
                        fontFamily: FONTS_INTER,
                        display: "flex",
                      }}
                      align="right"
                    ></StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid>
              {meals - items !== 0 ? <OnTableRow3 type={"user"} /> : <></>}
            </Grid>


            {McartDetails?.map((row, index) => {
              console.log("ASASASASa", row.members);
              const update2 = row.members?.map((d) => {
                return {
                  ...d,
                  deliveryDate: JSON.parse(d.productDetails)[0].deliveryDate,
                };
              });

              var result = groupByKey(update2, "deliveryDate");

              let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));
              // console.log("^^^^^^^^^^^", cItem);
              var cItem2 = cItem.sort(function (a, b) {
                if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                return -1;
              });
              console.log("ZXZXZXZX", cItem2);

              var arrMItems = McartDetails[index]?.members?.filter(function (
                el
              ) {
                return el.expire === 0;
              });

              return (
                <>
                  {
                    // arrMItems.length > 0 &&
                    <>


                      <Typography style={{ display: "flex", flexWrap: "wrap" }}>
                        <Typography
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            padding: "5px 10px",
                            background: COLORS.GrayDark3,
                            color: COLORS.Primary,
                            width: "fit-content",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 187,
                            height: 47,
                            borderRadius: "8px",
                          }}
                          sx={{
                            margin: {
                              sm: "0px 10px 5px 0px",
                              xs: "5px",
                            },
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "22pt",
                              fontFamily: FONTS_AC,
                              fontWeight: "600",
                              marginRight: 10,
                            }}
                          >
                            {McartDetails[index]?.members[0]?.noofmeals}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "10pt",
                              fontFamily: FONTS_INTER,
                            }}
                          >
                            Assigned Meals
                          </Typography>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            padding: "5px 10px",
                            background: COLORS.GrayDark3,
                            color: "white",
                            width: "fit-content",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 187,
                            height: 47,
                            borderRadius: "8px",
                          }}
                          sx={{
                            margin: {
                              sm: "0px 10px 5px 0px",
                              xs: "5px",
                            },
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "22pt",
                              fontFamily: FONTS_AC,
                              fontWeight: "600",
                              marginRight: 10,
                              color: COLORS.Orange4,
                            }}
                          >
                            {arrMItems?.length}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "10pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.Orange3,
                            }}
                          >
                            Used Meals
                          </Typography>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            padding: "5px 10px",
                            background: COLORS.GrayDark3,
                            color: COLORS.Red2,
                            width: "fit-content",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 187,
                            height: 47,
                            borderRadius: "8px",
                          }}
                          sx={{
                            margin: {
                              sm: "0px 10px 5px 0px",
                              xs: "5px",
                            },
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "22pt",
                              fontFamily: FONTS_AC,
                              fontWeight: "600",
                              marginRight: 10,
                            }}
                          >
                            {McartDetails[index]?.members[0]?.noofmeals -
                              arrMItems?.length}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "10pt",
                              fontFamily: FONTS_INTER,
                            }}
                          >
                            Available Meals
                          </Typography>
                        </Typography>
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            padding: "0px 20px",
                            fontWeight: "300",
                            color: "#000",
                            // textTransform: "capitalize",
                            fontFamily: FONTS_INTER,
                            fontSize: "14pt",
                            marginBottom: 0,
                            marginTop: 30,
                          }}
                        >
                          Meal plan for{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {McartDetails[index].members[0].firstName +
                              " " +
                              McartDetails[index].members[0].lastName}
                          </span>
                        </Typography>
                      </Typography>
                      <Grid xs={12}>
                        <TableContainer
                          // component={Paper}
                          style={{
                            // background: "#fff",
                            marginBottom: 50,
                            background: COLORS.GrayDark3,
                          }}
                        >
                          <Table
                            sx={{ minWidth: 800, background: COLORS.GrayDark3 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "20%",
                                    padding: "16px 16px",
                                  }}
                                  align="left"
                                >
                                  Delivery Day 
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "10%",
                                    padding: "16px 8px",
                                  }}
                                  align="left"
                                >
                                  Type
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "18%",
                                    padding: "16px 8px",
                                  }}
                                  align="left"
                                >
                                  Meal
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "10%",
                                    padding: "16px 8px ",
                                  }}
                                  align="left"
                                >
                                  Option
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "17%",
                                    padding: "16px 8px",
                                  }}
                                  align="left"
                                >
                                  Delivery Address
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "12%",
                                    padding: "16px 8px",
                                  }}
                                  align="left"
                                >
                                  City
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "5%",
                                  }}
                                  align="right"
                                ></StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "13pt",
                                    fontFamily: FONTS_INTER,
                                    width: "%",
                                  }}
                                  align="right"
                                ></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody style={{}}>
                              {cItem2?.map((item) => {
                                let cartProducts = item[Object.keys(item)[0]];

                                // console.log("hello>>>", cartProducts.filter(expire => expire.expire === 1))
                                return (
                                  <>
                                    {
                                      // cartProducts.filter(expire => expire.expire === 1).length === 0 &&

                                      <StyledTableRow
                                        style={{
                                          background: COLORS.GrayDark3,
                                        }}
                                      >
                                        <StyledTableCell
                                          colSpan={8}
                                          style={{
                                            fontSize: "11pt",
                                            fontFamily: FONTS_INTER,

                                            padding: "0px 4px 0px 4px",
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          <div
                                            style={{
                                              background: "white",

                                              borderRadius: 8,
                                              marginTop: 12,
                                              width: "99%",
                                              marginLeft: "6px",
                                            }}
                                          >
                                            {cartProducts?.map((row, index) => (

                                              <>
                                                {
                                                  // row.expire === 0 &&
                                                  <OnTableRow row={row} index={index} />
                                                }

                                              </>
                                            ))}
                                          </div>
                                        </StyledTableCell>
                                      </StyledTableRow>

                                    }

                                  </>

                                );
                              })}

                              {/* {mexpired[index]?.map((row, index) => (
                            <OnTableRow2
                              row={row}
                              index={index}
                              type={"member"}
                            />
                          ))} */}

                              {/* {McartDetails[index]?.members[0]?.noofmeals -
                            McartDetails[index]?.members.length !==
                            0 || mexpired[index].length > 0 ? (
                            <OnTableRow3
                              type={"member"}
                              row={row?.members[0]}
                            />
                          ) : (
                            <></>
                          )} */}

                              <StyledTableRow>
                                <StyledTableCell />
                                <StyledTableCell colSpan={5}></StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid>
                          {/* || mexpired[index]?.length > 0 */}
                          {McartDetails[index]?.members[0]?.noofmeals -
                            arrMItems?.length !==
                            0 ? (
                            <OnTableRow3 type={"member"} row={row?.members[0]} />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  }
                </>

              );
            })}

            {/* {McartDetails?.map((row, index) => (
             
            ))} */}

            <Grid xs={12}>
              <TableContainer
                // component={Paper}
                style={{
                  // background: "#fff",
                  marginBottom: 10,
                }}
              >
                <Table
                  sx={{
                    minWidth: {
                      xs: 330,
                      sm: 800,
                    },
                    // background:COLORS.GrayDark3
                  }}
                  aria-label="customized table"
                >
                  <TableBody>
                    <StyledTableRow>
                      {/* <StyledTableCell3
                        align="right"
                        colSpan={4}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                      
                      </StyledTableCell3> */}
                      <StyledTableCell3
                        align="right"
                        colSpan={4}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                        {`( ${name} )`}{" "}

                        {
                          customer?.isStudent === 1 ? "SUB TOTAL ( 10% Discount ) "
                            :
                            "SUB TOTAL"
                        }
                        {/* SUB TOTAL */}
                      </StyledTableCell3>
                      <StyledTableCell3
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                        sx={{
                          width: {
                            sm: "200px",
                            xs: "125px",
                          },
                        }}
                      >
                        {"AUD" + " " + parseFloat(total).toFixed(2)}
                      </StyledTableCell3>
                    </StyledTableRow>

                    {Tmembers?.map((row, index) => {
                      if (row?.packageId !== null) {
                        return (
                          <StyledTableRow>
                            <StyledTableCell3
                              align="right"
                              colSpan={4}
                              style={{
                                border: `4px solid ${COLORS.InputBorderColor}`,
                                //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                                borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                                borderRight: `6px solid ${COLORS.InputBorderColor}`,
                              }}
                            >
                              {`( ${row?.firstName + " " + row?.lastName} )`}{" "}
                              {
                                row?.isStudent === 1 ? "SUB TOTAL ( 10% Discount ) "
                                  :
                                  "SUB TOTAL"
                              }
                              {/* SUB TOTAL */}
                            </StyledTableCell3>
                            <StyledTableCell3
                              align="right"
                              style={{
                                border: `4px solid ${COLORS.InputBorderColor}`,
                                // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                                borderRight: `8px solid ${COLORS.InputBorderColor}`,
                                borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                              }}
                              sx={{
                                width: {
                                  sm: "200px",
                                  xs: "125px",
                                },
                              }}
                            >
                              {
                                row?.isStudent === 1 ?
                                  "AUD" + " " + parseFloat(row?.price * 90 / 100).toFixed(2)

                                  :
                                  "AUD" + " " + parseFloat(row?.price).toFixed(2)

                              }
                              {/* {"AUD" + " " + parseFloat(row?.price).toFixed(2)} */}
                            </StyledTableCell3>
                          </StyledTableRow>
                        );
                      }
                    })}

                    {/* {McartDetails?.map((row, index) => (
                      <StyledTableRow>
                        <StyledTableCell3
                          align="right"
                          colSpan={4}
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                        >
                          {`( ${row.members[0]?.firstName +
                            " " +
                            row.members[0]?.lastName
                            } )`}{" "}
                          SUB TOTAL
                        </StyledTableCell3>
                        <StyledTableCell3
                          align="right"
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                          sx={{
                            width: {
                              sm: "200px",
                              xs: "125px",
                            },
                          }}
                        >
                          {"LKR" +
                            " " +
                            parseFloat(row.members[0]?.price).toFixed(2)}
                        </StyledTableCell3>
                      </StyledTableRow>
                    ))} */}
                    <StyledTableRow>
                      <StyledTableCell3
                        align="right"
                        colSpan={4}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                        TOTAL
                      </StyledTableCell3>
                      <StyledTableCell3
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                        sx={{
                          width: {
                            sm: "200px",
                            xs: "125px",
                          },
                        }}
                      >
                        {"AUD" + " " + parseFloat(mTot + total).toFixed(2)}
                      </StyledTableCell3>
                    </StyledTableRow>


                    {

                      delType === "delivery" &&

                      <StyledTableRow>
                        <StyledTableCell3
                          align="right"
                          colSpan={4}
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                        >
                          DELIVERY
                        </StyledTableCell3>
                        <StyledTableCell3
                          align="right"
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                          sx={{
                            width: {
                              sm: "200px",
                              xs: "125px",
                            },
                          }}
                        >

                          {"AUD" +
                            " " +
                            parseFloat(calDelivery()).toFixed(2)}
                        </StyledTableCell3>
                      </StyledTableRow>


                    }





                    <StyledTableRow>
                      <StyledTableCell3
                        align="right"
                        colSpan={4}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                        GST 10%
                      </StyledTableCell3>
                      <StyledTableCell3
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                        sx={{
                          width: {
                            sm: "200px",
                            xs: "125px",
                          },
                        }}
                      >

                        {"AUD" +
                          " " +
                          parseFloat(((mTot + total + calDelivery()) * 10) / 100).toFixed(2)}
                      </StyledTableCell3>
                    </StyledTableRow>



                    <StyledTableRow>
                      <StyledTableCell2
                        align="right"
                        colSpan={4}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderBottom: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                          color: COLORS.TitleColor7,
                        }}
                      >
                        FINAL TOTAL
                      </StyledTableCell2>

                      <StyledTableCell2
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderBottom: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                          color: COLORS.TitleColor7,
                        }}
                        sx={{
                          width: {
                            sm: "200px",
                            xs: "125px",
                          },
                        }}
                      >





                        {"AUD" +
                          " " +
                          parseFloat((((mTot + total + calDelivery()) * 110) / 100)).toFixed(2)}
                      </StyledTableCell2>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {customer.packageId !== null && customer.packageId !== undefined ? (
              <>
                <Grid item xs={12}>
                  {/* <Grid xs={12}
                    style={{
                      // marginBottom: "1vh",
                      display: "flex",
                      marginTop: "3vh",
                      alignItems: "center",

                    }}
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <Grid lg={5.7} md={7.2} xs={12}>
                      <Typography
                        style={{
                          fontSize: "13pt",
                          fontFamily: FONTS_INTER,
                          fontWeight: "600",
                          color: COLORS.Primary,
                          textAlign: "left",
                        }}
                      >
                        How would you like to collect the order ?
                      </Typography>
                    </Grid>
                    <Grid
                      lg={6.3}
                      md={4.8}
                      xs={12}
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "0px",
                        },
                        width: {
                          xs: "100%",
                          sm: "fit-content",
                        },
                      }}
                    >
                      <Typography
                        style={{ display: "flex" }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                          },
                        }}
                      >
                        <Typography
                          style={{
                            // marginBottom: "1vh",
                            marginTop: "1.5vh",
                            alignItems: "center",
                            marginLeft: "0.7vW"
                          }}
                          sx={{
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                            },
                          }}
                        >
                          <Grid
                            lg={5}
                            xs={12}
                            sx={{
                              paddingRight: { md: "18px" },
                              margin: {
                                xs: "15px 0px",
                              },
                              width: {
                                xs: "100%",
                                sm: "fit-content",
                              },
                              marginRight: "10px",
                            }}
                          >
                           

                            <div id="selectElemement">
                              <ThemeProvider theme={theme2}>
                                <Select

                                  classes={classes2}

                                  // disabled={idisable}
                                  // onClick={() => {
                                  //   const cartCount =
                                  //     window.document.querySelector(".MuiList-root");

                                  //   if (cartCount) {
                                  //     let dow = cartCount?.parentElement;
                                  //     if (dow) {
                                  //       dow.style.left = "105px";
                                  //     }
                                  //   }
                                  // }}
                                  // classes={classes}
                                  fullWidth
                                  placeholder="select delivery type"
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  // multiple


                                  value={delType}
                                  // onChange={handleChange}
                                  // input={<OutlinedInput label="Name" />}
                                  // MenuProps={MenuProps}
                                  style={{
                                    // flexDirection: "column",
                                    // display: "flex",
                                    // alignItems: "flex-start",
                                    background: COLORS.GrayDark3,
                                    // // padding: "5px 10px",
                                    borderRadius: "5px",
                                    borderColor: "transparent",
                                    width: "395px",
                                    textAlign: "left",
                                    height: "40px"
                                  }}
                                  onChange={(event, newValue) => {
                                    console.log("------------>", newValue.props.value);

                                    setdelType(newValue.props.value)

                                    // localStorage.setItem("delType", newValue.props.value)


                                  }}
                                // sx={{
                                //   "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                                //     // height: 10,
                                //     border: "none",
                                //   },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                //     {
                                //       width: "100%",
                                //       // padding: "5px 12px ",
                                //       textAlign: "left",
                                //       display: "flex",
                                //       flexDirection: "column",
                                //       borderColor: "white",
                                //     },
                                //   "& .MuiList-root .MuiMenu-list ": {
                                //     flexDirection: "column",
                                //     display: "flex",
                                //     alignItems: "flex-start",
                                //     borderColor: "white",
                                //   },
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //     {
                                //       borderColor: "white",
                                //       border: "none",
                                //     },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //     {
                                //       borderColor: "white",
                                //       border: "none",
                                //     },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                //     {
                                //       padding: "1px 12px",
                                //       borderColor: "white",
                                //     },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                                //     {
                                //       display: "flex",
                                //     },
                                //   "& .MuiPaper-root": {
                                //     minWidth: "300px",
                                //     left: "100px",
                                //   },
                                // }}
                                >
                                  <MenuItem
                                    key={0}
                                    value={""}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>Please Select Collect Option
                                      </label>
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    key={0}
                                    value={"pick up"}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>In-store pick up
                                      </label>
                                    </div>
                                  </MenuItem>

                                  <MenuItem
                                    key={0}
                                    value={"delivery"}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>Delivery ($5 will be charged for each delivery)
                                      </label>
                                    </div>
                                  </MenuItem>

                                </Select>
                              </ThemeProvider>
                            </div>



                          </Grid>

                        </Typography>
                      </Typography>
                    </Grid>

                  </Grid> */}
                  <Grid xs={12}
                    style={{
                      marginBottom: "1vh",
                      display: "flex",
                      // marginTop: "3vh",
                      alignItems: "center",
                      marginTop: "-1.5vw",

                    }}
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <Grid lg={5.7} md={7.2} xs={12}>
                      <Typography
                        style={{
                          fontSize: "13pt",
                          fontFamily: FONTS_INTER,
                          fontWeight: "600",
                          color: COLORS.Primary,
                          textAlign: "left",
                        }}
                      >
                        {/* How would you like to collect the order ? */}
                      </Typography>
                    </Grid>
                    <Grid
                      lg={6.3}
                      md={4.8}
                      xs={12}
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "0px",
                        },
                        width: {
                          xs: "100%",
                          sm: "fit-content",
                        },
                      }}
                    >
                      <Typography
                        style={{ display: "flex" }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                          },
                        }}
                      >
                        <Typography
                          style={{
                            marginBottom: "1vh",
                            // marginTop: "1.5vh",
                            alignItems: "center",
                            marginLeft: "0.7vW"
                          }}
                          sx={{
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                            },
                          }}
                        >
                          <Grid
                            lg={12}
                            xs={12}
                            sx={{
                              paddingRight: { md: "18px" },
                              margin: {
                                xs: "15px 0px",
                              },
                              width: {
                                xs: "100%",
                                sm: "fit-content",
                              },
                              marginRight: "10px",
                            }}
                          >
                            {/* <div
                        style={{
                          marginLeft: 0,
                          height: "40px",
                          borderRadius: 6,
                          background: COLORS.GrayDark3,
                          width: matches.matches === true ? "16.6vw" : "265px",
                          padding:
                            matches.matches === true ? "0px 0.2vw" : "0px 5px",
                        }}
                      >
                        <Autocomplete
                          options={members}
                          classes={classes}
                          // value={memberDropDown}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          onChange={(event, newValue) => {
                            console.log(
                              "on Chnage --->", newValue
                            )
                            setcurrMember(newValue);
                            handleChangeMember(event, newValue);
                            // setMemberDropDown(newValue)
                          }}
                          renderOption={(option) => {

                            return (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "12pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "",
                                    color: COLORS.TextColor,
                                    fontFamily: FONTS_INTER,
                                    width:
                                      matches.matches === true
                                        ? "14.9vw"
                                        : "233px",
                                  }}
                                >
                                  {option.firstName === "Add Member" ? <div
                                    onClick={() => setOpen4(true)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContetn: "flex-start"
                                    }}>
                                    <AddCircleIcon style={{
                                      fontSize: 15,
                                      color: COLORS.Primary
                                    }}
                                    />
                                    <div>&nbsp; Add Member</div></div> : <>{`${option.firstName} ${option.lastName}`
                                    }</>}

                                </p>
                              </React.Fragment>
                            );
                          }}
                          ListboxProps={{
                            style: {
                              fontSize: "12pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              // width: matches.matches === true ?"16.6vw"  : "200px",
                              background: "white",
                              // width:"100%"
                            },
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div> */}

                            {/* <div id="selectElemement">
                              <ThemeProvider theme={theme2}>
                                <Select

                                  classes={classes2}

                                  // disabled={idisable}
                                  // onClick={() => {
                                  //   const cartCount =
                                  //     window.document.querySelector(".MuiList-root");

                                  //   if (cartCount) {
                                  //     let dow = cartCount?.parentElement;
                                  //     if (dow) {
                                  //       dow.style.left = "105px";
                                  //     }
                                  //   }
                                  // }}
                                  // classes={classes}
                                  fullWidth
                                  placeholder="select delivery type"
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  // multiple


                                  value={delType}
                                  // onChange={handleChange}
                                  // input={<OutlinedInput label="Name" />}
                                  // MenuProps={MenuProps}
                                  style={{
                                    // flexDirection: "column",
                                    // display: "flex",
                                    // alignItems: "flex-start",
                                    background: COLORS.GrayDark3,
                                    // // padding: "5px 10px",
                                    borderRadius: "5px",
                                    borderColor: "transparent",
                                    width: "395px",
                                    textAlign: "left",
                                    height: "40px"
                                  }}
                                  onChange={(event, newValue) => {
                                    console.log("------------>", newValue.props.value);

                                    setdelType(newValue.props.value)

                                    // localStorage.setItem("delType", newValue.props.value)


                                  }}
                                // sx={{
                                //   "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                                //     // height: 10,
                                //     border: "none",
                                //   },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                //     {
                                //       width: "100%",
                                //       // padding: "5px 12px ",
                                //       textAlign: "left",
                                //       display: "flex",
                                //       flexDirection: "column",
                                //       borderColor: "white",
                                //     },
                                //   "& .MuiList-root .MuiMenu-list ": {
                                //     flexDirection: "column",
                                //     display: "flex",
                                //     alignItems: "flex-start",
                                //     borderColor: "white",
                                //   },
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //     {
                                //       borderColor: "white",
                                //       border: "none",
                                //     },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //     {
                                //       borderColor: "white",
                                //       border: "none",
                                //     },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                //     {
                                //       padding: "1px 12px",
                                //       borderColor: "white",
                                //     },
                                //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                                //     {
                                //       display: "flex",
                                //     },
                                //   "& .MuiPaper-root": {
                                //     minWidth: "300px",
                                //     left: "100px",
                                //   },
                                // }}
                                >
                                  <MenuItem
                                    key={0}
                                    value={""}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>Please Select Collect Option
                                      </label>
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    key={0}
                                    value={"pick up"}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>In-store pick up
                                      </label>
                                    </div>
                                  </MenuItem>

                                  <MenuItem
                                    key={0}
                                    value={"delivery"}
                                    // onClick={() => setOpen4(true)}
                                    style={{
                                      padding: "6px 16px ",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label>Delivery ($5 will be charged for each delivery)
                                      </label>
                                    </div>
                                  </MenuItem>

                                </Select>
                              </ThemeProvider>
                            </div> */}
                            {/* {delType === "delivery" && (
                              <Typography
                                variant="caption"
                                // color="red"
                                style={{
                                  textAlign: "left",
                                  fontFamily: FONTS_INTER,
                                  // margin: "5px 0px",
                                  fontSize: "8pt",
                                  marginLeft: "0vW",


                                }}
                              >
                                Delivery is available only for selected suburbs. Please check "Pick up & Delivery" page for more details.
                              </Typography>
                            )} */}


                          </Grid>

                        </Typography>
                      </Typography>
                    </Grid>

                  </Grid>
                </Grid>


                <Typography
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "3vh",
                    alignItems: "center",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid lg={5.7} md={7.2} xs={12}>
                    <Typography
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "600",
                        color: COLORS.Primary,
                        textAlign: "left",
                      }}
                    >
                      Do you want to create meal plans for your family members ?
                    </Typography>
                  </Grid>
                  <Grid
                    lg={6.3}
                    md={4.8}
                    xs={12}
                    sx={{
                      marginTop: {
                        xs: "10px",
                        sm: "0px",
                      },
                      width: {
                        xs: "100%",
                        sm: "fit-content",
                      },
                    }}
                  >
                    <Typography
                      style={{ display: "flex" }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          flex: 1,
                        }}
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                          },
                          alignItems: {
                            sm: "center",
                            xs: "flex-start",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "Yes"}
                            onChange={handleChange}
                            value="Yes"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            style={{
                              color: COLORS.Primary,
                              padding: "9px",
                            }}
                          // onClick={()=>{
                          //   const di =window.document.querySelector("#selectElemement")
                          //   console.log("--->",di)


                          //   if(di){
                          //     const dd = window.document.querySelector("fieldset")
                          //     dd.style.border="none"
                          //     const du = window.document.querySelector("#selectElemement").querySelector("input")
                          //     console.log("--->",du)
                          //     const dut = du.parentElement;
                          //     dut.style.padding="6px 10px"


                          //   }
                          // }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            Yes
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "No"}
                            onChange={handleChange}
                            value="No"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "B" }}
                            style={{
                              color: COLORS.Primary,
                              padding: "9px",
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            No
                          </Typography>
                        </div>
                      </Typography>
                      <Typography
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                          },
                        }}
                      >

                        <Button
                          size="large"
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            // marginTop: 10,
                            padding: "6px 25px",
                            background: COLORS.Primary,
                            borderRadius: 4,
                            cursor: "pointer",
                            color: "white",
                          }}
                          variant="contained"
                          color="success"
                          onClick={(e) => Checkout()}
                        >
                          CHECKOUT
                        </Button>

                      </Typography>
                    </Typography>
                  </Grid>
                </Typography>

                <Typography
                  style={{
                    marginBottom: "1vh",
                    display: idisable === false ? "flex" : "none",
                    marginTop: "1.5vh",
                    alignItems: "center",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid
                    lg={5}
                    xs={12}
                    sx={{
                      paddingRight: { md: "18px" },
                      margin: {
                        xs: "15px 0px",
                      },
                      width: {
                        xs: "100%",
                        sm: "fit-content",
                      },
                      marginRight: "10px",
                    }}
                  >
                    {/* <div
                        style={{
                          marginLeft: 0,
                          height: "40px",
                          borderRadius: 6,
                          background: COLORS.GrayDark3,
                          width: matches.matches === true ? "16.6vw" : "265px",
                          padding:
                            matches.matches === true ? "0px 0.2vw" : "0px 5px",
                        }}
                      >
                        <Autocomplete
                          options={members}
                          classes={classes}
                          // value={memberDropDown}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          onChange={(event, newValue) => {
                            console.log(
                              "on Chnage --->", newValue
                            )
                            setcurrMember(newValue);
                            handleChangeMember(event, newValue);
                            // setMemberDropDown(newValue)
                          }}
                          renderOption={(option) => {

                            return (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "12pt",
                                    padding: "0",
                                    margin: "0",
                                    width: "",
                                    color: COLORS.TextColor,
                                    fontFamily: FONTS_INTER,
                                    width:
                                      matches.matches === true
                                        ? "14.9vw"
                                        : "233px",
                                  }}
                                >
                                  {option.firstName === "Add Member" ? <div
                                    onClick={() => setOpen4(true)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContetn: "flex-start"
                                    }}>
                                    <AddCircleIcon style={{
                                      fontSize: 15,
                                      color: COLORS.Primary
                                    }}
                                    />
                                    <div>&nbsp; Add Member</div></div> : <>{`${option.firstName} ${option.lastName}`
                                    }</>}

                                </p>
                              </React.Fragment>
                            );
                          }}
                          ListboxProps={{
                            style: {
                              fontSize: "12pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              // width: matches.matches === true ?"16.6vw"  : "200px",
                              background: "white",
                              // width:"100%"
                            },
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div> */}
                    <div id="selectElemement">
                      <ThemeProvider theme={theme2}>
                        <Select

                          classes={classes2}

                          disabled={idisable}
                          // onClick={() => {
                          //   const cartCount =
                          //     window.document.querySelector(".MuiList-root");

                          //   if (cartCount) {
                          //     let dow = cartCount?.parentElement;
                          //     if (dow) {
                          //       dow.style.left = "105px";
                          //     }
                          //   }
                          // }}
                          // classes={classes}
                          fullWidth
                          placeholder="select your family member"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          // multiple
                          value={currMember}
                          // onChange={handleChange}
                          // input={<OutlinedInput label="Name" />}
                          // MenuProps={MenuProps}
                          style={{
                            // flexDirection: "column",
                            // display: "flex",
                            // alignItems: "flex-start",
                            background: COLORS.GrayDark3,
                            // // padding: "5px 10px",
                            borderRadius: "5px",
                            // borderColor: "transparent",
                            border: "1px white",
                            width: "335px",
                            textAlign: "left",
                            height: "40px"
                          }}
                          onChange={(event, newValue) => {
                            console.log("------------>", newValue.props.value);
                            setcurrMember(newValue.props.value);

                            handleChangeMember(event, newValue.props.value);
                          }}
                        // sx={{
                        //   "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                        //     // height: 10,
                        //     border: "none",
                        //   },
                        //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        //     {
                        //       width: "100%",
                        //       // padding: "5px 12px ",
                        //       textAlign: "left",
                        //       display: "flex",
                        //       flexDirection: "column",
                        //       borderColor: "white",
                        //     },
                        //   "& .MuiList-root .MuiMenu-list ": {
                        //     flexDirection: "column",
                        //     display: "flex",
                        //     alignItems: "flex-start",
                        //     borderColor: "white",
                        //   },
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //     {
                        //       borderColor: "white",
                        //       border: "none",
                        //     },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //     {
                        //       borderColor: "white",
                        //       border: "none",
                        //     },
                        //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        //     {
                        //       padding: "1px 12px",
                        //       borderColor: "white",
                        //     },
                        //   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                        //     {
                        //       display: "flex",
                        //     },
                        //   "& .MuiPaper-root": {
                        //     minWidth: "300px",
                        //     left: "100px",
                        //   },
                        // }}
                        >
                          <MenuItem
                            key={0}
                            value={""}
                            // onClick={() => setOpen4(true)}
                            style={{
                              padding: "6px 16px ",
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <label>Select Member
                              </label>
                            </div>
                          </MenuItem>
                          <MenuItem
                            key={0}
                            value={{ id: null }}
                            onClick={() => setOpen4(true)}
                            style={{
                              padding: "6px 16px ",
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AddCircleIcon
                                style={{ fontSize: 15, color: COLORS.Primary }}
                                onClick={() => setOpen4(true)}
                              />
                              <label>&nbsp; Add Member</label>
                            </div>
                          </MenuItem>
                          {members.map((name) => (
                            <MenuItem
                              key={name.id}
                              value={name}
                              style={{
                                padding: "6px 16px ",
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                              sx={{
                                padding: "0px",
                              }}

                            // style={getStyles(name, personName, theme)}
                            >
                              <div style={{ padding: "0px 16px" }}>
                                {name.firstName + " " + name.lastName}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </ThemeProvider>
                    </div>


                  </Grid>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {/* <Button
                  size="small"
                  style={{
                    fontSize: "12pt",
                    fontFamily: FONTS_INTER,
                    background: COLORS.TextColor,
                    color: COLORS.Orange,
                  }}
                  variant="contained"
                  color="warning"
                  sx={{
                    // marginTop: { xs: "10px" },
                    padding: "6px 14px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setOpen2(true);
                    setplanType("user");
                  }}
                >
                  SELECT PLAN
                </Button> */}
                      {/* <Link
                  to="/add-member"
                  style={{
                    textDecorationLine: "none",
                  }}
                > */}
                      <Button
                        disabled={idisable}
                        size="small"
                        style={{
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          background: COLORS.Orange,
                          // color: COLORS.yellow,
                          color: "white",
                          borderRadius: 4,
                          padding: "3px 25px",
                        }}
                        variant="contained"
                        sx={{
                          padding: "4px 25px",
                          marginRight: {
                            xs: "0px",
                            sm: "10px",
                          },
                          width: {
                            xs: "100%",
                            // sm: "fit-content",
                            sm: "25vW",
                          },
                          marginTop: {
                            xs: "10px",
                            sm: "0px",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (memberId !== null) {
                            if (currMember.packageId !== null) {
                              setTimeout(() => {
                                history.push(
                                  `/plans?memberId=${currMember.id}`
                                );
                              }, 0);
                            } else {
                              setOpen(true);
                              setplanType("member");
                            }
                          } else {
                            setOpenToast(true);
                            setColor(COLORS.Orange);
                            setAlertClass("error");
                            setMsgDesc("");
                            setMsg("Select a member first.");
                          }
                        }}
                      >
                        {currMember === "" || currMember === null
                          ? "SELECT A PACKAGE FOR THE MEMBER AND CREATE MEAL PLANS"
                          : currMember.packageId !== null &&
                            currMember.packageId !== undefined
                            ? "CREATE MEAL PLAN"
                            : "SELECT A PACKAGE FOR THE MEMBER AND CREATE MEAL PLANS"}
                        {/* SELECT PACKAGE & CREATE MEAL PLAN */}
                      </Button>
                    </div>
                  </div>
                </Typography>

                {/* <Typography
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "3vh",
                    alignItems: "flex-start",
                  }}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                  }}
                >
                  <Grid lg={5.7} md={7.2} xs={12}>

                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "flex-start"
                      }}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                        },
                      }}
                    >
                     
                      <Button
                        size="large"
                        style={{
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          // marginTop: 10,
                          padding: "6px 25px",
                          background: COLORS.Primary,
                          borderRadius: 4,
                          cursor: "pointer",
                          color: "white",
                        }}
                        variant="contained"
                        color="success"
                        onClick={(e) => Checkout()}
                      >
                        CHECKOUT
                      </Button>
                     
                    </Typography>

                  </Grid>
                  <Grid
                    lg={6.3}
                    md={4.8}
                    xs={12}
                    sx={{
                      marginTop: {
                        xs: "10px",
                        sm: "0px",
                      },
                      width: {
                        xs: "100%",
                        sm: "fit-content",
                      },
                    }}
                  >

                  </Grid>
                </Typography> */}

                <Typography
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <Link
                    to="/checkout"
                    style={{
                      textDecoration: "none",
                      color: "white",

                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <Button
                      size="large"
                      style={{
                        fontSize: "12pt",
                        fontFamily: FONTS_INTER,
                        // marginTop: 10,
                        padding: "6px 14px",
                        background: COLORS.Primary,
                        width: "100%",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="success"
                      onClick={() => setOpen3(true)}
                    >
                      CHECKOUT
                    </Button>
                  </Link>
                </Typography>


              </>
            ) : (
              <></>
            )}

            {/* <div style={{ float: "right", marginBottom: "15vh" }}>
              <Button
                size="large"
                style={{
                  fontSize: "12pt",
                  fontFamily: FONTS_INTER,
                  marginTop: 10,
                  // marginRight: 10,
                  background: COLORS.Primary,
                  padding: "3px 35px",
                }}
                variant="contained"
                // color="warning"
                onClick={() => {
                  // setOpen2(true);
                  // setplanType("user");
                }}
              >
                Checkout
              </Button>
            </div> */}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center",
                  }}
                >
                  Please Upgrade Your Package
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    You have reached the maximum number of meals of your current
                    package. If you want to add more meals please upgrade your
                    package.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 10,
                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    For any clarifications please contact our Order Processing
                    Unit between 9 am and 5 pm through (03) 8794 8694
                  </Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 5, mb: 2 }}
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    UPGRADE NOW
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon
                  sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                  }}
                >
                  Order Successful
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10px",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    Thank you for completing your weekly meal plan with us. Your
                    meal plan will be delivered between 5pm and 8.30pm every
                    day. Please check your order summary for more details.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Breakfast 6am - 9pm <br />
                      Lunch 11 a.m - 1.30
                      <br />
                      Dinner 5.30 p.m - 8.30 p.m
                    </p>
                  </Typography>

                  <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    <p>
                      (Delivery times can be vary depending on the road traffic
                      conditions.)
                    </p>
                  </Typography>
                  {/* 
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                    Thank you for completing your weekly meal plan with us. Your meal plan will be delivered between 5pm and 8.30pm every day. Please check your order summary for more details.
                    </p>
                  </Typography> */}
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90vw",
              md: "95vw",
              lg: "95vw",
              sm: "90vw",
            },
            maxHeight: "95vh",
            overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            sx={{
              // width: {
              //   xs: "95vh",
              //   md: "50px",
              // },
              padding: "30px",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={12}
                sm={11}
                container
                spacing={2}
                sx={{
                  paddingLeft: {
                    xs: 0,
                    sm: "-16px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "none",
                    },
                    justifyContent: "flex-end",
                  }}
                >
                  <div onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: matches.matches ? "22pt" : "26pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                      }}
                      sx={{
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                  }}
                  sx={{
                    display: {
                      xs: "none",
                      lg: "flex",
                    },
                  }}
                >
                  {packages?.map((data) => {
                    return <PROUDCT_MEALS_CARD data={data} />;
                  })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                  }}
                  sx={{
                    display: {
                      sm: "none",
                      xs: "flex",
                    },
                  }}
                >
                  {packages
                    ?.filter((_, index) => index === productMealsIndex)
                    ?.map((data) => {
                      return <PROUDCT_MEALS_CARD data={data} />;
                    })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    // display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                  sx={{
                    display: {
                      lg: "none",
                      xs: "flex",
                    },
                  }}
                >
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      opacity: productMealsIndex > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      // window.document.querySelector("#areas").scrollLeft -= 100;
                      if (productMealsIndex > 0) {
                        setProductMealsIndex(productMealsIndex - 1);
                      }
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      opacity:
                        productMealsIndex < packages?.length - 1 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (productMealsIndex < packages?.length - 1) {
                        setProductMealsIndex(productMealsIndex + 1);
                      }
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default MealsTable;
