import {
  Backdrop,
  CircularProgress,
  createTheme,
  Divider,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import Checkbox from "@mui/material/Checkbox";
import { useHistory, Link } from "react-router-dom";
import { css } from "@emotion/react";
import Paper from "@mui/material/Paper";

import { COLORS } from "../../Constants/Colors";
import { LoginQuery } from "../../Services/API/Post";
import AuthContext from "../../Context/AuthContext";
import { FONTS_INTER } from "../../Constants";
import "./login.css";
import { CurrentUser } from "../../Services/API/Get";
import { IMAGE_SERVER_URL_DEV2 } from "../../Constants/System";

function ProfilePopup({ open, handleClose }) {
  const { setUser, user } = useState(AuthContext);
  const [open2, setOpen2] = useState(false);

  const history = useHistory();

  const [userD, setUserD] = React.useState("");

  React.useEffect(() => {
    // getCurrentUser();
    console.log("--------->", user);
    const response = window.localStorage.getItem(
      `${window.location.hostname}.userDetails`
    );
    setUserD(JSON.parse(response));
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper elevation={24}>
        <div
          className="cart_dropDown1"
          style={{
            background: "white",
            minWidth: 237,
            height: 218,
            position: "absolute",
            right: "10%",
            // top: 255,
            borderRadius: 4,
            boxShadow: "5px 10px 18px #888888",
            // display: "flex",
            // alignItems: "center",
            // flexDirection: "columnI",
            // alignItems: "flex-start",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "15px 25px",
              marginTop: "14px",
              marginBottom: "5px",
            }}
          >
            {userD === null ? (
              <Skeleton variant="circular" width={33} height={33} />
            ) : (
              <>
                {userD?.image ? (
                  <img
                    src={`${IMAGE_SERVER_URL_DEV2}${userD?.image}`}
                    style={{
                      marginRight: 6,
                      cursor: "pointer",
                      background: COLORS.Primary,
                      borderRadius: "50%",
                      padding: 1,
                      fontSize: 28,
                      color: "white",
                      width: "33px",
                      height: "33px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon
                    style={{
                      marginRight: 6,
                      cursor: "pointer",
                      background: COLORS.Primary,
                      borderRadius: "50%",
                      padding: 6,
                      fontSize: 28,
                      color: "white",
                      width: "28px",
                      height: "28px",
                    }}
                  />
                )}
              </>
            )}
            <div>
              <Typography
                style={{
                  fontSize: "12pt",
                  color: COLORS.Primary,
                  fontFamily: FONTS_INTER,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {userD?.firstName}
              </Typography>
              <Typography
                style={{
                  fontSize: "9pt",
                  color: COLORS.TextColor,
                  fontFamily: FONTS_INTER,
                  textAlign: "left",
                }}
              >
                {userD?.email}
              </Typography>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Divider
              style={{
                width: "72%",
                borderBottomWidth: "1px",
                borderColor: COLORS.Primary,
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                padding: "25px 10px",
              }}
            >
              <Link
                to="/Profile"
                onClick={handleClose}
                style={{
                  display: "flex",
                  cursor: "pointer",
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,
                  width: "70%",
                  marginBottom: "8px",
                  color: COLORS.TitleColor5,
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                My Profile
              </Link>
              <Link
                to="/OrderSummary"
                onClick={handleClose}
                style={{
                  display: "flex",
                  cursor: "pointer",
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,

                  width: "70%",
                  marginBottom: "7px",
                  textDecoration: "none",
                  color: COLORS.TitleColor5,
                  fontWeight: "bold",
                }}
              >
                Last Order Summary
              </Link>
              <Typography
                style={{
                  display: "flex",
                  cursor: "pointer",
                  fontSize: "10pt",
                  fontFamily: FONTS_INTER,
                  color: COLORS.Primary,
                  width: "70%",
                  marginBottom: "3px",

                  fontWeight: "bold",
                }}
                onClick={() => {
                  setOpen2(true);
                  window.localStorage.removeItem(
                    `${window.location.hostname}.login`
                  );
                  window.localStorage.removeItem("exprTime");
                  localStorage.clear();

                  history.push("/home");
                  window.location.reload();
                }}
              >
                Log out
              </Typography>
            </div>
          </div>
        </div>
      </Paper>

    </>
  );
}

export default ProfilePopup;
