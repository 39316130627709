import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { withStyles } from "@material-ui/core/styles";
import "./privacy.css";
import { COLORS } from "../../Constants/Colors";
import { FONTS_INTER } from "../../Constants";
const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainHeading: {
    fontSize: "36px",
    fontWeight: "bolder",
    lineHeight: 1.5,

    fontFamily: "Inter,sans-serif",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16pt",
    },
  },

  subHeading: {
    fontSize: "24px",
    fontWeight: 400,
    color: "#6b7177",
    lineHeight: 1.5,
    fontFamily: "Inter,sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    ["@media (max-width:320px)"]: {
      fontSize: "10px",
    },
  },
  subHeading2: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#6b7177",

    fontFamily: "Inter,sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
    ["@media (max-width:320px)"]: {
      fontSize: "9px",
    },
  },
  subHeading3: {
    fontSize: "13pt",
    fontWeight: 400,
    color: "#6b7177",
    fontFamily: FONTS_INTER,
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    ["@media (max-width:320px)"]: {
      fontSize: "10px",
    },
  },
  heading: {
    fontSize: "1em",
    fontWeight: 450,
    padding: "0.5em 0.6em 0.8em",
    color: "#42474c",
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: "10px",
    // },
    // ["@media (max-width:320px)"]: {
    //   fontSize: "9px",
    // },
  },

  container: {
    backgroundColor: "white",
    width: "100vw",
    height: "25vw",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    padding: 50,
  },
  home_gridRow: {
    border: "1px solid rgba(0,0,0,0.0)",
  },
  ZkoobieLogo: {
    alignContent: "center",
    height: "5vw",
    boxShadow: "none",
    marginRight: "0",
    margin: "3vw 0vw 0vw -10vw",
    [theme.breakpoints.down("xs")]: {
      height: "10vw",
      margin: "5vw 0 5vw -10vw",
    },
  },
});

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.onChangeBtn1 = this.onChangeBtn1.bind(this);
    this.onChangeBtn2 = this.onChangeBtn2.bind(this);
    this.onChangeBtn3 = this.onChangeBtn3.bind(this);
    this.onChangeBtn4 = this.onChangeBtn4.bind(this);
    this.onChangeBtn5 = this.onChangeBtn5.bind(this);
    this.onChangeBtn6 = this.onChangeBtn6.bind(this);
    this.onChangeBtn7 = this.onChangeBtn7.bind(this);
    this.onChangeBtn8 = this.onChangeBtn8.bind(this);
    this.onChangeBtn9 = this.onChangeBtn9.bind(this);
    this.onChangeBtn10 = this.onChangeBtn10.bind(this);
    this.state = {
      tab: "1",
      btnWeight: 600,
      borderLeft: `3px solid ${COLORS.Primary}`,
    };
  }
  onChangeBtn1(e) {
    this.setState({
      btnWeight: 600,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "1",
      borderLeft: `3px solid ${COLORS.Primary}`,
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn2(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 600,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "2",
      borderLeft: "",
      borderLeft2: `3px solid ${COLORS.Primary}`,
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn3(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 600,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "3",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: `3px solid ${COLORS.Primary}`,
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn4(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 600,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "4",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: `3px solid ${COLORS.Primary}`,
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn5(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 600,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "5",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: `3px solid ${COLORS.Primary}`,
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn6(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 600,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "6",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: `3px solid ${COLORS.Primary}`,
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn7(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 600,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "7",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: `3px solid ${COLORS.Primary}`,
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn8(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 600,
      btnWeight9: 400,
      btnWeight10: 400,
      tab: "8",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: `3px solid ${COLORS.Primary}`,
      borderLeft9: "",
      borderLeft10: "",
    });
  }
  onChangeBtn9(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 600,
      btnWeight10: 400,
      tab: "9",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: `3px solid ${COLORS.Primary}`,
      borderLeft10: "",
    });
  }
  onChangeBtn10(e) {
    this.setState({
      btnWeight: 400,
      btnWeight2: 400,
      btnWeight3: 400,
      btnWeight4: 400,
      btnWeight5: 400,
      btnWeight6: 400,
      btnWeight7: 400,
      btnWeight8: 400,
      btnWeight9: 400,
      btnWeight10: 600,
      tab: "10",
      borderLeft: "",
      borderLeft2: "",
      borderLeft3: "",
      borderLeft4: "",
      borderLeft5: "",
      borderLeft6: "",
      borderLeft7: "",
      borderLeft8: "",
      borderLeft9: "",
      borderLeft10: `3px solid ${COLORS.Primary}`,
    });
  }
  render() {
    const { classes } = this.props;
    var matches = window.matchMedia("(max-width: 600px)");
    return (
      <>
        <Grid
          container
          spacing={0}
          style={{ padding: matches.matches === true ? 26 : 50 }}
        >
          <Grid
            item
            xs={12}
            style={{
              height: "25vw",
              backgroundColor: COLORS.PrimaryBackground,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <label className={classes.mainHeading}>Privacy Policy</label>
            </div>
            <div>
              <label className={classes.subHeading}>
                {" "}
                How Ceylon Healthy Food handles your personal data
              </label>
            </div>
            <div>
              <label className={classes.subHeading2}>
                Updated on 23rd May 2022
              </label>
            </div>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 50 }}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={4}
                style={{
                  textAlign: "left",
                  // borderLeft: "1px solid #D6D6D6",
                  paddingTop: 15,
                }}
                className="sideNavBarPrivacy"
              >
                <div style={{ borderLeft: this.state.borderLeft }}>
                  <button
                    onClick={this.onChangeBtn1}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    We respect your privacy{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft2 }}>
                  <button
                    onClick={this.onChangeBtn2}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight2,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    Collection of personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft3 }}>
                  <button
                    onClick={this.onChangeBtn3}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight3,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    How we collect your personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft4 }}>
                  <button
                    onClick={this.onChangeBtn4}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight4,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Use of your personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft5 }}>
                  <button
                    onClick={this.onChangeBtn5}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight5,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Disclosure of your personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft6 }}>
                  <button
                    onClick={this.onChangeBtn6}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight6,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Security of your personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft7 }}>
                  <button
                    onClick={this.onChangeBtn7}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight7,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Access to your personal information{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft8 }}>
                  <button
                    onClick={this.onChangeBtn8}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight8,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Complaints about privacy{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft9 }}>
                  {" "}
                  <button
                    onClick={this.onChangeBtn9}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight9,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
cursor:"pointer"
                    }}
                  >
                    Changes to Privacy Policy{" "}
                  </button>
                </div>
                <div style={{ borderLeft: this.state.borderLeft10 }}>
                  <button
                    onClick={this.onChangeBtn10}
                    className={classes.heading}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      width: "25vw",
                      //   height: "2.5vw",
                      textAlign: "left",
                      fontWeight: this.state.btnWeight10,
                      fontFamily: FONTS_INTER,
                      fontSize: "13pt",
                      cursor:"pointer"
                    }}
                  >
                    {" "}
                    Website{" "}
                  </button>
                </div>
              </Grid>

              <Grid
                item
                xs={8}
                style={{ textAlign: "left" }}
                className="sideNavBarPrivacy"
              >
                {this.state.tab === "1" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        We respect your privacy
                      </h2>
                    </div>
                    (a) Ceylon Healthy Food respects your right to privacy and
                    is committed to safeguarding the privacy of our customers
                    and website visitors. This policy sets out how we collect
                    and treat your personal information.
                    <br />
                    <br />
                    (b) "Personal information" is the information we hold which
                    is identifiable as being about you.
                  </div>
                ) : this.state.tab === "2" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Collection of personal information
                      </h2>
                    </div>
                    (a) Ceylon Healthy Food will, from time to time, receive and
                    store the personal information you enter onto our website,
                    provided to us directly or given to us in other forms.
                    <br />
                    <br />
                    (b) You may provide basic information such as your name,
                    phone number, address, and email address to enable us to
                    send information, provide updates and process your product
                    or service order. We may collect additional information at
                    other times, including but not limited to, when you provide
                    feedback when you provide information about your personal or
                    business affairs, change your content or email preference,
                    respond to surveys and/or promotions, provide financial or
                    credit card information, or communicate with our customer
                    support.
                    <br />
                    <br />
                    (c) Additionally, we may also collect any other information
                    you provide while interacting with us.
                  </div>
                ) : this.state.tab === "3" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        How we collect your personal information
                      </h2>
                    </div>
                    (a) Ceylon Healthy Food collects personal information from
                    you in a variety of ways, including when you interact with
                    us electronically or in person, when you access our website
                    and when we provide our services to you. We may receive
                    personal information from third parties. If we do, we will
                    protect it as set out in this Privacy Policy.
                  </div>
                ) : this.state.tab === "4" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Use of your personal information
                      </h2>
                    </div>
                    (a) Ceylon Healthy Food may use personal information
                    collected from you to provide you with information, updates,
                    and our services. We may also make you aware of new and
                    additional products, services, and opportunities available
                    to you. We may use your personal information to improve our
                    products and services and better understand your needs.
                    <br />
                    <br />
                    (b) Ceylon Healthy Food may contact you by a variety of
                    measures including, but not limited to telephone, email, SMS
                    or mail.
                  </div>
                ) : this.state.tab === "5" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Disclosure of your personal information
                      </h2>
                    </div>
                    (a) We may disclose your personal information to any of our
                    employees, officers, insurers, professional advisers,
                    agents, suppliers or subcontractors insofar as reasonably
                    necessary for the purposes set out in this Policy. When
                    required, personal information is only supplied to a third
                    party to deliver our services.
                    <br />
                    <br />
                    (b) We may from time to time need to disclose personal
                    information to comply with a legal requirement, such as a
                    law, regulation, court order, subpoena, warrant, during a
                    legal proceeding or in response to a law enforcement agency
                    request.
                    <br />
                    <br />
                    (c) We may also use your personal information to protect the
                    copyright, trademarks, legal rights, property or safety of
                    Ceylon Healthy Food,
                    <span
                      // style={{ padding: "0px 10px", background: "Highlight" ,color:"black" }}
                    >
                    https://ceylonhealthyfood.com.au
                    </span>
                    , its customers or third parties.
                    <br />
                    <br />
                    (d) Information that we collect may from time to time be
                    stored, processed in or transferred between parties located
                    in countries outside of Australia. <br />
                    <br />
                    (e) If there is a change of control in our business or a
                    sale or transfer of business assets, we reserve the right to
                    transfer to the extent permissible by law our user
                    databases, together with any personal information and
                    non-personal information contained in those databases. This
                    information may be disclosed to a potential purchaser under
                    an agreement to maintain confidentiality. We would seek to
                    only disclose information in good faith and where required
                    by any of the above circumstances.
                    <br />
                    <br />
                    (f) By providing us with personal information, you consent
                    to the terms of this Privacy Policy and the types of
                    disclosure covered by this Policy. When we disclose your
                    personal information to third parties, we will request that
                    the third party follow this Policy regarding handling your
                    personal information.
                  </div>
                ) : this.state.tab === "6" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Security of your personal information
                      </h2>
                    </div>
                    (a) Ceylon Healthy Food is committed to ensuring that the
                    information you provide to us is secure. In order to prevent
                    unauthorised access or disclosure, we have put in place
                    suitable physical, electronic and managerial procedures to
                    safeguard and secure information and protect it from misuse,
                    interference, loss and unauthorised access, modification and
                    disclosure. <br />
                    <br />
                    (b) The transmission and exchange of information are carried
                    out at your own risk. We cannot guarantee the security of
                    any information that you transmit to us or receive from us.
                    Although we take measures to safeguard against unauthorised
                    disclosures of information, we cannot assure you that the
                    personal information that we collect will not be disclosed
                    in a manner that is inconsistent with this Privacy Policy.
                  </div>
                ) : this.state.tab === "7" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2>
                        <b>Access to your personal information </b>
                      </h2>
                    </div>
                    (a) You may request details of personal information that we
                    hold about you in accordance with the provisions of the
                    Privacy Act 1988 (Cth). A small administrative fee may be
                    payable for the provision of information. If you would like
                    a copy of the information, we hold about you or believe that
                    any information we hold on you is inaccurate, out of date,
                    incomplete, irrelevant or misleading, please email us at{" "}
                    <span
                      // style={{ padding: "0px 10px", background: "Highlight",color:"black" }}
                    >
                      info@ceylonhealthyfood.com.au
                    </span>{" "}
                    <br />
                    <br />
                    (b) We reserve the right to refuse to provide you with
                    information that we hold about you, in certain circumstances
                    set out in the Privacy Act.
                  </div>
                ) : this.state.tab === "8" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Complaints about privacy
                      </h2>
                    </div>
                    (a) If you have any complaints about our privacy practices,
                    please feel free to send in details of your complaints to{" "}
                    <span
                      // style={{ padding: "0px 10px", background: "Highlight" }}
                    >
                      3/26-28 Verdun Dr, Narre Warren VIC 3805
                    </span>{" "}
                    We take complaints very seriously and will respond shortly
                    after receiving written notice of your complaint.
                  </div>
                ) : this.state.tab === "9" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Changes to Privacy Policy
                      </h2>
                    </div>
                    (a) Please be aware that we may change this Privacy Policy
                    in the future. We may modify this Policy at any time, in our
                    sole discretion and all modifications will be effective
                    immediately upon our posting of the modifications on our
                    website or notice board. Please check back from time to time
                    to review our Privacy Policy.
                    <br />
                  </div>
                ) : this.state.tab === "10" ? (
                  <div className={classes.subHeading3}>
                    <div>
                      <h2 style={{ fontWeight: "500", color: "#212326" }}>
                        Website
                      </h2>
                    </div>
                    (a)  When you come to our website{" "}
                    <span
                      // style={{ padding: "0px 10px", background: "Highlight",color:"black"  }}
                    >
                     https://ceylonhealthyfood.com.au
                    </span>{" "}
                    we may collect certain information such as browser type,
                    operating system, website visited immediately before coming
                    to our site, etc. This information is used in an aggregated
                    manner to analyse how people use our site, such that we can
                    improve our service. <br />
                    <br />
                    (b) We may use cookies on our website from time to
                    time. Cookies are very small files that a website uses to
                    identify you when you come back to the site and to store
                    details about your use of the site. Cookies are not
                    malicious programs that access or damage your computer. Most
                    web browsers automatically accept cookies, but you can
                    choose to reject cookies by changing your browser settings.
                    However, this may prevent you from taking full advantage of
                    our website. Our website may from time to time use cookies
                    to analyse website traffic and help us provide a better
                    website visitor experience. In addition, cookies may be used
                    to serve relevant ads to website visitors through third
                    party services such as Google AdWords. These ads may appear
                    on this website or other websites you visit.
                    <br />
                    <br />
                    (c)  Our site may from time to time have
                    links to other websites not owned or controlled by us. These
                    links are meant for your convenience only. Links to third
                    party websites do not constitute sponsorship or endorsement
                    or approval of these websites. Please be aware that Ceylon
                    Healthy Food is not responsible for the privacy practices of
                    other such websites. When they leave our website, we
                    encourage our users to be aware to read the privacy
                    statements of each website that collects personally
                    identifiable information.
                  </div>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "left" }}
                className="mainBarPrivacy425"
              >
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn1}
                    >
                      <span style={{ flex: 1 }}>We respect your privacy</span>{" "}
                      {this.state.tab === "1" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "1" && (
                    <>
                      (a) Ceylon Healthy Food respects your right to privacy and
                      is committed to safeguarding the privacy of our customers
                      and website visitors. This policy sets out how we collect
                      and treat your personal information.
                      <br />
                      <br />
                      (b) "Personal information" is the information we hold
                      which is identifiable as being about you.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn2}
                    >
                      <span style={{ flex: 1 }}>
                        Collection of personal information
                      </span>{" "}
                      {this.state.tab === "2" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "2" && (
                    <>
                      (a) Ceylon Healthy Food will, from time to time, receive
                      and store the personal information you enter onto our
                      website, provided to us directly or given to us in other
                      forms.
                      <br />
                      <br />
                      (b) You may provide basic information such as your name,
                      phone number, address, and email address to enable us to
                      send information, provide updates and process your product
                      or service order. We may collect additional information at
                      other times, including but not limited to, when you
                      provide feedback when you provide information about your
                      personal or business affairs, change your content or email
                      preference, respond to surveys and/or promotions, provide
                      financial or credit card information, or communicate with
                      our customer support.
                      <br />
                      <br />
                      (c) Additionally, we may also collect any other
                      information you provide while interacting with us.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn3}
                    >
                      <span style={{ flex: 1 }}>
                        How we collect your personal information
                      </span>{" "}
                      {this.state.tab === "3" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "3" && (
                    <>
                      (a) Ceylon Healthy Food collects personal information from
                      you in a variety of ways, including when you interact with
                      us electronically or in person, when you access our
                      website and when we provide our services to you. We may
                      receive personal information from third parties. If we do,
                      we will protect it as set out in this Privacy Policy.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn4}
                    >
                      <span style={{ flex: 1 }}>
                        Use of your personal information
                      </span>

                      {this.state.tab === "4" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "4" && (
                    <>
                      (a) Ceylon Healthy Food may use personal information
                      collected from you to provide you with information,
                      updates, and our services. We may also make you aware of
                      new and additional products, services, and opportunities
                      available to you. We may use your personal information to
                      improve our products and services and better understand
                      your needs.
                      <br />
                      <br />
                      (b) Ceylon Healthy Food may contact you by a variety of
                      measures including, but not limited to telephone, email,
                      SMS or mail.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn5}
                    >
                      <span style={{ flex: 1 }}>
                        Disclosure of your personal information
                      </span>

                      {this.state.tab === "5" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "5" && (
                    <>
                      (a) We may disclose your personal information to any of
                      our employees, officers, insurers, professional advisers,
                      agents, suppliers or subcontractors insofar as reasonably
                      necessary for the purposes set out in this Policy. When
                      required, personal information is only supplied to a third
                      party to deliver our services.
                      <br />
                      <br />
                      (b) We may from time to time need to disclose personal
                      information to comply with a legal requirement, such as a
                      law, regulation, court order, subpoena, warrant, during a
                      legal proceeding or in response to a law enforcement
                      agency request.
                      <br />
                      <br />
                      (c) We may also use your personal information to protect
                      the copyright, trademarks, legal rights, property or
                      safety of Ceylon Healthy Food,
                      <span
                        // style={{ padding: "0px 10px", background: "Highlight",color:"black"  }}
                      >
                       https://ceylonhealthyfood.com.au
                      </span>
                      , its customers or third parties.
                      <br />
                      <br />
                      (d) Information that we collect may from time to time be
                      stored, processed in or transferred between parties
                      located in countries outside of{" "}
                      <span
                        // style={{ padding: "0px 10px", background: "Highlight" }}
                      >
                        Australia.{" "}
                      </span>
                      <br />
                      <br />
                      (e) If there is a change of control in our business or a
                      sale or transfer of business assets, we reserve the right
                      to transfer to the extent permissible by law our user
                      databases, together with any personal information and
                      non-personal information contained in those databases.
                      This information may be disclosed to a potential purchaser
                      under an agreement to maintain confidentiality. We would
                      seek to only disclose information in good faith and where
                      required by any of the above circumstances.
                      <br />
                      <br />
                      (f) By providing us with personal information, you consent
                      to the terms of this Privacy Policy and the types of
                      disclosure covered by this Policy. When we disclose your
                      personal information to third parties, we will request
                      that the third party follow this Policy regarding handling
                      your personal information.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn6}
                    >
                      <span style={{ flex: 1 }}>
                        Security of your personal information
                      </span>{" "}
                      {this.state.tab === "6" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "6" && (
                    <>
                      (a) Ceylon Healthy Food is committed to ensuring that the
                      information you provide to us is secure. In order to
                      prevent unauthorised access or disclosure, we have put in
                      place suitable physical, electronic and managerial
                      procedures to safeguard and secure information and protect
                      it from misuse, interference, loss and unauthorised
                      access, modification and disclosure. <br />
                      <br />
                      (b) The transmission and exchange of information are
                      carried out at your own risk. We cannot guarantee the
                      security of any information that you transmit to us or
                      receive from us. Although we take measures to safeguard
                      against unauthorised disclosures of information, we cannot
                      assure you that the personal information that we collect
                      will not be disclosed in a manner that is inconsistent
                      with this Privacy Policy.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn7}
                    >
                      <span style={{ flex: 1 }}>
                        Access to your personal information
                      </span>
                      {this.state.tab === "7" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "7" && (
                    <>
                      (a) You may request details of personal information that
                      we hold about you in accordance with the provisions of the
                      Privacy Act 1988 (Cth). A small administrative fee may be
                      payable for the provision of information. If you would
                      like a copy of the information, we hold about you or
                      believe that any information we hold on you is inaccurate,
                      out of date, incomplete, irrelevant or misleading, please
                      email us at{" "}
                      <span
                        // style={{ padding: "0px 10px", background: "Highlight",color:"black"  }}
                      >
                        info@ceylonhealthyfood.com.au
                      </span>{" "}
                      <br />
                      <br />
                      (b) We reserve the right to refuse to provide you with
                      information that we hold about you, in certain
                      circumstances set out in the Privacy Act.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn8}
                    >
                      <span style={{ flex: 1 }}> Complaints about privacy</span>

                      {this.state.tab === "8" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "8" && (
                    <>
                      (a) If you have any complaints about our privacy
                      practices, please feel free to send in details of your
                      complaints to{" "}
                      <span
                        // style={{ padding: "0px 10px", background: "Highlight" }}
                      >
                        3/26-28 Verdun Dr, Narre Warren VIC 3805
                      </span>{" "}
                      We take complaints very seriously and will respond shortly
                      after receiving written notice of your complaint.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn9}
                    >
                      <span style={{ flex: 1 }}>
                        {" "}
                        Changes to Privacy Policy
                      </span>

                      {this.state.tab === "9" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "9" && (
                    <>
                      (a) Please be aware that we may change this Privacy Policy
                      in the future. We may modify this Policy at any time, in
                      our sole discretion and all modifications will be
                      effective immediately upon our posting of the
                      modifications on our website or notice board. Please check
                      back from time to time to review our Privacy Policy.
                    </>
                  )}
                </div>
                <div className={classes.subHeading3}>
                  <div>
                    <h2
                      style={{
                        display: "flex",

                        alignItems: "center",
                        fontWeight: "500",
                        color: "#212326",
                      }}
                      onClick={this.onChangeBtn10}
                    >
                      <span style={{ flex: 1 }}>Website</span>
                      {this.state.tab === "10" ? (
                        <RemoveIcon style={{ fontSize: 20 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 20 }} />
                      )}
                    </h2>
                  </div>
                  {this.state.tab === "10" && (
                    <>
                      (a)  When you come to our
                      website{" "}
                      <span
                        // style={{ padding: "0px 10px", background: "Highlight",color:"black"  }}
                      >
                       https://ceylonhealthyfood.com.au
                      </span>{" "}
                      we may collect certain information such as browser type,
                      operating system, website visited immediately before
                      coming to our site, etc. This information is used in an
                      aggregated manner to analyse how people use our site, such
                      that we can improve our service. <br />
                      <br />
                      (b)  We may use cookies on our website from time to
                      time. Cookies are very small files that a website uses to
                      identify you when you come back to the site and to store
                      details about your use of the site. Cookies are not
                      malicious programs that access or damage your computer.
                      Most web browsers automatically accept cookies, but you
                      can choose to reject cookies by changing your browser
                      settings. However, this may prevent you from taking full
                      advantage of our website. Our website may from time to
                      time use cookies to analyse website traffic and help us
                      provide a better website visitor experience. In addition,
                      cookies may be used to serve relevant ads to website
                      visitors through third party services such as Google
                      AdWords. These ads may appear on this website or other
                      websites you visit.
                      <br />
                      <br />
                      (c)  Our site may from time to time have
                      links to other websites not owned or controlled by us.
                      These links are meant for your convenience only. Links to
                      third party websites do not constitute sponsorship or
                      endorsement or approval of these websites. Please be aware
                      that Ceylon Healthy Food is not responsible for the
                      privacy practices of other such websites. When they leave
                      our website, we encourage our users to be aware to read
                      the privacy statements of each website that collects
                      personally identifiable information.
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(PrivacyPolicy);
