import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Nav from "./Nav";

import Aboutus from "./Componet/Aboutus";
import Team from "./Componet/Team";
import Vision from "./Componet/Vision";
import { useLocation } from "react-router-dom";
// import WhoWeAre from "./Componet/WhoWeAre";
function Contact() {
  const { search } = useLocation();

  var x = window.matchMedia("(max-width: 600px)");
  const [switches, setSwitches] = useState({
    tab1: x.matches === true ? false : true, //  Our Nutritionist
    tab2: false, // Our Specialty
    tab3: false, // Our Kitchen
    tab4: false,
    tab5: false,
  });
  useEffect(() => {
    console.log("about us param", search);
    switch (search) {
      case "?tab=tab1":
        setSwitches({
          tab1: true, //  Our Nutritionist
          tab2: false, // Our Specialty
          tab3: false, // Our Kitchen
        });
        break;
      case "?tab=tab2":
        setSwitches({
          tab1: false, //  Our Nutritionist
          tab2: true, // Our Specialty
          tab3: false, // Our Kitchen
        });
        break;
      case "?tab=tab3":
        setSwitches({
          tab1: false, //  Our Nutritionist
          tab2: false, // Our Specialty
          tab3: true, // Our Kitchen
        });
        break;
      default:
        setSwitches({
          tab1: x.matches === true ? false : true, //  Our Nutritionist
          tab2: false, // Our Specialty
          tab3: false, // Our Kitchen
        });
        break;
    }
  }, [search]);
  return (
    <Grid
      Container
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <Grid item md={1} sm={0}></Grid> */}
      <Grid
        item
        md={11}
        sm={12}
        style={{
          padding: "35px 10px",
        }}
      >
        <Grid Container xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid
            item
            md={3}
            xs={12}
            style={{
              padding: 10,
            }}
          >
            <Nav switches={switches} setSwitches={setSwitches} />
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            style={{
              padding: 10,
              paddingLeft: 25,
            }}
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            {/*   Our Nutritionist */}
            {switches?.tab1 === true && <Team />}
            {/*  Our Kitchen */}
            {switches?.tab3 === true && <Vision />}
            {/*  Our Specialty */}
            {switches?.tab2 === true && <Aboutus />}
            {/* {switches?.tab4 === true && <WhoWeAre />} */}
            {/* {switches?.tab5 === true && <WhoWeAre />} */}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item md={1} sm={0}></Grid> */}
    </Grid>
  );
}

export default Contact;
