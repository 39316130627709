import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";

import { FONTS, FONTS_INTER, FONTS_RC } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import COMPANY from "../../Assets/company.png";
import { getCompany, Categories, CurrentUser } from "../../Services/API/Get";
import { addSubscribe } from "../../Services/API/Post";
import { HashRouter as Router, useHistory } from "react-router-dom";
import CartContext from "../../Context/CartContext";
import ErrorPopup from "../Models/errorPopup.jsx";
import RegistrationPopup from "../Models/RegistrationPopup";
import RegistrationPopup2 from "../Models/RegistrationPopup2";
import "./home.css";
import Paypal from "../../Assets/aa.png";

const styles = {
  headerTitles: {
    fontFamily: FONTS_INTER,
    fontSize: "9.5pt",
    fontWeight: "bold",
    marginBottom: 13,
    color: COLORS.Primary,
  },
  subTitle: {
    fontFamily: FONTS_INTER,
    fontSize: "9.5pt",
    marginTop: 6,
    marginBottom: 3,
    color: COLORS.GrayDark2,
    // fontWeight: "bold",
    cursor: "pointer",
  },
  subTitle2: {
    fontFamily: FONTS_INTER,
    fontSize: "9.5pt",
    marginTop: 6,
    marginBottom: 3,
    color: COLORS.GrayDark2,
    cursor: "pointer",
    // fontWeight: "bold",
  },
  subTitle3: {
    fontFamily: FONTS_INTER,
    fontSize: "9.5pt",
    marginTop: 6,
    marginBottom: 3,
    color: COLORS.GrayDark2,
    cursor: "pointer",
    textDecoration: "none",
    // fontWeight: "bold",
  },
  icon_color: COLORS.Primary,
  subTitle4: {
    fontFamily: FONTS_INTER,
    fontSize: "9.5pt",
    marginTop: 6,
    marginBottom: 3,
    color: COLORS.GrayDark2,
    cursor: "pointer",
    textTransform: "capitalize",
    // fontWeight: "bold",
  },
};

function Footer({ title }) {
  var matches2 = window.matchMedia("(max-width: 1340px)");
  const { cart, setMenuCategory, scrollToElement, scrollToElement2 } =
    useContext(CartContext);
  const [company, setcompany] = React.useState("");
  const [Category, setCategory] = React.useState([]);
  const [userId, setuserId] = React.useState(null);
  const [email, setemail] = React.useState("");
  const [openRegForm, setOpenRegForm] = useState(false);
  const [openRegForm2, setOpenRegForm2] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const history = useHistory();

  const companyGet = () => {
    getCompany()
      .then((res) => {
        console.log(res.data);
        setcompany(res?.data[res.data.length - 1]);
      })
      .catch((error) => {
        console.log("error", error.response);
        console.log("current package error", error.response.data.message);
      });
  };

  const categoryGet = () => {
    Categories()
      .then((res) => {
        const newupdate = res?.data?.map((item) => {
          return {
            ...item,
            maincategory: `${item.maincategory
              .toLowerCase()[0]
              .toUpperCase()}${item.maincategory.toLowerCase().substring(1)}`,
          };
        });
        console.log("---------->", newupdate);
        setCategory(newupdate);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
      });
  };

  const getUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          setuserId(cData?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const postSubscribe = () => {
    const obj = {
      userId: userId,
      email: email,
    };

    console.log("sub>>>>>", obj);
    if (email !== "") {
      addSubscribe(obj)
        .then((res) => {
          setemail("");
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Successfully Subscribed");
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current user error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  React.useEffect(() => {
    companyGet();
    categoryGet();
    getUser();
  }, []);

  return (
    <>
      <RegistrationPopup
        open={openRegForm}
        handleClose={() => setOpenRegForm(false)}
      />
      <RegistrationPopup2
        open={openRegForm2}
        handleClose={() => setOpenRegForm2(false)}
      />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Grid container xs={12}>
        <Grid
          xs={12}
          container
          spacing={0}
          style={{
            backgroundColor: COLORS.GrayDark3,
            color: "white",
            justifyContent: "center",
          }}
        >
          <Grid xs={11} style={{ width: "100%", padding: "40px 20px" }}>
            <Grid
              xs={12}
              container
              spacing={0}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: matches2.matches === true ? "none" : "flex",
              }}
            >
              <Grid sm={2.4} item>
                <div className="row">
                  <div
                    style={{
                      textAlign: "left",
                      //  padding: "0px 50px 0px 50px"
                    }}
                  >
                    <div style={styles.headerTitles}>CONTACT SUPPORT</div>
                    <div>
                      {/* <p style={styles.subTitle}>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      />
                      {company.address + " " + company.postCode}
                    </p> */}
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faPhone}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        {/* {company.phoneNumber} */}
                        (03) 8794 8694
                      </p>
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faEnvelope}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        {company.email}
                      </p>
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faEnvelope}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        3/26-28 Verdun Drive,<br /> Narre Warren, VIC 3805.
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: -3,
                        }}
                      >
                        {/* <Typography
                        sx={{
                          width: "41px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "black",
                          "&:hover": {
                            background: COLORS.Primary,
                          },
                          borderRadius: "50%",
                        }}
                      > */}
                        {/* <FontAwesomeIcon
                        icon={faFaceBook}
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      /> */}
                        <FacebookRoundedIcon
                          style={{
                            width: "47px",
                            height: "40px",
                          }}
                          sx={{
                            // background: "white",
                            color: COLORS.Primary,
                            // color: "black",
                            // marginRight: "10px",
                            // fontSize: "35px",

                            "&:hover": {
                              color: COLORS.Orange,
                            },
                            borderRadius: "50%",
                            marginRight: "15px",
                          }}
                          className="socialIcon"
                          onClick={() => window.open(`${company?.facebook}`)}
                        />
                        {/* </Typography> */}
                        <Typography
                          sx={{
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: COLORS.Primary,
                            "&:hover": {
                              background: COLORS.Orange,
                            },
                            borderRadius: "50%",
                          }}
                        >
                          <InstagramIcon
                            className="socialIcon"
                            style={{
                              fontSize: "25px",
                            }}
                            sx={{
                              color: "white",
                              fontSize: "28px",
                              // "&:hover": {
                              //   color: COLORS.Primary,
                              // },
                            }}
                            onClick={() => window.open(`${company?.instagram}`)}
                          />
                        </Typography>
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid sm={1.5} item>
                <div
                  style={{
                    textAlign: "left",
                    //  padding: "0px 50px 0px 50px"
                  }}
                >
                  <div
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: "9.5pt",
                      fontWeight: "bold",
                      marginBottom: 7,
                      color: COLORS.Primary,
                    }}
                  >
                    MENUS
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Category?.map((data) => (
                      <a
                        style={styles.subTitle4}
                        onClick={() => {
                          history.push("/plans");
                          setTimeout(() => {
                            scrollToElement();
                            setMenuCategory(data?.mainId);
                          }, [1000]);
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        {data.maincategory}
                      </a>
                    ))}
                  </div>
                </div>
              </Grid>

              <Grid sm={2.4} item>
                <div
                  style={{
                    textAlign: "left",
                    // padding: "0px 50px 0px 50px"
                  }}
                >
                  <div
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: "9.5pt",
                      fontWeight: "bold",
                      marginBottom: 7,
                      color: COLORS.Primary,
                    }}
                  >
                   DELIVERY HOURS & ADDRESS
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ marginRight: 20 }}>
                      {/* <p style={styles.subTitle}> */}
                        {/* {" "} */}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        {/* Tuesday */}
                      {/* </p> */}
                      <p style={styles.subTitle}>
                        {" "}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        Friday
                      </p>
                      {/* <p style={styles.subTitle}>Public Holidays</p> */}
                    </div>
                    <div>
                      {/* <p style={styles.subTitle}> */}
                        {" "}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        {/* 4 pm - 8 pm */}
                      {/* </p> */}
                      <p style={styles.subTitle}>
                        {" "}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        4 pm - 8 pm
                      </p>

                      {/* <p style={styles.subTitle}>CLOSED</p> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ marginRight: 20 }}>
                      <p style={styles.subTitle}>
                        {/* {" "} */}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        8/11 Swift Way, <br />Dandenong South VIC 3175
                      </p>

                      {/* <p style={styles.subTitle}>Public Holidays</p> */}
                    </div>

                  </div>
                </div>
              </Grid>
              <Grid sm={2.1} item>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        // padding: "0px 50px 0px 50px",
                        float: "right",
                      }}
                    >
                      <div style={styles.headerTitles}>USEFUL LINKS</div>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // history.push("/plans");
                          // window.scroll(0, 700);
                          if (title === "home") {
                            scrollToElement2();
                          } else {
                            history.push("/home?pricing=true");
                            window.scrollTo(0, 0);
                            // window.scroll(0, 2600);
                            scrollToElement2();
                          }
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Packages
                      </p>
                      <p
                        style={styles.subTitle}
                        onClick={() => {
                          history.push("/Privacy");
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Privacy Policy
                      </p>
                      <p style={styles.subTitle}
                        onClick={() => {
                          history.push("/work?refund=true");

                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Refund Policy
                      </p>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // if (matches2.matches === true) {
                          //   history.push("/kitchen_partner");
                          //   window.scroll(0, 0);
                          // } else {
                          //   setOpenRegForm(true);
                          // }
                          history.push("/home?retailer=true");
                          // window.scroll(0,0)
                        }}
                      >
                        Partnered Retailer
                      </p>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // if (matches2.matches === true) {
                          //   history.push("/kitchen_partner");
                          //   window.scroll(0, 0);
                          // } else {
                          //   setOpenRegForm(true);
                          // }
                          history.push("/home?kitchen=true");
                          // window.scroll(0,0)
                        }}
                      >
                        Kitchen Partner
                      </p>
                      {/* <p
                        style={styles.subTitle2}
                        onClick={() => {
                          if (matches2.matches === true) {
                            history.push("/delivery_partner");
                            window.scroll(0, 0);
                          } else {
                            setOpenRegForm2(true);
                          }
                        }}
                      >
                        Delivery partner
                      </p> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sm={2.4} item>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        textAlign: "left",
                        // padding: "0px 50px 0px 50px",
                        float: "right",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "9.5pt",
                          fontWeight: "bold",
                          marginBottom: 10,
                          color: COLORS.Primary,
                        }}
                      >
                        GET THE LATEST UPDATES{" "}
                      </div>
                      <p
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "9.5pt",
                          marginTop: 6,
                          marginBottom: 3,
                          color: COLORS.GrayDark2,
                          lineHeight: 1.6,
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Please enter a valid email below to receive the latest
                        updates, offers and discounts
                      </p>
                    </div>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        display: "flex",
                        background: "white",
                        borderRadius: 4,
                        padding: "0px 0px 0px 4px",
                        margin: "10px 0px",
                      }}
                    >
                      <input
                        placeholder="Enter your email"
                        style={{
                          padding: "4px ",
                          border: "none",
                          fontSize: "9.5pt",
                          width: "178px",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TitleColor4,
                          height: "2rem",
                        }}
                        onChange={(e) => {
                          setemail(e.target.value);
                          // seterrofficeAddress(false);
                        }}
                      />
                      <Button
                        style={{
                          background: COLORS.Orange,
                          color: "white",
                          fontSize: "9.5pt",
                          padding: "2px 10px",
                        }}
                        onClick={postSubscribe}
                      >
                        SUBSCRIBE
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              spacing={2}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: matches2.matches === true ? "flex" : "none",
                
              }}
            >
              <Grid sm={3} xs={12} item>
                <div
                  style={{
                    textAlign: "left",
                    //  padding: "0px 50px 0px 50px"
                  }}
                >
                  <h4 style={styles.headerTitles}>MENUS</h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {Category?.map((data) => (
                      <a
                        style={styles.subTitle4}
                        onClick={() => {
                          history.push("/plans");
                          setTimeout(() => {
                            scrollToElement();
                            setMenuCategory(data?.mainId);
                          }, [1000]);
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        {data.maincategory}
                      </a>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid sm={3} xs={12} item>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        textAlign: "left",
                        // padding: "0px 50px 0px 50px",
                        // float: "right",
                      }}
                    >
                      <h4 style={styles.headerTitles}>USEFUL LINKS</h4>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // history.push("/plans");
                          // window.scroll(0, 700);
                          if (title === "home") {
                            scrollToElement2();
                          } else {
                            history.push("/home?pricing=true");
                            // window.scroll(0, 2600);
                            scrollToElement2();
                          }
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Packages
                      </p>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          history.push("/Privacy");
                          window.scrollTo(0, 0);
                        }}
                      >
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Privacy Policy
                      </p>
                      <p style={styles.subTitle2}
                        onClick={() => {
                          history.push("/work?refund=true");

                        }}
                      >Refund Policy</p>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // if (matches2.matches === true) {
                          //   history.push("/kitchen_partner");
                          //   window.scroll(0, 0);
                          // } else {
                          //   setOpenRegForm(true);
                          // }
                          history.push("/home?retailer=true");
                          // window.scroll(0,0)
                        }}
                      >
                        Partnered Retailer
                      </p>
                      <p
                        style={styles.subTitle2}
                        onClick={() => {
                          // if (matches2.matches === true) {
                          //   history.push("/kitchen_partner");
                          //   window.scroll(0, 0);
                          // } else {
                          //   setOpenRegForm(true);
                          // }
                          history.push("/home?kitchen=true");
                          // window.scroll(0,0)
                        }}
                      >
                        Kitchen Partner
                      </p>
                      {/* <p
                        style={styles.subTitle2}
                        onClick={() => {
                          if (matches2.matches === true) {
                            history.push("/delivery_partner");
                            window.scroll(0, 0);
                          } else {
                            setOpenRegForm2(true);
                          }
                        }}
                      >
                        Delivery partner
                      </p> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sm={3} xs={12} item>
                <Typography
                  sx={{
                    marginRight: {
                      xs: "14px",
                      sm: 0,
                    },
                  }}
                  style={{
                    textAlign: "left",
                    // padding: "0px 50px 0px 50px"
                  }}
                >
                  <h4
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: "9.5pt",
                      fontWeight: "bold",
                      marginBottom: 7,
                      color: COLORS.Primary,
                    }}
                  >
                    DELIVERY HOURS & ADDRESS
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ marginRight: 20 }}>
                      <p style={styles.subTitle}>
                        {/* {" "} */}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        {/* Tuesday
                        <br /> */}
                        Friday
                        {/* Public Holidays */}
                      </p>
                    </div>
                    <p style={styles.subTitle}>
                      {" "}
                      {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                      {/* 4 pm - 8 pm
                      <br /> */}
                      4 pm - 8 pm
                      {/* CLOSED */}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ marginRight: 20 }}>
                      <p style={styles.subTitle}>
                        {/* {" "} */}
                        {/* <FontAwesomeIcon
                    icon={faCaretRight}
                    color={styles.icon_color}
                    style={{ marginRight: 10 }}
                  /> */}
                        8/11 Swift Way, <br />
                        Dandenong South VIC 3175
                        {/* Public Holidays */}
                      </p>
                    </div>

                  </div>
                </Typography>
              </Grid>

              <Grid sm={3} xs={12} item>
                <div className="row">
                  <div
                    style={{
                      textAlign: "left",
                      //  padding: "0px 50px 0px 50px"
                    }}
                  >
                    <h4
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: "9.5pt",
                        fontWeight: "bold",
                        marginBottom: 13,
                        color: COLORS.Primary,
                      }}
                    >
                      CONTACT SUPPORT
                    </h4>
                    <div>
                      {/* <p style={styles.subTitle}>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      />
                      {company.address + " " + company.postCode}
                    </p> */}
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faPhone}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        (03) 8794 8694
                        {/* {company.phoneNumber} */}
                      </p>
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faEnvelope}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        {company.email}
                      </p>
                      <p style={styles.subTitle}>
                        {/* <FontAwesomeIcon
                        icon={faEnvelope}
                        color={styles.icon_color}
                        style={{ marginRight: 10 }}
                      /> */}
                        3/26-28 Verdun Drive, Narre Warren, VIC 3805.
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: -3,
                          marginBottom: "0px",
                        }}
                      >
                        {/* <Typography
                        sx={{
                          width: "41px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "black",
                          "&:hover": {
                            background: COLORS.Primary,
                          },
                          borderRadius: "50%",
                        }}
                      > */}
                        {/* <FontAwesomeIcon
                        icon={faFaceBook}
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      /> */}
                        <FacebookRoundedIcon
                          style={{
                            width: "47px",
                            height: "45px",
                          }}
                          sx={{
                            color: COLORS.Primary,
                            // color: "black",
                            // marginRight: "10px",
                            // fontSize: "35px",

                            "&:hover": {
                              color: COLORS.Orange,
                            },
                            borderRadius: "50%",
                            marginRight: "15px",
                          }}
                          className="socialIcon"
                          onClick={() => window.open(`${company?.facebook}`)}
                        />
                        {/* </Typography> */}
                        <Typography
                          sx={{
                            width: "38px",
                            height: "38px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: COLORS.Primary,
                            "&:hover": {
                              background: COLORS.Orange,
                            },
                            borderRadius: "50%",
                          }}
                        >
                          <InstagramIcon
                            className="socialIcon"
                            style={{
                              fontSize: "28px",
                            }}
                            sx={{
                              color: "white",

                              // "&:hover": {
                              //   color: COLORS.Primary,
                              // },
                            }}
                            onClick={() => window.open(`${company?.instagram}`)}
                          />
                        </Typography>
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid sm={5} xs={12} item>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        textAlign: "left",
                        // padding: "0px 50px 0px 50px",
                        float: "right",
                      }}
                    >
                      <h4 style={styles.headerTitles}>
                        GET THE LATEST UPDATES{" "}
                      </h4>
                      <p style={styles.subTitle}>
                        {" "}
                        {/* <FontAwesomeIcon
                        icon={faCaretRight}
                        color={styles.icon_color}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      /> */}
                        Please enter a valid email below to receive the latest
                        updates, offers and discounts
                      </p>
                    </div>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        display: "flex",
                        background: "white",
                        borderRadius: 4,
                        padding: "0px 0px 0px 4px",
                        margin: "10px 0px",
                      }}
                    >
                      <input
                        placeholder="Enter your email"
                        style={{
                          padding: "4px ",
                          border: "none",
                          fontSize: "9.5pt",
                          width: "50vw",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TitleColor4,
                          height: "2rem",
                        }}
                        onChange={(e) => {
                          setemail(e.target.value);
                          // seterrofficeAddress(false);
                        }}
                      />
                      <Button
                        style={{
                          background: COLORS.Orange,
                          color: "white",
                          fontSize: "9.5pt",
                          padding: "2px 10px",
                        }}
                        onClick={postSubscribe}
                      >
                        SUBSCRIBE
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid container spacing={0}>
            <Grid
              container
              spacing={0}
              style={{
                padding: "30px 0px 0px",
              }}
            >
              <Grid item md={9}></Grid>

              <Grid
                item
                md={3}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img src={PAYMENT} style={{ height: 40 }} />
              </Grid>
            </Grid>
          </Grid> */}
          </Grid>

          <Grid xs={11} style={{ width: "100%", padding: "40px 20px" }}>

            <img
              src={Paypal}
              style={{
                height: "30px",
                display: "flex",
                justifyContent: "start"
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: COLORS.Primary,
              fontFamily: FONTS,
              paddingBottom: 5,
              padding: "10px",
            }}
          >
            <div>
              <Typography
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                }}
              >
                <a
                  href="https://infinitycs.com.au"
                  target="_blank"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: matches2.matches === true ? "column" : "row",
                  }}
                >
                  <div
                    style={{
                      fontSize: "9.5pt",
                      fontFamily: FONTS_RC,
                      marginRight: 3,
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    Powered By
                    {/* InfinityCs */}
                  </div>
                  <img
                    src={COMPANY}
                    style={{
                      height: "35px",
                    }}
                  />
                </a>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
export default Footer;
