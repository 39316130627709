import {
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import "../../css/image.css";
import { FONTS, FONTS_AC, FONTS_INTER, FONTS_RC, FONTS_ANOTON } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import LoginPopup from "../Models/LoginPopup";
import { getLogo, getCover, Categories } from "../../Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";
import HOME_IMG from "../../Assets/Banner/Home.png";

import CartContext from "../../Context/CartContext";
import AuthContext from "../../Context/AuthContext";
import ProfilePopup from "../Models/profilePopup";
import Steps from "../../Componets/Steps";
import D_LOGO from "../../Assets/DLogin.png";
import PERSON_ICON from "./persion_icon.png";
import SHOPPING_ICON from "./shopping_icon.png";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  mealsPlan: {
    borderRadius: "20px",
  },
  menus: {
    fontFamily: FONTS_INTER,
    textDecoration: "none",
    color: "white",
    whiteSpace: "nowrap",
    marginRight: "18px",
    textTransform: "uppercase",
    fontWeight: "400",
    fontSize: "12pt",
    cursor: "pointer",
  },
  menusParent: {
    marginBottom: 3,
  },
  divider: {
    // color: "white",
    background: "white",
    borderBottomWidth: "1px",
  },
  listText: {
    fontSize: "12pt",
    fontFamily: FONTS_INTER,
    color: "white",
    padding: "6px 0px",
    display: "flex",
    flex: 1,
  },
};

export default function Header({
  size = 5,
  title,
  order = false,
  src = null,
  bannerShow = false,
}) {
  var myVar;
  var matches2 = window.matchMedia("(max-width: 1200px)");
  const [sBanner, setSBanner] = useState(false);
  const [sBanner2, setSBanner2] = useState(false);
  const { user } = useContext(AuthContext);
  const [openLogin, setOpenLogin] = useState();
  const [cover, setCover] = useState("");
  const [logo, setlogo] = useState("");
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const { cart, setMenuCategory, scrollToElement, scrollToElement2 } =
    useContext(CartContext);
  const [mealSwitch, setMealSwitch] = useState(false);
  const [aboutSwitch, setAboutSwitch] = useState(false);
  const [Category, setCategory] = React.useState([]);
  const [scrollPoints, setScrollPoints] = useState(0);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const coverGet = () => {
    getCover()
      .then((res) => {
        console.log(res.data[res.data.length - 1]);
        setCover(res.data[res.data.length - 1]);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const logoGet = () => {
    getLogo()
      .then((res) => {
        console.log(res.data);
        setlogo(res.data[res.data.length - 1].image);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const categoryGet = () => {
    Categories()
      .then((res) => {
        const newupdate = res?.data?.map((item) => {
          return {
            ...item,
            maincategory: `${item.maincategory
              .toLowerCase()[0]
              .toUpperCase()}${item.maincategory.toLowerCase().substring(1)}`,
          };
        });
        console.log("---------->", newupdate);
        setCategory(newupdate);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    logoGet();
    coverGet();
    categoryGet();
    // iconGet();
    // captionGet();
    // brandGet();
    setTimeout(() => setSBanner(true), 500);
    setTimeout(() => setSBanner2(true), 800);
  }, []);
  // if (matches2.matches === true) {
  //   window.addEventListener("scroll", () => {
  //     const qSelector = window.document.querySelector("#stickyheader");
  //     var stickyHeaderTop = qSelector.scrollTop;
  //     // console.log("_______________", scrollPoints);

  //     setScrollPoints(window.scrollY);
  //     if (window.scrollY > stickyHeaderTop) {
  //       qSelector.style.position = "fixed";
  //     } else {
  //       qSelector.style.position = "static";
  //     }
  //   });
  // }
  React.useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      if (moving < 160) {
        setVisible(true);
      } else {
        setVisible(position > moving);
        setPosition(moving);
      }
      // setVisible(position > moving);
      // setPosition(moving)
    };
    window.addEventListener("scroll", handleScroll);
    // myVar = setTimeout(() => {
    //   if (window.scrollY > scrollPoints) {
    //     if (openMenu === false) {
    //       console.log("---SCROLL DOWN---", window.scrollY);

    //       // window.document.querySelector("#stickyheader").style.position =
    //       //   "fixed";
    //     }
    //   } else {
    //     console.log("++++SCROLL UP+++++++", scrollPoints);

    //     // window.document.querySelector("#stickyheader").style.position = "static";
    //   }
    // }, 1000);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (openMenu === true) {
  //       console.log("clearing time out");
  //       clearTimeout(myVar);
  //       window.document.querySelector("#stickyheader").style.position = "fixed";
  //     }
  //   }, 1000);
  // }, [openMenu]);
  const cls = visible ? "visible" : "hidden";
  return (
    <>
      <div
        style={{ display: "hidden" }}
      >
        <a href="/home"></a>
        <a href="/Profile"></a>
        <a href="/Meals"></a>
        <a href="/plans"></a>
        <a href="/cart"></a>
        <a href="/login"></a>
        <a href="/OrderSummary"></a>
        <a href="/SignUp"></a>
        <a href="/Checkout"></a>
        <a href="/Privacy"></a>
        <a href="/reset-password"></a>
        <a href="/kitchen_partner"></a>
        <a href="/delivery_partner"></a>
        <a href="/retail_partner"></a>
      </div>
      <Grid
        xs={12}
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {/* Hader */}
            <div
              id="stickyheader"
              className={cls}
              style={
                matches2.matches === true
                  ? visible === true
                    ? {
                      // position: "fixed",
                      background: "white",
                      zIndex: 2,
                      top: 0,
                      transition: "top 0.4s ease-out",
                    }
                    : {
                      // position: "fixed",
                      background: "white",
                      zIndex: 2,
                      top: -150,
                      transition: "top 0.4s ease-out",
                    }
                  : {
                    position: "static",
                  }
              }
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "200px",
                  width: "100vw",
                }}
                sx={{
                  height: {
                    sm: "200px",
                    xs: "150px",
                  },
                  // position: {
                  //   sm: "unset",
                  //   xs: "fixed",
                  // },
                }}
              >
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  sx={{
                    marginTop: {
                      xs: "13px",
                      sm: "0px",
                    },
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: matches2.matches === true ? "block" : "none",
                      justifyContent: "center",
                    }}

                  // sx={{
                  //   display: {
                  //     xs: "flex",
                  //     sm: "none",
                  //   },

                  // }}
                  >
                    {openMenu === false ? (
                      <div
                        onClick={() => {
                          setOpenMenu(true);
                          clearTimeout(myVar);
                          // window.document.querySelector(
                          //   "#stickyheader"
                          // ).style.position = "fixed";
                        }}
                      >
                        <MenuIcon style={{ fontSize: "2rem" }} />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setOpenMenu(false);
                          // window.document.querySelector(
                          //   "#stickyheader"
                          // ).style.position = "static";
                        }}
                      >
                        <CloseIcon style={{ fontSize: "2rem" }} />
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    lg={12}
                    // sm={12}
                    sx={{
                      height: {
                        sm: "200px",
                        xs: "120px",
                      },
                    }}
                  >
                    {logo ? (
                      <img
                        // src={`${IMAGE_SERVER_URL_DEV}${logo}`}
                        src={`${IMAGE_SERVER_URL_DEV}${"/uploads/logo/logo.png"}`}
                        // src={IMG}

                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push("/home");
                          setOpenMenu(false);
                          // if(matches2.matches === false){
                          // window.scroll(0,300)
                          // }
                        }}
                      />
                    ) : (
                      <img
                        src={D_LOGO}
                        // src={IMG}

                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => history.push("/home")}
                      />
                    )}
                    {/* <img
                    src={`${IMAGE_SERVER_URL_DEV}${logo}`}
                    // src={IMG}

                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/home")}
                  /> */}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      padding: {
                        xs: "0px",
                        sm: "0px 4vW"
                      }
                    }}
                    style={{
                      display: matches2.matches === true ? "block" : "none",
                      justifyContent: "center",

                      // padding:"0px 6vW"
                    }}
                  // sx={{
                  //   display: {
                  //     xs: "flex",
                  //     sm: "none",
                  //   },
                  //   justifyContent: "center",
                  // }}
                  >
                    {/* <IconButton> */}

                    <Typography>
                      <div
                        onClick={() => {
                          setOpenMenu(false);
                          history.push("/cart");
                        }}
                      >
                        <div
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            color: "white",
                            border: "0.8px solid white",
                            fontSize: "7pt",
                            marginTop: -7,
                            marginLeft: 18,

                            width: 16,
                            height: 16,
                            borderRadius: "50%",
                            background: COLORS.Primary,
                            display: "grid",
                            placeItems: "center",
                            fontFamily: "Inter",
                          }}
                        >
                          {cart}
                        </div>
                        <ShoppingCartOutlinedIcon style={{ fontSize: "2rem" }} />
                      </div>
                    </Typography>
                    {/* </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
              {openMenu && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: matches2.matches === true ? "block" : "none",
                    zIndex: 2,
                    width: "100vw",
                    maxHeight: "80vh",
                    overflowY: "scroll",
                  }}
                // sx={{
                //   display: {
                //     xs: "block",
                //     sm: "none",
                //     // marginTop: "150px",
                //     // position: "fixed",
                //   },


                // }}
                >
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      bgcolor: COLORS.Primary,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    {/* {window.localStorage.getItem(
                      `${window.location.hostname}.login`
                    ) === null && (
                      <>
                        <ListItemButton
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 5vw",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: FONTS_RC,
                                // background: COLORS.Orange,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // fontSize: "16pt",
                                fontSize: "18px",
                                fontWeight: "400",
                                height: 30,
                                borderRadius: 5,
                                padding: "3px 0px",
                              }}
                            >
                              <Link
                                to="/SignUp"
                                style={{
                                  fontFamily: FONTS_RC,
                                  textDecoration: "none",
                                  // color: COLORS.Orange,
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenMenu(false);
                                  if (matches2.matches === true) {
                                    window.scroll(0, 350);
                                  }
                                }}
                              >
                                Register Now
                              </Link>
                            </Typography>
                          </div>
                          <div onClick={() => setOpenMenu(false)}>
                            <CloseRoundedIcon
                              style={{
                                color: "white",
                              }}
                            />
                          </div>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                      </>
                    )} */}
                    <ListItemButton
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={styles.listText}
                        onClick={() => {
                          history.push("/plans");
                          setOpenMenu(false);
                          window.scroll({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                          });
                        }}
                      >
                        Meal Plans
                      </Typography>
                      {mealSwitch === false ? (
                        <div onClick={() => setMealSwitch(true)}>
                          <KeyboardArrowDownIcon
                            style={{
                              color: "white",
                              fontSize: "24px",
                            }}
                          />
                        </div>
                      ) : (
                        <div onClick={() => setMealSwitch(false)}>
                          <KeyboardArrowUpIcon
                            style={{
                              color: "white",
                              fontSize: "24px",
                            }}
                          />
                        </div>
                      )}
                    </ListItemButton>
                    {mealSwitch &&
                      Category?.map((data) => (
                        <>
                          <ListItemButton
                            style={{
                              background: COLORS.Primary4,
                              borderBottom: "1px solid white",
                              padding: "5px 5vw",
                              width: "100%",
                            }}
                            onClick={() => {
                              history.push("/plans");
                              scrollToElement();
                              setMenuCategory(data?.mainId);
                            }}
                          >
                            <Typography
                              sx={styles.listText}
                              onClick={() => {
                                history.push("/plans");
                                setOpenMenu(false);
                              }}
                            >
                              {data.maincategory}
                            </Typography>
                          </ListItemButton>
                        </>
                      ))}
                    {!mealSwitch && <Divider sx={styles.divider} />}
                    <ListItemButton
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={styles.listText}
                        onClick={() => {
                          history.push("/work");
                          setOpenMenu(false);
                          window.scroll({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                          });
                        }}
                      >
                        How it works
                      </Typography>
                    </ListItemButton>
                    <Divider sx={styles.divider} />
                    <ListItemButton
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={styles.listText}
                        onClick={() => {
                          history.push("/Meals");
                          setOpenMenu(false);
                          window.scroll({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                          });
                        }}
                      >
                        {/* Delivery */}
                        Delivery
                      </Typography>
                    </ListItemButton>
                    <Divider sx={styles.divider} />
                    <ListItemButton
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={styles.listText}
                        onClick={() => {
                          if (title === "home") {
                            scrollToElement2();
                            setOpenMenu(false);
                          } else {
                            history.push("/home?pricing=true");
                            // window.scroll(0, 2600);
                            setOpenMenu(false);

                            setTimeout(() => {
                              scrollToElement2();
                            }, 2000);
                          }
                        }}
                      >
                        Packages
                      </Typography>
                    </ListItemButton>
                    <Divider sx={styles.divider} />

                    {/* <ListItemButton
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={styles.listText}
                        onClick={() => {
                          history.push(`/about?tab=tab1`);
                          setOpenMenu(false);
                          window.scroll({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                          });
                        }}
                      >
                        About us
                      </Typography>
                      {aboutSwitch === false ? (
                        <div
                          onClick={() => {
                            // setOpenMenu(true);

                            setAboutSwitch(true);
                          }}
                        >
                          <KeyboardArrowDownIcon
                            style={{
                              color: "white",
                              fontSize: "24px",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            // setOpenMenu(true);
                            setAboutSwitch(false);
                          }}
                        >
                          <KeyboardArrowUpIcon
                            style={{
                              color: "white",
                              fontSize: "24px",
                            }}
                          />
                        </div>
                      )}
                    </ListItemButton>
                    {aboutSwitch &&
                      [
                        {
                          id: "tab1",
                          title: "Our Nutritionist",
                        },
                        {
                          id: "tab2",
                          title: "Our Specialty",
                        },
                        {
                          id: "tab3",
                          title: "Our Kitchen",
                        },
                        // {
                        //   title: " Who we are ?",
                        // },
                      ]?.map((d) => {
                        return (
                          <ListItemButton
                            style={{
                              background: COLORS.Primary4,
                              borderBottom: "1px solid white",
                              padding: "5px 5vw",
                              width: "100%",
                            }}
                            onClick={() => {
                              history.push(`/about?tab=${d.id}`);
                              setOpenMenu(false);
                              window.scroll({
                                behavior: "smooth",
                                left: 0,
                                top: 260,
                              });
                            }}
                          >
                            <Typography
                              sx={styles.listText}
                              // onClick={() => {
                              //   history.push("/about");
                              //   setOpenMenu(false);
                              // }}
                            >
                              {d.title}
                            </Typography>
                          </ListItemButton>
                        );
                      })} */}
                    <Divider sx={styles.divider} />
                    <ListItemButton
                      onClick={() => {
                        history.push("/ContactUs");
                        setOpenMenu(false);
                        window.scroll({
                          behavior: "smooth",
                          left: 0,
                          top: 0,
                        });
                      }}
                      style={{
                        padding: "5px 5vw",
                        width: "100%",
                      }}
                    >
                      <Typography sx={styles.listText}>Contact us</Typography>
                    </ListItemButton>
                    <Divider sx={styles.divider} />
                    {user === null ? (
                      <>
                        <ListItemButton
                          style={{
                            padding: "5px 5vw",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          onClick={() => {
                            setOpenMenu(false);

                            var x = window.matchMedia("(max-width: 600px)");
                            console.log("--------->", x);
                            if (x.matches) {
                              history.push("/login");
                            } else {
                              setOpenLogin(!openLogin);
                            }
                            window.scroll({
                              behavior: "smooth",
                              left: 0,
                              top: 0,
                            });
                          }}
                        >
                          <LoginRoundedIcon
                            style={{
                              color: "white",
                              marginRight: 10,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12pt",

                              fontFamily: FONTS_INTER,
                              color: "white",
                              fontWeight: "bold",
                              padding: "6px 0px",
                            }}
                          >
                            Login
                          </Typography>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                        <ListItemButton
                          style={{
                            padding: "5px 5vw",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          onClick={() => {
                            // to="/SignUp"

                            setOpenMenu(false);
                            history.push("/SignUp");
                            if (matches2.matches === true) {
                              window.scroll(0, 350);
                            }
                          }}
                        >
                          <PersonAddAltRoundedIcon
                            style={{
                              color: "white",
                              marginRight: 10,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12pt",

                              fontFamily: FONTS_INTER,
                              color: "white",
                              fontWeight: "bold",
                              padding: "6px 0px",
                            }}
                          >
                            Register Now
                          </Typography>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                      </>
                    ) : (
                      <>
                        <ListItemButton
                          onClick={() => {
                            history.push("/Profile");
                            setOpenMenu(false);
                            window.scroll({
                              behavior: "smooth",
                              left: 0,
                              top: 0,
                            });
                          }}
                          style={{
                            padding: "5px 5vw",
                            width: "100%",
                          }}
                        >
                          <AccountCircleIcon
                            style={{
                              color: "white",
                              marginRight: 10,
                              fontSize: 30,
                            }}
                          />
                          <Typography sx={styles.listText}>
                            My profile
                          </Typography>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                        <ListItemButton
                          onClick={() => {
                            history.push("/OrderSummary");
                            setOpenMenu(false);
                            window.scroll({
                              behavior: "smooth",
                              left: 0,
                              top: 0,
                            });
                          }}
                          style={{
                            padding: "5px 5vw",
                            width: "100%",
                          }}
                        >
                          <CheckBoxOutlinedIcon
                            style={{
                              color: "white",
                              marginRight: 10,
                              fontSize: 30,
                            }}
                          />
                          <Typography sx={styles.listText}>
                            Last order summary
                          </Typography>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                        <ListItemButton
                          onClick={() => {
                            setOpenMenu(false);
                            window.localStorage.removeItem(
                              `${window.location.hostname}.login`
                            );
                            window.localStorage.removeItem("exprTime");
                            window.localStorage.clear();
                            history.push("/home");
                            window.location.reload();
                          }}
                          style={{
                            padding: "5px 5vw",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <LogoutRoundedIcon
                            style={{
                              color: "white",
                              marginRight: 10,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12pt",
                              fontFamily: FONTS_INTER,
                              color: "white",
                              fontWeight: "bold",
                              padding: "6px 0px",
                              cursor: "pointer",
                            }}
                          >
                            Log out
                          </Typography>
                        </ListItemButton>
                        <Divider sx={styles.divider} />
                      </>
                    )}

                    <br />
                    <ListItemText
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13pt",
                          fontFamily: FONTS_INTER,
                          color: "white",
                        }}
                      >
                        Need help ? Contact our support center
                      </Typography>
                    </ListItemText>
                    <ListItemText
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17.5pt",
                          fontFamily: FONTS_INTER,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        (03) 8794 8694
                      </Typography>
                    </ListItemText>
                    <br />
                  </List>
                </Grid>
              )}
            </div>

            {/* Menu */}

            <Grid
              item
              xs={12}
              style={{
                display: matches2.matches === false ? "block" : "none"
              }}
            // sx={{
            //   display: {
            //     xs: "none",
            //     sm: "block",
            //   },
            // }}
            >
              <AppBar
                position="static"
                style={{ backgroundColor: COLORS.Primary }}
              >
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={11}>
                    <Toolbar
                      style={{
                        minHeight: 54,
                      }}
                      sx={{
                        "&.css-1t29gy6-MuiToolbar-root": {
                          minHeight: 56,
                        },
                      }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        sx={{
                          flexDirection: {
                            xs: "column",
                            md: "row",
                          },
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          sx={{
                            flexDirection: {
                              xs: "column",
                              md: "row",
                            },
                            padding: {
                              xs: "20px",
                              md: "0px",
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <Link to="/plans" style={styles.menus}>
                              MEAL PLANS
                            </Link>
                          </Typography>
                          <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <Link to="/work" style={styles.menus}>
                              HOW IT WORKS
                            </Link>
                          </Typography>
                          <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <a
                              style={styles.menus}
                              onClick={() => {
                                if (title === "home") {
                                  scrollToElement2();
                                } else {
                                  history.push("/home?pricing=true");
                                  // window.scroll(0, 2600);
                                  scrollToElement2();
                                }
                              }}
                            >
                              PACKAGES
                            </a>
                          </Typography>
                          <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <Link to="/Meals" style={styles.menus}>
                              {/* DELIVERY */}
                              Delivery
                            </Link>
                          </Typography>
                          {/* <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <Link to="/about" style={styles.menus}>
                              ABOUT US
                            </Link>
                          </Typography> */}
                          <Typography
                            variant="body1"
                            style={styles.menusParent}
                          >
                            <Link to="/ContactUs" style={styles.menus}>
                              CONTACT US
                            </Link>
                          </Typography>
                          {/* <Typography variant="body1">
                            <Link style={styles.menus}>DOWNLOAD NOW</Link>
                          </Typography> */}
                        </Grid>
                        <Grid item md={1.8}>
                          {window.localStorage.getItem(
                            `${window.location.hostname}.login`
                          ) === null && (
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: FONTS,
                                  background: COLORS.Orange,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // fontSize: "20pt",
                                  fontSize: "12pt",
                                  fontWeight: "600",
                                  height: 56,
                                }}
                              >
                                <p
                                  style={{
                                    // fontFamily: FONTS_RC,
                                    fontFamily: FONTS_INTER,
                                    textDecoration: "none",
                                    // color: COLORS.TitleColor5,
                                    color: "white",

                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push("/SignUp");
                                    if (matches2.matches === true) {
                                      window.scroll(0, 350);
                                    } else {
                                      window.scroll(0, 1000);
                                    }
                                  }}
                                >
                                  Register Now
                                </p>
                              </Typography>
                            )}
                        </Grid>
                        <Grid item md={1} style={{ marginLeft: 10 }}>
                          {window.localStorage.getItem(
                            `${window.location.hostname}.login`
                          ) === null && (
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: FONTS,
                                  // background: COLORS.Orange,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "12pt",
                                  fontWeight: "600",
                                  height: 56,
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: FONTS_INTER,
                                    textDecoration: "none",
                                    // color: COLORS.TitleColor5,
                                    color: "white",

                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // window.scroll(0, 0);
                                    var x =
                                      window.matchMedia("(max-width: 600px)");
                                    console.log("--------->", x);
                                    if (x.matches) {
                                      history.push("/login");
                                    } else {
                                      setOpenLogin(!openLogin);
                                    }
                                  }}
                                >
                                  Sign In
                                </p>
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{
                            float: "right",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {/* PersonIcon */}
                            <img
                              src={PERSON_ICON}
                              style={{
                                marginRight: 15,
                                cursor: "pointer",
                                background: "white",
                                borderRadius: "50%",
                                width: 34,
                                height: 34,
                                fontSize: 28,
                                color: "black",
                              }}
                              onClick={() => {
                                // window.scroll(0, 0);
                                var x = window.matchMedia("(max-width: 600px)");

                                if (x.matches) {
                                  history.push("/login");
                                } else {
                                  setOpenLogin(!openLogin);
                                }
                              }}
                            />
                            {user !== null && openLogin === true && (
                              <div
                                style={{
                                  position: "absolute",
                                  marginTop: 45,
                                  right: "5%",
                                }}
                              >
                                <ProfilePopup
                                  open={openLogin}
                                  handleClose={() => setOpenLogin(false)}
                                />{" "}
                              </div>
                            )}
                          </div>
                          <div>
                            <div
                              style={{
                                zIndex: 1,
                                position: "absolute",
                                color: "white",
                                border: "0.8px solid white",
                                fontSize: "7pt",
                                marginTop: -7,
                                marginLeft: 18,

                                width: 16,
                                height: 16,
                                borderRadius: "50%",
                                background: COLORS.Primary,
                                display: "grid",
                                placeItems: "center",
                                fontFamily: "Inter",
                              }}
                            >
                              {cart}
                            </div>
                            <Link
                              to="/cart"
                              style={{
                                textDecorationLine: "none",
                              }}
                            >
                              <img
                                src={SHOPPING_ICON}
                                style={{
                                  cursor: "pointer",
                                  background: "white",
                                  borderRadius: "50%",
                                  // padding: 58,
                                  // fontSize: 22,
                                  width: 34,
                                  height: 34,
                                  color: "black",
                                  marginBottom: -7,
                                }}
                              />
                              {/* <ShoppingCartRoundedIcon
                                style={{
                                  cursor: "pointer",
                                  background: "white",
                                  borderRadius: "50%",
                                  padding: 58,
                                  fontSize: 22,
                                  width: 28,
                                  height: 28,
                                  color: "black",
                                  marginBottom: -7,
                                }}
                              /> */}
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </Grid>
                </Grid>
              </AppBar>
            </Grid>
            <Grid
              xs={12}
              sx={{
                marginTop: {
                  xs: "150px",
                  sm:"200px",
                  lg:"0px"
                }
              }}
            // style={{
            //   marginTop: matches2?.matches ? "150px" : 0,
            // }}
            >
              {!bannerShow && (
                <Grid item xs={12}>
                  <Grid xs={12} container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "0px",

                        display: "flex",
                        alignItems: "center",
                        backgroundPosition: "center",
                        minHeight:
                          matches2.matches === true
                            ? title === "home"
                              ? "400px"
                              : "238px"
                            : "400px",
                      }}
                    >
                      <>
                        {src ? (
                          <img
                            src={src}
                            style={{
                              width: "100vw",
                              height:
                                matches2.matches === true
                                  ? title === "home"
                                    ? "400px"
                                    : title === "OUR HEALTHY MEAL PLANS" ? "210px" : "238px"
                                  : "auto",

                              objectFit:
                                matches2.matches === true ? "cover" : "contain",
                              zIndex: -1,
                              opacity:
                                title === "home" && matches2.matches === true
                                  ? 0.5
                                  : 1,
                            }}
                          />
                        ) : (
                          <>
                            {cover.image ? (
                              <img
                                src={`${IMAGE_SERVER_URL_DEV}${cover.image}`}
                                style={{
                                  width: "100vw",
                                  height:
                                    matches2.matches === true
                                      ? title === "home"
                                        ? "400px"
                                        : "238px"
                                      : "auto",
                                  objectFit:
                                    matches2.matches === true
                                      ? "cover"
                                      : "contain",

                                  objectPosition:
                                    matches2.matches === true ? "65%" : "none",

                                  zIndex: -1,
                                  opacity:
                                    title === "home" &&
                                      matches2.matches === true
                                      ? 0.5
                                      : 1,
                                }}
                              />
                            ) : (
                              <img
                                src={HOME_IMG}
                                style={{
                                  width: "100vw",
                                  height:
                                    matches2.matches === true
                                      ? title === "home"
                                        ? "400px"
                                        : "238px"
                                      : "auto",

                                  objectFit:
                                    matches2.matches === true
                                      ? "cover"
                                      : "contain",
                                  objectPosition:
                                    matches2.matches === true ? "65%" : "none",
                                  // opacity: 0.7,
                                  zIndex: -1,
                                  opacity:
                                    title === "home" &&
                                      matches2.matches === true
                                      ? 0.5
                                      : 1,
                                }}
                              />
                            )}
                          </>
                        )}
                      </>

                      {sBanner && (
                        <Grid
                          container
                          xs={12}
                          spacing={0}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            zIndex: 1,
                          }}
                        >
                          <Grid item xs={11} style={{ padding: "0px 16px" }}>
                            <Grid container xs={12}>
                              <Grid
                                item
                                lg={size}
                                xs={12}
                                style={{
                                  textAlign:
                                    matches2.matches && title === "home"
                                      ? "center"
                                      : "left",
                                  padding: "25px 1px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems:
                                    matches2.matches && title === "home"
                                      ? "center"
                                      : "flex-start",
                                }}
                              >
                                <div>
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        lg: "56pt",
                                        md: "56pt",
                                        sm: "2rem",
                                        // xs: "40pt",
                                        xs: "32pt",
                                      },
                                    }}
                                    style={{
                                      fontWeight: "500",
                                      // color: COLORS.TitleColor,
                                      color: COLORS.Primary,
                                      fontFamily: FONTS_ANOTON,
                                      fontSize: matches2.matches
                                        ? "32pt"
                                        : "56pt",
                                      lineHeight:
                                        matches2.matches === true ? 1.15 : 1.25,

                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {title === "home" ? (
                                      <>
                                        {matches2.matches === true ? (
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                lg: "56pt",
                                                md: "56pt",
                                                sm: "2rem",

                                                xs: "32pt",
                                              },
                                            }}
                                            style={{
                                              fontWeight: "500",
                                              color: COLORS.Primary,
                                              // color: "black",
                                              fontFamily: FONTS_ANOTON,
                                              fontSize: matches2.matches
                                                ? "32pt"
                                                : "56pt",

                                              lineHeight: 1.15,
                                            }}
                                          >
                                            {/* Time to say Hello to a Healthy
                                            lifestyle! */}
                                            {cover.caption1}{" "}{cover.caption2}
                                          </Typography>
                                        ) : (
                                          <>
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  lg: "56pt",
                                                  md: "56pt",
                                                  sm: "2rem",
                                                  // xs: "40pt",
                                                  xs: "32pt",
                                                },
                                              }}
                                              style={{
                                                fontWeight: "500",
                                                color: COLORS.Primary,
                                                fontFamily: FONTS_ANOTON,
                                                fontSize: matches2.matches
                                                  ? "32pt"
                                                  : "56pt",
                                                lineHeight: 1.25,
                                              }}
                                            >
                                              {/* Time to say Hello to a */}
                                              {cover.caption1}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  lg: "56pt",
                                                  md: "56pt",
                                                  sm: "2rem",
                                                  // xs: "40pt",
                                                  xs: "32pt",
                                                },
                                              }}
                                              style={{
                                                fontWeight: "500",
                                                color: COLORS.Primary,
                                                fontFamily: FONTS_ANOTON,
                                                fontSize: matches2.matches
                                                  ? "32pt"
                                                  : "56pt",
                                                lineHeight: 1.25,
                                              }}
                                            >
                                              {/* Healthy lifestyle! */}
                                              {cover.caption2}
                                            </Typography>
                                          </>
                                        )}

                                        <Typography
                                          style={{
                                            // color: COLORS.Primary,
                                            color: "black",
                                            fontFamily: FONTS_INTER,
                                            lineHeight: 1.25,
                                            marginTop: 10,
                                            fontWeight: "600",
                                            fontSize: matches2.matches
                                              ? "15pt"
                                              : "18pt",
                                            textTransform: "none",
                                          }}
                                          sx={{
                                            fontSize: {
                                              lg: "18pt",

                                              xs: "15pt",
                                            },
                                          }}
                                        >
                                          Enjoy doorstep delivery of your orders once a week
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        {title ===
                                          "HOW WE DELIVER  YOUR WEEKLY MEALS ?" ? (
                                          matches2.matches === true ? (
                                            <>
                                              {/* How we <br />
                                              deliver */}
                                              {/* How to <br />
                                              pick up */}
                                              Convenient <br /> home delivery
                                            </>
                                          ) : (
                                            <>
                                              {/* HOW WE DELIVER <br />
                                              YOUR WEEKLY MEALS ? */}
                                              {/* How we deliver ? */}
                                              {/* How to pick up ? */}
                                              Convenient home delivery
                                            </>
                                          )
                                        ) : (
                                          <>
                                            {title ===
                                              "OUR HEALTHY MEAL PLANS" ? (
                                              matches2.matches === true ? (
                                                <>
                                                  Healthy <br />
                                                  meal plans
                                                </>
                                              ) : (
                                                <>
                                                  {/* OUR HEALTHY <br />
                                                  MEAL PLANS */}
                                                  Healthy <br />
                                                  meal plans
                                                </>
                                              )
                                            ) : (
                                              <>
                                                {title === "HOW IT WORKS ?" ? (
                                                  matches2.matches === true ? (
                                                    <>
                                                      How it <br />
                                                      works
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* OUR HEALTHY <br />
                                                      MEAL PLANS */}
                                                      How it works
                                                    </>
                                                  )
                                                ) : (
                                                  title
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Typography>
                                </div>
                                {order && (
                                  <div>
                                    {/* {window.localStorage.getItem(
                                      `${window.location.hostname}.login`
                                    ) === null && ( */}
                                    <Button
                                      variant="outlined"
                                      style={{
                                        backgroundColor: COLORS.Orange,

                                        color: "white",
                                        outline: "none",

                                        fontFamily: FONTS_INTER,
                                        // height: 34,
                                        borderRadius: 5,
                                        // maxWidth: 170,
                                        letterSpacing: "unset",
                                        fontWeight: "400",
                                        border: "none",
                                        fontSize: matches2.matches === true ? "9pt" : "12pt",
                                        height: matches2.matches === true ? "29px" : "38px",
                                        maxWidth: 200,
                                        width: matches2.matches === true ? 150 : 200,
                                        padding: "5px 15px",
                                        marginTop: matches2?.matches
                                          ? "25px"
                                          : "24px",
                                      }}
                                      onClick={() => {
                                        // history.push("/SignUp");

                                        if (window.localStorage.getItem(
                                          `${window.location.hostname}.login`
                                        ) === null) {
                                          if (matches2.matches === true) {
                                            history.push("/login");
                                            // window.scroll(0, 350);
                                          } else {
                                            setOpenLogin(true);
                                            // window.scroll(0, 1000);
                                          }
                                        } else {
                                          history.push("/plans");
                                        }
                                      }}
                                    >
                                      Create Meal Plan
                                    </Button>
                                    {/* )} */}
                                  </div>
                                )}
                              </Grid>
                              <Grid item xs={6}></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {user === null && (
        <LoginPopup open={openLogin} handleClose={() => setOpenLogin(false)} />
      )}
      {!bannerShow && (
        <Grid
          xs={12}
          container
          spacing={0}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Steps />
        </Grid>
      )}
    </>
  );
}
