import React from "react";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import IMG from "../../Assets/sample.png";
import { COLORS } from "../../Constants/Colors";
import { FONTS_AC, FONTS_INTER } from "../../Constants";
import CloseIcon from "@mui/icons-material/Close";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";
function MealsDetails({ openViewMore, setOpenViewMore, id }) {
  var matches = window.matchMedia("(max-width: 900px)");
  var matches2 = window.matchMedia("(max-width: 600px)");
  const styles = {
    minCalories: {
      width: matches.matches ? "7vw" : "6vw",
      height: matches.matches ? "7vw" : "6vw",
      background: COLORS.Primary,
      padding: "17px",
      borderRadius: "50%",
      border: matches.matches ? "2px solid white" : "4px solid white",
      // outline: matches.matches
      //   ? `2px solid ${COLORS.Primary}`
      //   : `3px solid ${COLORS.Primary}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      // margin: 2,
    },
    minCaloriesText1: {
      fontSize: "13pt",
      fontWeight: "600",
      fontFamily: FONTS_AC,
      lineHeight: 1,
      color: "white",
      whiteSpace: "nowrap"
    },
    minCaloriesText2: {
      fontSize: "7pt",
      fontFamily: FONTS_INTER,
      color: "white",
    },
  };
  //   const [products, setProducts] = React.useState([]);

  //   const companyGet = () => {

  //     getProductbyId(id)
  //       .then((res) => {
  //         console.log(res.data);
  //         setProducts(res.data);
  //         // setcompany(res?.data[res.data.length - 1]);

  //       })
  //       .catch((error) => {
  //         console.log("error", error.response);
  //         console.log("get product", error.response.data.message);

  //       });

  // };

  // React.useEffect(() => {
  //   companyGet();

  // }, []);
  return (
    <Modal
      open={openViewMore}
      onClose={() => setOpenViewMore(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: matches.matches ? "85vw" : "60vw",
          maxHeight: "90%",
          overflowY: "scroll",
          background: matches.matches ? COLORS.GrayDark3 : "white",
          border: "2px solid #ff",
          boxShadow: 24,
          padding: matches.matches ? 18 : 24,
          borderRadius: "4px",
        }}
      >
        <Typography
          style={{
            justifyContent: "flex-end",
            width: "100%",
          }}
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <CloseIcon
            sx={{
              color: COLORS.TitleColor3,
              fontSize: 18,
              marginBottom: 1,
              cursor: "pointer"
            }}
            onClick={() => setOpenViewMore(false)}
          />
        </Typography>
        <Grid
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            background: "white",
            flexDirection: matches.matches ? "column" : "row",
            background: "white",
          }}
        >
          <Grid md={4.5} xs={12}>
            <img
              src={`${IMAGE_SERVER_URL_DEV}${id.image}`}
              style={{
                width: "100%",
                // height: "100%",
                objectFit: matches.matches ? "cover" : "contain",
                height: matches2.matches ? "40vh" :  "auto",
              }}
            />
          </Grid>
          <Grid
            md={7.5}
            xs={12}
            style={{
              padding: matches?.matches ? "2% 3%" : 0,
            }}
          >
            <Typography
              sx={{
                // width: "100%",
                // height: "100%",
                paddingLeft: {
                  md: "66px",
                  xs: "0px",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              component="div"
            >
              {/* <Typography
                style={{
                  justifyContent: "flex-end",
                  width: "100%",
                }}
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                }}
              >
                <CloseIcon
                  sx={{
                    color: COLORS.TitleColor3,
                    fontSize: 16,
                    cursor: "pointer"
                  }}
                  onClick={() => setOpenViewMore(false)}
                />
              </Typography> */}
              <Typography
                sx={{
                  fontFamily: FONTS_AC,
                  fontSize: matches.matches ? "18pt" : "16pt",
                  color: COLORS.Primary,
                  fontWeight: "600",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                {/* Kurakkan String Hoppers with curries */}
                {id?.productName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: FONTS_INTER,
                  fontSize: matches?.matches ? "12pt" : "9pt",
                  color: COLORS.TextColor,
                  textAlign: matches?.matches ? "center" : "left",
                  fontWeight: matches?.matches ? "500" : "400",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                {/* Pumpkin, carrot, green beans and chickpeas cooked in a Sri
                Lankan style coconut curry sauce, topped with toasted cashews,
                coriander and served with 15 String Hoppers */}
                {id.mealDescription}
              </Typography>
              <Typography
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                  },
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginBottom: 2,
                }}
              >
                <Typography
                  style={{
                    border: matches.matches
                      ? `2px solid ${COLORS.Primary}`
                      : `3px solid ${COLORS.Primary}`,
                    borderRadius: "50%",

                  }}
                >
                  <Typography style={styles.minCalories}>
                    <Typography style={styles.minCaloriesText1}>
                      {id.calories1}
                    </Typography>
                    <Typography style={styles.minCaloriesText2}>
                      Calory(kcal)
                    </Typography>
                  </Typography>
                </Typography>
                <Typography
                  style={{
                    border: matches.matches
                      ? `2px solid ${COLORS.Primary}`
                      : `3px solid ${COLORS.Primary}`,
                    borderRadius: "50%",
                  }}
                >
                  <Typography style={styles.minCalories}>
                    <Typography style={styles.minCaloriesText1}>
                      {id.calories2}
                    </Typography>
                    <Typography style={styles.minCaloriesText2}>
                      Protein(g)
                    </Typography>
                  </Typography>
                </Typography>
                <Typography
                  style={{
                    border: matches.matches
                      ? `2px solid ${COLORS.Primary}`
                      : `3px solid ${COLORS.Primary}`,
                    borderRadius: "50%",
                  }}
                >
                  <Typography style={styles.minCalories}>
                    <Typography style={styles.minCaloriesText1}>
                      {id.calories3}
                    </Typography>
                    <Typography style={styles.minCaloriesText2}>
                      Carb(g)
                    </Typography>
                  </Typography>
                </Typography>
                <Typography
                  style={{
                    border: matches.matches
                      ? `2px solid ${COLORS.Primary}`
                      : `3px solid ${COLORS.Primary}`,
                    borderRadius: "50%",
                  }}
                >
                  <Typography style={styles.minCalories}>
                    <Typography style={styles.minCaloriesText1}>
                      {id.calories4}
                    </Typography>
                    <Typography style={styles.minCaloriesText2}>
                      Fat(g)
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
              <Divider
                sx={{
                  width: "80%",
                  height: "1px",
                  background: COLORS.Primary,
                  marginBottom: "8px",
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: FONTS_INTER,
                  fontSize: matches.matches ? "15pt" : "9pt",
                  color: COLORS.Orange4,
                  textAlign: "center",
                  fontWeight: "600",
                  marginBottom: "10px",
                  marginTop: matches.matches ? "10px" : "0px",
                }}
              >
                INGREDIANTS
              </Typography>
              <Divider
                sx={{
                  width: "80%",
                  height: "1px",
                  // background: COLORS.Primary,
                  margin: "5px 0px 20px",
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: FONTS_INTER,
                  fontSize: matches.matches ? "12pt" : "8pt",
                  color: matches.matches ? COLORS?.GrayDark7 : COLORS.TextColor,
                  // textAlign:"center",
                  fontWeight: "400",
                  marginBottom: matches?.matches ? "25px" : "10px",
                  width: "100%",
                  textAlign: matches?.matches ? "center" : "left",
                }}
              >
                {id.ingredient}
              </Typography>
              <Typography
                sx={{
                  fontFamily: FONTS_INTER,
                  fontSize: matches.matches ? "12pt" : "8pt",
                  color: COLORS.TextColor3,
                  // textAlign:"center",
                  fontWeight: "400",
                  marginBottom: matches?.matches ? "20px" : "10px",
                  width: "100%",
                  textAlign: matches?.matches ? "center" : "left",
                }}
              >
                {/* <b>ALLERGENS</b>: Contains Cashew, Wheat, Gluten. Made within
                the same facility that also processes products with Wheat,
                Gluten, Milk, Egg, Tree Nuts, Peanut, Fish, Sesame, Soy & Lupin. */}
                <b>ALLERGENS</b>: {id.allergyDescription}
              </Typography>
              <Typography
                sx={{
                  fontFamily: FONTS_INTER,
                  fontSize: matches.matches ? "12pt" : "8pt",
                  color: matches.matches ? COLORS?.GrayDark7 : COLORS.TextColor,
                  // textAlign:"center",
                  fontWeight: "400",
                  width: "100%",
                  textAlign: matches?.matches ? "center" : "left",
                  marginBottom: matches?.matches ? "20px" : "10px",
                }}
              >
               Our nutritional information takes into account all sauces, dressings, condiments, and garnishes 
               included in a dish. We have made every effort to ensure that all information is accurate at the time of 
               publication. However, please be aware that the information provided may be subject to change without notice.
                {/* {id.mealDescription} */}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {/* {[1, 2, 3, 4].map((item) => {
            return (
              <Typography
                sx={{
                  width: "65px",
                  height: "65px",
                  background: COLORS.Primary,
                  padding: "17px",
                  borderRadius: "50%",
                  border: "4px solid white",
                  outline: `3px solid ${COLORS.Primary}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30pt",
                    fontWeight: "600",
                    fontFamily: FONTS_AC,
                    lineHeight: "33px",
                    color: "white",
                  }}
                >
                  470
                </Typography>
                <Typography
                  sx={{
                    fontSize: "9pt",
                    fontFamily: FONTS_INTER,
                    color: "white",
                  }}
                >
                  Calories
                </Typography>
              </Typography>
            );
          })} */}
          <Typography
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                width: "65px",
                height: "65px",
                background: COLORS.Primary,
                padding: "17px",
                borderRadius: "50%",
                border: "4px solid white",
                outline: `3px solid ${COLORS.Primary}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "22pt",
                  fontWeight: "600",
                  fontFamily: FONTS_AC,
                  lineHeight: "33px",
                  color: "white",
                  whiteSpace: "nowrap"
                }}
              >
                {id.calories1}
              </Typography>
              <Typography
                sx={{
                  fontSize: "9pt",
                  fontFamily: FONTS_INTER,
                  color: "white",
                }}
              >
                Calory(kcal)
              </Typography>
            </Typography>
            <Typography
              sx={{
                width: "65px",
                height: "65px",
                background: COLORS.Primary,
                padding: "17px",
                borderRadius: "50%",
                border: "4px solid white",
                outline: `3px solid ${COLORS.Primary}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "22pt",
                  fontWeight: "600",
                  fontFamily: FONTS_AC,
                  lineHeight: "33px",
                  color: "white",
                }}
              >
                {id.calories2}
              </Typography>
              <Typography
                sx={{
                  fontSize: "9pt",
                  fontFamily: FONTS_INTER,
                  color: "white",
                }}
              >
                Protein (g)
              </Typography>
            </Typography>
            <Typography
              sx={{
                width: "65px",
                height: "65px",
                background: COLORS.Primary,
                padding: "17px",
                borderRadius: "50%",
                border: "4px solid white",
                outline: `3px solid ${COLORS.Primary}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "22pt",
                  fontWeight: "600",
                  fontFamily: FONTS_AC,
                  lineHeight: "33px",
                  color: "white",
                }}
              >
                {id.calories3}
              </Typography>
              <Typography
                sx={{
                  fontSize: "9pt",
                  fontFamily: FONTS_INTER,
                  color: "white",
                }}
              >
                Carb (g)
              </Typography>
            </Typography>
            <Typography
              sx={{
                width: "65px",
                height: "65px",
                background: COLORS.Primary,
                padding: "17px",
                borderRadius: "50%",
                border: "4px solid white",
                outline: `3px solid ${COLORS.Primary}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "22pt",
                  fontWeight: "600",
                  fontFamily: FONTS_AC,
                  lineHeight: "33px",
                  color: "white",
                }}
              >
                {id.calories4}
              </Typography>
              <Typography
                sx={{
                  fontSize: "9pt",
                  fontFamily: FONTS_INTER,
                  color: "white",
                }}
              >
                Fat (g)
              </Typography>
            </Typography>
          </Typography>
          {/* <Typography
                sx={{
                  width: "65px",
                  height: "65px",
                  background: COLORS.Primary,
                  padding: "17px",
                  borderRadius: "50%",
                  border: "4px solid white",
                  outline: `3px solid ${COLORS.Primary}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30pt",
                    fontWeight: "600",
                    fontFamily: FONTS_AC,
                    lineHeight: "33px",
                    color: "white",
                  }}
                >
                   {id.calories5}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "9pt",
                    fontFamily: FONTS_INTER,
                    color: "white",
                  }}
                >
                  Calories
                </Typography>
              </Typography> */}
        </Grid>
      </Box>
    </Modal>
  );
}

export default MealsDetails;
