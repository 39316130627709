import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "./Footer";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import Slider from "react-slick";

import img from "../../Assets/sample.png";
import img2 from "../../Assets/sample2.JPG";
import img3 from "../../Assets/sample21.JPG";
import img4 from "../../Assets/banner1.png";
import img5 from "../../Assets/banner2.png";
import img6 from "../../Assets/banner3.png";
import img7 from "../../Assets/banner4.png";
import VEG from "../../Assets/Veg4.png";
import VEG1 from "../../Assets/vegi7.png";
import Slider1 from "../../Assets/Slider/1.png";
import Slider2 from "../../Assets/Slider/2.png";
import Slider3 from "../../Assets/Slider/6.png";
import Slider4 from "../../Assets/Slider/4.png";
import Slider5 from "../../Assets/Slider/5.png";
// import Slider6 from "../../Assets/Slider/6.png";
import GC from "../../Assets/GreenComa.png";
import OC from "../../Assets/OrangeComa.png";
import SELECTOR from "../../Assets/OrangeComa.png";
import NO_REGISTER from "./IMG4.png";
import NO_REGISTER1 from "./IMG6.png";
import NO_REGISTER2 from "./IMG7.png";
import BIKE_IMG from "./IMG2.png";

import "../../css/image.css";
import "./floating.css";

import {
  FONTS,
  FONTS_INTER,
  FONTS_AC,
  FONTS_RC,
  FONTS_CR,
  FONTS_LEAGUE,
  FONTS_CHEWY,
  FONTS_CHALISTOGO,
  FONTS_RS,
  FONTS_TEL,
  FONTS_ANOTON,
} from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import ErrorPopup from "../Models/errorPopup.jsx";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  getLogo,
  getCover,
  getIcon,
  getCaption,
  getBrand,
  getTestimonial,
  getPackages,
  CurrentUser,
  Categories,
} from "../../Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";

import "./home.css";
import DeliverLabel from "../../Componets/DeliverLabel";
import { downPackageToUser, packageToUser } from "../../Services/API/Put";

import Steps from "../../Componets/Steps";
import AuthContext from "../../Context/AuthContext";
import StepsContext from "../../Context/StepsContext";
import CartContext from "../../Context/CartContext";
import LoginPopup from "../Models/LoginPopup";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";
import RegistrationPopup from "../Models/RegistrationPopup";
import RegistrationPopup2 from "../Models/RegistrationPopup3";
import Bike from "./bike.png";
import { height } from "@mui/system";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useRef } from "react";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxWidth: 300,
};
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.Primary,
    },
    secondary: {
      main: "#f44336",
    },
  },
});

function Home() {
  const mySlider = React.useRef(null);
  const matches = useMediaQuery("(max-width:600px)");
  const matches900 = useMediaQuery("(max-width:900px)");
  const matches1200 = useMediaQuery("(max-width:1200px)");
  var mat425 = window.matchMedia("(max-width: 600px)");
  var mat920 = window.matchMedia("(min-width: 1920px)");
  var mat340 = window.matchMedia("(max-width: 1340px)");
  const { setSteps } = useContext(StepsContext);

  const errScroll = useRef();

  const errScroll2 = useRef();

  // const { setMenuCategory, scrollToElement } = useContext(CartContext);

  const [logo, setlogo] = useState("");
  const [cover, setCover] = useState("");
  const [icons, setIcons] = useState([]);
  const [captions, setCaptions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [testi, setTesti] = useState([]);
  const [packages, setPackages] = useState([]);
  const [userId, setuserId] = useState(null);
  const [openLogin, setOpenLogin] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = useState([]);
  const [lOpen, setlOpen] = useState(false);
  const history = useHistory();
  const [openRegister, setopenRegister] = useState(false);
  const [openRegForm, setOpenRegForm] = useState(false);
  const [openRegForm2, setOpenRegForm2] = useState(false);
  const [openSuccess, setopenSuccess] = React.useState(false);

  const [customer, setCustomer] = useState(null);
  const [downPkg, setdownPkg] = useState(false);
  const [upPkg, setupPkg] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const { setMenuCategory, testRef2, scrollToElement2 } =
    useContext(CartContext);

  const handleClose = () => setOpen(false);

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: matches1200 === true ? 2 : 4,
    slidesToScroll: matches1200 === true ? 2 : 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    // rows: matches === true ? 2 : 1,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: matches1200 === true ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  React.useEffect(() => {
    let pQuery = query.get("kitchen");

    console.log("KKKDDDd", pQuery)

    if (pQuery === "true") {

      setTimeout(() => {
        errScroll.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        history.push("/home")
      }, 1000);

    }


  }, [query.get("kitchen")])

  React.useEffect(() => {
    let pQuery = query.get("retailer");

    console.log("KKKDDDd", pQuery)

    if (pQuery === "true") {

      setTimeout(() => {
        errScroll2.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        history.push("/home")
      }, 1000);

    }


  }, [query.get("retailer")])

  React.useEffect(() => {
    let pQuery = query.get("login");

    console.log("KKKDDDd", pQuery)

    if (pQuery === "true") {

      setTimeout(() => {
        setOpenLogin(true);
        history.push("/home")
      }, 0);

    }


  }, [query.get("login")])

  const assignPackage = (pkgId, meals) => {
    console.log("pkg id >>>", pkgId);

    const obj = {
      packageId: pkgId,
    };

    if (!customer) {

      if (mat425.matches === true) {
        history.push("/login");
        window.scroll({
          behavior: "smooth",
          left: 0,
          top: 0,
        });
      } else {
        setOpenLogin(true);
      }

    } else if (customer?.noofmeals < meals || customer?.packageId === null) {
      packageToUser(obj, userId, "update")
        .then((res) => {
          console.log(res.data);
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setOpen(false);
          // setAlertClass("success");
          // setMsg("Package Assigned to User");
          // getCurrentUser();
          getUser();
          setTimeout(() => {

            history.push("/plans");
            window.scroll(0, 0);
          }, 0);
          setSteps(3);
          // setTimeout(() => {
          //   history.push("/plans");
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");

          setMsg(error.response.data.message);
        });

    } else {

      setdownPkg(true);
      setupPkg(pkgId);
      return;



    }

    // packageToUser(obj, userId, " ")
    //   .then((res) => {
    //     console.log(res.data);
    //     // setOpenToast(true);
    //     // setColor(COLORS.Primary);
    //     // setAlertClass("success");
    //     // setMsg("Package selected successfully!");
    //     setTimeout(() => {

    //       history.push("/plans");
    //       window.scroll(0, 0);
    //     }, 0);
    //     setSteps(3);
    //   })
    //   .catch((error) => {
    //     console.log("error", error.response);
    //     console.log("current package error", error.response.data.message);
    //     if (error.response.data.statusCode === 401) {
    //       // setOpenToast(true);
    //       // setColor(COLORS.Orange);
    //       // setAlertClass("error");
    //       // setMsg(error.response.data.message);
    //       setTimeout(() => {
    //         if (mat425.matches === true) {
    //           history.push("/login");
    //           window.scroll({
    //             behavior: "smooth",
    //             left: 0,
    //             top: 0,
    //           });
    //         } else {
    //           setOpenLogin(true);
    //         }
    //         // history.push("/login");
    //         // window.location.reload();
    //       }, 0);
    //     } else {
    //       setOpenToast(true);
    //       setColor(COLORS.Orange);
    //       setAlertClass("error");
    //       setMsg(error.response.data.message);
    //     }
    //   });
  };

  const getUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          setuserId(cData?.id);
          setCustomer(cData);
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  const getDeliverycharge = () => {

    const Arr = JSON.parse(localStorage.getItem("orderDates"))

    let Friarr = []

    let Tuearr = []

    let delivery = 5

    let friday, tuesday = ""

    Arr?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(1, "days")

          break;

        case "Thu":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(2, "days")

          break;

        case "Fri":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(3, "days")

          break;


      }



    });


    let delType = localStorage.getItem("delType");


    // if (delType === "delivery") {

    //   if (Friarr.length > 0) {
    //     delivery += 5;
    //   }

    //   if (Tuearr.length > 0) {
    //     delivery += 5;
    //   }

    // }

    return delivery;



  }

  const getSuccessDates = () => {

    const Arr = JSON.parse(localStorage.getItem("orderDates"))

    let Friarr = []

    let Tuearr = []

    let friday, tuesday = ""

    Arr?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      // switch (day) {
      //   case "Sat":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(1, "days")

      //     break;

      //   case "Sun":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(2, "days")

      //     break;

      //   case "Mon":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(3, "days")

      //     break;

      //   case "Tue":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(4, "days")

      //     break;

      //   case "Wed":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(1, "days")

      //     break;

      //   case "Thu":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(2, "days")

      //     break;

      //   case "Fri":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(3, "days")

      //     break;


      // }

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Friarr.push(item2);

          friday = moment(item2).subtract(5, "days")

          break;

        case "Thu":

          Friarr.push(item2);

          friday = moment(item2).subtract(6, "days")

          break;

        case "Fri":

          Friarr.push(item2);

          friday = moment(item2)

          break;


      }



    });

    return moment(friday).format("Do dddd MMMM");

    // return (

    //   <>
    //     <p>
    //       {
    //         tuesday > friday ?

    //           (
    //             <>
    //               {Friarr.length > 0 &&

    //                 <>
    //                   {
    //                     getDeliverycharge() > 0 ?

    //                       `${moment(friday).format("Do dddd")} - You will receive `

    //                       :

    //                       `${moment(friday).format("Do dddd")} - You can collect `


    //                   }

    //                   {Friarr?.map((item2, index) => {

    //                     return (
    //                       <>
    //                         {
    //                           index === Friarr.length - 2 ?

    //                             `${moment(item2).format("Do MMM")} & `

    //                             :
    //                             index != Friarr.length - 1 ?

    //                               `${moment(item2).format("Do MMM")},`

    //                               :

    //                               `${moment(item2).format("Do MMM")} Meals`
    //                         }

    //                       </>
    //                     )

    //                   })

    //                   }

    //                 </>

    //               }

    //               <br /><br />


    //               {Tuearr.length > 0 &&

    //                 <>
    //                   {

    //                     getDeliverycharge() > 0 ?
    //                       `${moment(tuesday).format("Do ddd")} - You will receive `

    //                       :

    //                       `${moment(tuesday).format("Do ddd")} - You can collect `

    //                   }

    //                   {Tuearr?.map((item2, index) => {

    //                     return (
    //                       <>
    //                         {
    //                           index === Tuearr.length - 2 ?

    //                             `${moment(item2).format("Do MMM")} & `

    //                             :
    //                             index != Tuearr.length - 1 ?

    //                               `${moment(item2).format("Do MMM")},`

    //                               :

    //                               `${moment(item2).format("Do MMM")} Meals`
    //                         }

    //                       </>
    //                     )

    //                   })

    //                   }

    //                 </>

    //               }
    //             </>
    //           )

    //           :
    //           (
    //             <>
    //               {Tuearr.length > 0 &&

    //                 <>
    //                   {

    //                     getDeliverycharge() > 0 ?
    //                       `${moment(tuesday).format("Do ddd")} - You will receive `

    //                       :

    //                       `${moment(tuesday).format("Do ddd")} - You can collect `

    //                   }

    //                   {Tuearr?.map((item2, index) => {

    //                     return (
    //                       <>
    //                         {
    //                           index === Tuearr.length - 2 ?

    //                             `${moment(item2).format("Do MMM")} & `

    //                             :
    //                             index != Tuearr.length - 1 ?

    //                               `${moment(item2).format("Do MMM")},`

    //                               :

    //                               `${moment(item2).format("Do MMM")} Meals`
    //                         }

    //                       </>
    //                     )

    //                   })

    //                   }

    //                 </>

    //               }

    //               <br /> <br />

    //               {Friarr.length > 0 &&

    //                 <>
    //                   {
    //                     getDeliverycharge() > 0 ?

    //                       `${moment(friday).format("Do dddd")} - You will receive `

    //                       :

    //                       `${moment(friday).format("Do dddd")} - You can collect `


    //                   }

    //                   {Friarr?.map((item2, index) => {

    //                     return (
    //                       <>
    //                         {
    //                           index === Friarr.length - 2 ?

    //                             `${moment(item2).format("Do MMM")} & `

    //                             :
    //                             index != Friarr.length - 1 ?

    //                               `${moment(item2).format("Do MMM")},`

    //                               :

    //                               `${moment(item2).format("Do MMM")} Meals`
    //                         }

    //                       </>
    //                     )

    //                   })

    //                   }

    //                 </>

    //               }





    //             </>
    //           )


    //       }


    //     </p>
    //   </>
    // );





  }

  const logoGet = () => {
    getLogo()
      .then((res) => {
        console.log(res.data);
        setlogo(res.data[res.data.length - 1].image);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const coverGet = () => {
    getCover()
      .then((res) => {
        console.log(res.data[res.data.length - 1]);
        setCover(res.data[res.data.length - 1]);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const iconGet = () => {
    getIcon()
      .then((res) => {
        console.log(res.data);
        setIcons(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const captionGet = () => {
    getCaption()
      .then((res) => {
        console.log(res.data);
        setCaptions(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const brandGet = () => {
    getBrand()
      .then((res) => {
        console.log(res.data);
        setBrands(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const testimonialGet = () => {
    getTestimonial()
      .then((res) => {
        console.log(res.data);
        setTesti(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };
  const categoryGet = () => {
    Categories()
      .then((res) => {
        const newupdate = res?.data?.map((item) => {
          return {
            ...item,
            maincategory: `${item.maincategory
              .toLowerCase()[0]
              .toUpperCase()}${item.maincategory.toLowerCase().substring(1)}`,
          };
        });
        console.log("---------->", newupdate);
        const lnewUpdate = newupdate?.map((item) => {
          console.log("bbbbb>>>", `${item?.maincategory}`.toUpperCase())
          let img = "";
          switch (`${item?.maincategory}`.toUpperCase()) {
            case "HEALTHY DIET":
              img = Slider2;
              break;
            case "DIABETIC CONTROL":
              img = Slider5;
              break;
            case "CHOLESTEROL CONTROL":
              img = Slider4;
              break;
            case "WEIGHT CONTROL":
              img = Slider3;
              break;

            case "MUSCLE GAIN":
              img = Slider2;
              break;
          }
          return {
            ...item,
            image: img,
          };
        });
        console.log("-lnewUpdate-", lnewUpdate);
        setCategory(lnewUpdate);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
      });
  };

  React.useEffect(() => {

    const test = async () => {
      let pQuery = query.get("kitchen");

      console.log("KKKDDDd", pQuery)

      if (pQuery === "true") {

        setTimeout(() => {
          errScroll.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
          history.push("/home")
        }, 0);

      }

    }


    const test2 = async () => {

      logoGet();
      coverGet();
      iconGet();
      captionGet();
      brandGet();
      testimonialGet();
      getPackage();
      getUser();
      categoryGet();

    }

    const fetchData = async () => {

      await test2();
      // await test();

    }

    fetchData();


  }, []);

  React.useEffect(() => {
    let pQuery = query.get("pricing");

    let sQuery = query.get("payment");

    if (pQuery === "true") {
      // setlOpen(true);
      // setTimeout(() => {
      // }, 2000);
      scrollToElement2();
      setlOpen(false);
      history.push("/home");
      // scrollToElement2();
    }

    if (sQuery === "true") {
      // setlOpen(true);
      // setTimeout(() => {
      // }, 2000);
      setSteps(6);
      localStorage.setItem("order", true);
      setopenSuccess(true);
      // history.push("/home");

      // scrollToElement2();
    }
  }, []);

  return (
    <>
      <Modal
        open={downPkg}
        onClose={() => setdownPkg(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center",
                  }}
                >
                  Package Downgrade
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    Your package will be downgraded and the cart items will be removed.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 10,
                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    {/* For any clarifications please contact our Order Processing
                    Unit between 9 am and 5 pm through (03) 8794 8694 */}
                  </Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    // sx={{ mt: 5, mb: 2 }}
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                      marginTop: "40px"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      // setOpen(true);

                      downPackageToUser({ packageId: upPkg }, userId, "update")
                        .then((res) => {
                          console.log(res.data);
                          setOpenToast(true);
                          setColor(COLORS.Primary);
                          setOpen(false);
                          setAlertClass("success");
                          setMsg("Package Assigned to User");
                          getUser();
                          setdownPkg(false);
                          // setTimeout(() => {
                          //   history.push("/plans");
                          //   window.location.reload();
                          // }, 2000);
                        })
                        .catch((error) => {
                          console.log("error", error.response);
                          console.log("current package error", error.response.data.message);
                          setOpenToast(true);
                          setColor(COLORS.Orange);
                          setAlertClass("error");

                          setMsg(error.response.data.message);
                        });

                    }}
                  >
                    DOWNGRADE NOW
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <RegistrationPopup
        open={openRegForm}
        handleClose={() => setOpenRegForm(false)}
      />
      <RegistrationPopup2
        open={openRegForm2}
        handleClose={() => setOpenRegForm2(false)}
      />
      <LoadingSpinner open={lOpen} />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        onScroll={() => {
          console.log("scrolling...");
        }}
        xs={12}
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}></Grid>
        {/* <Steps /> */}

        {/* <Grid item xs={11} lg={9}
              md={10}style={{ padding: "30px" }}>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    "& 	.Mui-completed ": {
                      color: COLORS.Primary,
                      fontFamily: FONTS_INTER,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid> */}
        <Grid xs={12}>
          <Grid
            xs={12}
            container
            spacing={0}
            style={{
              padding: "50px 30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} style={{ borderRadius: "25px" }}>
              <Typography

                style={{
                  color: COLORS.TitleColor,
                  fontFamily: FONTS_ANOTON,
                  fontWeight: "500",
                  // letterSpacing: -1,
                  fontSize: mat425.matches ? "26pt" : "36pt",
                }}
                sx={{
                  // fontSize: {
                  //   sm: "36pt",
                  //   xs: "26pt",
                  // },
                  margin: {
                    lg: "0px 10px 50px",
                    xs: "0px 10px 20px",
                  },
                }}
              >
                MEAL PLANS
              </Typography>
            </Grid>

            <Grid
              container
              md={11}
            // style={{ display: "flex", justifyContent: "center" }}
            // sx={{
            //   flexWrap: {
            //     md: "nowrap",
            //   },
            //   overflowX: "scroll",
            //   overflowY: "hidden",
            // }}
            >
              <div className="container33333">
                <Slider {...settings}>
                  {category?.map((a) => (
                    <Grid
                      item
                      style={{
                        padding: "15px 25px",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      sx={{
                        minWidth: {
                          // md: "273px",
                        },
                      }}
                      onClick={() => {
                        // scrollToElement()
                        setMenuCategory(a?.mainId);
                        history.push("/plans");
                      }}
                    >
                      <img
                        className="mealsPlanImg"
                        src={a?.image}
                        alt="Third slide"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          width: matches1200 ? "39vw" : "20.5vW",
                          height: matches1200 ? "58vw" : "600px",
                          // borderRadius: 18,
                          borderRadius: 4,
                          marginBottom: "3.5vw",
                          cursor: "pointer",
                        }}
                      // height="250px"
                      />
                    </Grid>
                  ))}
                </Slider>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              xs={12}
              container
              spacing={0}
              style={{
                padding: "50px",
                backgroundColor: COLORS.PrimaryBackground,
                // borderRadius: 12,
              }}
            >
              {icons?.map((data) => {
                return (
                  <Grid
                    item
                    md={3}
                    xs={6}
                    style={{
                      marginBottom: 25,
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`${IMAGE_SERVER_URL_DEV}${data.image}`}
                        style={{
                          // border: "2px solid #FFFFFF",
                          // borderRadius: "none",
                          objectFit: "contain",
                          // background: "white",
                          padding: 3,
                          width: mat425.matches === true ? "50px" : "80px",
                          height: mat425.matches === true ? "50px" : "80px",
                        }}
                      // sx={{
                      //   width: {
                      //     sm: "80px",
                      //     xs: "50px",
                      //   },
                      //   height: {
                      //     sm: "80px",
                      //     xs: "50px",
                      //   },
                      // }}
                      />
                    </div>
                    <Typography
                      style={{
                        color: COLORS.Primary,
                        fontFamily: FONTS_AC,
                        textTransform: "uppercase",
                        marginBottom: 0,
                        marginTop: "10px",
                        lineHeight: 1.1,
                      }}
                      sx={{
                        fontSize: {
                          xs: "16pt",
                          sm: "18pt",
                        },
                      }}
                    >
                      {data.caption1 + " " + data.caption2}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <DeliverLabel />
          {mat340.matches !== true ? <div style={{ marginBottom: "50vh" }}></div> : <></>}

          <Grid item xs={12} id="PRICING">
            <Grid
              container
              spacing={2}
              style={{
                padding: "20px 36px 50px 50px",

                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <div>
                  <Typography
                    ref={testRef2}
                    style={{
                      margin: "35px 0px 25px 0px",
                      color: COLORS.TitleColor,
                      fontFamily: FONTS_ANOTON,
                      fontSize: mat425.matches ? "26pt" : "36pt",
                      fontWeight: "500",
                      // letterSpacing: -1
                    }}
                  // sx={{
                  //   fontSize: {
                  //     sm: "36pt",
                  //     xs: "22pt",
                  //   },

                  // }}
                  >
                    WEEKLY PACKAGES
                  </Typography>
                </div>
              </Grid>

              <Grid
                id="myPackages"
                // container
                xs={12}
                // md={10}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // flexWrap: "wrap",
                  width: "100%",
                }}
                className="hideScrollBar"
                sx={{
                  flexWrap: {
                    // sm: "nowrap",
                    xs: "wrap",
                    lg: "nowrap"
                  },
                  padding: {
                    lg: "0px 3.6vW"
                  },
                  // flexDirection: {
                  //   xs: "column",
                  //   sm: "row",
                  // },
                  overflowX: "scroll",
                }}
              >
                {packages?.map((item, index) => {
                  return (
                    <Grid
                      // item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        // margin: "10px 30px",
                        // background: "red",
                        width: "100%",

                        // height: "20px",
                      }}
                      sx={{
                        margin: {
                          xs: "10px",
                          sm: "10px 10px",
                        },
                      }}
                    >
                      <Card
                        style={{
                          maxWidth: 320,
                          backgroundColor:
                            (item.id === parseInt(customer?.packageId))
                              ? COLORS.GrayDark
                              : COLORS.Primary,
                        }}
                        sx={{
                          minWidth: {
                            xs: "100%",
                            // sm: "240px",
                            md: "260px",
                            lg: "100%",
                          },
                        }}
                      >
                        <CardContent
                          style={{
                            padding: "27px",
                          }}
                        >
                          {/* <Typography
                            sx={{
                              fontSize: "30pt",
                              color: COLORS.Orange2,
                              fontWeight: 400,
                              fontFamily: FONTS_AC,
                              textTransform: "uppercase",
                            }}
                            gutterBottom
                          >
                            {item?.name}
                          </Typography> */}
                          <Typography
                            variant="h3"
                            component="div"
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              margin: "25px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: 120,
                                height: 120,
                                borderRadius: "50%",
                                backgroundColor: "white",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: COLORS.Primary,
                                  fontFamily: FONTS_RC,
                                  fontSize: "39.5pt",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.noofmeals}
                              </div>
                              <div
                                style={{
                                  fontSize: "13.5pt",
                                  fontFamily: FONTS_AC,
                                }}
                              >
                                MEALS
                              </div>
                            </div>
                          </Typography>
                          <Typography
                            sx={{
                              // mb: 1,
                              margin: "12px 0px",
                              color: "white",
                              // fontSize: "1.2vW",
                              fontFamily: FONTS_INTER,
                              minHeight: mat920.matches ? "17vW" : "21vW",

                              fontSize: {
                                xs: "13pt",
                                lg: "1.1vW"

                              }
                            }}
                          >
                            {item?.description}
                          </Typography>
                          <Typography>
                            <p
                              style={{
                                fontFamily: FONTS_AC,
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "30pt",
                                margin: "25px 0px 0px 0px",
                              }}
                            >
                              {" "}
                              {item?.price}{" "}
                              <span style={{ fontSize: "29pt" }}>AUD</span>
                            </p>
                            <p
                              style={{
                                fontFamily: FONTS_INTER,
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "8.5pt",
                                margin: "0px 0px 15px 0px",
                              }}
                            >

                              {`($ ${parseFloat(item?.price / item?.noofmeals).toFixed(2)} PER MEAL)`}

                            </p>
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              disabled={
                                (item.id === parseInt(customer?.packageId)
                                ) ? true
                                  : false
                              }
                              style={{
                                background:
                                  (item.id === parseInt(customer?.packageId)
                                  ) ? COLORS.GrayDark2
                                    : COLORS.Orange,
                                color: "white",
                                height: 36,
                                width: 125,
                                fontFamily: FONTS_INTER,
                                fontSize: "13pt",
                                fontWeight: "bold",
                              }}
                              onClick={() => assignPackage(item.id, item.noofmeals)}
                            >
                              SELECT
                            </Button>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center", marginTop: "4vW" }}
          >
            <Grid
              xs={12}
              container
              spacing={0}
              style={{
                padding: "50px",
                // backgroundColor: COLORS.Primary,
                // backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${data.image})`,
                backgroundImage: `url(${mat425.matches === true ? img5 : img4
                  })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                // borderRadius: 12,
                height: mat425.matches === true ? "110vW" : "40vW",
                cursor: "pointer",

              }}

              onClick={() => { history.push("/plans"); window.scroll(0, 0) }}
            >
              {/* <Grid item md={12} style={{ display: "grid" }}>
                <div
                  style={{
                    alignItems: "flex-end",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "18pt",
                      color: COLORS.Orange4,
                      fontFamily: FONTS_RS,
                      textTransform: "capitalize",
                      marginBottom: 0,
                      fontSize: "36pt",
                      fontWeight: "bold",
                      marginTop: "0px",
                      lineHeight: 1.2,
                    }}
                  >
                    
                    FREE Registration
                  </h1>
                </div>
                <h4
                  style={{
                    fontSize: "18pt",
                    color: COLORS.TitleColor7,
                    fontFamily: FONTS_RS,
                    // textTransform: "capitalize",
                    marginBottom: 0,
                    fontSize: "16pt",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}
                >
                  for anyone and everyone
                </h4>
              </Grid> */}
            </Grid>
          </Grid>


          <Grid item xs={12}
            style={{
              borderRadius: "25px",
              marginTop: mat425.matches ? "0vW" : "4vW",
              // display: mat425.matches ? "flex" : "none", 
              justifyContent: "center"
            }}

          >
            <Typography
              style={{
                color: COLORS.Primary,
                fontFamily: FONTS_ANOTON,
                fontWeight: "500",
                // letterSpacing: -1,
                fontSize: mat425.matches ? "26pt" : "36pt",
              }}
              sx={{
                // fontSize: {
                //   sm: "36pt",
                //   xs: "26pt",
                // },
                margin: {
                  sm: "0px 10px 50px",
                  xs: "40px 0px 0px",
                },
              }}
            >
              WHY CEYLON HEALTHY FOOD
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
            sx={{
              marginTop: {
                xs: "0px",
                sm: "0px"
              }
            }}
          >


            <Grid
              xs={12}
              container
              spacing={0}
              style={{
                padding: mat425.matches ? "20px" : "0px",
                // backgroundColor: COLORS.Primary,
                // backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${data.image})`,
                // backgroundImage: `url(${mat425.matches === true ? img7 : img6
                //   })`,
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "100%",
                // // borderRadius: 12,
                // height: mat425.matches === true ? "1050px" : "32vW",
                cursor: "pointer",

              }}

              onClick={() => { history.push("/plans"); window.scroll(0, 0) }}
            >
              <img
                src={mat425.matches === true ? img7 : img6}
                style={{
                  height: "100%",
                  width: "100%"
                }}

              />
              {/* <Grid item md={12} style={{ display: "grid" }}>
                <div
                  style={{
                    alignItems: "flex-end",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "18pt",
                      color: COLORS.Orange4,
                      fontFamily: FONTS_RS,
                      textTransform: "capitalize",
                      marginBottom: 0,
                      fontSize: "36pt",
                      fontWeight: "bold",
                      marginTop: "0px",
                      lineHeight: 1.2,
                    }}
                  >
                    
                    FREE Registration
                  </h1>
                </div>
                <h4
                  style={{
                    fontSize: "18pt",
                    color: COLORS.TitleColor7,
                    fontFamily: FONTS_RS,
                    // textTransform: "capitalize",
                    marginBottom: 0,
                    fontSize: "16pt",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}
                >
                  for anyone and everyone
                </h4>
              </Grid> */}
            </Grid>
          </Grid>


          {/* <Grid item xs={12}>
            <Grid
              xs={12}
              container
              spacing={0}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} style={{ padding: "0px 16px" }}>
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    // flexWrap: "wrap-reverse",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "36pt",
                          md: "2.8rem",
                          sm: "2rem",
                          xs: "34.5pt",
                        },
                        lineHeight: 1,

                        flexDirection: {
                          xs: "row",
                          sm: "column",
                        },
                        // textTransform: {
                        //   xs: "uppercase",
                        //   sm: "none",
                        // },
                        width: {
                          sm: "74%",
                          xs: "90%",
                        },
                        
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        // alignItems: "center",
                        fontWeight: "600",
                        textAlign: "left",
                        color: COLORS.TitleColor,
                        fontFamily: FONTS_AC,
                        marginTop: 100,
                        marginLeft:mat425.matches ? "-10px" : "",
                        fontSize: mat425.matches ? "34.5pt" : "36pt",
                        whiteSpace: mat425.matches ? "unset" : "unset",

                      }}
                    >
                      WHY CEYLON HEALTHY FOOD?
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        fontSize: "14.2pt",
                        color: COLORS.GrayDark2,
                        fontFamily: FONTS_INTER,
                        // textTransform: "capitalize",
                        fontWeight: 400,

                        marginTop: 10,


                        fontStyle: "unset",
                        fontSize: mat425.matches ? "17pt" : "14.2pt",

                        textAlign: "left"
                      }}
                      sx={{
                        marginLeft: {
                          xs: 0,
                          sm: "6%",
                        },
                        padding: {
                          xs: "0px 0px 0px 40px",
                          sm: "20px 0px 20px 0px",
                        },
                        fontSize: {
                          xs: "17pt",
                          sm: "14.2pt",
                        },
                        marginBottom: {
                          xs: "30px",
                          sm: "80px",
                        },
                        width: {
                          sm: "74%",
                          xs: "90%",
                        },
                      }}
                    >
                     
                      <ul className="bullets" style={{ listStyleType: "disc", listStylePosition: "outside", fontFamily: FONTS_INTER, }}>
                        <li>Nutritionist Designed Meal Plans.</li><br />
                        <li>All food is carried in refrigerated vehicles that
                          can hold the food at a temperature of 5°C or below.</li><br />
                        <li>All meals are prepared under the guidance of a chef with 25+ years of experience. </li><br />
                        <li>Daily preparation to guarantee freshness.</li>
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    lg={6}
                    xs={12}
                    style={{
                      textAlign: "center",
                      padding: "25px 1px 0px",

                      display: "flex",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                      src={VEG}
                    />

                    <img
                      class="vegetable "
                      style={{
                        width: "100%",
                        position: "relative",
                        top: "-11%",
                        right: "116%",

                        // margin: "1rem 0rem -3.5rem -4rem",
                      }}
                      src={VEG1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}



          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center", }}
            sx={{
              marginTop: {
                xs: "0px",
                md: "5vW",
              }
            }}
          >
            <Grid
              xs={12}
              md={12}
              container
              spacing={0}
              // style={{ padding: "50px" }}
              sx={{
                padding: {
                  xs: "0px 0px 0px 0px",
                  sm: "0px 0px",
                },
                display: "flex",
                alignItems: "center",
                background: "#ecf5f6",
                marginBottom: "-5px"
              }}
            >

              <Grid
                ref={errScroll2}
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",


                }}
                sx={{
                  marginTop: {
                    md: "0px",
                    xs: "35px",
                  },
                }}
              >
                <Typography
                  style={{
                    fontFamily: FONTS_ANOTON,
                    color: COLORS.TitleColor,
                    fontSize: mat425.matches ? "20pt" : "26pt",
                    fontWeight: "500",
                    letterSpacing: -1
                  }}


                >
                  BECOME A
                </Typography>
                <Typography
                  style={{
                    fontFamily: FONTS_ANOTON,
                    color: COLORS.Orange,
                    fontSize: mat425.matches ? "30pt" : "36pt",
                    textTransform: "uppercase",
                    lineHeight: 1,
                    fontWeight: "500",
                    letterSpacing: -1
                  }}


                >
                  PARTNERED RETAILER
                </Typography>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontSize: "13pt",
                    color: COLORS.TitleColor5,

                    marginTop: "25px",
                  }}
                  sx={{
                    width: {
                      sm: "70%",
                      xs: "95%",
                    },
                  }}
                >
                  We partner with grocery shops and supermarkets to cater Ceylon Healthy Food meal packs to
                  your customers conveniently. For more information on joining the Ceylon Healthy Food retail
                  network please refer to the link below.

                </Typography>
                <Button
                  onClick={() => {
                    if (mat425.matches === true) {
                      history.push("/retail_partner");
                      window.scroll(0, 0);
                    } else {
                      setOpenRegForm2(true);
                    }
                  }}
                  style={{
                    boarder: "none",
                    outline: "none",
                    fontSize: "12pt",
                    color: "white",
                    background: COLORS.Primary,
                    padding: "6px 30px",
                    marginTop: "30px",
                  }}
                >
                  Apply Now
                </Button>
              </Grid>
              <Grid xs={12} md={6}
                sx={{
                  marginTop: {
                    xs: "30px",
                    md: "0px"
                  },
                }}

                style={{
                  // backgroundImage: `url(${NO_REGISTER1
                  //   })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  objectFit: "cover",
                  // borderRadius: 12,
                  // height: mat425.matches === true ? "83vW" : "40.5vW",
                }}
              >
                <img
                  src={NO_REGISTER1}
                  style={{
                    objectFit: "cover",
                    // width: mat425.matches === true ? "100%" : "85%",
                    height: "100%",
                    width: "100%"
                  }}
                />
                {/* <img
                  src={NO_REGISTER1}
                  style={{
                    objectFit: "contain",
                    width: mat425.matches === true ? "100%" : "100%",
                    height: "200px",

                  }}
                /> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12}
            style={{
              marginTop: "-6.5px"
            }}
          >
            <Grid
              container
              spacing={0}
              style={{
                padding: "50px",
                backgroundColor: COLORS.Primary,
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <div>
                  <Typography
                    style={{
                      margin: "0px 0px 35px 0px",
                      color: "white",
                      fontFamily: FONTS_ANOTON,
                      fontSize: mat425.matches ? "26pt" : "36pt",
                      fontWeight: "500",
                      // letterSpacing: -1,
                    }}
                  // sx={{
                  //   fontSize: {
                  //     sm: "36pt",
                  //     xs: "26pt",
                  //   },
                  // }}
                  >
                    WHAT OUR CLIENTS SAY
                  </Typography>
                </div>
              </Grid>
              <Grid
                xs={12}
                md={10}
                // className="hideScrollBar"
                style={{
                  display: "flex",

                  flexWrap: "nowrap",
                  overflowX: "hidden",
                  overflowY: "hidden",
                  paddingBottom: "15px",
                }}
              >
                <div
                  className="container33333"
                  style={{
                    height: "100%",
                  }}
                >
                  <Slider ref={mySlider} {...settings2}>
                    {testi?.map((data, index) => {
                      return (
                        <Card
                          sx={{
                            minWidth: {
                              xs: "100%",
                              sm: "80%",
                              lg: "35vW",
                              // sm: "300px",
                            },

                            maxWidth: 320,
                            backgroundColor: "white",
                            borderRadius: "4px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "94%",
                              // width:"320px",
                            }}
                          >
                            <Typography
                              sx={{
                                justifyContent: "space-between",
                                display: "flex",
                                // fontSize: "24pt",
                                color: "#e2dc4e",
                                fontWeight: 600,
                                alignItems: "center",
                                padding: " 15px 29px 0px 29px",
                              }}
                            >
                              <div>
                                <img
                                  src={index % 2 === 0 ? GC : OC}
                                  style={{ width: "65px" }}
                                />
                              </div>
                              <div>
                                <Avatar
                                  style={{ width: "60px", height: "60px" }}
                                  src={`${IMAGE_SERVER_URL_DEV}${data.image}`}
                                ></Avatar>
                              </div>
                            </Typography>

                            <Typography
                              sx={{
                                mb: 1.5,

                                color: COLORS.TextColor,
                                fontSize: mat425.matches !== true ? "12pt" : "11pt",
                                padding: "20px 25px ",
                                fontFamily: FONTS_INTER,
                                // minHeight: "233px",
                                display: "flex",
                                flex: 1,
                                // minHeight: 300,
                                minHeight: mat920.matches === true ? "280px" : mat425.matches === true ? "28vW" : matches900 === true ? "38vW" : matches1200 === true ? "25vW" : "47vH"
                              }}
                              color="text.secondary"
                            >
                              {data.description}
                            </Typography>
                            <Typography
                              sx={{
                                minHeight: "90px",
                              }}
                            >
                              <Typography
                                // variant="h6"
                                style={{
                                  fontWeight: "600",
                                  color:
                                    index % 2 === 0
                                      ? COLORS.Primary
                                      : COLORS.Orange,
                                  fontFamily: FONTS_AC,
                                  fontSize: "16pt",
                                }}
                              >
                                {data.name}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: FONTS_AC,
                                  color: COLORS.TextColor,
                                  fontSize: "13pt",
                                }}
                              >
                                {data.job}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  color:
                                    index % 2 === 0
                                      ? COLORS.Primary
                                      : COLORS.Orange,
                                  fontFamily: FONTS_AC,
                                  fontSize: "11pt",
                                }}
                              >
                                {data.city}
                              </Typography>
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Slider>
                  <Grid
                    xs={12}
                    style={{
                      // display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                    sx={{
                      display: {
                        lg: "none",
                        xs: "flex",
                      },
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        display: "grid",
                        placeItems: "center",
                        padding: 6,
                        borderRadius: "50%",
                        marginRight: 5,
                      }}
                      onClick={() => {
                        mySlider?.current?.slickPrev();
                        // window.document.querySelector(
                        //   "#areas"
                        // ).scrollLeft -= 100;
                      }}
                    >
                      <KeyboardArrowLeftIcon
                        style={{
                          fontSize: 24,
                          color: COLORS.Primary,
                        }}
                      />
                    </div>

                    <div
                      style={{
                        background: "white",
                        display: "grid",
                        placeItems: "center",
                        padding: 6,
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        // window.document.querySelector(
                        //   "#areas"
                        // ).scrollLeft += 100;
                        mySlider?.current?.slickNext();
                      }}
                    >
                      <KeyboardArrowRightIcon
                        style={{
                          fontSize: 24,
                          color: COLORS.Primary,
                        }}
                      />
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            ref={errScroll}
            container
            xs={12}
            style={{ display: "flex", justifyContent: "center", marginBottom: "-6.5px" }}
          >
            <Grid
              xs={12}
              md={12}
              container
              spacing={0}
              // style={{ padding: "50px" }}
              sx={{
                padding: {
                  xs: "0px 0px 80px 0px",
                  sm: "0px 0px",
                },
                display: "flex",
                alignItems: "center",
                background: "#ecf5f6",
              }}
            >
              <Grid xs={12} md={6}
                sx={{
                  marginTop: {
                    xs: "0px",
                  },
                }}

                style={{
                  // backgroundImage: `url(${NO_REGISTER2
                  //   })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  objectFit: "cover",
                  // borderRadius: 12,
                  // height: mat425.matches === true ? "83vW" : "40.5vW",
                }}
              >
                <img
                  src={NO_REGISTER2}
                  style={{
                    objectFit: "cover",
                    // width: mat425.matches === true ? "100%" : "85%",
                    height: "100%",
                    width: "100%"
                  }}
                />
                {/* <img
                  src={NO_REGISTER2}
                  style={{
                    objectFit: "contain",
                    width: mat425.matches === true ? "100%" : "85%",
                    height: "100%",
                  }}
                /> */}
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                sx={{
                  marginTop: {
                    // sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xs: "35px",
                  },
                  marginBottom: {
                    sm: "40px",
                    md: "0px",
                    // lg: "0px",
                    xs: "35px",
                  },

                }}
              >
                <Typography
                  style={{
                    fontFamily: FONTS_ANOTON,
                    color: COLORS.Primary,
                    fontSize: mat425.matches ? "20pt" : "26pt",
                    fontWeight: "500",
                    letterSpacing: -1,
                  }}

                >
                  BECOME A
                </Typography>
                <Typography
                  style={{
                    fontFamily: FONTS_ANOTON,
                    color: COLORS.TitleColor,
                    fontSize: mat425.matches ? "30pt" : "36pt",
                    textTransform: "uppercase",
                    lineHeight: 1,
                    fontWeight: "500",
                    letterSpacing: -1,
                  }}

                >
                  PARTNERED kitchen
                </Typography>
                <Typography

                  style={{
                    fontFamily: FONTS_INTER,
                    // fontSize: "13pt",
                    color: COLORS.TitleColor5,

                    marginTop: "25px",
                  }}
                  sx={{
                    width: {
                      sm: "70%",
                      xs: "95%",
                    },
                    fontSize: {
                      xs: "13pt",
                      lg: "13pt",
                      md: "11pt"
                    }
                  }}
                >
                  Earn a passive income by joining hands with us as a registered
                  kitchen partner and earn a range of benefits. While the demand
                  for our business grows higher, we're rapidly expanding our
                  kitchen franchise. By becoming a partner kitchen you will
                  receive an opportunity to work closely with us and share
                  freshly prepared, healthy meals with our valued customers.
                </Typography>
                <Button
                  onClick={() => {
                    if (mat425.matches === true) {
                      history.push("/kitchen_partner");
                      window.scroll(0, 0);
                    } else {
                      setOpenRegForm(true);
                    }
                  }}
                  style={{
                    boarder: "none",
                    outline: "none",
                    fontSize: "12pt",
                    color: "white",
                    background: COLORS.Primary,
                    padding: "6px 30px",
                    marginTop: "30px",
                  }}
                >
                  Apply Now
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: COLORS.GrayDark3,
              // background: `url(${Bike})`,
            }}
          >
            <Grid
              xs={11}
              md={11}
              container
              spacing={0}
              // style={{ padding: "50px" }}
              sx={{
                padding: {
                  xs: "80px 0px",
                  sm: "80px 50px",
                },
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Grid
                xs={12}
                sm={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                sx={{
                  height: {
                    sm: "80vh",
                    xs: "fit-content",
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: "22pt",
                    fontFamily: FONTS_RC,
                    color: COLORS.TitleColor5,
                    textTransform: "uppercase",
                  }}
                >
                  
                  become a
                </Typography>
                <Typography
                  style={{
                    fontSize: "38.6pt",
                    fontFamily: FONTS_AC,
                    color: COLORS.Primary,
                    textTransform: "uppercase",
                    lineHeight: 1,
                    fontWeight: "bold",
                  }}
                >
                  Delivery Partner
                  
                </Typography>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontSize: "13pt",
                    color: COLORS.TitleColor5,
                    marginTop: "35px",
                  }}
                  sx={{
                    width: {
                      xs: "95%",
                      sm: "80%",
                    },
                  }}
                >
                  Become a registered delivery partner and help us deliver
                  freshly cooked, healthy meals to our lovely customers while
                  earning an extra income. You will be required to deliver meals
                  within a 5-8 km radius within your registered kitchen.
                  Register now as our demand for delivery riders grows higher
                  and enjoy a range of benefits by working with us.
                </Typography>
                <Button
                  style={{
                    color: "white",
                    background: COLORS.Orange2,
                    padding: "6px 20px",
                    fontSize: "12pt",
                    fontFamily: FONTS_INTER,
                    marginTop: "40px",
                  }}
                  onClick={() => {
                    if (mat425.matches === true) {
                      history.push("/delivery_partner");
                      window.scroll(0, 0);
                    } else {
                      setOpenRegForm2(true);
                    }
                  }}
                >
                  Register Now
                </Button>
              </Grid>
             
              <img
                src={BIKE_IMG}
                style={{
                  objectFit: "contain",
                  position: mat425.matches === true ? "relative" : "absolute",
                  right: mat425.matches === true ? "0vw" : "6vw",
                  width: mat425.matches === true ? "90vw" : "48vw",
                  marginTop: mat425.matches === true ? "50px" : "0px",
                }}
              />
            </Grid>
          </Grid> */}


          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              xs={11}
              md={10}
              container
              spacing={0}
              // style={{ padding: "50px" }}
              sx={{
                padding: {
                  xs: "10px",
                  sm: "50px",
                },
              }}
            >
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <div>
                  <p
                    style={{
                      margin: "30px 0px 20px 0px",
                      color: COLORS.TitleColor,
                      fontFamily: FONTS_AC,
                      fontSize: matches === true ? "30pt" : "36pt",
                      fontWeight: "bold",
                    }}
                  >
                    INSURANCE PARTNERS
                  </p>
                </div>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  overflowX: "scroll",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {brands?.map((data) => {
                  return (
                    <Grid xs={6}>
                      <div>
                        <img
                          className="insurance_image"
                          src={`${IMAGE_SERVER_URL_DEV}${data.image}`}
                          alt="Third slide"
                          height="200px"
                          // width="400px"
                          style={{
                            objectFit: "contain",
                            // width: "300px",
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <RegistrationPopup
        open={openRegister}
        handleClose={() => setopenRegister(false)}
      />
      {window.localStorage.getItem(`${window.location.hostname}.login`) ===
        null && (
          <LoginPopup open={openLogin} handleClose={() => setOpenLogin(false)} />
        )}
      <Modal
        open={openSuccess}
        onClose={() => {
          setopenSuccess(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon
                  sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                  }}
                >
                  Order Successful
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    {/* Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on  between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details. */}
                    {/* {`Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on ${localStorage.getItem('deliveryDate')} between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details.`} */}

                    {
                      getDeliverycharge() > 0 ?

                        `Thank you for completing your weekly meal plan with us. Your order will be delivered on ${getSuccessDates()} between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.`

                        :

                        "Thank you for completing your weekly meal plan with us. Your order can be collected on the following days between 4pm and 8pm."

                    }

                    {/* Thank you for completing your weekly meal plan with us. Your order can be collected or get delivered through Uber Package delivery on the following days between 4pm and  8pm. */}

                  </Typography>

                  {/* <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Breakfast 6am - 9pm <br />
                      Lunch 11 a.m - 1.30
                      <br />
                      Dinner 5.30 p.m - 8.30 p.m
                    </p>
                    <p>
                      27th Fri  -  28th,29th,30th & 31st Meals
                      <br />
                      31st Tue -  01st, 2nd & 3rd Meals
                    </p>
                    {getSuccessDates()}
                  </Typography> */}

                  {/* <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                     
                      Your order can be collected from our pickup location or get it delivered from
                      uber delivery centre.
                     

                    </p>
                  </Typography> */}

                  <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                      {/* (Delivery times can be vary depending on the road traffic
                      conditions.) */}
                      Please check your order confirmation email for more details.

                    </p>
                  </Typography>

                  {/* <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      For any clarifications please contact our Order Processing
                      Unit between 9 am and 5 pm through 0112 222 222
                    </p>
                  </Typography> */}
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
}

export default Home;
