import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { COLORS } from "../../Constants/Colors";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { Container } from "react-bootstrap";
import { FONTS_AC, FONTS_INTER } from "../../Constants";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { FormGroup, Form } from "reactstrap";
import { resetPassword } from "../../Services/API/Post";
import {
  Card,
  CardContent,
  Divider,
  getCardUtilityClass,
  Grid,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { getPackages } from "../../Services/API/Get";
import { packageToUser } from "../../Services/API/Put";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CssBaseline from "@mui/material/CssBaseline";

export default function PackagePopup({ open, handleClose, id }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleToastClose = () => {
    setOpenToast(false);
  };

  const history = useHistory();
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [email2, setEmail2] = useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [errPassword, setErrPassword] = React.useState("");

  const [packages, setPackages] = useState([]);
  const [userId, setuserId] = useState("");

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId) => {
    console.log("pkg id >>>", pkgId);

    const obj = {
      packageId: pkgId,
    };

    packageToUser(obj, id)
      .then((res) => {
        console.log(res.data);
        setOpenToast(true);
        setColor(COLORS.Primary);
        setAlertClass("success");
        setMsg("Package Assigned to User");
        setTimeout(() => {
          history.push("/plans");
        }, 2000);
      })
      .catch((error) => {
        console.log("error", error.response);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getPackage();
  }, []);

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90vw",
              md: "90vw",
              lg: "75vw",
              sm: "70vw",
            },
            maxHeight: "98vh",
            // overflowY: "scroll",
            borderRadius: "4px",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
            }}
            sx={{
              padding: "30px",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
               
              }}
            >
              <Grid xs={11} container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: "26pt",
                        fontFamily: FONTS_INTER,
                      }}
                      sx={{
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </h1>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {packages?.map((data) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          margin: "10px 30px",
                        }}
                      >
                        <Card
                          sx={{
                            minWidth: 240,
                            maxWidth: 300,
                            // minWidth: 275,
                            // maxWidth: 220,
                            // backgroundColor: "#6caca7",
                            backgroundColor: COLORS.Primary,
                          }}
                        >
                          <CardContent
                            style={{
                              padding: "27px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "30pt",
                                color: COLORS.Orange2,
                                fontWeight: 400,
                                fontFamily: FONTS_AC,
                                textTransform: "uppercase",
                                justifyContent: "center",
                                display: "flex",
                              }}
                              gutterBottom
                            >
                              {data?.name}
                            </Typography>
                            <Typography
                              component="div"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                margin: "10px 0px 0px",
                                // marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: 110,
                                  height: 110,
                                  borderRadius: "50%",
                                  backgroundColor: "#e8eeee",
                                  padding: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: COLORS.PrimaryText,
                                    fontSize: "41.2pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "600",
                                    lineHeight: "40px",
                                  }}
                                >
                                  {data.noofmeals}
                                </Typography>
                                <Typography
                                  style={{
                                    color: COLORS.TextColor,
                                    fontSize: "13.9pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "500",
                                  }}
                                >
                                  MEALS
                                </Typography>
                              </div>
                            </Typography>
                            <Typography
                              sx={{
                                // mb: 1.5,
                                // mt: 3,
                                color: "white",
                                textAlign: "center",
                                fontSize: "9.9pt",
                                fontFamily: FONTS_INTER,
                                margin: "20px 0px 10px",
                              }}
                              color="text.secondary"
                            >
                              {data.description}
                            </Typography>
                            <Typography>
                              <p
                                style={{
                                  fontWeight: "600",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "32.9pt",
                                  fontFamily: FONTS_AC,
                                  margin: "10px 0px",
                                }}
                              >
                                {" "}
                                {"Rs " + data.price}{" "}
                              </p>
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                style={{
                                  background: COLORS.Orange,
                                  fontSize: "9.9pt",
                                  fontFamily: FONTS_INTER,
                                }}
                                onClick={() => assignPackage(data.id)}
                              >
                                SELECT
                              </Button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
