import { ThemeProvider } from "@emotion/react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  createTheme,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useContext } from "react";
// import { Button } from "react-bootstrap";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import Checkbox from "@mui/material/Checkbox";
import { useHistory, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { FormGroup, Form } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";

import { FONTS, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import { LoginQuery, sendVerification } from "../../Services/API/Post";
import Verification from "./verificationPopup.jsx";
import ErrorPopup from "./errorPopup.jsx";
import AuthContext from "../../Context/AuthContext";
import StepsContext from "../../Context/StepsContext";
import "./login.css";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";

function LoginPopup({ open, handleClose }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "20px 0px",
    borderRadius: "4px",
  };
  const GreenCheckbox = withStyles({
    root: {
      color: "#E2E2E2",
      "&$checked": {
        color: COLORS.Primary,
      },
      marginLeft: "0.5rem",
      padding: 0,
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const { setUser } = useContext(AuthContext);
  const { setSteps } = useContext(StepsContext);

  const passwordRef = React.useRef();

  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errPassword, setErrPassword] = React.useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const history = useHistory();
  const [lOpen, setlOpen] = useState(false);

  const [forgot, setOpenForgot] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // let email = data.get("username");
    // let password = data.get("password");

    if (email === "") {
      setErrEmail("This Field Required");
    }

    if (email !== "") {
      setErrEmail("");
    }

    if (password === "") {
      setErrPassword("This Field Required");
    }

    if (password !== "") {
      setErrPassword("");
    }
    // eslint-disable-next-line no-console

    let obj = {
      email: email,
      password: password,
    };

    if (email !== "" && password !== "") {
      setlOpen(true);
      LoginQuery(obj)
        .then((res) => {
          // setUser("logged");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Login Successful");

          let decoded = jwt_decode(res?.data);
          if (res?.status) {
            localStorage.setItem(
              `${window.location.hostname}.login`,
              JSON.stringify(res)
            );

            localStorage.setItem("exprTime", decoded?.exp);
            // localStorage.setItem("remember", rememberMe);
          }

          // history.push("/");
          // window.location.reload();

          setTimeout(() => {
            history.push("/home");
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          setlOpen(false);
          console.log(error);

          if (error.response.data.statusCode === 401) {
            setErrPassword(error.response.data.message);
          } else if (error.response.data.statusCode === 500) {
            setErrEmail(error.response.data.errorMessage);
          } else {
            setOpenToast(true);
            setColor("red");
            setAlertClass("error");
            setMsg(error.response.data.message);
          }
        });
    }
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // let email =data.get('username')

    let obj ={
        email:email,
    }

    console.log("send >>>",obj)

    if(!email){
      setErrEmail("This field required")
    }

    if(email){
      sendVerification(obj)
        .then((res) => {
          setOpenToast(true)
          setAlertClass("success");
          setColor(COLORS.Primary)
          setMsg("Password reset link has been emailed")
          // if (res.status) {
          //   localStorage.setItem(
          //     `${window.location.hostname}.login`,
          //     JSON.stringify(res)
          //   );
          // }

          // setEmail("");
          // setPassword("");

          // history.push("/");
          // setTimeout(() => { setMsg(""); }, 4000);
        })
        .catch((error) => {
          console.log(error)
          // setColor("red");
          setAlertClass("error");
          setOpenToast(true)
          setColor("red")
          setMsg(error.response.data.message)
        });
    }

    


  };

  return (
    <div>
      <LoadingSpinner open={lOpen} />
      <Verification open={forgot} handleClose={() => setOpenForgot(false)} />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              {/* <CssBaseline /> */}
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: COLORS.Orange }}>
                  <PersonOutlineIcon />
                </Avatar>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    fontSize: "14pt",
                  }}
                >
                  Login
                </Typography>
                <Form className="mb-3" onSubmit={handleSubmit}>
                  <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop: 20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Username (Email) *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.emailColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <MailOutlineIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        autoFocus
                        // required
                        // type="email"
                        name="username"
                        id="username"
                        placeholder="Enter email"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        autoComplete="new-password"
                        onKeyDown={(event) => {
                          if (event.key === "Tab") {
                            event.preventDefault();
                            passwordRef.current.focus();
                          }
                        }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrEmail("");
                        }}
                        // onChange={this.onChangeEmail}
                        // value={email}
                      />
                    </Paper>
                    <Box textAlign="right">
                      <div className="text-right">
                        <span
                          style={{
                            fontSize: "8pt",
                            color: "red",
                            fontFamily: FONTS_INTER,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {errEmail}
                        </span>
                      </div>
                    </Box>
                  </FormGroup>
                  <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop: 20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Password *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.pddwrdColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <LockIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        // required
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        ref={passwordRef}
                        autoComplete="new-password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setErrPassword("");
                        }}
                        // onChange={this.onChangePassword}
                        // value={this.state.password}
                      />
                    </Paper>
                    <Box textAlign="right">
                      <div className="text-right">
                        <span
                          style={{
                            fontSize: "8pt",
                            color: "red",
                            fontFamily: FONTS_INTER,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {errPassword}
                        </span>
                      </div>
                    </Box>
                  </FormGroup>
                  <Box
                    style={{
                      width: "18.5rem",
                      height: "2.5rem",
                      marginTop: 25,
                      // borderColor: this.state.pddwrdColor,
                    }}
                    elevation={0}
                    variant="outlined"
                  >
                    <GreenCheckbox
                      size="small"
                      // onChange={(e) => {
                      //   this.onChangeCheck(e);
                      // }}
                      // checked={this.state.checkedValues.includes(clients.orderOptions.createdAt)}
                      // defaultChecked={this.state.temp}

                      defaultChecked={false}
                    />

                    <label
                      style={{ fontSize: "8.5pt", fontFamily: FONTS_INTER }}
                    >
                      {" "}
                      Remember me
                    </label>
                    <label
                      style={{
                        fontSize: "8.5pt",
                        marginLeft: "5.4rem",
                        color: COLORS.Orange,
                        fontFamily: FONTS_INTER,
                        cursor: "pointer",
                      }}
                      // onClick={() => {
                      //   handleClose();
                      //   setOpenForgot(true);
                      // }}
                      onClick={handleSubmit2}
                    >
                      Forgot Password
                    </label>
                  </Box>

                  <Box textAlign="center">
                    <Button
                      type="submit"
                      // fullWidth
                      variant="contained"
                      sx={{ mt: 4, mb: 4 }}
                      style={{
                        fontFamily: FONTS_INTER,
                        fontSize: "12pt",
                        color: "white",
                        background: COLORS.Primary,
                        borderRadius: 4,
                        width: "175px",
                        height: "35px",
                        border: "none",
                        marginTop: 10,
                        cursor: "pointer",
                      }}
                    >
                      LOGIN
                    </Button>
                  </Box>
                  <Grid
                    container
                    style={{
                      fontFamily: FONTS_INTER,
                      fontSize: "9px",
                      marginTop: 15,
                    }}
                  >
                    <Grid item xs>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          fontSize: "9pt",
                          margin: "5px 0px",
                          textAlign: "center",
                        }}
                      >
                        <p>
                          Not yet register ?{" "}
                          <Link
                            // to="/SignUp"
                            style={{
                              textDecorationLine: "none",
                              color: COLORS.Primary,
                              fontWeight: "500",
                              fontFamily: FONTS_INTER,
                              fontSize: "9pt",
                            }}
                            // onClick={handleClose}
                            onClick={() => {
                              history.push("/SignUp");
                              window.scroll(0, 1000);
                              handleClose();
                            }}
                          >
                            Create your account now
                          </Link>
                        </p>
                      </Typography>
                    </Grid>

                    {/* <Grid item>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          FontFamily: FONTS,
                          margin: "5px 0px",
                        }}
                      >
                        <Link href="#" style={{ textDecoration: 'none' }}>
                          Create your account now
                        </Link>
                      </Typography>
         
                    </Grid> */}
                  </Grid>
                </Form>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                  {/* <InputLabel
           style={{
            fontFamily: FONTS,
            color: "black",
            marginTop:20,
          
          }}
          
          >Username*</InputLabel> */}

                  {/* <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    placeholder="Enter Email"
                    // autoFocus
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><MailOutlineIcon /></InputAdornment>,

                    }}
                    style={{
                      fontFamily: FONTS,
                      color: "black",
                      marginTop: 20,

                    }}
                    onChange={(e) =>
                      setErrEmail("")            
                      }
                  /> */}

                  {/* {errEmail && (
                          <Box textAlign='right'>
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS,
                          margin: "5px 0px",

                        }}
                      >
                        {errEmail}
                      </Typography>
                      </Box>
                    )} */}

                  {/* <InputLabel 
           style={{
            fontFamily: FONTS,
            color: "black",
            marginTop:20,
          
          }}
             >Password*</InputLabel> */}
                  {/* <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    placeholder="Enter Password"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                    }}
                    onChange={(e) =>
                      setErrPassword("")            
                      }
                  /> */}

                  {/* {errPassword && (
                     <Box textAlign='right'>
                      <Typography
                        variant="caption"
                        color="red"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS,
                          margin: "5px 0px",

                        }}
                      >
                       {errPassword}
                      </Typography>
                      </Box>
                    )} */}

                  {/* <Grid container
                    style={{
                      fontFamily: FONTS,
                      fontSize: "8pt"
                    }}>
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox value="remember" />}
                        label="Remember"
                        style={{
                          fontFamily: FONTS,
                          color: "black",
                          marginTop: 0,

                        }}
                      />
                    </Grid>

                    <Grid item
                      style={{
                        marginTop: 10,

                      }}>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          FontFamily: FONTS,
                          margin: "5px 0px",
                          fontSize: "9pt"

                        }}
                      >

                        <Link href="#" style={{ textDecoration: 'none', color: COLORS.Orange, }}>
                          Forgot Password
                        </Link>

                      </Typography>
                      
                    </Grid>
                  </Grid> */}

                  {/* <Box textAlign='center'>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    style={{
                      fontFamily: FONTS,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                      width: "175px",
                      
                    }}
                  >
                    LOGIN
                  </Button>
                  </Box> */}

                  {/* <Grid container
                    style={{
                      fontFamily: FONTS,
                      fontSize: "8pt"
                    }}>
                    <Grid item xs>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          FontFamily: FONTS,
                          margin: "5px 0px",
                          textAlign: "center",
                        }}
                      >
                        <p>
                          Not yet register ? {" "}
                          <Link to ="/SignUp" 
                               style={{
                                textDecorationLine: "none",
                                color: COLORS.Primary,
                                fontWeight: "500",
                              }}
                          >
                            Create your account now
                          </Link>
                        </p>
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
}

export default LoginPopup;
