import Routes from "./Routes";
import AuthContext from "./Context/AuthContext";
import { useEffect, useState, useRef } from "react";
import "./App.css";
import CartContext from "./Context/CartContext";
import StepsContext from "./Context/StepsContext";
import CartCount from "./Componets/CartCount";
import { CurrentUser } from "./Services/API/Get";

function App() {
  const [user, setUser] = useState(null);

  const [cart, setCart] = useState(0);
  const [steps, setSteps] = useState(1);
  const [menuCategory, setMenuCategory] = useState(null);
  const testRef = useRef(null);
  const testRef2 = useRef(null);
  const testRef3 = useRef(null);
  useEffect(() => {
    const response = window.localStorage.getItem(
      `${window.location.hostname}.login`
    );
    if (response) {
      CartCount().then((res) => {
        setCart(res);
        if (res !== 0) {
          setSteps(4);
        }
        if (window.localStorage.getItem(`order`) === "true") {
          setSteps(6);
        }
      });

      console.log("-------->", response);

      setSteps(2);
      CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          console.log("+++++++++++", cData);
          const response = window.localStorage.setItem(
            `${window.location.hostname}.userDetails`,
            JSON.stringify(cData)
          );
          setUser(cData);

          if (cData.packageId !== null) {
            setSteps(3);
          }
        }
        if (window.localStorage.getItem(`order`) === "true") {
          setSteps(6);
        }
      });
      
    }
  }, []);
  return (
    <div className="App">
      <AuthContext.Provider value={{ user: user, setUser: setUser }}>
        <StepsContext.Provider value={{ steps: steps, setSteps: setSteps }}>
          <CartContext.Provider
            value={{
              cart: cart,
              setCart: setCart,
              menuCategory: menuCategory,
              setMenuCategory: setMenuCategory,
              testRef: testRef,
              testRef2: testRef2,
              testRef3: testRef3,
              scrollToElement: () =>
                testRef.current.scrollIntoView({
                  behavior: "smooth",
                }),
              scrollToElement2: () =>
                testRef2.current.scrollIntoView({
                  behavior: "smooth",
                }),
                scrollToElement3: () =>
                testRef3.current.scrollIntoView({
                  behavior: "smooth",
                }),
            }}
          >
            <Routes />
          </CartContext.Provider>
        </StepsContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
