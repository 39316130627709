import {
  Button,
  Card,
  CardContent,
  Divider,
  getCardUtilityClass,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  FONTS,
  FONTS2,
  FONTS_AC,
  FONTS_CR,
  FONTS_INTER,
} from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import IMG from "../../Assets/sample.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import DeliverLabel from "../../Componets/DeliverLabel";
import {
  Categories,
  getProduct,
  CurrentUser,
  getMember,
  getPackages,
  getCart,
  getMemberCart,
} from "../../Services/API/Get";
import { addCart } from "../../Services/API/Post";
import { IMAGE_SERVER_URL_DEV, TIMEOUT } from "../../Constants/System";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { width } from "@mui/system";
import MealsDetails from "../Models/MealsDetails";
import { ViewModuleRounded } from "@mui/icons-material";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { packageToUser, packageToMember } from "../../Services/API/Put";
import CartCount from "../../Componets/CartCount";
import CartContext from "../../Context/CartContext";
import _ from "lodash";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

function ErrorPopup({ openToast, handleClose, alertClass, msg, msgDesc = "", close }) {
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": {
      // color: theme.palette.primary.main,
      fontSize: 14,
    },
  }));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxWidth: 300,
    borderRadius: "4px",
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });


  return (
    <>
      <Modal
        open={openToast}
        // onClose={openToast === true
        //   ? setTimeout(() => {
        //       handleClose(false);
        //     }, TIMEOUT)
        //   : false}
        // onBackdropClick={()=> handleClose(false)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {alertClass === "success" ?

                  <>
                    <CheckCircleIcon sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }} />

                    <Typography
                      component="h1"
                      variant="h5"
                      style={{
                        fontSize: "14pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        marginTop: 7,
                        textAlign: "center",
                      }}
                    >
                      Success
                    </Typography>

                  </>

                  :

                  <>

                    <WarningIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />

                    <Typography
                      component="h1"
                      variant="h5"
                      style={{
                        fontSize: "14pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        marginTop: 7,
                        textAlign: "center",
                      }}
                    >
                      {msgDesc ? msgDesc : "Error"}
                      {/* Error */}
                    </Typography>

                  </>

                }

                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    {msg}
                  </Typography>

                </Box>

              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>

    </>
  );
}

export default ErrorPopup;
