import { Typography } from "@mui/material";
import React, { useState } from "react";
import { FONTS, FONTS_ANOTON, FONTS_INTER } from "../../../Constants";
import { COLORS } from "../../../Constants/Colors";
import { 
  getAboutus,
  } from "../../../Services/API/Get";

function Aboutus() {
  const [aboutus, setaboutus] = useState("");

  const aboutGet  = () => {
    getAboutus()
      .then((res) => {
        console.log(res.data);
        setaboutus(res.data[res.data.length - 1].paragraph1)
      })
      .catch((error) => {
        console.log("error",error)
        console.log("current user error", error.response.data.message);
      });
     
  }
  
  React.useEffect(() => {
    aboutGet();
  }, []);

  return (
    <Typography variant="div">
      <Typography
        variant="h5"
        style={{
          textAlign: "left",
          
          color: COLORS.Primary,
          textTransform: "uppercase",
          // letterSpacing: 0.9,
          fontFamily: FONTS_ANOTON,
          fontSize:"20pt",
        }}
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
         Our Specialty
      </Typography>
      {/* <Typography
        variant="body1"
        style={{
       
          marginTop: 15,
          color: COLORS.TextColor,
          fontFamily: FONTS_INTER,
      fontSize:"13pt",
        }}
        sx={{
          textAlign: {
            xs: "center",
            sm: "justify",
          },
        }}
      >
       Gather round, we’re here to tell you a story
      </Typography> */}
      {/* <Typography
        variant="body1"
        style={{
        
          marginTop: 15,
          color: COLORS.TextColor,
          fontFamily: FONTS_INTER,
          fontSize: "13pt",
        }}
        sx={{
          textAlign: {
            xs: "center",
            sm: "justify",
          },
        }}
      >
        {"\t"}
        Founded by two passionate foodies who were concerned about their health and nutrition, 
        Ceylon Healthy Food entrées the Australian and Sri Lankan markets in 2022. 
        The vision to serve quality meals catering to the nutrition requirement of our customers while providing them with 
        a convenient alternative to preparing healthy meals at home is what we’re here for. 
      </Typography> */}
      <Typography
        variant="body1"
        style={{
        
          marginTop: 15,
          color: COLORS.TextColor,
          fontFamily: FONTS_INTER,
          fontSize: "13pt",
        }}
        sx={{
          textAlign: {
            xs: "center",
            sm: "justify",
          },
        }}
      >
        {"\t"}
        Our meal plans are prepared under the continuous guidance of our expert nutritionist, 
        using only the best quality ingredients with no added flavour. You will find yourself indulging in our 
        succulent meals with zero worries as our team works hard to fulfill your dietary requirement while 
        maintaining true balance in what you consume. 
      </Typography>
      {/* <Typography
        variant="body1"
        style={{
          textAlign: "justify",
          marginTop: 15,
          color: COLORS.TextColor,
          fontFamily: FONTS,
        }}
      >
        {"\t"}Depending on the specific company, some information about goals,
        attitude or other aspects of culture that aren't strictly tied to
        business practices are included as well. The about us page is often a
        reflection of the purpose and personality of the business and its owners
        or top employees. Finally, the page can also incorporate contact or
        locational information. One way to view the about us concept is as a
        text self-portrait or short autobiography created by a business.
      </Typography> */}
    </Typography>
  );
}

export default Aboutus;
