import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Package from "../Models/packagePopup.jsx";
import { RegisterUser } from "../../Services/API/Post";
import { FONTS, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import AVATAR from "../../Assets/profileAvatar.png";
import MemberProfile from "./MemberProfile.jsx";
import MainProfile from "./MainProfile.jsx";
import {
  CurrentUser,
  getMemberbyId,
  getPackages,
  getOrderCart,
  getOrderMemberCart,
} from "../../Services/API/Get";

function Profile() {
  const history = useHistory();
  const [mainProfile, setMainProfile] = useState(true);
  const [status, setstatus] = useState(false);
  const [memberId, setmemberId] = useState("");
  const [memeberProfiles, setMemeberProfiles] = useState([
    // {
    //   id: 1,
    //   status: false,
    // },
    // {
    //   id: 2,
    //   status: false,
    // },
  ]);
  const [userProfiles, setuserProfiles] = useState("");

  useEffect(() => {

    getUsers();

  }, [mainProfile]);

  useEffect(() => {

    if (status === true)

      getUsers();


  }, [status]);

  const getUsers = () => {

    console.log("clicked>>>>>>>>")

    let mData = [];
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          setuserProfiles(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          // setuserId(cData?.id);
          getMemberbyId(cData.id)
            .then((res) => {
              console.log(res.data);
              mData = res.data;
              // setMembers(res?.data);
              // if (mainProfile) {
              const update = mData?.map((d) => {

                console.log("asasasa", d.id, memberId)

                if (mainProfile) {

                  return { ...d, status: false, details: d };

                } else {

                  if (d.id === memberId)

                    return { ...d, status: true, details: d };

                  else

                    return { ...d, status: false, details: d };

                }




              });
              setMemeberProfiles(update);
              setstatus(false);
              // }
            })
            .catch((error) => {
              console.log("error", error);
              setMemeberProfiles([]);
            });

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });

  }

  // useEffect(() => {
  //   let mData = [];

  //   if(mainProfile)

  //   CurrentUser()
  //     .then((res) => {
  //       if (res.statusCode === 200) {
  //         const cData = res.data[0];
  //         setuserProfiles(cData);
  //         const obj = {
  //           id: cData.id,
  //           email: cData?.email,
  //           firstName: cData?.firstName,
  //           lastName: cData?.lastName,
  //           phone: cData?.mobileNumber,
  //         };
  //         // setuserId(cData?.id);
  //         getMemberbyId(cData.id)
  //           .then((res) => {
  //             console.log(res.data);
  //             mData = res.data;
  //             // setMembers(res?.data);
  //             console.log("<<<<<<<", mData);
  //             if (mainProfile) {
  //               const update = mData?.map((d) => {
  //                 return { ...d, status: false, details: d };
  //               });
  //               setMemeberProfiles(update);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("error", error);
  //             setMemeberProfiles([]);
  //           });

  //         localStorage.setItem(
  //           `${window.location.hostname}.profile`,
  //           JSON.stringify(obj)
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       console.log("current user error", error.response.data.message);
  //       // setOpenToast(true);
  //       // setColor(COLORS.Orange);
  //       // setAlertClass("error");
  //       // setMsg(error.response.data.message);
  //     });
  // }, [mainProfile]);
  return (
    <React.Fragment>
      <Grid
        style={{
          padding: "30px 0px",
        }}
      >
        {userProfiles !== "" ? (
          <>
            <MainProfile
              mainProfile={mainProfile}
              setMainProfile={setMainProfile}
              data={userProfiles}
            />
            {memeberProfiles?.map((d) => {
              return (
                <MemberProfile
                  getUsers={getUsers}
                  data={d}
                  setMemeberProfiles={setMemeberProfiles}
                  memeberProfiles={memeberProfiles}
                  setMainProfile={setMainProfile}
                  status={status}
                  setstatus={setstatus}
                  setmemberId={setmemberId}
                />
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Profile;
