import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  FONTS,
  FONTS2,
  FONTS_AC,
  FONTS_ANOTON,
  FONTS_CR,
  FONTS_INTER,
  FONTS_RC,
} from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import IMG from "../../Assets/sample.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import DeliverLabel from "../../Componets/DeliverLabel";
import {
  Categories,
  getProduct,
  CurrentUser,
  getMember,
  getPackages,
  getCart,
  getMemberCart,
} from "../../Services/API/Get";
import { addCart } from "../../Services/API/Post";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { height, textTransform, width } from "@mui/system";
import MealsDetails from "../Models/MealsDetails";
import { ViewModuleRounded } from "@mui/icons-material";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { packageToUser, packageToMember } from "../../Services/API/Put";
import CartCount from "../../Componets/CartCount";
import CartContext from "../../Context/CartContext";
import StepsContext from "../../Context/StepsContext";
import _ from "lodash";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorPopup from "../Models/errorPopup.jsx";
import LoginPopup from "../Models/LoginPopup";
import COMING from "../../Assets/bg_meal.png";

var moment = require("moment");

let toCart = [];

function Plans() {

  var matches = window.matchMedia("(max-width: 900px)");
  var matches1200 = window.matchMedia("(max-width: 1200px)");
  var matches600 = window.matchMedia("(max-width: 600px)");
  var matches340 = window.matchMedia("(max-width: 1340px)");
  var matches920 = window.matchMedia("(min-width: 1920px)");
  var matches1600 = window.matchMedia("(min-width: 1500px)");
  var matches1300 = window.matchMedia("(min-width: 1300px)");

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": {
      // color: theme.palette.primary.main,
      fontSize: matches600.matches ? "10pt" : matches.matches ? "20px" : "15px",
    },
  }));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxWidth: 300,
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  const [days, seDays] = useState([
    {
      id: "11",
      name: "Monday",
      status: true,
    },
    {
      id: "12",
      name: "Tuesday",
      status: false,
    },
    {
      id: "13",
      name: "Wednesday",
      status: false,
    },
    {
      id: "14",
      name: "Thursday",
      status: false,
    },
    {
      id: "15",
      name: "Friday",
      status: false,
    },
    {
      id: "16",
      name: "Saturday",
      status: false,
    },
    {
      id: "17",
      name: "Sunday",
      status: false,
    },
  ]);
  const history = useHistory();

  const { setCart, setMenuCategory, menuCategory, testRef, testRef3 } =
    useContext(CartContext);
  const [openLogin, setOpenLogin] = React.useState(false);
  const { setSteps } = useContext(StepsContext);
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const [productMealsIndex, setProductMealsIndex] = useState(0);
  const [category, setCategory] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [currUser, setcurrUser] = useState(null);
  const [scategory, setSCategory] = useState("");
  const [day, setDay] = useState("");
  const [products, setProducts] = useState([]);
  const [userId, setUserId] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [openViewMore, setOpenViewMore] = useState(false);
  const [dayId, setDayId] = useState(0);
  const [prodId, setprodId] = useState("");

  const [open2, setOpen2] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [packages, setPackages] = useState([1]);

  const [cartDetails, setCartdetails] = useState([]);
  const [McartDetails, setMCart] = useState([]);

  const handleClose2 = () => setOpen2(false);
  const handleClose = () => setOpen(false);

  const [errPop, seterrPop] = React.useState(false);
  const [meatType, setSelectedValue] = useState("");
  const [lDate, setlDate] = useState(new Date());
  const [mDate, setMDate] = useState(new Date());
  const [fDate, setFDate] = useState(new Date());
  const [cfDate, setCfDate] = useState(new Date());
  const [descId, setdescId] = useState(0);

  const [mealType, setmealType] = useState("Breakfast");

  const [toCartDetails, settoCartDetails] = useState([]);

  const [msgDesc, setMsgDesc] = useState("");

  const handleToastClose = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    console.log("to cart>>>>", toCartDetails)
  }, [toCartDetails])

  const cartAdd = () => {
    var objectSize = Object.keys(toCartDetails).length;

    let memberId = query.get("memberId");

    var cartLength = []

    if (memberId !== null) {

      cartLength = McartDetails

    } else {
      cartLength = cartDetails
    }

    console.log("LOOJNNN", objectSize)

    if (currUser === null) {
      if (matches.matches === true) {
        history.push("/login")

      } else {

        setOpenLogin(true);
      }
      return;
    }

    if ((cartLength.length === currUser?.noofmeals) && objectSize === 0) {

      setOpen2(true);

    } else if ((cartLength.length + objectSize) === currUser?.noofmeals) {
      AddtoCart2();
    } else {
      setOpenToast(true);
      setColor(COLORS.Orange);
      setAlertClass("error");
      setMsgDesc("Incomplete Meal Plan");
      setMsg(
        `Please complete full weekly plan for ${customer?.firstName + " " + customer.lastName} prior to checkout`
      );
    }

  }

  const cartDisable = () => {
    var objectSize = Object.keys(toCartDetails).length;

    let memberId = query.get("memberId");

    var cartLength = []

    if (memberId !== null) {

      cartLength = McartDetails

    } else {
      cartLength = cartDetails
    }

    if ((cartLength.length + objectSize) === customer.noofmeals) {
      return false;
    } else {
      return true;
    }
  }

  const setNextday = () => {

    let Day = new Date().toDateString().split(" ")[0]

    let nextDay = new Date();

    let fromDate = new Date();


    // if (Day === "Sat") {
    //   nextDay.setDate(fromDate.getDate() + 6);

    // } else if (Day === "Sun" && date.getHours() >= 15 && date.getMinutes() > 1) {
    //   nextDay.setDate(fromDate.getDate() + 5);

    // } else if (Day === "Sun" && date.getHours() < 15 && date.getMinutes() < 60) {
    //   nextDay.setDate(fromDate.getDate() + 2);

    // } else if (Day === "Mon") {
    //   nextDay.setDate(fromDate.getDate() + 4);

    // } else if (Day === "Tue") {
    //   nextDay.setDate(fromDate.getDate() + 3);

    // } else if (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60) {
    //   nextDay.setDate(fromDate.getDate() + 2);

    // } else if (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) {
    //   nextDay.setDate(fromDate.getDate() + 6);

    // }
    // else if (Day === "Thu") {
    //   nextDay.setDate(fromDate.getDate() + 5);

    // } else if (Day === "Fri") {
    //   nextDay.setDate(fromDate.getDate() + 4);
    // }

    if (Day === "Sat") {
      nextDay.setDate(fromDate.getDate() + 6);

    } else if (Day === "Sun") {
      nextDay.setDate(fromDate.getDate() + 5);

    } else if (Day === "Mon") {
      nextDay.setDate(fromDate.getDate() + 4);

    } else if (Day === "Tue") {
      nextDay.setDate(fromDate.getDate() + 3);

    } else if (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60) {
      nextDay.setDate(fromDate.getDate() + 2);

    } else if (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) {
      nextDay.setDate(fromDate.getDate() + 9);

    }
    else if (Day === "Thu") {
      nextDay.setDate(fromDate.getDate() + 8);

    } else if (Day === "Fri") {
      nextDay.setDate(fromDate.getDate() + 7);
    }

    console.log("asasas<<>>>>", nextDay)

    return nextDay;

  }

  useEffect(() => {


    return () => {
      toCart = []
    }
  }, [])



  let newList = [];

  let date = new Date();

  var descArr = [
    {
      description:
        "Is cholesterol giving you a headache? Our accredited nutritionist is here to ensure your food will maintain your cholesterol levels in balance.",
    },
    {
      description:
        "Are you tired of trying in vain to find clothes that highlight your figure? Keeping at a healthy weight can be accomplished by regular exercise and the enjoying of delicious food. Your diet is your most effective tool for managing your weight.",
    },
    {
      description:
        "Are you living with diabetes or worried about your sugar levels? Take charge of your diabetes situation with an accredited nutritionist’s style of healthy remedies.",
    },
    {
      description:
        "Are you struggling to find the ideal place to order healthy, freshly-prepared meals? Then this is the place for you. Maintain a balanced diet enriched with nutritional value that keeps you in great health and stamina throughout your week.",
    },
    {
      description:
        "Skinny much? We have a solution. Our gain plans will get you back in good shape with the right amount of nutrition you need. ",
    },
  ];

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  let meals = null;

  const getMainCategory = () => {
    let dayQuery = query.get("mealDay");
    let expDate = new Date();
    let mealDay = "";
    var nextDay = new Date(date);

    let Day = date.toDateString().split(" ")[0];

    // nextDay.setDate(day.getDate() + 1);
    if (dayQuery === null) {

      // if (date.getHours() >= 15 && date.getMinutes() > 1) {
      //   nextDay.setDate(date.getDate() + 3);
      //   setlDate(nextDay);
      //   setMDate(nextDay);
      //   setFDate(nextDay);
      //   setCfDate(nextDay);
      // } else if (date.getHours() < 15 && date.getMinutes() < 60) {
      //   nextDay.setDate(date.getDate() + 2);
      //   setlDate(nextDay);
      //   setMDate(nextDay);
      //   setFDate(nextDay);
      //   setCfDate(nextDay);
      // }


      // if (Day === "Sun" && date.getHours() >= 15 && date.getMinutes() > 1) {
      //   nextDay.setDate(date.getDate() + 6);

      // } else if (Day === "Mon") {
      //   nextDay.setDate(date.getDate() + 5);

      // } else if (Day === "Tue") {
      //   nextDay.setDate(date.getDate() + 4);

      // } else if (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60) {
      //   nextDay.setDate(date.getDate() + 3);

      // } else if (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) {
      //   nextDay.setDate(date.getDate() + 7);

      // } else if (Day === "Thu") {
      //   nextDay.setDate(date.getDate() + 6);

      // } else if (Day === "Fri") {
      //   nextDay.setDate(date.getDate() + 5);

      // } else if (Day === "Sat") {
      //   nextDay.setDate(date.getDate() + 4);

      // } else if (Day === "Sun" && date.getHours() < 15 && date.getMinutes() < 60) {
      //   nextDay.setDate(date.getDate() + 3);

      // }

      // if (Day === "Sun" && date.getHours() >= 15 && date.getMinutes() > 1) {
      //   nextDay.setDate(date.getDate() + 6);

      // } else 

      if (Day === "Mon") {
        nextDay.setDate(date.getDate() + 7);

      } else if (Day === "Tue") {
        nextDay.setDate(date.getDate() + 6);

      } else if (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60) {
        nextDay.setDate(date.getDate() + 5);

      } else if (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) {
        nextDay.setDate(date.getDate() + 12);

      } else if (Day === "Thu") {
        nextDay.setDate(date.getDate() + 11);

      } else if (Day === "Fri") {
        nextDay.setDate(date.getDate() + 10);

      } else if (Day === "Sat") {
        nextDay.setDate(date.getDate() + 9);

      } else if (Day === "Sun") {
        nextDay.setDate(date.getDate() + 8);

      }

      // if (Day === "Mon") {
      //   nextDay.setDate(date.getDate() - 9);

      // } else if (Day === "Tue") {
      //   nextDay.setDate(date.getDate() - 8);

      // } else if (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60) {
      //   nextDay.setDate(date.getDate() - 7);

      // } else if (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) {
      //   nextDay.setDate(date.getDate());

      // } else if (Day === "Thu") {
      //   nextDay.setDate(date.getDate() - 1);

      // } else if (Day === "Fri") {
      //   nextDay.setDate(date.getDate() - 2);

      // } else if (Day === "Sat") {
      //   nextDay.setDate(date.getDate() - 3);

      // } else if (Day === "Sun") {
      //   nextDay.setDate(date.getDate() - 4);

      // }

      setlDate(nextDay);
      setMDate(nextDay);
      setFDate(nextDay);
      setCfDate(nextDay);




    } else {
      expDate = new Date(dayQuery);
      expDate.setDate(expDate.getDate() + 7);
      mealDay = moment(new Date(dayQuery)).format("ddd");
      setlDate(expDate);
      setMDate(expDate);
      setFDate(expDate);
    }


    if (
      (Day === "Sat" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Sun" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      let week = [
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Tuesday");
    } else if (
      (Day === "Sun" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Mon" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("wed<><><><>");
      let week = [
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Wednesday");
    } else if (
      (Day === "Mon" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Tue" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("thu<><><><>");
      let week = [
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Thursday");
    } else if (
      (Day === "Tue" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Wed" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("fri<><><><>");
      let week = [
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Friday");
    } else if (
      (Day === "Wed" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Thu" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("sat<><><><>");
      let week = [
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Saturday");
    } else if (
      (Day === "Thu" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Fri" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("sun<><><><>");
      let week = [
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Sunday");
    } else if (
      (Day === "Fri" && date.getHours() >= 15 && date.getMinutes() > 1) ||
      (Day === "Sat" && date.getHours() < 15 && date.getMinutes() < 60)
    ) {
      console.log("mon<><><><>");
      let week = [
        {
          id: "11",
          name: "Monday",
          status:
            dayQuery !== null && mealDay === "Mon"
              ? true
              : dayQuery === null
                ? true
                : false,
        },
        {
          id: "12",
          name: "Tuesday",
          status:
            dayQuery !== null && mealDay === "Tue"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "13",
          name: "Wednesday",
          status:
            dayQuery !== null && mealDay === "Wed"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "14",
          name: "Thursday",
          status:
            dayQuery !== null && mealDay === "Thu"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "15",
          name: "Friday",
          status:
            dayQuery !== null && mealDay === "Fri"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "16",
          name: "Saturday",
          status:
            dayQuery !== null && mealDay === "Sat"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
        {
          id: "17",
          name: "Sunday",
          status:
            dayQuery !== null && mealDay === "Sun"
              ? true
              : dayQuery === null
                ? false
                : false,
        },
      ];
      seDays(week);
      setDay("Monday");
    }

    Categories()
      .then((res) => {
        setCategory(res.data);
        if (menuCategory != null) {
          setSCategory(menuCategory);
          res.data?.map((q, index) => {
            if (q.mainId === menuCategory) {
              setdescId(index);
            }
          });
        } else {
          setSCategory(res.data[0].mainId);

          setdescId(0);
        }

        // getProduct(JSON.stringify([day]),JSON.stringify([id]))
        // .then((res) => {
        //   console.log("Product >>",res.data);
        //   setProducts(res.data.result)

        //   // setCategory(res.data);
        // })
        // .catch((error) => {
        //   console.log("error", error);
        //   console.log("current package error", error.response.data.message);

        // });
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
      });
  };

  const getProducts = () => {
    let dayQuery = query.get("mealDay");

    var nextDay = new Date(date);

    let toDate = new Date(mDate);
    let fromDate = new Date(fDate);
    let Daymeal = "";

    // nextDay.setDate(day.getDate() + 1);

    if (date.getHours() >= 15 && date.getMinutes() > 1) {
      nextDay.setDate(date.getDate() + 3);
    } else if (date.getHours() < 15 && date.getMinutes() < 60) {
      nextDay.setDate(date.getDate() + 2);
    }


    // toDate.setDate(mDate.getDate() + 8);

    toDate.setDate(cfDate.getDate() + 9);


    if (dayQuery !== null) {
      Daymeal = moment(fromDate).format("dddd");
    } else {
      // Daymeal = day;
      Daymeal = moment(fromDate).format("dddd");
    }

    console.log("start>>>", moment(cfDate).format("YYYY-MM-DD"))
    console.log("end>>>", moment(toDate).format("YYYY-MM-DD"))

    var weekNo = Math.ceil(moment(cfDate).date() / 7)

    console.log("week>>>", weekNo)

    getProduct(
      // JSON.stringify([Daymeal]),
      JSON.stringify([]),
      JSON.stringify([mealType]),
      JSON.stringify([scategory]),
      moment(cfDate).format("YYYY-MM-DD") + " " + "00:00:00",
      // moment(toDate).format("YYYY-MM-DD") + " " + "00:00:00",
      "9999-12-31" + " " + "00:00:00",
      // "",
      // "",
      // weekNo > 3 ? weekNo - 3 : weekNo,
      1,
      9
    )
      .then((res) => {
        var newArr = [];

        res.data.result?.map((d) => {
          if (d.mealType === "Breakfast") {
            newArr.push(d);
          }
        });
        res.data.result?.map((d) => {
          if (d.mealType === "Lunch") {
            newArr.push(d);
          }
        });
        res.data.result?.map((d) => {
          if (d.mealType === "Dinner") {
            newArr.push(d);
          }
        });
        console.log("get All products------>>>>", res.data);

        setProducts(newArr);
        setSelectedValue("");

        // setCategory(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setProducts([]);
        setSelectedValue("");
      });
  };

  const getCurrentUser = () => {
    let memberId = query.get("memberId");

    if (memberId !== null) {
      getMember(memberId)
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];

            setUserId(cData?.id);
            setAddress(cData?.homeAddress);
            setCity(cData?.homeCity);
            setCustomer(cData);
            setcurrUser(cData);
          }
        })
        .catch((error) => {
          setcurrUser(null);
          console.log("error", error);
          console.log("current user error", error.response.data.message);
        });
    } else {
      CurrentUser()
        .then((res) => {

          if (res.statusCode === 200) {
            const cData = res.data[0];

            setUserId(cData?.id);
            setAddress(cData?.homeAddress);
            setCity(cData?.homeCity);
            setCustomer(cData);

            setcurrUser(cData);
          }
        })
        .catch((error) => {
          setcurrUser(null);
          console.log("error", error);
          console.log("current user error", error.response.data.message);
        });
    }
  };

  const AddtoCart2 = () => {
    var products = toCart.filter(function (e) {
      return e != null;
    });

    let memberId = query.get("memberId");


    let cItem = Object.entries(toCartDetails).map(([k, v]) => ({ [k]: v }));
    // console.log("^^^^^^^^^^^", cItem);
    var cItem2 = cItem.sort(function (a, b) {
      if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
      return -1;
    });
    console.log("ZXZXZXZX", cItem2);

    var newArr = []

    const update2 = cItem2?.map((d) => {

      newArr.push(d[Object.keys(d)[0]])

    });

    console.log("newArr>>", newArr)

    // let fromDate = new Date(fDate);

    // product.qty = 1;
    // product.meatType = mType;
    // product.deliveryDate =
    //   moment(fromDate).format("YYYY-MM-DD") + " " + "00:00:00";

    let obj = {
      // productId: productId.toString(),
      userId: userId,
      deliveryAddress: address,
      city: city,
      productDetails: newArr,
      // productDetails:product,
      customerDetails: customer,
      currentTime: moment(new Date()).format("YYYY-MMMM-DD HH:mm:ss"),
      // meatType: mType,
    };
    console.log(">>>", obj);

    if (currUser === null) {
      if (matches.matches === true) {
        history.push("/login")

      } else {

        setOpenLogin(true);
      }
      return;
    }

    // if (newArr.length === 0) {
    //   setOpenToast(true);
    //   setColor(COLORS.Orange);
    //   setAlertClass("error");
    //   setMsg("Please choose the meat options. ");
    // }

    if (!(newArr.length === 0)) {
      if (memberId !== null) {
        let obj1 = {
          // productId: productId.toString(),
          userId: memberId,
          deliveryAddress: address,
          city: city,
          productDetails: newArr,
          // productDetails:product,
          customerDetails: customer,
          currentTime: moment(new Date()).format("YYYY-MMMM-DD HH:mm:ss"),
          // meatType: meatType,
          member: 1,
        };
        console.log("To cart >>>>>", obj1);
        addCart(obj1)
          .then((res) => {
            console.log(res.data);

            setOpenToast(true);
            setColor(COLORS.Primary);
            setAlertClass("success");
            setMsg("Product Added to cart");
            CartCount().then((res) => {
              setCart(res);
            });
            setSteps(3);
            setSelectedValue("");
            CartGet();
            toCart = [];
            settoCartDetails([]);
          })
          .catch((error) => {
            console.log("error", error.response);
            console.log("current user error", error.response.data.message);
            if (error.response.data.statusCode === 401) {
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
              if (matches.matches === true) {
                history.push("/login")

              } else {

                setOpenLogin(true);
              }
              setTimeout(() => {
                // history.push("/login");
                // window.location.reload();
              }, 3000);
            } else if (
              error.response.data.errorMessage === "package exceeded"
            ) {
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);

              // setTimeout(() => {
              //   setOpen2(true);
              // }, 2000);
              setOpen2(true);
            } else if (error.response.data.errorMessage === "Please select your package before creating meal plans. ") {
              setOpen(true);
            }

            else {
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsgDesc("");
              setMsg(error.response.data.message);
            }
          });
      } else {
        console.log("To cart >>>>>", obj);

        addCart(obj)
          .then((res) => {
            setOpenToast(true);
            setColor(COLORS.Primary);
            setAlertClass("success");
            setMsg("Product Added to cart");
            CartCount().then((res) => {
              setCart(res);
            });
            setSelectedValue("");
            CartGet();
            toCart = [];
            settoCartDetails([])
          })
          .catch((error) => {
            console.log("error", error.response);
            console.log("current user error", error.response.data.message);
            if (error.response.data.statusCode === 401) {
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
              // setTimeout(() => {
              //   history.push("/login");
              //   window.location.reload();
              // }, 3000);
              if (matches.matches === true) {
                history.push("/login")

              } else {

                setOpenLogin(true);
              }
            } else if (

              error.response.data.errorMessage === "package exceeded"
            ) {
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);

              // setTimeout(() => {
              //   setOpen2(true);
              // }, 2000);
              setOpen2(true);
            } else if (error.response.data.errorMessage === "Please select your package before creating meal plans. ") {
              setOpen(true);
            }

            else {
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsgDesc("");
              setMsg(error.response.data.message);
            }
          });
      }
    }
  };

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log("get all packages", res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsgDesc("");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId) => {
    console.log("pkg id >>>", pkgId);

    let planType = "";

    let memberId = query.get("memberId");

    if (memberId !== null) {
      planType = "member";
    } else {
      planType = "user";
    }

    const obj = {
      packageId: pkgId,
    };
    if (planType === "user") {
      packageToUser(obj, userId, "update")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setOpen(false);
          setAlertClass("success");
          setMsg("Package Assigned to User");
          getCurrentUser();
          // setTimeout(() => {
          //   history.push("/plans");
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    } else if (planType === "member") {
      packageToMember(obj, memberId, "update")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setOpen(false);
          setMsg("Package Assigned to Member");
          getCurrentUser();
          // setTimeout(() => {
          //   history.push(`/plans?memberId=${memberId}`);
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsgDesc("");
          setMsg(error.response.data.message);
        });
    }
  };

  const CartGet = () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;

    let memberId = query.get("memberId");

    CurrentUser().then((res) => {
      if (res.statusCode === 200) {
        const cData = res.data[0];

        // setCustomer(cData);

        // setname(cData.firstName + " " + cData.lastName);

        getCart({
          sortField: sortField,
          sort: sort,
          limit: limit,
          page: page,
          userId: cData?.id,
        })
          .then((res) => {
            console.log("Cart >>>", res.data);

            var newArray1 = res.data?.filter(function (el) {
              return el.expire === 0;
            });

            setCartdetails(newArray1);
            // setTotal(res.data[0].price);
            // setitems(res.data.length);
            // setMeals(res.data[0].noofmeals);
            // Address = res.data
            // setAddress(res.data);
          })
          .catch((error) => {
            console.log("error", error);
            console.log("current package error", error.response.data.message);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });

        if (memberId != null) {
          getMemberCart({
            sortField: sortField,
            sort: sort,
            limit: limit,
            page: page,
            userId: cData?.id,
          })
            .then((res) => {
              console.log("Member Cart >>>", res.data);

              _.map(res.data, "members")?.map((item, index) => {
                item.map((data, index1) => {
                  if (data.memberId === parseInt(memberId)) {
                    newList.push(data);
                  }
                });
              });

              console.log("new list >>>>", newList);

              var newArray2 = newList?.filter(function (el) {
                return el.expire === 0;
              });

              setMCart(newArray2);
              // setTotal(res.data[0].price)
              // setitems(res.data.length)
              // setMeals(res.data[0].noofmeals)
              // setAddress(res.data)
            })
            .catch((error) => {
              console.log("error", error);
              console.log("current package error", error.response.data.message);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        }
      }
    });
  };
  const PROUDCT_MEALS_CARD = ({ item, index }) => {
    const [mType, setMtype] = useState("");
    let memberId = query.get("memberId");
    let product = Object.assign({}, item);
    let cdisable = false;
    var result = "";

    if (
      _.map(cartDetails, "productId").includes(item.id) &&
      memberId === null
    ) {
      result = cartDetails.find((obj) => {
        return obj.productId === item.id;
      });
      // console.log("QWQWQWQW",result)
      cdisable = true;
    } else if (
      _.map(McartDetails, "productId").includes(item.id) &&
      memberId !== null
    ) {
      result = McartDetails.find((obj) => {
        return obj.productId === item.id;
      });
      cdisable = true;
    } else {
      cdisable = false;
    }

    let fromDate = new Date(fDate);

    product.qty = 1;
    product.deliveryDate =
      moment(setNextday()).format("YYYY-MM-DD") + " " + "00:00:00";

    const handleChange = (event) => {
      console.log("type>>>>>", event.target.value);
      product.meatType = event.target.value;
      setMtype(event.target.value);
      product.mainId = scategory;
      // let newARR = [...toCartDetails]
      toCart[product.id] = product;

      var cartLength = []

      if (memberId !== null) {

        cartLength = McartDetails

      } else {
        cartLength = cartDetails
      }

      var objectSize = Object.keys(toCartDetails).length;

      console.log("cust||||||", customer, objectSize);

      if (currUser === null) {
        if (matches.matches === true) {
          history.push("/login")

        } else {

          setOpenLogin(true);
        }

      } else {

        if (customer.packageId === null) {
          setOpen(true);
          return;
        }

        if ((cartLength.length + objectSize) === customer.noofmeals) {
          setOpen2(true)
        } else {

          var newInput = Object.assign({},
            toCartDetails, { [product.id]: product });

          settoCartDetails(newInput)

        }


        // newARR[product.id] = product

        // settoCartDetails(newARR)
        console.log("||||||", toCart);

      }




    };

    return (
      <Grid
        item
        md={3.8}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          alignItems: "center",

          width: "100%",
        }}
        sx={{
          marginTop: {
            xs: "0px",
            sm: "15px",
          },
          marginBottom: {
            xs: "0px",
            sm: "15px",
          },
        }}
      >
        <div
          style={{
            background: COLORS.GrayBackground,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* <Typography
            gutterBottom
            variant="h6"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              background: COLORS.Primary,
              color: "white",
              textAlign: "center",
              padding: "5px 0px",
              fontFamily: FONTS_CR,
              fontSize: "20pt",
              textTransform: "capitalize",
            }}
          >
            {item.mealType}
          </Typography> */}
          <img
            src={`${IMAGE_SERVER_URL_DEV}${item.image}`}
            style={{
              width: "100%",
              objectFit: "cover",
              background: "white",
              height: "19vW",
            }}
          />
        </div>
        <Typography
          variant="body1"
          style={{
            fontFamily: FONTS_AC,
            fontSize: "14pt",
            color: COLORS.Primary,
            padding: "15px 0px",
            textTransform: "capitalize",
          }}
        >
          {item.productName}
        </Typography>
        <Divider style={{ width: "60%" }} />
        <Typography
          variant="body2"
          style={{
            fontFamily: FONTS_INTER,
            fontSize: "11pt",
            color: COLORS.TextColor,
            padding: "10px 0px",
            fontWeight: "bold",
          }}
        >
          Choose your meat option
        </Typography>
        <FormControl
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
          // value={value}
          // onChange={handleChange}
          >
            {JSON.parse(item?.meatType)?.map((item) => {

              return (
                <StyledFormControlLabel
                  value={item}
                  control={
                    <Radio
                      name="abc"
                      // disabled = {cdisable}
                      checked={
                        // result?.meatType === item ? true : item === mType
                        result?.meatType === item ? true : toCartDetails[product.id]?.meatType === item ? true : false
                      }
                      size="small"
                      style={{
                        color:
                          cdisable === true ? COLORS.GrayDark2 : COLORS.Primary,
                        padding: 4,
                      }}
                      onChange={cdisable === false ? handleChange : null}
                      onClick={() => {
                        if (cdisable === true) {
                          setOpenToast(true);
                          setAlertClass("error");
                          setMsgDesc("");
                          setMsg(
                            `Item "${product.productName}" already exist in Cart`
                          );
                        } else if (toCart[product.id]?.meatType === item) {


                          var newInput = Object.assign({},
                            toCartDetails, delete toCartDetails[product.id]);

                          settoCartDetails(newInput)
                        }
                      }}
                    />
                  }
                  label={item}
                  style={{
                    color:
                      cdisable === true ? COLORS.TextColor3 : COLORS.TextColor,
                    fontFamily: FONTS_INTER,
                    fontSize: "11pt",
                  }}
                />
              );
            })}
          </RadioGroup>{" "}
        </FormControl>

        <Typography
          onClick={() => {
            setOpenViewMore(true);

            setprodId(item);
          }}
          // variant="body1"
          style={{
            padding: "6px 20px",
            borderRadius: "20px",
            width: "fit-content",
            margin: "15px 0px 15px",
            color: COLORS.Primary,
            fontFamily: FONTS_INTER,
            fontSize: "11pt",
            cursor: "pointer",
            border: ` 1px solid ${COLORS.Primary}`,
            fontWeight: "bold",
          }}
        >
          View more
        </Typography>
      </Grid>
    );
  };
  const PROUDCT_MEALS_CARD_MOBILE = ({ item, index }) => {
    const [mType, setMtype] = useState("");
    let memberId = query.get("memberId");
    let product = Object.assign({}, item);
    let cdisable = false;
    var result = "";

    if (
      _.map(cartDetails, "productId").includes(item.id) &&
      memberId === null
    ) {
      result = cartDetails.find((obj) => {
        return obj.productId === item.id;
      });
      // console.log("QWQWQWQW",result)
      cdisable = true;
    } else if (
      _.map(McartDetails, "productId").includes(item.id) &&
      memberId !== null
    ) {
      result = McartDetails.find((obj) => {
        return obj.productId === item.id;
      });
      cdisable = true;
    } else {
      cdisable = false;
    }

    let fromDate = new Date(fDate);

    product.qty = 1;
    product.deliveryDate =
      moment(setNextday()).format("YYYY-MM-DD") + " " + "00:00:00";

    const handleChange = (event) => {
      console.log("type>>>>>", event.target.value);
      product.meatType = event.target.value;
      product.mainId = scategory;
      setMtype(event.target.value);
      // let newARR = [...toCartDetails]
      toCart[product.id] = product;

      var cartLength = []

      if (memberId !== null) {

        cartLength = McartDetails

      } else {
        cartLength = cartDetails
      }

      var objectSize = Object.keys(toCartDetails).length;

      console.log("cust||||||", customer, objectSize);

      if (currUser === null) {

        if (matches.matches === true) {
          history.push("/login")

        } else {

          setOpenLogin(true);
        }

      } else {

        if (customer.packageId === null) {
          setOpen(true);
          return;
        }

        if ((cartLength.length + objectSize) === customer.noofmeals) {
          setOpen2(true)
        } else {

          var newInput = Object.assign({},
            toCartDetails, { [product.id]: product });

          settoCartDetails(newInput)

        }


        // newARR[product.id] = product

        // settoCartDetails(newARR)
        console.log("||||||", toCart);

      }




    };

    return (
      <Grid
        container
        xs={12}
        style={{ padding: 0, margin: 0, marginBottom: "0.5vh" }}
      >
        <Grid item xs={5.5} style={{ padding: 0, margin: 0, height: "31vh",display:"flex",alignItems:"center",background: COLORS.GrayDark3, }}>
          {/* <Typography
            sx={{
              fontSize: {
                xs: "4vW",
                sm: "24px",
              }
            }}
            style={{
              fontFamily: FONTS_CR,
              background: COLORS.GrayDark3,
              color: "transparent",
              width: "100%",
              textAlign: "center",
              height: "3.5vh",
              // fontSize: "4vw",
            }}
          >
            {item.mealType}
          </Typography> */}
          <img
            src={`${IMAGE_SERVER_URL_DEV}${item.image}`}
            style={{
              width: "100%",
              height: "27.5vh",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid
          item
          xs={6.5}
          style={{
            background: COLORS.GrayDark3,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: 10,
            height: "31vh",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "4vW",
                sm: "24px",
              }
            }}
            style={{
              fontFamily: FONTS_AC,
              color: COLORS.Primary,
              marginBottom: "0.5vh",
              // fontSize: "4vw",
            }}
          >
            {item.productName}
          </Typography>
          <Divider style={{ width: "60%" }} />
          <FormControl
            style={{
              display: "flex",
              flex: 1,
              marginTop: "0.5vh",
              marginRight: -5,
            }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            // value={value}
            // onChange={handleChange}
            >
              {JSON.parse(item?.meatType)?.map((item) => {
                return (
                  <StyledFormControlLabel
                    value={item}
                    control={
                      <Radio
                        name="abc"
                        // disabled = {cdisable}
                        checked={
                          // result?.meatType === item ? true : item === mType
                          result?.meatType === item ? true : toCartDetails[product.id]?.meatType === item ? true : false
                        }
                        // size="small"
                        style={{
                          color:
                            cdisable === true ? COLORS.GrayDark2 : COLORS.Primary,
                          padding: 4,
                        }}
                        onChange={cdisable === false ? handleChange : null}
                        onClick={() => {
                          if (cdisable === true) {
                            setOpenToast(true);
                            setAlertClass("error");
                            setMsgDesc("");
                            setMsg(
                              `Item "${product.productName}" already exist in Cart`
                            );
                          } else if (toCart[product.id]?.meatType === item) {


                            var newInput = Object.assign({},
                              toCartDetails, delete toCartDetails[product.id]);

                            settoCartDetails(newInput)
                          }
                        }}
                      />
                    }
                    label={item}

                    style={{
                      color:
                        cdisable === true ? COLORS.TextColor3 : COLORS.TextColor,
                      fontFamily: FONTS_INTER,
                      fontSize: matches600.matchees === true ? "11pt" : "2px",
                    }}
                  />
                );
              })}
            </RadioGroup>{" "}
          </FormControl>
          <Button
            onClick={() => {
              setOpenViewMore(true);

              setprodId(item);
            }}
            style={{
              fontFamily: FONTS_AC,
              color: "white",
              background: COLORS.Orange,
              padding: "0px",
              fontSize: "3.2vw",
              borderRadius: "10vw",
              textTransform: "inherit",
            }}
          >
            View
          </Button>
        </Grid>
      </Grid>
      // <Grid
      //   item
      //   md={3.8}
      //   xs={12}
      //   style={{
      //     display: "flex",
      //     flexDirection: "column",
      //     background: "white",
      //     alignItems: "center",

      //     width: "100%",
      //   }}
      //   sx={{
      //     marginTop: {
      //       xs: "0px",
      //       sm: "15px",
      //     },
      //   }}
      // >
      //   <div
      //     style={{
      //       background: COLORS.GrayBackground,
      //       display: "flex",
      //       flexDirection: "column",
      //       width: "100%",
      //     }}
      //   >
      //     <Typography
      //       gutterBottom
      //       variant="h6"
      //       style={{
      //         display: "flex",
      //         justifyContent: "center",
      //         width: "100%",
      //         background: COLORS.Primary,
      //         color: "white",
      //         textAlign: "center",
      //         padding: "5px 0px",
      //         fontFamily: FONTS_CR,
      //         fontSize: "20pt",
      //         textTransform: "capitalize",
      //       }}
      //     >
      //       {item.mealType}
      //     </Typography>
      //     <img
      //       src={`${IMAGE_SERVER_URL_DEV}${item.image}`}
      //       style={{
      //         width: "100%",
      //         objectFit: "cover",
      //         background: "white",
      //         height: "215px",
      //       }}
      //     />
      //   </div>
      //   <Typography
      //     variant="body1"
      //     style={{
      //       fontFamily: FONTS_AC,
      //       fontSize: "16pt",
      //       color: COLORS.Primary,
      //       padding: "15px 0px",
      //       textTransform: "capitalize",
      //     }}
      //   >
      //     {item.productName}
      //   </Typography>
      //   <Divider style={{ width: "60%" }} />
      //   <Typography
      //     variant="body2"
      //     style={{
      //       fontFamily: FONTS_INTER,
      //       fontSize: "11pt",
      //       color: COLORS.TextColor,
      //       padding: "10px 0px",
      //       fontWeight: "bold",
      //     }}
      //   >
      //     Choose your meat option
      //   </Typography>
      //   <FormControl
      //     style={{
      //       display: "flex",
      //       flex: 1,
      //     }}
      //   >
      //     <RadioGroup
      //       aria-labelledby="demo-controlled-radio-buttons-group"
      //       name="controlled-radio-buttons-group"
      //       // value={value}
      //       // onChange={handleChange}
      //     >
      //       {JSON.parse(item?.meatType)?.map((item) => {
      //         return (
      //           <StyledFormControlLabel
      //             value={item}
      //             control={
      //               <Radio
      //                 // disabled = {cdisable}
      //                 checked={
      //                   result?.meatType === item ? true : item === mType
      //                 }
      //                 size="small"
      //                 style={{
      //                   color:
      //                     cdisable === true ? COLORS.GrayDark2 : COLORS.Primary,
      //                   padding: 4,
      //                 }}
      //                 onChange={cdisable === false ? handleChange : null}
      //                 onClick={() => {
      //                   if (cdisable === true) {
      //                     setOpenToast(true);
      //                     setAlertClass("error");
      //                     setMsg(
      //                       `Item "${product.productName}" already exist in Cart`
      //                     );
      //                   }
      //                 }}
      //               />
      //             }
      //             label={item}
      //             style={{
      //               color:
      //                 cdisable === true ? COLORS.TextColor3 : COLORS.TextColor,
      //               fontFamily: FONTS_INTER,
      //               fontSize: "11pt",
      //             }}
      //           />
      //         );
      //       })}
      //     </RadioGroup>{" "}
      //   </FormControl>

      //   <Typography
      //     onClick={() => {
      //       setOpenViewMore(true);

      //       setprodId(item);
      //     }}
      //     // variant="body1"
      //     style={{
      //       color: COLORS.TextColor,
      //       padding: "8px 20px",
      //       borderRadius: "20px",
      //       width: "fit-content",
      //       margin: "0px 0px 15px",
      //       color: COLORS.TextColor,
      //       fontFamily: FONTS_INTER,
      //       fontSize: "11pt",
      //       cursor: "pointer",
      //     }}
      //   >
      //     View more
      //   </Typography>
      // </Grid>
    );
  };
  const PROUDCT_MEALS_CARD_R = ({ data, meals }) => {
    return (
      <Grid
        item
        xs={4}
        // md={4}
        spacing={0}
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "10px 20px",
        }}
      >
        <Card
          style={{
            // minWidth: 265,
            minWidth: "100%",
            maxWidth: 300,

            backgroundColor:
              customer.packageId !== null && (data.id === parseInt(customer.packageId) ||
                data.noofmeals < meals ||
                meals === null)
                ? COLORS.GrayDark
                : COLORS.Primary,
          }}
        >
          <CardContent
            style={{
              padding: matches.matches ? "14px 18px" : "27px",
            }}
          >
            <Typography
              style={{
                fontSize: matches?.matches ? "28pt" : "30pt",
                color: COLORS.Orange2,
                fontWeight: 400,
                fontFamily: FONTS_AC,
                textTransform: "uppercase",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {data?.name}
            </Typography>
            <Typography
              component="div"
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "10px 0px 0px",
                // marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: matches.matches ? "98px" : 110,
                  height: matches.matches ? "98px" : 110,
                  borderRadius: "50%",
                  backgroundColor: "#e8eeee",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: COLORS.PrimaryText,
                    fontSize: matches.matches ? "38.2pt" : "41.2pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                    lineHeight: "40px",
                  }}
                >
                  {data.noofmeals}
                </Typography>
                <Typography
                  style={{
                    color: COLORS.TextColor,
                    fontSize: matches.matches ? "12.9pt" : "13.9pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "500",
                  }}
                >
                  MEALS
                </Typography>
              </div>
            </Typography>
            <Typography
              style={{
                // mb: 1.5,
                // mt: 3,
                color: "white",
                textAlign: "center",
                fontSize: "9.9pt",
                fontFamily: FONTS_INTER,
                margin: "20px 0px 10px",
                minHeight: "200px",
              }}
              color="text.secondary"
            >
              {data.description}
            </Typography>
            <Typography>
              <p
                style={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: matches.matches ? "29.9pt" : "32.9pt",
                  fontFamily: FONTS_AC,
                  margin: "10px 0px",
                }}
              >
                {" "}
                {data.price}
                {" AUS "}
              </p>
            </Typography>
            <Typography
              variant="body2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                disabled={
                  customer.packageId !== null && (data.id === parseInt(customer.packageId) ||
                    data.noofmeals < meals ||
                    meals === null
                  ) ? true
                    : false
                }
                style={{
                  background:
                    customer.packageId !== null && (data.id === parseInt(customer.packageId) ||
                      data.noofmeals < meals ||
                      meals === null
                    ) ? COLORS.GrayDark2
                      : COLORS.Orange,
                  fontSize: "13pt",
                  fontFamily: FONTS_INTER,
                  // background-color: rgb(255, 168, 20);
                  color: "white",
                  height: " 36px",
                  width: " 125px",

                  fontWeight: "bold",
                }}
                onClick={() => assignPackage(data.id)}
              >
                SELECT
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  React.useEffect(() => {
    if (scategory !== "") {
      getProducts();
    }
  }, [scategory, day, mDate, mealType]);
  React.useEffect(() => {
    if (menuCategory !== null) {
      setSCategory(menuCategory);
      console.log("------>>>", category);
      category?.map((q, index) => {
        if (q.mainId === menuCategory) {
          setdescId(index);
        }
      });
    }
  }, [menuCategory]);

  React.useEffect(() => {
    getMainCategory();
    getCurrentUser();
    getPackage();
    CartGet();
  }, [menuCategory]);

  return (
    <>
      <ErrorPopup
        openToast={openToast}
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
        msgDesc={msgDesc}
      />
      <LoginPopup
        open={openLogin}
        handleClose={() => {
          setOpenLogin(false);
          // history.goBack();
        }}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center",
                  }}
                >
                  Please Upgrade Your Package
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    You have reached the maximum number of meals of your current
                    package. If you want to add more meals please upgrade your
                    package.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 10,
                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    {/* For any clarifications please contact our Order Processing
                    Unit between 9 am and 5 pm through (03) 8794 8694 */}
                  </Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 5, mb: 2 }}
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                      setOpen2(false);
                    }}
                  >
                    UPGRADE NOW
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: matches?.matches ? "90vw" : "95vw",
            maxHeight: "98vh",
            overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              padding: matches?.matches ? "15px" : "30px",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: matches.matches ? "10px" : "0px",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={12}
                sm={11}
                container
                spacing={2}
                style={{
                  marginLeft: matches?.matches ? "0px" : "-16px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                    justifyContent: "flex-end",
                    display: matches?.matches ? "flex" : "none",
                  }}
                >
                  <div onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: matches.matches ? "22pt" : "26pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "bold",
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </Typography>
                  </div>
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{
                    justifyContent: "center",
                    display: matches1200?.matches ? "none" : "flex",
                    width: "100%",
                  }}
                  // className="hideScrollBar"
                  sx={{
                    flexWrap: {
                      // sm: "nowrap",
                      xs: "nowrap",
                      // lg: "nowrap"
                    },
                    // flexDirection: {
                    //   xs: "column",
                    //   sm: "row",
                    // },
                    overflowX: "auto",

                  }}
                >
                  {/* -------------------- */}
                  {packages?.map((data) => {
                    if (data.id === parseInt(customer.packageId)) {
                      meals = data?.noofmeals;
                    }
                    return <>
                      {/* <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          // flexWrap: "wrap",
                          width: "100%",
                        }}
                        className="hideScrollBar"
                        sx={{
                          flexWrap: {
                            // sm: "nowrap",
                            xs: "nowrap",
                            // lg: "nowrap"
                          },
                          // flexDirection: {
                          //   xs: "column",
                          //   sm: "row",
                          // },
                          overflowX: "scroll",
                        }}

                      > */}



                      <PROUDCT_MEALS_CARD_R data={data} meals={meals} />

                      {/* </Grid> */}
                    </>;
                  })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    justifyContent: "center",
                    display: matches1200?.matches ? "flex" : "none",
                  }}
                >
                  {packages
                    ?.filter((_, index) => index === productMealsIndex)
                    ?.map((data) => {
                      return <PROUDCT_MEALS_CARD_R data={data} />;
                    })}
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    // display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    display: matches1200?.matches ? "flex" : "none",
                  }}
                >
                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      marginRight: 5,
                      opacity: productMealsIndex > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      // window.document.querySelector("#areas").scrollLeft -= 100;
                      if (productMealsIndex > 0) {
                        setProductMealsIndex(productMealsIndex - 1);
                      }
                    }}
                  >
                    <KeyboardArrowLeftIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      background: COLORS.Primary,
                      display: "grid",
                      placeItems: "center",
                      padding: 6,
                      borderRadius: "50%",
                      opacity:
                        productMealsIndex < packages?.length - 1 ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (productMealsIndex < packages?.length - 1) {
                        setProductMealsIndex(productMealsIndex + 1);
                      }
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{
                        fontSize: 24,
                        color: "white",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: matches.matches ? "90vw" : "70vw",
            maxHeight: "90%",
            background: COLORS.GrayDark3,
            // overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              padding: "30px",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={11}
                container
                spacing={2}
                // sx={{
                //   padding: {
                //     xs: 0,
                //     sm: "10px",
                //     md: "50px",
                //   },
                // }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: "26pt",
                        fontFamily: FONTS_INTER,
                      }}
                      sx={{
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </h1>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {packages?.map((data) => {
                    if (data.id === parseInt(customer.packageId)) {
                      meals = data?.noofmeals;
                    }

                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          margin: "10px 30px",
                        }}
                      >
                        <Card
                          sx={{
                            minWidth: 240,
                            maxWidth: 300,
                            // minWidth: 275,
                            // maxWidth: 220,
                            // backgroundColor: "#6caca7",

                            backgroundColor:
                              data.id === parseInt(customer.packageId) ||
                              data.noofmeals < meals ||
                              meals === null
                                ? COLORS.GrayDark
                                : COLORS.Primary,
                          }}
                        >
                          <CardContent
                            style={{
                              padding: "27px",
                            }}
                          >
                            <Typography
                              component="div"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                margin: "10px 0px 0px",
                                // marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: 110,
                                  height: 110,
                                  borderRadius: "50%",
                                  backgroundColor: "#e8eeee",
                                  padding: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: COLORS.PrimaryText,
                                    fontSize: "41.2pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "600",
                                    lineHeight: "40px",
                                  }}
                                >
                                  {data?.noofmeals}
                                </Typography>
                                <Typography
                                  style={{
                                    color: COLORS.TextColor,
                                    fontSize: "13.9pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "500",
                                  }}
                                >
                                  MEALS
                                </Typography>
                              </div>
                            </Typography>
                            <Typography
                              sx={{
                                // mb: 1.5,
                                // mt: 3,
                                color: "white",
                                textAlign: "center",
                                fontSize: "9.9pt",
                                fontFamily: FONTS_INTER,
                                margin: "20px 0px 10px",
                              }}
                              color="text.secondary"
                            >
                              {data?.description}
                            </Typography>
                            <Typography>
                              <p
                                style={{
                                  fontWeight: "600",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "32.9pt",
                                  fontFamily: FONTS_AC,
                                  margin: "10px 0px",
                                }}
                              >
                                {" "}
                                {"Rs " + data?.price}{" "}
                              </p>
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                disabled={
                                  data.id === parseInt(customer.packageId) ||
                                  data.noofmeals < meals ||
                                  meals === null
                                    ? true
                                    : false
                                }
                                variant="contained"
                                color="warning"
                                style={{
                                  background:
                                    data.id === parseInt(customer.packageId) ||
                                    data.noofmeals < meals ||
                                    meals === null
                                      ? COLORS.GrayDark2
                                      : COLORS.Orange,
                                  fontSize: "9.9pt",
                                  fontFamily: FONTS_INTER,
                                }}
                                onClick={() => assignPackage(data?.id)}
                              >
                                SELECT
                              </Button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
      <Grid
        ref={testRef}
        xs={12}
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "40px",
          flexDirection: "column",
        }}
      >
        <Grid
          xs={12}
          lg={10}
          md={10}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {category?.map((item, index) => {
              return (
                <Grid
                  onClick={() => {
                    setMenuCategory(item?.mainId);
                    setSCategory(item.mainId);

                    setdescId(index);
                  }}
                  key={item?.mainId}
                  md={1.6}
                  // sm={3}
                  xs={5.9}
                  style={{
                    margin: "5px 0px",
                  }}
                  sx={{
                    minWidth: {
                      // lg: "295px",
                      sm: "19vW"
                    },
                  }}
                >
                  <Button
                    size="small"
                    style={{
                      padding: matches.matches === true ? "0px 8.1vW" : "0px 20px",

                      background:
                        item.mainId === scategory

                          ? COLORS.Primary
                          : COLORS.PrimaryBackground,

                      color:
                        item.mainId === scategory

                          ? "white"
                          : COLORS.PrimaryText,
                      fontFamily: FONTS_AC,
                      fontSize: "13pt",
                      // whiteSpace: "break",
                      width: "100%",
                      height: "100%",
                      //   width:"8%"
                    }}
                    sx={{
                      margin: "10px",

                      marginLeft: {
                        xs: 0,
                      },
                    }}

                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>
                        {item.maincategory}
                      </div>

                      {/* {
                        index !== 0 && <div style={{ fontSize: "11pt", opacity: 0.8 }} >Coming Soon</div>
                      } */}


                    </div>

                  </Button>

                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          xs={11}
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            background: COLORS.GrayBackground,
            padding: 32,
            marginTop: 35,
          }}
        >
          <Typography
            style={{
              fontFamily: FONTS_INTER,
              fontSize: "11pt",
              color: COLORS.Primary,
            }}
          >
            {descArr[descId].description}
          </Typography>
          <Typography
            variant="caption"
            style={{
              fontFamily: FONTS_INTER,
              fontSize: "8pt",
              color: COLORS.TextColor,
              fontStyle: "italic",
              marginTop: "35px",
            }}
          >
            All meals prepared by us are curated by our accredited nutritionist,
            and by consuming these meals, you will lead a balanced, healthy
            lifestyle. The priority is given to the fresh taste with nutrition
            rather than artificial flavour. All meals are prepared while
            considering general health and safety guidelines. If you are under
            specific medication for any health concerns or allergies, we
            recommend you consult your doctor before ordering our meal plans.
            Please go through our menus thoroughly and place your order as we do
            not take responsibility for any health concerns or allergies.
          </Typography>
        </Grid>
        <Grid
          ref={testRef3}
          xs={11}
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            //   background: COLORS.GrayBackground,
            padding: "15px ",
            marginTop: 20,
            width: "100%",
            alignItems: "center",
          }}
        >
          {/* <Typography
            style={{
              background: COLORS.Orange,
              color: "white",
              padding: "10px 20px",
              borderRadius: "20px",
              width: "fit-content",
              fontFamily: FONTS_INTER,
              fontSize: "12pt",
            }}
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
         
            {`${moment(fDate).format("dddd")} ${moment(fDate).format(
              "DD-MM-YYYY"
            )}`}
          </Typography> */}

          {/* <Typography
            style={{
              background: COLORS.Orange,
              color: "white",
              padding: "10px 20px",
              // borderRadius: "20px",
              width: "100%",
              fontFamily: FONTS_INTER,
              fontSize: "12pt",

              alignItems: "center",
              justifyContent: "space-between",
            }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                opacity: dayId === 0 ? 0 : 1,
                // days?.filter((u) => u.name === moment(fDate).format("dddd"))
                //   .length === 0
                //   ? 0
                //   : 1,
              }}
              onClick={() => {
                if (query.get("mealDay") === null) {
                  let newDate = new Date(lDate);
                  let newDate2 = new Date(lDate);
                  let my = 0;
                  const upDays = days?.filter(
                    (u) => u.name === moment(fDate).format("dddd")
                  );
                  days?.map((d, index) => {
                    if (d.name === moment(fDate).format("dddd")) {
                      my = index;
                      console.log("=====+++++++++", my);
                    }
                  });
                  const upDays2 = days?.filter((u, index) => index === my - 1);
                  if (upDays2?.length !== 0) {
                    const upDays = days?.map((u) =>
                      u.id === upDays2[0].id
                        ? { ...u, status: true }
                        : { ...u, status: false }
                    );
                    seDays(upDays);
                    setDay(upDays2[0].name);
                    newDate.setDate(lDate.getDate() + (my - 1));
                    newDate2.setDate(lDate.getDate() + (my - 1));
                    setMDate(newDate);
                    setFDate(newDate2);
                    setDayId(my - 1);
                  } else {
                    console.log("=====+++++++++", upDays2);
                  }
                }
              }}
            >
              <ArrowBackIosNewIcon />
            </div>

 
            {`${moment(fDate).format("dddd")} ${moment(fDate).format(
              "DD-MM-YYYY"
            )}`}
            <div
              style={{
                display: "flex",
                opacity: dayId === days.length - 1 ? 0 : 1,
              }}
              onClick={() => {
                if (query.get("mealDay") === null) {
                  let newDate = new Date(lDate);
                  let newDate2 = new Date(lDate);
                  let my = 0;
                  const upDays = days?.filter(
                    (u) => u.name === moment(fDate).format("dddd")
                  );
                  days?.map((d, index) => {
                    if (d.name === moment(fDate).format("dddd")) {
                      my = index;
                      // console.log("=====+++++++++", my);
                    }
                  });
                  const upDays2 = days?.filter((u, index) => index === my + 1);
                  if (upDays2?.length !== 0) {
                    const upDays = days?.map((u) =>
                      u.id === upDays2[0].id
                        ? { ...u, status: true }
                        : { ...u, status: false }
                    );
                    seDays(upDays);
                    setDay(upDays2[0].name);
                    newDate.setDate(lDate.getDate() + (my + 1));
                    newDate2.setDate(lDate.getDate() + (my + 1));
                    setMDate(newDate);
                    setFDate(newDate2);
                    setDayId(my + 1);
                  } else {
                    console.log("=====+++++++++", upDays2);
                  }
                }
              }}
            >
              <ArrowForwardIosIcon />
            </div>
          </Typography> */}
          <Grid xs={12} container
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "16pt",
                  sm: "24pt",
                  md: "26pt",
                  lg: "30pt"
                }
              }}
              variant="h4"
              style={{
                textAlign: "center",
                padding: "10px 0px",
                fontWeight: "400",
                color: COLORS.Primary,
                textTransform: "uppercase",
                fontFamily: FONTS_ANOTON,
                whiteSpace:"nowrap"
                // fontSize: "30pt",
              }}
            >
              Weekly Menu {`( ${moment(cfDate).format("YYYY.MM.DD")} - ${moment(cfDate).add(6, "days").format("YYYY.MM.DD")} )`}
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              width: "100%",
              marginTop: 20,
            }}
            sx={{
              justifyContent: {
                xs: "center",
                sm: "space-between",
              },
            }}
          >
            <Grid
              item
              md={2}
              style={{
                flexDirection: "column",
                width: "100%",
              }}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              {/* {days?.map((d, index) => {
                return (
                  <Typography
                    onClick={() => {
                      if (query.get("mealDay") === null) {
                        let newDate = new Date(lDate);
                        let newDate2 = new Date(lDate);

                        const upDays = days?.map((u) =>
                          u.id === d.id
                            ? { ...u, status: true }
                            : { ...u, status: false }
                        );
                        seDays(upDays);
                        setDay(d.name);

                        newDate.setDate(lDate.getDate() + index);
                        newDate2.setDate(lDate.getDate() + index);

                        setMDate(newDate);
                        setFDate(newDate2);
                        setDayId(index);
                      }
                    }}
                    key={d.id}
                    style={{
                      background:
                        d.status === true
                          ? COLORS.Orange
                          : COLORS.GrayBackground,
                      color: d.status === true ? "white" : COLORS.Primary,
                      // padding: "8px 20px",
                      padding: "8px 0px",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "5px 0px",
                      cursor: "pointer",
                      fontFamily: FONTS_INTER,
                      fontSize: "11pt",
                    }}
                  >
                   
                    <span>
                      {" "}
                      {`${moment(cfDate).add(index, "days").format("ddd")}`}
                      {` ${moment(cfDate).add(index, "days").format("DD MMM")}`}
                    </span>
                    
                  </Typography>
                );
              })} */}

              {["Breakfast", "Lunch", "Dinner"]?.map((d, index) => {
                return (
                  <Typography
                    onClick={() => {
                      setmealType(d)
                    }}
                    key={d.id}
                    style={{
                      background:
                        mealType === d
                          ? COLORS.Orange
                          : COLORS.GrayBackground,
                      color: mealType === d ? "white" : COLORS.Primary,
                      // padding: "8px 20px",
                      padding: "8px 0px",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "10px 0px",
                      cursor: "pointer",
                      fontFamily: FONTS_INTER,
                      fontSize: "11pt",
                    }}
                  >

                    <span>
                      {" "}
                      {d}
                    </span>

                  </Typography>
                );
              })}

              <Typography variant="div" style={{ marginTop: "7vW" }}>
                <Grid container xs={12}
                  style={{
                    marginTop: "3.5vW",
                    padding: "18px 0px",
                    width: "100%",
                    // margin: "5px 0px",
                    fontFamily: FONTS_INTER,
                    // background: COLORS.TitleColor,
                    background: COLORS.PrimaryBackground,
                    textTransform: "none",
                    height: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Grid xs={12}
                    item

                  >
                    <Typography variant="div"
                    >
                      <Typography
                        style={{
                          color: COLORS.Primary,
                          fontSize: "13pt",
                        }}
                      >
                        Next Delivery

                      </Typography>

                      <Typography
                        style={{
                          padding: "5px 40px"
                        }}
                      >
                        <Divider style={{ width: "100%", }} />
                      </Typography>

                      <Typography
                        style={{
                          color: COLORS.Primary,
                          fontSize: "11pt",
                          fontWeight: "bold",
                          fontFamily: FONTS_INTER,
                        }}
                      >
                        {/* 25th Feb Monday */}
                        {moment(setNextday()).format("Do MMM dddd")}

                      </Typography>
                      <Typography
                        style={{
                          color: COLORS.Primary,
                          fontSize: "10pt",
                          fontStyle: "italic",
                          fontFamily: FONTS_INTER,
                        }}
                      >
                        Between 4pm - 8pm

                      </Typography>



                    </Typography>

                  </Grid>

                </Grid>
                <Button
                  onClick={() => cartAdd()}
                  style={{
                    padding: "6px 0px",
                    borderRadius: "20px",
                    width: "100%",
                    margin: "55px 0px 15px",
                    cursor: "pointer",
                    fontFamily: FONTS_INTER,
                    fontSize: "11pt",
                    background: cartDisable() === true ? COLORS.GrayBackground : "",
                    border: `1px solid ${COLORS.Primary}`,
                    color: COLORS.Primary,
                    textTransform: "none",
                    opacity: cartDisable() === true ? "0.7" : "1"
                  }}
                >
                  Add to cart
                </Button>

                <Button
                  disabled={cartDetails.length === 0 ? true : false}
                  onClick={() => {
                    history.push("/cart");
                  }}
                  style={{
                    padding: "6px 0px",
                    borderRadius: "20px",
                    width: "100%",
                    margin: "5px 0px",
                    cursor: "pointer",
                    fontFamily: FONTS_INTER,
                    fontSize: "11pt",
                    background: COLORS.Primary,
                    color: "white",
                    textTransform: "none",
                    opacity: cartDetails.length === 0 ? 0.5 : 1
                  }}
                >
                  Checkout
                </Button>
              </Typography>

            </Grid>
            {/** mobile view */}
            <Grid
              item
              md={10}
              xs={12}
              // lg={10}
              sx={{
                paddingLeft: {
                  sm: "15px",
                  xs: "0px",
                },
                marginTop: {
                  xs: "10px",
                },
                display: {
                  xs: "none",
                  sm: "none",
                },
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  width: "100%",
                  height: "100%",
                  background: COLORS.GrayBackground,
                  padding: "15px 0  ",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  container
                  xs={11}
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "center",
                  }}
                >


                  {products
                    ?.filter((_, index) => index === productMealsIndex)
                    .map((item, index) => {
                      return <PROUDCT_MEALS_CARD item={item} index={index} />;
                    })}

                </Grid>
              </Typography>
            </Grid>
            <Grid
              xs={12}
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
              sx={{
                display: {
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <div
                style={{
                  background: COLORS.Primary,
                  display: "grid",
                  placeItems: "center",
                  padding: 6,
                  borderRadius: "50%",
                  marginRight: 5,
                  opacity: productMealsIndex > 0 ? 1 : 0.5,
                }}
                onClick={() => {
                  // window.document.querySelector("#areas").scrollLeft -= 100;
                  if (productMealsIndex > 0) {
                    setProductMealsIndex(productMealsIndex - 1);
                  }
                }}
              >
                <KeyboardArrowLeftIcon
                  style={{
                    fontSize: 24,
                    color: "white",
                  }}
                />
              </div>

              <div
                style={{
                  background: COLORS.Primary,
                  display: "grid",
                  placeItems: "center",
                  padding: 6,
                  borderRadius: "50%",
                  opacity: productMealsIndex < products?.length - 1 ? 1 : 0.5,
                }}
                onClick={() => {
                  if (productMealsIndex < products?.length - 1) {
                    setProductMealsIndex(productMealsIndex + 1);
                  }
                }}
              >
                <KeyboardArrowRightIcon
                  style={{
                    fontSize: 24,
                    color: "white",
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              md={10}
              xs={11}
              // lg={10}
              sx={{
                paddingLeft: {
                  sm: "15px",
                  xs: "0px",
                },
                marginTop: {
                  xs: "10px",
                },
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              style={{
                // display: "flex",
                flexDirection: "column",
                //   width: "100%",
              }}
            >
              <Typography
                style={{
                  width: "100%",
                  height: "100%",
                  background: COLORS.GrayBackground,
                  padding: 15,
                }}
              >
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid item xs={12}
                  >
                    {
                      scategory !== 3 && scategory !== "" && scategory !== 4 &&

                      <Typography>
                        <img
                          src={COMING}
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            width: "100%",
                            // height: "430px",
                            height: "100%",
                            // borderRadius: 18,
                            cursor: "pointer",
                          }}
                        />
                      </Typography>
                    }


                  </Grid>

                  {
                    (scategory === 3 || scategory === 4) &&

                    <Grid item xs={12} style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      height: matches920.matches === true ? "34vW" : matches1600.matches === true ? "38vW" : matches1300.matches === true ? "40vW" : "45vW",
                      overflowY: "auto"
                    }}>
                      {products?.map((item, index) => {
                        return <PROUDCT_MEALS_CARD item={item} index={index} />;
                      })}
                    </Grid>

                  }

                </Grid>
              </Typography>
              {/* <Grid style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    background: COLORS.Orange,
                    color: "white",
                    padding: "5px 45px",
                    borderRadius: "20px",
                    width: "fit-content",
                    fontFamily: FONTS_AC,
                    fontSize: "13.4pt",
                    cursor: "pointer",
                    boxShadow: "0 2px 4px 0 #888888",
                    display: "inline-block",
                    // textTransform: "capitalize",
                    marginTop: 15,
                  }}
                  sx={{
                    margin: {
                      sm: "20px 0px 15px 15px",
                      xs: "20px 0px 15px 0px",
                    },
                  }}
                  onClick={() => {
                    AddtoCart2();
                  }}
                >
                  Add meals to cart
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* new Mobile */}
      <Typography
        sx={{

          display: {
            xs: "flex",
            md: "none",
          },

        }}
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          // display: matches.matches ? "flex" : "none",
          flexDirection: "column",
          minHeight: "70vh",
          background: "white",
        }}
        component="div"
      >
        <Grid
          container
          xs={12}
          className="hideScrollBar"
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent:"space-around",
            flexWrap: "nowrap",
            overflowX: "scroll",
          }}
        >
          {/* {days?.map((d, index) => {
            // console.log("this is d mean", d);
            return (
              <Grid
                xs={2.3}
                style={{
                  background:
                    d.status === true
                      ? COLORS.Orange
                      : COLORS.PrimaryBackground,
                  color: d.status === true ? "white" : COLORS.Primary,

                  padding: "5pt 9pt",
                  minWidth: "19.2vw",
                  marginRight: "0.8vw",
                  cursor: "pointer",
                  height: "fit-content",
                }}
              >
                <Typography
                  onClick={() => {
                    if (query.get("mealDay") === null) {
                      let newDate = new Date(lDate);
                      let newDate2 = new Date(lDate);
                      console.log("show date0 ", d);

                      console.log("show date1 ", newDate);
                      console.log("show date2", newDate2);

                      const upDays = days?.map((u) =>
                        u.id === d.id
                          ? { ...u, status: true }
                          : { ...u, status: false }
                      );
                      seDays(upDays);
                      setDay(d.name);

                      newDate.setDate(lDate.getDate() + index);
                      newDate2.setDate(lDate.getDate() + index);
                      console.log("show date3", newDate);
                      console.log("show date4", newDate2);

                      setMDate(newDate);
                      setFDate(newDate2);
                      setDayId(index);
                    }
                  }}
                  key={d.id}
                  sx={{
                    fontSize: {
                      xs: "4.6vW",
                      sm: "20px",
                    }
                  }}
                  style={{
                    fontFamily: FONTS_RC,
                    // fontSize: "4.6vw",
                  }}
                >
                 
                  <b>{`${moment(cfDate).add(index, "days").format("ddd")}`}</b>
                  <br />

                  <span>
                    {" "}
                    {` ${moment(cfDate).add(index, "days").format("DD MMM")}`}
                  </span>
                </Typography>
              </Grid>
            );
          })} */}
          {["Breakfast", "Lunch", "Dinner"]?.map((d, index) => {
            // console.log("this is d mean", d);
            return (
              <Grid
                xs={4}
                style={{
                  background:
                    d === mealType
                      ? COLORS.Orange
                      : COLORS.PrimaryBackground,
                  color: d === mealType ? "white" : COLORS.Primary,

                  padding: "5pt 9pt",
                  // minWidth: "19.2vw",
                  marginRight: "0.8vw",
                  cursor: "pointer",
                  height: "fit-content",
                }}
              >
                <Typography
                  onClick={() => {
                    setmealType(d)
                  }}
                  key={d.id}
                  sx={{
                    fontSize: {
                      xs: "4.6vW",
                      sm: "20px",
                    }
                  }}
                  style={{
                    fontFamily: FONTS_RC,
                    // fontSize: "4.6vw",
                  }}
                >

                  <b>{d}</b>
                  <br />

                  {/* <span>
                    {" "}
                    {` ${moment(cfDate).add(index, "days").format("DD MMM")}`}
                  </span> */}
                </Typography>
              </Grid>
            );
          })}
          {/* {[1, 2, 3, 4, 5, 6, 7]?.map((d) => {
            return (
              <Grid
                xs={2.3}
                style={{
                  background: COLORS.PrimaryBackground,
                  padding: "5pt 9pt",
                  minWidth: "19.2vw",
                  marginRight: "0.8vw",
                  cursor: "pointer",
                  height: "fit-content",
                }}
              >
                <Typography
                  style={{
                    fontFamily: FONTS_RC,
                    fontSize: "4.6vw",
                  }}
                >
                  <b>{`${moment(fDate).format("ddd")}`}</b>
                  <br />

                  <span> {` ${moment(fDate).format("DD MMM")}`}</span>
                </Typography>
              </Grid>
            );
          })} */}
        </Grid>
        <Grid item xs={12}
        >
          {
            scategory !== 3 && scategory !== "" && scategory !== 4 &&

            <Typography>
              <img
                src={COMING}
                style={{
                  width: "100%",
                  objectFit: "contain",
                  width: "100%",
                  // height: "430px",
                  height: "70vH",
                  // borderRadius: 18,
                  cursor: "pointer",
                }}
              />
            </Typography>
          }


        </Grid>
        <Typography
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2.5vw",
          }}
        >
          {products?.map((item, index) => {
            return <PROUDCT_MEALS_CARD_MOBILE item={item} index={index} />;
          })}
          {/* {[1, 2, 3]?.map((i) => {
            return (
              <Grid
                container
                xs={12}
                style={{ padding: 0, margin: 0, marginBottom: "0.5vh" }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ padding: 0, margin: 0, height: "24vh" }}
                >
                  <Typography
                    style={{
                      fontFamily: FONTS_CR,
                      background: COLORS.Primary,
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                      height: "3.5vh",
                      fontSize: "4vw",
                    }}
                  >
                    Breakfast
                  </Typography>
                  <img
                    src={IMG}
                    style={{
                      width: "100%",
                      height: "20.5vh",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    background: COLORS.GrayDark3,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: 10,
                    height: "24vh",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FONTS_AC,
                      color: COLORS.Primary,
                      marginBottom: "0.5vh",
                      fontSize: "4vw",
                    }}
                  >
                    Kurakkan String Hoppers
                  </Typography>
                  <Divider style={{ width: "60%" }} />
                  <FormControl
                    style={{
                      display: "flex",
                      flex: 1,
                      marginTop: "0.5vh",
                    }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      // value={value}
                      // onChange={handleChange}
                    >
                      {[1, 2, 3]?.map((e) => {
                        return (
                          <StyledFormControlLabel
                            // value={item}
                            control={
                              <Radio
                                // disabled = {cdisable}

                                size="small"
                                style={{
                                  color:
                                    false === true
                                      ? COLORS.GrayDark2
                                      : COLORS.Primary,
                                  padding: 2,
                                  margin: 0,
                                }}
                                // onChange={false === false ? handleChange : null}
                                onClick={() => {
                                  if (false === true) {
                                    setOpenToast(true);
                                    setAlertClass("error");
                                    setMsg(`Item  already exist in Cart`);
                                  }
                                }}
                              />
                            }
                            label={"item"}
                            style={{
                              color:
                                false === true
                                  ? COLORS.TextColor3
                                  : COLORS.TextColor,
                              fontFamily: FONTS_INTER,
                              fontSize: "5vw",
                            }}
                          />
                        );
                      })}
                    </RadioGroup>{" "}
                  </FormControl>
                  <Button
                    style={{
                      fontFamily: FONTS_AC,
                      color: "white",
                      background: COLORS.Orange,
                      padding: "0px",
                      fontSize: "3.2vw",
                      borderRadius: "10vw",
                      textTransform: "inherit",
                    }}
                  >
                    View
                  </Button>
                </Grid>
              </Grid>
            );
          })} */}
        </Typography>
      </Typography>
      <Grid
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "center",

        }}
      >
        <Button

          style={{
            background: cartDisable() === true ? COLORS.GrayBackground : COLORS.Orange,
            color: cartDisable() === true ? COLORS.Primary : "white",
            padding: "5px 45px",
            borderRadius: "20px",
            width: "fit-content",
            fontFamily: FONTS_AC,
            // fontSize: "13.4pt",
            cursor: "pointer",
            boxShadow: "0 2px 4px 0 #888888",
            display: "inline-block",
            // textTransform: "capitalize",
            opacity: cartDisable() === true ? "0.7" : 1,
            marginTop: 15,
          }}
          sx={{
            margin: {
              // sm: "20px 0px 15px 15px",
              xs: "20px 0px 15px 0px",
            },
            fontSize: {
              xs: "13.4pt",
              sm: "18.4pt"
            }
          }}
          onClick={() => cartAdd()}
        >
          Add meals to cart
        </Button>

      </Grid>
      <Grid

        style={{
          display: matches.matches === true ? "flex" : "none",
          justifyContent: "center",
          padding: "0px 90px"
        }}
      // sx={{
      //   display: {
      //     xs: "flex",
      //     sm: "none",
      //   },
      //   justifyContent: "center",
      //   padding: "0px 90px"
      // }}
      >
        <Grid item
          style={{
            marginTop: "10px",
            marginBottom: "40px",
            padding: "18px 20px",
            width: "fit-content",
            // margin: "5px 0px",
            fontFamily: FONTS_INTER,
            // background: COLORS.TitleColor,
            background: COLORS.PrimaryBackground,
            textTransform: "none",
            height: "fit-content"
          }}

        >
          <Typography variant="div"
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "13pt",
                  sm: "18pt"
                }
              }}
              style={{
                color: COLORS.Primary,
                // fontSize: "13pt",
              }}
            >
              Next Delivery

            </Typography>

            <Typography
              style={{
                padding: "5px 40px"
              }}
            >
              <Divider style={{ width: "100%", }} />
            </Typography>

            <Typography
              sx={{
                fontSize: {
                  xs: "11pt",
                  sm: "16pt"
                }
              }}
              style={{
                color: COLORS.Primary,
                // fontSize: "11pt",
                fontWeight: "bold",
                fontFamily: FONTS_INTER,
              }}
            >

              {/* 25th Feb Monday */}
              {moment(setNextday()).format("Do MMM dddd")}

            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "10pt",
                  sm: "15pt"
                }
              }}
              style={{
                color: COLORS.Primary,
                // fontSize: "10pt",
                fontStyle: "italic",
                fontFamily: FONTS_INTER,
              }}
            >
              Between 4pm - 8pm

            </Typography>



          </Typography>
        </Grid>
      </Grid>
      {/* new Mobile */}
      <Grid xs={12}>
        <Grid
          xs={12}
          container
          spacing={0}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DeliverLabel />
        </Grid>
      </Grid>
      {matches340.matches !== true ? <div style={{ marginBottom: "50vh" }}></div> : <></>}

      {/* {matches.matches !== true ? <div style={{ marginBottom: "20vh" }}></div> : <></>} */}

      <MealsDetails
        setOpenViewMore={setOpenViewMore}
        openViewMore={openViewMore}
        id={prodId}
      />
    </>
  );
}

export default Plans;
