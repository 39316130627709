import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Radio,
  Select,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorPopup from "../Models/errorPopup.jsx";
import { Hidden } from "@mui/material";

import {
  CurrentUser,
  getMemberbyId,
  getPackages,
  getOrderCart,
  getOrderMemberCart,
} from "../../Services/API/Get";
import {
  packageToUser,
  packageToMember,
  CartAddress,
} from "../../Services/API/Put";
import Addmember from "../Models/addMemberPopup.jsx";
import { FONTS, FONTS_AC, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";
var moment = require("moment");

function MealsTable() {
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("No");
  const [showContent, setShowContent] = useState(0);

  const [userId, setuserId] = useState(null);
  const [memberId, setmemberId] = useState(null);
  const [members, setMembers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [planType, setplanType] = useState("");
  const [cartDetails, setCart] = useState([]);
  const [total, setTotal] = useState("");
  const [delivery] = useState(470);
  const [items, setitems] = useState("");
  const [meals, setMeals] = useState("");
  const [address, setAddress] = useState([]);
  const [name, setname] = useState("");
  const [idisable, setidisable] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [listOf, setListOf] = useState([

  ]);
  let mTot = 0;
  const [McartDetails, setMCart] = useState([]);
  const history = useHistory();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "No") {
      setidisable(true);
    } else {
      setidisable(false);
    }
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxWidth: 300,
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.Primary,
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.GrayDark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "11pt",
      color: COLORS.TextColor,
      fontFamily: FONTS,
      borderBottom: "none",
    },
  }));
  const StyledTableCell2 = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: "#000",
      fontFamily: FONTS,
      fontWeight: "600",
      padding: "16px 8px",
    },
  }));
  const StyledTableCell3 = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: COLORS.TextColor,
      fontFamily: FONTS,
      borderBottom: "none",
      padding: 8,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    color: COLORS.TextColor,
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const getMembers = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          setuserId(cData?.id);
          getMemberbyId(cData.id)
            .then((res) => {
              console.log(res);
              setMembers(res?.data);
            })
            .catch((error) => {
              console.log("error", error);
            });

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeMember = (e, value) => {
    setmemberId(value.props.value);
  };

  const getPackage = () => {
    getPackages()
      .then((res) => {
        console.log(res.data);
        setPackages(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current package error", error.response.data.message);
        setOpenToast(true);
        setColor(COLORS.Orange);
        setAlertClass("error");
        setMsg(error.response.data.message);
      });
  };

  const assignPackage = (pkgId, planType) => {
    console.log("pkg id >>>", pkgId);
    console.log("plan id>>>", planType);

    const obj = {
      packageId: pkgId,
    };
    if (planType === "user") {
      packageToUser(obj, userId)
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setAlertClass("success");
          setMsg("Package Assigned to User");
          setTimeout(() => {
            history.push("/plans");
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    } else if (planType === "member") {
      packageToMember(obj, memberId, "save")
        .then((res) => {
          console.log(res.data);
          setOpenToast(true);
          setColor(COLORS.Primary);
          setMsg("Package Assigned to Member");
          setTimeout(() => {
            history.push(`/plans?memberId=${memberId}`);
          }, 2000);
        })
        .catch((error) => {
          console.log("error", error.response);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  const CartGet = () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;
    let arrUser = [];
    CurrentUser().then((res) => {
      if (res.statusCode === 200) {
        const cData = res.data[0];

        setCustomer(cData);

        setname(cData.firstName + " " + cData.lastName);
        getOrderCart({
          sortField: sortField,
          sort: sort,
          limit: limit,
          page: page,
          userId: cData?.id,
        })
          .then((res) => {
            console.log("Cart >>>", res.data);

            const uCartItems = res.data?.map((d) => {
              return {
                ...d,
                deliveryDate: d.deliveryDate,
              };
            });

            var result = groupByKey(uCartItems, "deliveryDate");

            let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));

            var cItem2 = cItem.sort(function (a, b) {
              if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
              return -1;
            });
            let product = Object.assign({}, res.data[0]);

            product.usedMeals = res.data.length;
            product.type = "user";

            const update3 = res.data?.map((d) => {
              return { ...d, deliveryDate: d.deliveryDate };
            });

            product.productD = update3;

            arrUser.push(product);
            const update5 = arrUser?.map((d) => {
              return { ...d, status: false, id: d.rawId };
            });
            setListOf(update5);
            console.log("*%*%*%*%*%*%", cItem2);

            setCart(cItem2);
            setTotal(res.data[0].price);
            setitems(res.data.length);
            setMeals(res.data[0].noofmeals);
            // Address = res.data
            setAddress(res.data);
            setShowContent(1)
          })
          .catch((error) => {
            setShowContent(2)
            console.log("error", error);
            console.log("current package error", error.response.data.message);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });

        getOrderMemberCart({
          sortField: sortField,
          sort: sort,
          limit: limit,
          page: page,
          userId: cData?.id,
        })
          .then((res) => {
            console.log("Member Cart >>>", res.data);
            setMCart(res.data);
            // setTotal(res.data[0].price)
            // setitems(res.data.length)
            // setMeals(res.data[0].noofmeals)
            // setAddress(res.data)
          })
          .catch((error) => {
            console.log("error", error);
            console.log("current package error", error.response.data.message);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setShowContent(1);
      }
    });
  };

  // const onChangeAddress = (e, cartId, index) => {
  //   let cartId1 = cartId;
  //   let index1 = index;

  //   console.log("edit address>>>>", e.target.value, cartId, index);
  //   const upD = address?.map((d) =>
  //     d.cartId === cartId
  //       ? {
  //           ...d,
  //           homeAddress: e.target.value,
  //         }
  //       : { ...d, homeAddress: cartDetails[index]?.homeAddress }
  //   );
  //   setAddress(upD);

  //   // Address = upD

  //   let obj = {
  //     deliveryAddress: address[index].homeAddress,
  //   };

  //   CartAddress(obj, cartId)
  //     .then((res) => {
  //       console.log("Up Cart >>>", res.data);
  //       // CartGet();
  //       // setMCart(res.data);
  //       // setTotal(res.data[0].price)
  //       // setitems(res.data.length)
  //       // setMeals(res.data[0].noofmeals)
  //       // setAddress(res.data)
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       console.log("current package error", error.response.data.message);
  //       // setOpenToast(true);
  //       // setColor(COLORS.Orange);
  //       // setAlertClass("error");
  //       // setMsg(error.response.data.message);
  //     });

  //   // cartDetails[index].homeAddress = e.target.value
  //   // address[index].homeAddress = e.target.value

  //   // this.state.dataList[index].owing = e.target.value
  //   // this.setState({
  //   //   owing: e.target.value
  //   // }, () => {
  //   //   console.log("owing  #######", this.state.dataList[index].owing)
  //   //   this.runSheetData(createdAt1, index1)
  //   // })
  // };

  React.useEffect(() => {
    getMembers();
    getPackage();
    CartGet();
  }, []);

  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose = () => setOpen(false);

  {
    _.map(McartDetails, "members")?.map(
      (row, index) => (mTot = parseFloat(row[0].price) + mTot)
    );
  }

  // Address = cartDetails
  const OnTableRow = ({ row, index }) => {
    // console.log("@@@@@@@",row)
    let date = row?.deliveryDate;
    let dDate = new Date(date);
    const [onUpdateAddress, setOnUpdateAddress] = useState(
      // address[index]?.homeAddress
      row?.homeAddress
    );
    const [onUpdateCity, setOnUpdateCity] = useState(
      // address[index]?.homeAddress
      row?.homeCity
    );
    const [edit, setEdit] = useState(true);
    const [myCartId, setMyCartId] = useState(null);
    const [myIndex, setMyIndex] = useState(0);
    const onChangeAddress = (e, cartId, index) => {
      let obj = {
        deliveryAddress: e,
      };

      CartAddress(obj, cartId)
        .then((res) => {
          setMyCartId(null);
          CartGet();
          // CartGet();
          // setMCart(res.data);
          // setTotal(res.data[0].price)
          // setitems(res.data.length)
          // setMeals(res.data[0].noofmeals)
          // setAddress(res.data)
        })
        .catch((error) => {
          console.log("error", error);
          console.log("current package error", error.response.data.message);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });

      // cartDetails[index].homeAddress = e.target.value
      // address[index].homeAddress = e.target.value

      // this.state.dataList[index].owing = e.target.value
      // this.setState({
      //   owing: e.target.value
      // }, () => {
      //   console.log("owing  #######", this.state.dataList[index].owing)
      //   this.runSheetData(createdAt1, index1)
      // })
    };

    // let cartProducts = item[Object.keys(item)[0]]
    // console.log("!@!@!@!@####",item[Object.keys(item)[0]])

    return (
      <StyledTableRow key={`${row.cartId}`}>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textDecoration: row.expire === 1 ? "line-through" : "none",
            padding: "5px 0px 5px 0px",
            width: "20%",
          }}
          component="th"
          scope="row"
        >
          {/* Wednesday, 22nd Feb */}
          {index === 0 ? (
            <div
              style={{
                background: "white",
                padding: "8px 8px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                // marginTop: 5,
              }}
            >
              {moment(dDate).format("dddd, DD MMM")}
            </div>
          ) : (
            <></>
          )}
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            padding: "5px 0px 5px 0px",
            textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "10%",
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.mealType}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "18%",
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.productName}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "5px 0px 5px 0px",
            textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "10%",
          }}
          align="left"
        >
          <div
            style={{
              background: "white",
              padding: "8px 8px",
              // marginTop: 5,
            }}
          >
            {row.meatType}
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "0px 0px 0px 0px",
            textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "22%",
          }}
          align="left"
        >
          {/* {row.homeAddress} */}
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              background: "white",
              padding: "6px 8px 5px",
              // marginTop: 5,
            }}
          >
            <input
              id="text"
              disabled={edit}
              style={{
                border: "none",
                borderRadius: "0px",
                // width: "9.2rem",ddd
                fontSize: "11pt",
                color: "#8A8A8A",
                flex: 1,
                padding: "8px 8px ",
                background: edit === false ? COLORS.GrayBackground2 : "white",
              }}
              //   onChange={(e) => {
              //     setAddress(e.target.value);

              //   }}
              onChange={(e) => {
                // onChangeAddress(e, row.cartId, index);
                setOnUpdateAddress(e.target.value);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              value={onUpdateAddress}
              placeholder="Enter Delivery Address"
            />
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            textTransform: "capitalize",
            padding: "0px",
            textDecoration: row.expire === 1 ? "line-through" : "none",
            width: "18%",
          }}
          align="left"
        >
          {/* {row.homeAddress} */}
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              padding: "6px 8px 5px",
              // marginTop: 5,
              background: "white",
              // borderTopRightRadius: "8px",
              // borderBottomRightRadius: "8px",
            }}
          >
            <input
              id="text"
              disabled={edit}
              style={{
                border: "none",
                borderRadius: "0px",
                // width: "9.2rem",ddd
                fontSize: "11pt",
                color: "#8A8A8A",
                flex: 1,
                padding: "8px 8px",
                width: "60%",
                background: edit === false ? COLORS.GrayBackground2 : "white",
              }}
              //   onChange={(e) => {
              //     setAddress(e.target.value);

              //   }}
              onChange={(e) => {
                // onChangeAddress(e, row.cartId, index);
                setOnUpdateAddress(e.target.value);
                setMyCartId(row.cartId);
                setMyIndex(index);
              }}
              value={row.homeCity}
              placeholder="Enter Delivery City"
            />
          </div>
        </StyledTableCell>

        <StyledTableCell
          style={{
            fontSize: "11pt",
            fontFamily: FONTS_INTER,
            display: "flex",
            padding: "0px 0px 0px 0px",
            marginTop: 5,
          }}
          align="right"
        >
          {edit === true ? (
            <div
              // onClick={() => setEdit(false)}
              style={{
                background: "white",
                width: "100%",
                padding: "3px 10px 4px 0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              {/* <Tooltip arrow title="Enable Address Field"> */}
              <EditIcon style={{ color: "white" }} />
              {/* </Tooltip> */}
            </div>
          ) : (
            <div
              style={{
                background: "white",
                width: "100%",
                padding: "3px 10px 4px 0px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
              onClick={() => {
                setOnUpdateAddress(row?.homeAddress);
                setMyCartId(null);
                setEdit(true);
              }}
            >
              {/* <Tooltip arrow title="Cancel">
                <ClearIcon />
              </Tooltip> */}
              <></>
            </div>
          )}
          {edit === false && myCartId !== null && (
            <div
              onClick={() =>
                onChangeAddress(onUpdateAddress, myCartId, myIndex)
              }
            >
              <Tooltip arrow title="Save New Address">
                <SaveIcon />
              </Tooltip>
            </div>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <LoadingSpinner open={showContent === 0 ? true : false} />
      <Addmember open={open4} handleClose={() => setOpen4(false)} />
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //       setOpenToast(false);
        //     }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />

      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 0px",
        }}
      >
        <Grid md={10} xs={11}>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              padding: "10px 0px",
              fontWeight: "500",
              color: COLORS.Primary,
              textTransform: "capitalize",
              fontFamily: FONTS_AC,
              fontSize: "36pt",
            }}
          >
            {/* YOUR WEEKLY MEAL PLAN */}
            Last Order Summary
          </Typography>
          <Typography
            style={{
              fontSize: "14pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
              marginBottom: 50,
            }}
          >
            {listOf.map((d, index) => {
              if (index === 0) {
                var result = _.map(d.productD, "deliveryDate");

                var result = result.sort(function (a, b) {
                  if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                  return -1;
                });

                const minDate = result.reduce((acc, date) => {
                  return acc && acc < date ? acc : date;
                }, "");
                const maxDate = result.reduce((acc, date) => {
                  return acc && acc > date ? acc : date;
                }, "");
                // console.log("GHGHGH", maxDate);

                return (
                  <>
                    for the period of
                    <br />
                    {/* 23.03.2022 - 30.03.2022 */}
                    {`${moment(minDate).format("DD.MM.YYYY")} - ${moment(
                      maxDate
                    ).format("DD.MM.YYYY")}`}
                  </>
                );
              }
            })}
          </Typography>
          <Typography
            style={{
              display: "flex",
              flexWrap: "wrap",
              visibility: showContent === 1 ? "visible" : "hidden",
            }}
          >
            <Typography
              style={{ display: "flex", flexWrap: "nowrap" }}
              sx={{
                width: { xs: "100%", sm: "fit-content" },
              }}
            >
              <Typography
                style={{
                  display: "flex",

                  background: "#B7CAC4",
                  color: "white",
                  // width: "fit-content",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  flex: 1,
                }}
                sx={{
                  margin: {
                    sm: "0px 10px 5px 0px",
                    xs: "5px",
                  },
                  whiteSpace: {
                    xs: "wrap",
                    sm: "nowrap",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  padding: { xs: "10px 5px", sm: "5px 10px" },
                  width: {
                    xs: "100%",
                    sm: "187px",
                  },
                  height: { xs: "fit-content", sm: "47px" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "22pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                  }}
                  sx={{
                    marginRight: { sm: "10px", xs: "0" },
                  }}
                >
                  {cartDetails.length > 0 ? JSON.parse(cartDetails[0][Object.keys(cartDetails[0])[0]][0]?.customerDetails).customer.noofmeals : 0}
                </Typography>
                <Typography
                  style={{
                    fontSize: "10pt",
                    fontFamily: FONTS_INTER,
                  }}
                >
                  Assigned Meals
                </Typography>
              </Typography>
              <Typography
                style={{
                  display: "flex",

                  background: COLORS.Primary,
                  color: "white",
                  // width: "fit-content",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  flex: 1,
                }}
                sx={{
                  margin: {
                    sm: "0px 10px 5px 0px",
                    xs: "5px",
                  },
                  whiteSpace: {
                    xs: "wrap",
                    sm: "nowrap",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  padding: { xs: "10px 5px", sm: "5px 10px" },
                  width: {
                    xs: "100%",
                    sm: "187px",
                  },
                  height: { xs: "fit-content", sm: "47px" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "22pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                  }}
                  sx={{
                    marginRight: { sm: "10px", xs: "0" },
                  }}
                >
                  {items}
                </Typography>
                <Typography
                  style={{
                    fontSize: "10pt",
                    fontFamily: FONTS_INTER,
                  }}
                  sx={{
                    padding: {
                      xs: "0px 10px",
                      sm: "0px",
                    },
                  }}
                >
                  Used Meals
                </Typography>
              </Typography>
              <Typography
                style={{
                  display: "flex",

                  background: COLORS.RedError,
                  color: "white",
                  // width: "fit-content",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  flex: 1,
                }}
                sx={{
                  margin: {
                    sm: "0px 10px 5px 0px",
                    xs: "5px",
                  },
                  whiteSpace: {
                    xs: "wrap",
                    sm: "nowrap",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  padding: { xs: "10px 5px", sm: "5px 10px" },
                  width: {
                    xs: "100%",
                    sm: "187px",
                  },
                  height: { xs: "fit-content", sm: "47px" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "22pt",
                    fontFamily: FONTS_AC,
                    fontWeight: "600",
                  }}
                  sx={{
                    marginRight: { sm: "10px", xs: "0" },
                  }}
                >
                  {cartDetails.length > 0 ? JSON.parse(cartDetails[0][Object.keys(cartDetails[0])[0]][0]?.customerDetails).customer.noofmeals - items : 0}
                </Typography>
                <Typography
                  style={{
                    fontSize: "10pt",
                    fontFamily: FONTS_INTER,
                  }}
                >
                  Available Meals
                </Typography>
              </Typography>
            </Typography>

            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                padding: "0px 20px",
                fontWeight: "300",
                color: COLORS.Primary,
                textTransform: "capitalize",
                fontFamily: FONTS_INTER,
                fontSize: "14pt",
                marginBottom: 0,
                marginTop: 30,
              }}
            >
              Meal plan for <span style={{ fontWeight: "bold" }}> {name}</span>
            </Typography>
          </Typography>
          {showContent === 2 ? (
            <>
              <Typography
                style={{
                  fontSize: "24pt",
                  fontFamily: FONTS_INTER,
                  color: COLORS.Red,
                  fontWeight: "bold",
                }}
              >
                Order Summary is empty!
              </Typography>
            </>
          ) : <></>}
          <Grid
            xs={12}
            style={{
              visibility: showContent === 1 ? "visible" : "hidden",
            }}
          >
            <TableContainer
              // component={Paper}
              style={{
                // background: "#fff",
                marginBottom: 10,
                background: COLORS.GrayDark3,
              }}
            >
              <Table
                sx={{ minWidth: 800, background: COLORS.GrayDark3 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "20%",
                        padding: "16px 16px",
                      }}
                      align="left"
                    >
                      Delivery Day 
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "10%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Type
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "18%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Meal
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "10%",
                        padding: "16px 8px 16px 8px",
                      }}
                      align="left"
                    >
                      Option
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "22%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      Delivery Address
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        width: "18%",
                        padding: "16px 8px",
                      }}
                      align="left"
                    >
                      City
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "13pt", fontFamily: FONTS_INTER }}
                      align="right"
                    ></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ paddingBottom: 5 }}>
                  {cartDetails?.map((item) => {
                    console.log("BOBO", JSON.parse(cartDetails[0][Object.keys(cartDetails[0])[0]][0]?.customerDetails).customer.noofmeals)
                    let cartProducts = item[Object.keys(item)[0]];
                    var newArr = [];
                    cartProducts?.map((d) => {
                      if (d.mealType === "Dinner") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Breakfast") {
                        newArr.push(d);
                      }
                    });
                    cartProducts?.map((d) => {
                      if (d.mealType === "Lunch") {
                        newArr.push(d);
                      }
                    });
                    return (
                      <StyledTableRow
                        style={{
                          background: COLORS.GrayDark3,
                        }}
                      >
                        <StyledTableCell
                          colSpan={7}
                          style={{
                            fontSize: "11pt",
                            fontFamily: FONTS_INTER,

                            padding: "0px 4px 0px 4px",
                          }}
                          component="th"
                          scope="row"
                        >
                          <div
                            style={{
                              background: "white",

                              borderRadius: 8,
                              marginTop: 12,
                              width: "99%",
                              marginLeft: "6px",
                            }}
                          >
                            {cartProducts?.map((row, index) => (
                              <>
                                <OnTableRow row={row} index={index} />
                              </>
                            ))}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {/* <StyledTableRow style={{ height: 5 }}>
                    <StyledTableCell style={{ padding: 3 }} />
                    <StyledTableCell colSpan={5} style={{ padding: 3 }}></StyledTableCell>
                  </StyledTableRow> */}
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell colSpan={5}></StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {McartDetails?.map((row, index) => {
              console.log("ASASASASa", row.members);
              const update2 = row.members?.map((d) => {
                return {
                  ...d,
                  deliveryDate: d.deliveryDate,
                };
              });

              var result = groupByKey(update2, "deliveryDate");

              let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));
              // console.log("^^^^^^^^^^^", cItem);
              var cItem2 = cItem.sort(function (a, b) {
                if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                return -1;
              });
              console.log("ZXZXZXZX", cItem2);

              return (
                <>
                  <Typography style={{ display: "flex", flexWrap: "wrap", marginTop: 50 }}>
                    <Typography
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "5px 10px",
                        background: "#B7CAC4",
                        color: "white",
                        width: "fit-content",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 187,
                        height: 47,
                        borderRadius: "8px",
                      }}
                      sx={{
                        margin: {
                          sm: "0px 10px 5px 0px",
                          xs: "5px",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "22pt",
                          fontFamily: FONTS_AC,
                          fontWeight: "600",
                          marginRight: 10,
                        }}
                      >
                        {JSON.parse(McartDetails[index]?.members[0]?.customerDetails).customer.noofmeals}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10pt",
                          fontFamily: FONTS_INTER,
                        }}
                      >
                        Assigned Meals
                      </Typography>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "5px 10px",
                        background: COLORS.Primary,
                        color: "white",
                        width: "fit-content",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 187,
                        height: 47,
                        borderRadius: "8px",
                      }}
                      sx={{
                        margin: {
                          sm: "0px 10px 5px 0px",
                          xs: "5px",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "22pt",
                          fontFamily: FONTS_AC,
                          fontWeight: "600",
                          marginRight: 10,
                        }}
                      >
                        {McartDetails[index]?.members.length}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10pt",
                          fontFamily: FONTS_INTER,
                        }}
                      >
                        Used Meals
                      </Typography>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "5px 10px",
                        background: COLORS.RedError,
                        color: "white",
                        width: "fit-content",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 187,
                        height: 47,
                        borderRadius: "8px",
                      }}
                      sx={{
                        margin: {
                          sm: "0px 10px 5px 0px",
                          xs: "5px",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "22pt",
                          fontFamily: FONTS_AC,
                          fontWeight: "600",
                          marginRight: 10,
                        }}
                      >
                        {JSON.parse(McartDetails[index]?.members[0]?.customerDetails).customer.noofmeals -
                          McartDetails[index]?.members.length}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10pt",
                          fontFamily: FONTS_INTER,
                        }}
                      >
                        Available Meals
                      </Typography>
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        textAlign: "center",
                        padding: "0px 20px",
                        fontWeight: "300",
                        color: COLORS.Primary,
                        textTransform: "capitalize",
                        fontFamily: FONTS_INTER,
                        fontSize: "14pt",
                        marginBottom: 0,
                        marginTop: 30,
                      }}
                    >
                      Meal plan for{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {McartDetails[index].members[0].firstName +
                          " " +
                          McartDetails[index].members[0].lastName}
                      </span>
                    </Typography>
                  </Typography>
                  <Grid xs={12}>
                    <TableContainer
                      // component={Paper}
                      style={{
                        // background: "#fff",
                        marginBottom: 10,
                        background: COLORS.GrayDark3,
                      }}
                    >
                      <Table
                        sx={{ minWidth: 800, background: COLORS.GrayDark3 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "20%",
                                padding: "16px 16px",
                              }}
                              align="left"
                            >
                              Delivery Day 
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "10%",
                                padding: "16px 8px",
                              }}
                              align="left"
                            >
                              Type
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "18%",
                                padding: "16px 8px",
                              }}
                              align="left"
                            >
                              Meal
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "10%",
                                padding: "16px 8px 16px 8px",
                              }}
                              align="left"
                            >
                              Option
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "22%",
                                padding: "16px 8px",
                              }}
                              align="left"
                            >
                              Delivery Address
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "13pt",
                                fontFamily: FONTS_INTER,
                                width: "18%",
                                padding: "16px 8px",
                              }}
                              align="left"
                            >
                              City
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ fontSize: "13pt", fontFamily: FONTS_INTER }}
                              align="right"
                            ></StyledTableCell>
                          </TableRow>
                        </TableHead>                        <TableBody style={{}}>
                          {cItem2?.map((item) => {
                            let cartProducts = item[Object.keys(item)[0]];
                            var newArr = [];
                            cartProducts?.map((d) => {
                              if (d.mealType === "Dinner") {
                                newArr.push(d);
                              }
                            });
                            cartProducts?.map((d) => {
                              if (d.mealType === "Breakfast") {
                                newArr.push(d);
                              }
                            });
                            cartProducts?.map((d) => {
                              if (d.mealType === "Lunch") {
                                newArr.push(d);
                              }
                            });
                            return (
                              <StyledTableRow
                                style={{
                                  background: COLORS.GrayDark3,
                                }}
                              >
                                <StyledTableCell
                                  colSpan={7}
                                  style={{
                                    fontSize: "11pt",
                                    fontFamily: FONTS_INTER,

                                    padding: "0px 4px 0px 4px",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  <div
                                    style={{
                                      background: "white",

                                      borderRadius: 8,
                                      marginTop: 12,
                                      width: "99%",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    {cartProducts?.map((row, index) => (
                                      <>
                                        <OnTableRow row={row} index={index} />
                                      </>
                                    ))}
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}

                          <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell colSpan={5}></StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              );
            })}

            {/* <Grid xs={12}>
              <TableContainer
                // component={Paper}
                style={{
                  // background: "#fff",
                  marginBottom: 10,
                }}
              >
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                     

                      <StyledTableCell3
                        align="right"
                        colSpan={2}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                        {`( ${name} )`} SUB TOTAL
                      </StyledTableCell3>
                      <StyledTableCell3
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderTop: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                        }}
                      >
                        {"LKR" + " " + parseFloat(total).toFixed(2)}
                      </StyledTableCell3>
                    </StyledTableRow>
                    {McartDetails?.map((row, index) => (
                      <StyledTableRow>
                        <StyledTableCell3
                          align="right"
                          colSpan={2}
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            //  borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                        >
                          {`( ${
                            row.members[0]?.firstName +
                            " " +
                            row.members[0]?.lastName
                          } )`}{" "}
                          SUB TOTAL
                        </StyledTableCell3>
                        <StyledTableCell3
                          align="right"
                          style={{
                            border: `4px solid ${COLORS.InputBorderColor}`,
                            // borderTop: `8px solid ${COLORS.InputBorderColor}`,
                            borderRight: `8px solid ${COLORS.InputBorderColor}`,
                            borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                          }}
                        >
                          {"LKR" +
                            " " +
                            parseFloat(row.members[0]?.price).toFixed(2)}
                        </StyledTableCell3>
                      </StyledTableRow>
                    ))}

                    <StyledTableRow>
                      <StyledTableCell2
                        align="right"
                        colSpan={2}
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderBottom: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `6px solid ${COLORS.InputBorderColor}`,
                          color: COLORS.TextColor,
                        }}
                      >
                        FINAL TOTAL
                      </StyledTableCell2>

                      <StyledTableCell2
                        align="right"
                        style={{
                          border: `4px solid ${COLORS.InputBorderColor}`,
                          borderBottom: `8px solid ${COLORS.InputBorderColor}`,
                          borderRight: `8px solid ${COLORS.InputBorderColor}`,
                          borderLeft: `6px solid ${COLORS.InputBorderColor}`,
                          color: COLORS.TextColor,
                        }}
                      >
                        {"LKR" + " " + parseFloat(mTot + total).toFixed(2)}
                      </StyledTableCell2>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> */}
            {/* {customer.packageId !== null ? (
              <>
                <div
                  style={{
                    marginBottom: "1vh",
                    display: "flex",
                    marginTop: "3vh",
                    alignItems: "center",
                  }}
                >
                  <Grid lg={5.7} md={7.2}>
                    <Typography
                      style={{
                        fontSize: "13pt",
                        fontFamily: FONTS_INTER,
                        fontWeight: "600",
                        color: COLORS.Primary,
                        textAlign: "left",
                      }}
                    >
                      Do you want to create meal plans for your family members ?
                    </Typography>
                  </Grid>
                  <Grid lg={6.3} md={4.8}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "Yes"}
                            onChange={handleChange}
                            value="Yes"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            style={{
                              color: COLORS.Primary,
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            Yes
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            size="small"
                            checked={selectedValue === "No"}
                            onChange={handleChange}
                            value="No"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "B" }}
                            style={{
                              color: COLORS.Primary,
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            No
                          </Typography>
                        </div>
                      </div>
                      <Link
                        to="/checkout"
                        style={{
                          textDecoration: "none",
                          color: "white",

                          cursor: "pointer",
                        }}
                      >
                        <Button
                          size="large"
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            // marginTop: 10,
                            padding: "3px 15px",
                            background: COLORS.Primary,
                          }}
                          variant="contained"
                          color="success"
                          onClick={() => setOpen3(true)}
                        >
                          CHECKOUT
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </div>
                {idisable === false ? (
                  <div
                    style={{
                      marginBottom: "1vh",
                      display: "flex",
                      marginTop: "1.5vh",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      lg={5.3}
                      md={7}
                      sx={{ paddingRight: { md: "18px" }, marginRight: "10px" }}
                    >
                      <Select
                        disabled={idisable}
                        fullWidth
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        // multiple
                        // value={personName}
                        // onChange={handleChange}
                        // input={<OutlinedInput label="Name" />}
                        // MenuProps={MenuProps}

                        onChange={(event, newValue) => {
                          handleChangeMember(event, newValue);
                        }}
                        sx={{
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              width: "100%",
                              padding: "5px 12px ",
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                            },
                        }}
                      >
                        <MenuItem
                          key={0}
                          value={0}
                          onClick={() => setOpen4(true)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AddCircleIcon
                              style={{ fontSize: 15, color: COLORS.Primary }}
                              onClick={() => setOpen4(true)}
                            />
                            <label>&nbsp; Add Member</label>
                          </div>
                        </MenuItem>
                        {members.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name.id}

                            // style={getStyles(name, personName, theme)}
                          >
                            {name.firstName + " " + name.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                     
                        <Button
                          disabled={idisable}
                          size="small"
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            background: COLORS.Orange,
                            color:"white",
                          }}
                          variant="contained"
                          // color="success"

                          sx={{ padding: "6px 14px", marginRight: "10px" }}
                          // onClick={() => {
                          //   setOpen2(true);
                          //   setplanType("member");
                          // }}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpen(true);
                            setplanType("member");
                          }}
                        >
                          SELECT PACKAGE & CREATE MEAL PLAN
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )} */}

            <div style={{ float: "right", marginBottom: "15vh" }}>
              {/* <Button
                size="large"
                style={{
                  fontSize: "12pt",
                  fontFamily: FONTS_INTER,
                  marginTop: 10,
                  marginRight: 10,
                  padding: "3px 15px",
                  background: COLORS.Orange,
                }}
                variant="contained"
                // color="warning"
                onClick={() => {
                  setOpen2(true);
                  setplanType("user");
                }}
              >
                ADD MORE
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: "400px" }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ m: 1, color: "red", width: 40, height: 40 }} />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center",
                  }}
                >
                  Please Upgrade Your Package
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    You have reached the maximum number of meals of your current
                    package. If you want to add more meals please upgrade your
                    package.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 10,
                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    For any clarifications please contact our Order Processing
                    Unit between 9 am and 5 pm through 0112 222 222
                  </Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 5, mb: 2 }}
                    style={{
                      fontSize: "12pt",
                      fontFamily: FONTS_INTER,
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    UPGRADE NOW
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon
                  sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                  }}
                >
                  Order Successful
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10px",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    Thank you for placing your weekly food order with us. We
                    deliver all orders everyday as per the below schedule.
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Breakfast 6am - 9pm <br />
                      Lunch 11 a.m - 1.30
                      <br />
                      Dinner 5.30 p.m - 8.30 p.m
                    </p>
                  </Typography>

                  <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    <p>
                      (Delivery times can be vary depending on the road traffic
                      conditions.)
                    </p>
                  </Typography>

                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "10pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      For any clarifications please contact our Order Processing
                      Unit between 9 am and 5 pm through 0112 222 222
                    </p>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90vw",
              md: "90vw",
              lg: "75vw",
              sm: "70vw",
            },
            maxHeight: "98vh",
            // overflowY: "scroll",
          }}
        >
          <Grid
            component="main"
            //   maxWidth="lg"
            style={{
              background: "white",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
            sx={{
              // width: {
              //   xs: "95vh",
              //   md: "50px",
              // },
              padding: "30px",
            }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                // overflowY: "scroll",
              }}
            >
              <Grid
                xs={11}
                container
                spacing={2}
              // sx={{
              //   padding: {
              //     xs: 0,
              //     sm: "10px",
              //     md: "50px",
              //   },
              // }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 0,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        color: COLORS.Orange,
                        textAlign: "center",
                        fontSize: "26pt",
                        fontFamily: FONTS_INTER,
                      }}
                      sx={{
                        margin: "0px",
                        flexWrap: "nowrap",
                      }}
                    >
                      Select a Package
                    </h1>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {packages?.map((data) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          margin: "10px 30px",
                        }}
                      >
                        <Card
                          sx={{
                            minWidth: 240,
                            maxWidth: 300,
                            // minWidth: 275,
                            // maxWidth: 220,
                            // backgroundColor: "#6caca7",
                            backgroundColor: COLORS.Primary,
                          }}
                        >
                          <CardContent
                            style={{
                              padding: "27px",
                            }}
                          >
                            <Typography
                              component="div"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                margin: "10px 0px 0px",
                                // marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: 110,
                                  height: 110,
                                  borderRadius: "50%",
                                  backgroundColor: "#e8eeee",
                                  padding: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: COLORS.PrimaryText,
                                    fontSize: "41.2pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "600",
                                    lineHeight: "40px",
                                  }}
                                >
                                  {data.noofmeals}
                                </Typography>
                                <Typography
                                  style={{
                                    color: COLORS.TextColor,
                                    fontSize: "13.9pt",
                                    fontFamily: FONTS_AC,
                                    fontWeight: "500",
                                  }}
                                >
                                  MEALS
                                </Typography>
                              </div>
                            </Typography>
                            <Typography
                              sx={{
                                // mb: 1.5,
                                // mt: 3,
                                color: "white",
                                textAlign: "center",
                                fontSize: "9.9pt",
                                fontFamily: FONTS_INTER,
                                margin: "20px 0px 10px",
                              }}
                              color="text.secondary"
                            >
                              {data.description}
                            </Typography>
                            <Typography>
                              <p
                                style={{
                                  fontWeight: "600",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "32.9pt",
                                  fontFamily: FONTS_AC,
                                  margin: "10px 0px",
                                }}
                              >
                                {" "}
                                {"Rs " + data.price}{" "}
                              </p>
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                style={{
                                  background: COLORS.Orange,
                                  fontSize: "9.9pt",
                                  fontFamily: FONTS_INTER,
                                }}
                                onClick={() => assignPackage(data.id, planType)}
                              >
                                SELECT
                              </Button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default MealsTable;
