import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FONTS_AC, FONTS_INTER } from "../../Constants";
import { COLORS } from "../../Constants/Colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner.js";

import IMG from "../../Assets/sample.png";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import {
  CurrentUser,
  getMemberbyId,
  getPackages,
  getOrderCart,
  getOrderMemberCart,
} from "../../Services/API/Get";
import {
  packageToUser,
  packageToMember,
  CartAddress,
} from "../../Services/API/Put";
import _ from "lodash";
import { IMAGE_SERVER_URL_DEV } from "../../Constants/System";
var moment = require("moment");

function Checkoutm() {
  const [edit, setEdit] = useState(true);
  const [listOf, setListOf] = useState([
    // {
    //   id: 1,
    //   status: false,
    // },
  ]);
  const [userId, setuserId] = useState(null);
  const [memberId, setmemberId] = useState(null);
  const [members, setMembers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [planType, setplanType] = useState("");
  const [cartDetails, setCart] = useState([]);
  const [total, setTotal] = useState("");
  const [delivery] = useState(470);
  const [items, setitems] = useState("");
  const [meals, setMeals] = useState("");
  const [address, setAddress] = useState([]);
  const [name, setname] = useState("");
  const [productMealsIndex, setProductMealsIndex] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [idisable, setidisable] = useState(true);
  let mTot = 0;
  const [McartDetails, setMCart] = useState([]);
  const [expired, setExpired] = useState([]);
  const [mexpired, setmExpired] = useState([]);
  const [showContent, setShowContent] = useState(0);

  const CartGet = () => {
    let sortField = "productId";
    let sort = "asc";
    let limit = Number.MAX_SAFE_INTEGER;
    let page = 1;
    let arrUser = [];

    CurrentUser().then((res) => {
      if (res.statusCode === 200) {
        const cData = res.data[0];

        setCustomer(cData);

        setname(cData.firstName + " " + cData.lastName);
        getOrderCart({
          sortField: sortField,
          sort: sort,
          limit: limit,
          page: page,
          userId: cData?.id,
        })
          .then((res) => {
            console.log("Cart >>>", res.data);
            let product = Object.assign({}, res.data[0]);

            product.usedMeals = res.data.length;
            product.type = "user";

            const update2 = res.data?.map((d) => {
              return { ...d, deliveryDate: d.deliveryDate };
            });

            product.productD = update2;

            arrUser.push(product);
            const update = arrUser?.map((d) => {
              return { ...d, status: false, id: d.rawId };
            });
            console.log("$$$$$$$$", update);
            setListOf(update);

            setCart(res.data);
            setTotal(res.data[0].price);
            setitems(res.data.length);
            setMeals(res.data[0].noofmeals);
            // Address = res.data
            setAddress(res.data);
            setShowContent(1);
            getOrderMemberCart({
              sortField: sortField,
              sort: sort,
              limit: limit,
              page: page,
              userId: cData?.id,
            })
              .then((res) => {
                console.log("Member Cart >>>", res.data);
                setMCart(res.data);
                let newArr = [];
                let mfilter = _.map(res.data, "members");
                console.log("<<<<<<<", mfilter);

                mfilter?.map((item, index) => {
                  console.log("!@@!@@", item[0]);
                  let product = Object.assign({}, item[0]);
                  product.usedMeals = item.length;
                  product.type = "member";

                  const update2 = item?.map((d) => {
                    return { ...d, deliveryDate: d.deliveryDate };
                  });

                  //   var newArray = update2?.filter(function (el) {
                  //     return el.expire === 1;
                  //   });

                  //  var newArr2 = _.uniqBy(newArray, function (e) {
                  //     return e.deliveryDate;
                  //   });

                  product.productD = update2;
                  // product.productExpire = newArr2

                  arrUser.push(product);
                });

                const update = arrUser?.map((d) => {
                  return { ...d, status: false, id: d.rawId };
                });
                console.log("$$$$$$$$", update);
                setListOf(update);
                // setTotal(res.data[0].price)
                // setitems(res.data.length)
                // setMeals(res.data[0].noofmeals)
                // setAddress(res.data)
              })
              .catch((error) => {
                console.log("error", error);
                console.log(
                  "current package error",
                  error.response.data.message
                );
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
              });
          })
          .catch((error) => {
            setShowContent(2);
            console.log("error", error);
            console.log("current package error", error.response.data.message);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setShowContent(1);
      }
    });
  };

  React.useEffect(() => {
    // getMembers();
    // getPackage();
    CartGet();
  }, []);

  {
    listOf?.map((row, index) => (mTot = parseFloat(row.price) + mTot));
  }

  const MinCard = ({ data, index }) => {
    return (
      <Grid
        container
        xs={12}
        style={{
          padding: "5px 10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          xs={2.8}
          style={{
            marginRight: "10px",
          }}
        >
          <img
            src={`${IMAGE_SERVER_URL_DEV}${data.image}`}
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "6px",
            }}
          />
        </Grid>
        <Grid
          xs={8.5}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography
            style={{
              fontSize: "11pt",
              fontFamily: FONTS_INTER,
              color: COLORS.Primary,
              fontWeight: "bold",
            }}
          >
            {data?.mealType}
          </Typography>
          <Typography style={{ display: "flex", marginBottom: "3px", flexDirection: "row" }}>
            <Typography
              style={{
                fontSize: "13pt",
                fontFamily: FONTS_INTER,
                color: COLORS.GrayDark2,
              }}
            >
              {data?.productName}{" "}
            </Typography>
            <Typography>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background:
                    data.meatType === "Vegetable"
                      ? "#5dbd34"
                      : data.meatType === "Fish"
                        ? "#5271ff"
                        : data.meatType === "Chicken"
                          ? "#ffc004"
                          : "red",
                  fontSize: "11pt",
                  fontFamily: FONTS_INTER,
                  padding: "3px",
                  marginLeft: "8px",
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {data.meatType === "Vegetable"
                  ? "V"
                  : data.meatType === "Fish"
                    ? "F"
                    : data.meatType === "Chicken"
                      ? "C"
                      : data.meatType === "Dried Fish"
                        ? "DF"
                        : data.meatType.charAt(0)}
              </div>
            </Typography>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                borderRadius: "8px",
                background: edit === false ? "white" : "transparent",
                padding: edit === false ? "5px 16px" : "5px 0px",
              }}
            >
              {edit === false ? (
                <input
                  id="text"
                  //   disabled={edit}
                  value={data?.homeCity}
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    // width: "9.2rem",ddd
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    flex: 1,
                    fontFamily: FONTS_INTER,
                    background: edit === false ? "white" : COLORS.GrayDark3,
                  }}
                  //   onChange={(e) => {
                  //     setAddress(e.target.value);

                  //   }}
                  onChange={(e) => {
                    // onChangeAddress(e, row.cartId, index);
                    // setOnUpdateAddress(e.target.value);
                    // setMyCartId(row.cartId);
                    // setMyIndex(index);
                  }}
                  //   value={onUpdateAddress}
                  placeholder="Enter Delivery Address"
                />
              ) : (
                <Typography
                  style={{
                    fontSize: "12pt",
                    color: "#A3A3A3",
                    flex: 1,
                    fontFamily: FONTS_INTER,
                  }}
                >
                  {`${data?.homeCity}`}
                </Typography>
              )}
            </div>
            {/* {edit === true ? (
              <div
                onClick={() => setEdit(false)}
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
              >
                <Tooltip arrow title="Enable Address Field">
                  <EditIcon />
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  //   background: "white",
                  marginLeft: 10,
                }}
                onClick={() => {
                  // setOnUpdateAddress(row?.homeAddress);
                  // setMyCartId(null);
                  setEdit(true);
                }}
              >
                <Tooltip arrow title="Cancel">
                  <ClearIcon />
                </Tooltip>
              </div>
            )}
            {edit === false && (
              //    myCartId !== null &&
              <div
                style={{
                  marginLeft: 10,
                }}
                //   onClick={() =>
                //     onChangeAddress(onUpdateAddress, myCartId, myIndex)
                //   }
              >
                <Tooltip arrow title="Save New Address">
                  <SaveIcon />
                </Tooltip>
              </div>
            )} */}
          </div>
        </Grid>
      </Grid>
    );
  };
  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  return (
    <>
      <LoadingSpinner open={showContent === 0 ? true : false} />
      <Grid
        xs={12}
        style={{
          minHeight: "40vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          xs={12}
          style={{
            display: showContent === 1 ? "block" : "none",
            width: "100%",
          }}
        >
          <Grid
            style={{
              padding: "25px 0px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontFamily: FONTS_AC,
                fontWeight: "bold",
                color: COLORS.TitleColor,
              }}
            >
              {/* My meal plan */}
              Last Order Summary
            </Typography>
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: FONTS_INTER,
                color: COLORS.Primary,
              }}
            >
              {listOf.map((d, index) => {
                if (index === 0) {
                  var result = _.map(d.productD, "deliveryDate");

                  var result = result.sort(function (a, b) {
                    if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                    return -1;
                  });

                  const minDate = result.reduce((acc, date) => {
                    return acc && acc < date ? acc : date;
                  }, "");
                  const maxDate = result.reduce((acc, date) => {
                    return acc && acc > date ? acc : date;
                  }, "");
                  // console.log("GHGHGH", maxDate);

                  return (
                    <>
                      for the period of
                      <br />
                      {/* 23.03.2022 - 30.03.2022 */}
                      {`${moment(minDate).format("DD.MM.YYYY")} - ${moment(
                        maxDate
                      ).format("DD.MM.YYYY")}`}
                    </>
                  );
                }
              })}
            </Typography>


          </Grid>
          {listOf.map((d) => {
            var result = groupByKey(d.productD, "deliveryDate");

            let cItem = Object.entries(result).map(([k, v]) => ({ [k]: v }));
            console.log("^^^^^^^^^^^", cItem);
            var cItem2 = cItem.sort(function (a, b) {
              if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
              return -1;
            });
            console.log("*%*%*%*%*%*%", cItem2);

            return (
              <Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: COLORS.Primary,
                    padding: "5px 30px",
                    height: "55px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15pt",
                      fontFamily: FONTS_INTER,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`${d.firstName + " " + d.lastName}`}
                  </Typography>
                  <div>
                    {d.status === false ? (
                      <div
                        onClick={() => {
                          const up = listOf?.map((e) =>
                            e.id === d.id
                              ? { ...e, status: true }
                              : { ...e, status: false }
                          );
                          setListOf(up);
                        }}
                      >
                        <KeyboardArrowDownIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          const up = listOf?.map((e) =>
                            e.id === d.id
                              ? { ...e, status: false }
                              : { ...e, status: false }
                          );
                          setListOf(up);
                        }}
                      >
                        <KeyboardArrowUpIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                {d.status && (
                  <>
                    <Grid
                      style={{
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid
                        xs={3.9}
                        style={{
                          background: COLORS.GrayDark6,
                          color: "white",
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          height: "41px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {`${JSON.parse(d?.customerDetails)?.customer.noofmeals} Entitled`}
                      </Grid>
                      <Grid
                        xs={3.9}
                        style={{
                          background: COLORS.Primary3,
                          color: "white",
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          height: "41px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {`${d.usedMeals} Used`}
                      </Grid>
                      <Grid
                        xs={3.9}
                        style={{
                          background: COLORS.Red,
                          color: "white",
                          fontSize: "12pt",
                          fontFamily: FONTS_INTER,
                          height: "41px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {`${JSON.parse(d?.customerDetails)?.customer.noofmeals - d.usedMeals} Unused`}
                      </Grid>
                    </Grid>
                    {cItem2?.map((item) => {
                      let cartProducts = item[Object.keys(item)[0]];
                      console.log("!@!@!@!@####", item[Object.keys(item)[0]]);
                      return (
                        <Grid
                          style={{
                            background: COLORS.GrayDark3,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "13pt",
                              fontFamily: FONTS_INTER,
                              color: COLORS.GrayDark2,
                              textAlign: "center",
                              padding: "15px 0px",
                            }}
                          >
                            {/* 23.04.2022 */}
                            {moment(
                              new Date(cartProducts[0]?.deliveryDate)
                            ).format("DD.MM.YYYY")}
                          </Typography>
                          {cartProducts?.map((data, index) => {
                            return <MinCard data={data} index={index} />;
                          })}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <hr
                              style={{
                                width: "90%",
                                //   paddingBottom: "10px",
                                margin: 0,
                                marginTop: "20px",
                              }}
                            />
                          </div>
                        </Grid>
                      );
                    })}
                  </>
                )}
                {/* <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: " 10px 30px",
                    height: "55px",
                    background: COLORS.GrayDark3,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15pt",
                      fontFamily: FONTS_INTER,
                      color: COLORS.Primary,
                    }}
                  >
                    SUB TOTAL
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "15pt",
                      fontFamily: FONTS_INTER,
                      color: COLORS.Primary,
                    }}
                  >
                    {`${d.price}.00`}
                  </Typography>
                </Grid> */}
              </Grid>
            );
          })}
          {/* <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: " 10px 30px",
              height: "55px",
              background: COLORS.TitleColor,
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "15pt",
                fontFamily: FONTS_INTER,
                color: "white",
              }}
            >
              FINAL TOTAL
            </Typography>
            <Typography
              style={{
                fontSize: "15pt",
                fontFamily: FONTS_INTER,
                color: "white",
              }}
            >
              {`${mTot}.00`}
            </Typography>
          </Grid> */}
        </Grid>

        {showContent === 2 ? (
          <Grid xs={11}>
            <Typography
              style={{
                fontSize: "20pt",
                fontFamily: FONTS_INTER,
                color: COLORS.Red,
                fontWeight: "bold",
              }}
            >
              Order Summary is empty!
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}

export default Checkoutm;
