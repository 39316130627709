import {
  IMAGE_SERVER_URL,
  IMAGE_SERVER_URL_DEV2,
} from "../../Constants/System";
import _ from "lodash";
var moment = require("moment");

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {

    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export const EMAIL_TAMPLETE2 = ({
  customerName,
  address,
  invoiceNo,
  date,
  cartArray = [],
  subTotal,
  address2,
  iUsers = [],
}) => {
  console.log("hello>>>>>>>", iUsers)

  const DEL_DATES2 = () => {

    let dates = []

    iUsers?.map((d) => {

      d.cItems?.map((d2) => {

        dates.push(JSON.parse(d2?.productDetails)[0]);

      });

    });

    const datesArrNew = _.uniq(_.map(dates, "deliveryDate"))

    console.log("<>LLLLLLL", datesArrNew)

    let Friarr = []

    let Tuearr = []

    let delivery = 5

    let friday, tuesday = ""

    datesArrNew?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(1, "days")

          break;

        case "Thu":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(2, "days")

          break;

        case "Fri":

          Tuearr.push(item2);

          tuesday = moment(item2).subtract(3, "days")

          break;


      }



    });

    let delType = localStorage.getItem("delType");


    // if (delType === "delivery") {

    //   if (Friarr.length > 0) {
    //     delivery += 5;
    //   }

    //   if (Tuearr.length > 0) {
    //     delivery += 5;
    //   }

    // }

    return delivery;


  }


  const DEL_DATES = () => {

    let dates = []

    iUsers?.map((d) => {

      d.cItems?.map((d2) => {

        dates.push(JSON.parse(d2?.productDetails)[0]);

      });

    });

    const datesArrNew = _.uniq(_.map(dates, "deliveryDate"))

    console.log("<>LLLLLLL", datesArrNew)

    let Friarr = []

    let Tuearr = []

    let friday, tuesday = ""

    datesArrNew?.map((item2, index) => {

      let day = moment(item2).format("ddd")

      // switch (day) {
      //   case "Sat":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(1, "days")

      //     break;

      //   case "Sun":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(2, "days")

      //     break;

      //   case "Mon":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(3, "days")

      //     break;

      //   case "Tue":

      //     Friarr.push(item2);

      //     friday = moment(item2).subtract(4, "days")

      //     break;

      //   case "Wed":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(1, "days")

      //     break;

      //   case "Thu":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(2, "days")

      //     break;

      //   case "Fri":

      //     Tuearr.push(item2);

      //     tuesday = moment(item2).subtract(3, "days")

      //     break;


      // }

      switch (day) {
        case "Sat":

          Friarr.push(item2);

          friday = moment(item2).subtract(1, "days")

          break;

        case "Sun":

          Friarr.push(item2);

          friday = moment(item2).subtract(2, "days")

          break;

        case "Mon":

          Friarr.push(item2);

          friday = moment(item2).subtract(3, "days")

          break;

        case "Tue":

          Friarr.push(item2);

          friday = moment(item2).subtract(4, "days")

          break;

        case "Wed":

          Friarr.push(item2);

          friday = moment(item2).subtract(5, "days")

          break;

        case "Thu":

          Friarr.push(item2);

          friday = moment(item2).subtract(6, "days")

          break;

        case "Fri":

          Friarr.push(item2);

          friday = moment(item2)

          break;


      }


    });

    // if (DEL_DATES2() > 0) {

    //   if (friday < tuesday) {

    //     return `<div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:50px">

    //     <h5 style="margin:5px;padding:0px 70px" class="txt-secondary txt-7">
    //     Thank you for completing your weekly meal plan with us. Your order will be delivered on the following days between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.

    //     </h5>
    //     <h5 style="margin:15px;padding:0px 70px" class="txt-secondary txt-7">
    //     ${Friarr.length > 0 ?

    //         `${moment(friday).format("Do dddd")} - You will receive ` +

    //         Friarr?.map((item2, index) => {

    //           return `${index === Friarr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Friarr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }

    //       ${Tuearr.length > 0 ?


    //         `<br/><br/>${moment(tuesday).format("Do dddd")} - You will receive ` +

    //         Tuearr?.map((item2, index) => {

    //           return `${index === Tuearr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Tuearr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    //   `;
    //         })

    //         :

    //         ""

    //       }


    //     </h5>
    //     <h5 style="margin:15px" class="txt-secondary txt-7">
    //     Pick up location - 8/11 Swift Way, Dandenong South VIC 3175. 
    //     </h5>

    // </div>`;

    //   } else {

    //     return `<div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:50px">

    //     <h5 style="margin:5px;padding:0px 70px" class="txt-secondary txt-7">
    //     Thank you for completing your weekly meal plan with us. Your order will be delivered on the following days between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.

    //     </h5>
    //     <h5 style="margin:15px;padding:0px 70px" class="txt-secondary txt-7">

    //     ${Tuearr.length > 0 ?


    //         `${moment(tuesday).format("Do dddd")} - You will receive ` +

    //         Tuearr?.map((item2, index) => {

    //           return `${index === Tuearr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Tuearr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }

    //     ${Friarr.length > 0 ?

    //         `<br/><br/>${moment(friday).format("Do dddd")} - You will receive ` +

    //         Friarr?.map((item2, index) => {

    //           return `${index === Friarr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Friarr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }




    //     </h5>
    //     <h5 style="margin:15px" class="txt-secondary txt-7">
    //     Pick up location - 8/11 Swift Way, Dandenong South VIC 3175. 
    //     </h5>

    // </div>`;

    //   }



    // } else {

    //   if (friday < tuesday) {
    //     return `<div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:50px">

    //     <h5 style="margin:5px;padding:0px 70px" class="txt-secondary txt-7">
    //     Thank you for completing your weekly meal plan with us. Your order can be collected on the following days between 4pm and 8pm.


    //     </h5>
    //     <h5 style="margin:15px;padding:0px 70px" class="txt-secondary txt-7">
    //     ${Friarr.length > 0 ?

    //         `${moment(friday).format("Do dddd")} - You can collect ` +

    //         Friarr?.map((item2, index) => {

    //           return `${index === Friarr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Friarr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }

    //       ${Tuearr.length > 0 ?


    //         `<br/><br/>${moment(tuesday).format("Do dddd")} - You can collect ` +

    //         Tuearr?.map((item2, index) => {

    //           return `${index === Tuearr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Tuearr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    //   `;
    //         })

    //         :

    //         ""

    //       }


    //     </h5>
    //     <h5 style="margin:15px" class="txt-secondary txt-7">
    //     Pick up location - 8/11 Swift Way, Dandenong South VIC 3175. 
    //     </h5>

    // </div>`;


    //   } else {

    //     return `<div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:50px">

    //     <h5 style="margin:5px;padding:0px 70px" class="txt-secondary txt-7">
    //     Thank you for completing your weekly meal plan with us. Your order can be collected on the following days between 4pm and 8pm.


    //     </h5>
    //     <h5 style="margin:15px;padding:0px 70px" class="txt-secondary txt-7">

    //     ${Tuearr.length > 0 ?


    //         `${moment(tuesday).format("Do dddd")} - You can collect ` +

    //         Tuearr?.map((item2, index) => {

    //           return `${index === Tuearr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Tuearr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }

    //     ${Friarr.length > 0 ?

    //         `<br/><br/>${moment(friday).format("Do dddd")} - You can collect ` +

    //         Friarr?.map((item2, index) => {

    //           return `${index === Friarr.length - 2 ?

    //             `${moment(item2).format("Do MMM")} & `

    //             :
    //             index != Friarr.length - 1 ?

    //               `${moment(item2).format("Do MMM")},`

    //               :

    //               `${moment(item2).format("Do MMM")} Meals`
    //             }
    // `;
    //         })

    //         :

    //         ""

    //       }




    //     </h5>
    //     <h5 style="margin:15px" class="txt-secondary txt-7">
    //     Pick up location - 8/11 Swift Way, Dandenong South VIC 3175. 
    //     </h5>

    // </div>`;

    //   }



    // }


    return `<div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:50px">
    
    <h5 style="margin:5px;padding:0px 70px" class="txt-secondary txt-7">
    Thank you for completing your weekly meal plan with us. Your order will be delivered on ${moment(friday).format("Do dddd MMMM")} between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.

    </h5>
    
    <h5 style="margin:15px" class="txt-secondary txt-7">
   Address - 8/11 Swift Way, Dandenong South VIC 3175. 
    </h5>
   
</div>`;




  }




  const MEMEBER_ARRAY = iUsers
    ?.map((item) => {



      let createNewArray = [];
      let showMyarray = [];
      const MemberCartArray = item;
      var result = _.map(MemberCartArray.cItems, "activeDate");
      var result = result.sort(function (a, b) {
        if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
        return -1;
      });

      const minDate = result.reduce((acc, date) => {
        return acc && acc < date ? acc : date;
      }, "");
      const maxDate = result.reduce((acc, date) => {
        return acc && acc > date ? acc : date;
      }, "");

      MemberCartArray.cItems.sort(dynamicSort("activeDate"));
      var groping = groupByKey(MemberCartArray.cItems, "activeDate");

      let cItem = Object.entries(groping).map(([k, v]) => ({
        [k]: v,
      }));
      var cItem2 = cItem.sort(function (a, b) {
        if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
        return -1;
      });
      const newUpdate = cItem2?.map((r) => {
        let cartProducts = r[Object.keys(r)[0]];
        console.log("show list of array length", cartProducts);

        var newArr = [];

        cartProducts?.map((d) => {
          if (d.mealType === "Breakfast") {
            newArr.push(d);
          }
        });
        cartProducts?.map((d) => {
          if (d.mealType === "Lunch") {
            newArr.push(d);
          }
        });
        cartProducts?.map((d) => {
          if (d.mealType === "Dinner") {
            newArr.push(d);
          }
        });

        newArr?.map((m, ix) => {
          let date = JSON.parse(m?.productDetails)[0]?.deliveryDate;
          let dDate = date;
          const tb = {
            date: ix === 0 ? moment(dDate).format("ddd, DD MMM") : "",
            productName: m.productName,
            mealType: m.mealType,
            meatType: m.meatType,
            homeAddress: m.homeAddress,
            category: m.category,
          };
          createNewArray.push(tb);
        });
      });

      return ` <div style="text-align:center">    
      <h4 class="txt-secondary text-center"  style="font-size:9pt;font-family:'Roboto';align-items:center;margin-bottom:5px;">
          Meal plan for ${MemberCartArray?.firstName + " " + MemberCartArray?.lastName
        }</h4>
      <h5 class="txt-secondary text-center"
      style="font-size:7pt;font-family:'Roboto';align-items:center;font-weight:400;margin-top: 0px;">
          
          Period ${moment(minDate).format("DD MMM")} - ${moment(
          maxDate
        ).format("DD MMM")}

    </h5>
  </div>
          <div style="padding: 5px 0px 0px;">
          <table style="width:100%;border-spacing: 0;">
          <tr class=" background-primary" style="color: white;
          font-size: 7pt;
          font-weight: 500;">
              <th style="text-align:center;white-space: nowrap;">
                 DATE
              </th>
              <th>
                  MEAL
              </th>
              <th>
                  CATEGORY
                </th>
              <th>
                  OPTION
              </th>
              <th>
                  TYPE
              </th>
              <th>
                  ADDRESS
              </th>
          </tr>
            ${createNewArray?.map((m) => {
          console.log("each row", m.date);
          return ` <tr class="row txt-secondary tb-row-2 ">
                  <td style="width:16%;padding-left:2%;text-align:left;">
                    ${m.date}
                  </td>
                  <td style="width:23%;text-align:left;">
                    ${m?.productName}
                  </td>
                  <td style="width:10%;text-align:left;">
                  ${m?.category}
                </td>
                  <td style="width:10%;padding-left:2%;text-align:left;">
                    ${m?.mealType}
                  </td>
                 
                  <td style="width:10%;padding-left:2%;text-align:left;">
                    ${m?.meatType}
                  </td>
                  <td style="width:30%;padding-left:2%;text-align:left;">
                    ${m?.homeAddress}
                  </td>
                </tr>`;
        })}

        </table>
        <div style="width:100%;border-top:1px solid #326764 ;"></div>
  </div>`;
    })
    ?.toString()
    .replaceAll(",", "");
  console.log("---------->member array3");
  return `
    <html lang='en'>
  
    <head>
        <meta charset='utf-8' />
        <title>INVOICE - Healthy Kitchen</title>
        <meta name='viewport' content='initial-scale=1.0; maximum-scale=1.0; width=device-width;'>
   
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link
            href="https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
            rel="stylesheet">
        <style>
            @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
    
        
            .txt-primary {
                color: #21949F
            }
    
            .background-primary {
                background-color: #21949F
            }
    
            .background-secondary {
                background-color: #E5DDD8
            }
    
            .background-tertiary {
                background-color: #326764
            }
    
            .txt-secondary {
                color: #326764;
                
            }
    
            .invoice-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
    
            .invoice-details-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex-direction: column;
            }
    
            .txt-invoice {
                font-family: sans-serif;
                text-transform: uppercase;
                text-align: right;
                font-weight: bold;
                letter-spacing: 2;
            }
    
            .txt-1 {
                font-size: 7pt;
                font-weight: 500;
                letter-spacing: 0.8px;
                font-family: 'Roboto';
            }
    
            .txt-2 {
                font-size: 15pt;
                font-weight: 600;
                font-family: sans-serif;
            }
    
            .txt-3 {
                font-size: 7pt;
                font-weight: 400;
                font-family: sans-serif;
            }
    
            .tb-row-1 {
    
                padding: 5px 0px;
                font-size: 7pt;
                font-weight: 400;
                /* display: flex; */
                /* align-items: center; */
                font-family: 'Roboto';
                /* letter-spacing: 0.8; */
    
            }
    
            .tb-row-2 {
    
                padding: 6px 0px;
                font-size: 7pt;
                font-weight: 400;
                /* display: flex;
                align-items: center; */
                font-family: 'Roboto';
                letter-spacing: 0.8;
    
            }
    
            .txt-4 {
                padding: 6px 5px;
                font-size: 14pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 1;
            }
    
            .contact-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
    
            }
    
            .txt-5 {
                font-size: 11pt;
                font-weight: 600;
    
                font-family: 'Roboto';
                letter-spacing: 2px;
            }
    
            .txt-6 {
                font-family: 'Roboto';
                letter-spacing: 0.8px;
                font-size: 10pt;
                margin-bottom: 3px;
            }
    
            .txt-7 {
                font-family: 'Roboto';
                font-weight: 400;
                letter-spacing: 0.8px;
                font-size: 9pt;
            }
    
            .custom-hr {
                width: 30%;
                height: 1.5px;
                margin: 6px 0px 10px;
                opacity: 1;
            }
    
            th {
                /* height: 32px;
                padding: 10px; */
                height: 25px;
                padding: 5px;
                text-align: left;
            }
    
            td {
                height: 20px;
                padding: 5px 10px;
                text-align: left;
    
            }
    
            body {
                background-color: #e5e5e5;
                padding: 0;
                margin: 0;
            }
        </style>
    </head>
    
    <body>
    <div class="container" style="background:#fff;">
    <div style="padding: 5%;min-height:768px;">
        <table style="width:100%">
            <tr>
                <td>
                    <img src="https://backend.admin.ceylonhealthyfoodinfinitycs.com.au/uploads/logo/logo.png"
                        style="width:45%;min-width:45%;object-fit:contain" alt="logo" />
                </td>
                <td>
                    <h3 class="txt-primary txt-invoice" style="font-size:18pt;margin-bottom: 0px;">
                        INVOICE
                    </h3>
                </td>
            </tr>
        </table>
        <table style="width:100%">
            <tr>
                <td>
                    <div style="margin-bottom:2px;" class="txt-secondary txt-1">
                        INVOICE TO:
                    </div>
                    <div style="margin-bottom:2px;white-space: nowrap;color:#595959" class="txt-secondary txt-3">
                    ${customerName}
                    </div>
                    <div class="txt-secondary txt-3" style="max-width:85%;color:#595959">
                    ${address}
                    <br/>
                    ${address2}
                    </div>
                </td>
                <td style="text-align: right;display:block;">
                    <div style="margin-bottom:2px;white-space: nowrap;" class="txt-secondary txt-1">
                        INVOICE DETAILS
                    </div>
                    <div style="margin-bottom:2px;color:#595959" class="txt-secondary txt-3">
                    No. ${invoiceNo}
                    </div>
                    <div style="color:#595959" class="txt-secondary txt-3">
                    ${moment(date).format("DD/MM/YYYY")}
                    </div>
                </td>
            </tr>
        </table>

        <div style="padding: 15px 0px 5px; ">
            <table style="width:100%;border-spacing: 0;">

                <tr class=" background-primary" style="color: white;
                  
                    font-size: 7pt;
                    font-weight: 500;">
                    <th style="text-align:center;">QTY</th>
                    <th style="width:50%;">DESCRIPTION</th>
                    <th>PRICE</th>
                    <th>SUBTOTAL</th>
                </tr>
                ${iUsers?.map((item, index) => {
    return `
                  <tr class="txt-secondary tb-row-1 ">
                  <td style="text-align:center;">${item?.noofmeals}</td>
                  <td>${item?.packageName + " " + "Package"}<br />
                      (${item?.firstName + " " + item?.lastName})<br />
                      ${item?.isStudent === 1 ? "( 10% Discount )" :""}
                      </td>
                  <td>${parseFloat(item?.isStudent === 1 ? item?.price * 90 / 100 : item?.price).toFixed(2)}</td>
                  <td>${parseFloat(item?.isStudent === 1 ? item?.price * 90 / 100 : item?.price).toFixed(2)}</td>
              </tr>
                  `;
  })}
              
                <tr>
                    <td colspan="4" style="height:0;padding-top:0;padding:3px;border-top:2px solid #326764 ;">
                    </td>
                </tr>
                <tr class="txt-secondary tb-row-1">
                    <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>

                    <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">Subtotal</td>
                    <td style="height:18px;padding:5px 10px;text-align: right;">AUS ${parseFloat(
    subTotal
  ).toFixed(2)}</td>
                </tr>
                ${DEL_DATES2() > 0 ?
      `
                  <tr class="txt-secondary tb-row-1">
                    <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>

                    <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">DELIVERY</td>
                    <td style="height:18px;padding:5px 10px;text-align: right;">AUS 
                    ${parseFloat(DEL_DATES2()).toFixed(2)}</td></tr>`

      :
      ""
    }
                <tr class="txt-secondary tb-row-1">
                    <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>

                    <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">GST (10%)</td>
                    <td style="height:18px;padding:5px 10px;text-align: right;">AUS ${parseFloat(
      (subTotal * 10) / 100
    ).toFixed(2)}</td>
                </tr>
                <tr class="txt-secondary tb-row-1 ">
                    <td style="height:18px;padding:0px 10px" style="text-align:center;" colspan="2"></td>

                    <td style="height:18px;padding:2px 10px 0px 10px;text-align: right;">Total</td>
                    <td style="height:18px;padding:5px 10px;text-align: right;">AUS ${parseFloat(
      ((subTotal + DEL_DATES2()) * 110) / 100
    ).toFixed(2)}</td>
                </tr>
            </table>
            ${DEL_DATES()}
        </div>
      
    </div>

   
    
            <div style="padding: 30px 25px 10px;font-family: sans-serif;          
            text-align: center;
            font-size: 13pt;">

           
                
               
      ${iUsers.length > 0
      ? `<h4 class="txt-primary text-center">ORDER SUMMARY</h4>
        ${MEMEBER_ARRAY} `
      : ""
    }
      <div class="contact-container " style="display: block; text-align:center ;margin-bottom: 40px;margin-top:20px">
      <h3 style="margin-bottom: 5px;display: block; " class="txt-primary txt-5">
          CONTACT SUPPORT
      </h3>
      <hr class="background-tertiary custom-hr" style="display: inline-block;" />
      <h4 style="margin-top: 0px;" class=" txt-secondary txt-6">
          (03) 8794 8694
      </h4>
      <h5 style="margin:5px" class="txt-secondary txt-7">
          info@ceylonhealthyfood.com.au
      </h5>
  </div>
      </div>
      </div>
        </div>
        </div>
          </div>
      </body>
      
      </html>`;
};
