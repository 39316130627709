import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { COLORS } from "../../Constants/Colors";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Avatar from '@mui/material/Avatar';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import {Typography} from "@material-ui/core";
import { Container } from "react-bootstrap";
import { FONTS_INTER } from "../../Constants";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { FormGroup,Form } from 'reactstrap';
import { sendVerification } from "../../Services/API/Post";
import ErrorPopup from "./errorPopup.jsx"
import "./login.css";

export default function VerificationPopup({open, handleClose }) {

    const theme = createTheme({
        palette: {
          primary: {
            main: COLORS.Primary,
          },
          secondary: {
            main: "#f44336",
          },
        },
      });

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius:"4px",
      };
    
      const handleToastClose = () => {
        setOpenToast(false);
      };

    
      const [openToast, setOpenToast] = useState(false);
      const [msg, setMsg] = useState("");
      const [alertClass, setAlertClass] = useState("success");
      const [color, setColor] = useState("green");

      const [email2, setEmail2] = useState("");
      const [errEmail, setErrEmail] = React.useState("");

      
    
      const onChangeEmail2 = (e) => {
    setEmail2(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email =data.get('username')

    let obj ={
        email:email,
    }

    console.log("send >>>",obj)

    if(obj.email === ""){
      setErrEmail("This field required")
    }

    if(obj.email !== ""){
      sendVerification(obj)
        .then((res) => {
          setOpenToast(true)
          setAlertClass("success");
          setColor(COLORS.Primary)
          setMsg("Password reset link has been emailed")
          // if (res.status) {
          //   localStorage.setItem(
          //     `${window.location.hostname}.login`,
          //     JSON.stringify(res)
          //   );
          // }

          // setEmail("");
          // setPassword("");

          // history.push("/");
          setTimeout(() => { setMsg(""); }, 4000);
        })
        .catch((error) => {
          console.log(error)
          // setColor("red");
          setAlertClass("error");
          setOpenToast(true)
          setColor("red")
          setMsg(error.response.data.message)
        });
    }

    


  };

  return (
      <>
       <ErrorPopup openToast={false} 
      //  handleClose={openToast === true ? setTimeout(() => {setOpenToast(false);}, 5000) : false}
      handleClose={()=> setOpenToast(false)}
        alertClass={alertClass} msg={msg} />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleToastClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: COLORS.Orange }}>
                  <LockResetIcon />
                </Avatar>
                <Typography
                  style={{
                    fontFamily: FONTS_INTER,
                    fontWeight:"bold",
                    fontSize:"14pt",

                  }}>
                  Forgot Password
                </Typography>
                <Form className="mb-3" onSubmit={handleSubmit}>
                <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop: 20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Email *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        border: "none",
                        background: COLORS.GrayDark3,
                        // borderColor: this.state.emailColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <MailOutlineIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        autoFocus
                        // required
                        // type="email"
                        name="username"
                        id="username"
                        placeholder="Enter email"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                          background: COLORS.GrayDark3,
                        }}
                        autoComplete="new-password"
                        // onKeyDown={(event) => {
                        //   if (event.key === "Tab") {
                        //     event.preventDefault();
                        //     passwordRef.current.focus();
                        //   }
                        // }}
                        onChange={(e) => {
                          // setEmail(e.target.value);
                          setErrEmail("");
                        }}
                        // onChange={this.onChangeEmail}
                        // value={email}
                      />
                    </Paper>
                    <Box textAlign="right">
                      <div className="text-right">
                        <span
                          style={{
                            fontSize: "8pt",
                            color: "red",
                            fontFamily: FONTS_INTER,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {errEmail}
                        </span>
                      </div>
                    </Box>
                  </FormGroup>
               {/* <FormGroup>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: "8.5pt",
                        color: "#747579",
                        paddingBottom: "0px",
                        marginLeft: "0.5rem",
                        marginTop:20,
                        fontFamily: FONTS_INTER,
                      }}
                    >
                      <b>Email *</b>
                    </div>
                    <Paper
                      style={{
                        marginLeft: "0.6rem",
                        width: "17.5rem",
                        height: "2.5rem",
                        // borderColor: this.state.emailColor,
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <IconButton className="p-1 ml-2">
                        <MailOutlineIcon style={{ fontSize: "13pt" }} />
                      </IconButton>
                      <input
                        // required
                        // type="email"
                        name="username"
                        id="username"
                        placeholder="Enter email"
                        className="bg-white"
                        style={{
                          border: "none",
                          borderRadius: "2px",
                          width: "14.7rem",
                          fontSize: "9pt",
                          height: "2rem",
                          borderColor: "white",
                          fontFamily: FONTS_INTER,
                        }}
                        onChange={(e) =>
                          setErrEmail("")            
                          }
                        // onChange={this.onChangeEmail}
                        // value={email}
                      />
                      <Box textAlign='right'>
                      <div className="text-right">
                        <span style={{ fontSize: "8pt", color: "red",fontFamily: FONTS_INTER,}}>
                          {errEmail}
                        </span>
                      </div>
                      </Box>
                    </Paper>
                  </FormGroup> */}
                  {openToast === true?
                                 <Box component="form" sx={{ mt: 3 }}>
                                 <Typography
                                   component="h5"
                                   variant="h5"
                                   style={{
                                     fontSize: "10pt",
                                     fontFamily: FONTS_INTER,
                                     marginTop: 12,
               
                                     color: color,
                                     textAlign: "center",
                                   }}
                                 >
                                  {msg}
                                 </Typography>
               
                               </Box>
                               :
                               <></>}
              

                  <Box textAlign='center'>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    sx={{ mt: 4, mb: 4 }}
                    style={{
                      fontFamily:FONTS_INTER,
                      fontSize:"12pt",
                      color: "white",
                      background: COLORS.Primary,
                      borderRadius: 4,
                      width: "200px",
                      height:"35px",
                      border: "none",
                      marginTop:40,
                    }}
                  >
                    SEND VERIFICATION
                  </Button>
                  </Box>
          
                  
                  </Form>

              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
  </>
  );
}