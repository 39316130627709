import { Grid, Typography } from "@mui/material";
import React from "react";
import Faq from "./Componets/Faq";

import Steps from "./Componets/Steps";

function Work() {
  return (
    <Grid
      container
      xs={12}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Steps />
      <Faq/>
    </Grid>
  );
}

export default Work;
