import React, { useState, useRef } from "react";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import { Snackbar } from "@material-ui/core";
import { Alert, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { RegisterUser, signUpVerification } from "../../Services/API/Post";
import {
  getSignUpVerification,
  getDistricts,
  getCities,
} from "../../Services/API/Get";
import { FONTS, FONTS_INTER } from "../../Constants";
import Package from "../Models/packagePopup.jsx";
import ErrorPopup from "../Models/errorPopup.jsx";
import { COLORS } from "../../Constants/Colors";
import IconButton from "@material-ui/core/IconButton";
import EditOffIcon from "@mui/icons-material/EditOff";
import TextField from "@mui/material/TextField";
// import Autocomplete from '@mui/material/Autocomplete';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import "./signup.css";
import { fontSize } from "@mui/system";
import { upperFirst, lowerCase } from 'lodash';
import LoadingSpinner from "../../Componets/LoadingSpinner/loadingSpinner";
import ProfilePopup from "../Models/profilePopup";
import LoginPopup from "../Models/LoginPopup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";

function SignUp() {
  const GreenCheckbox = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
      marginLeft: "0.5rem",
      padding: 0,
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  var matches = window.matchMedia("(max-width: 600px)");
  var matches900 = window.matchMedia("(max-width: 900px)");
  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.GrayBackground,
      },
      secondary: {
        main: COLORS.GrayBackground,
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    "@global": {
      ".MuiAutocomplete-option": {
        width: "100%",
      },
    },
    root: {
      "& .MuiOutlinedInput-root": {
        padding: 7,
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        padding: "0px",
        fontFamily: FONTS_INTER,
        fontSize: "10.5pt",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: 500,
        outline: "none",
        width: "100%",
        height: "1.4rem",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        background: "rgba(232,232,232,0.5)",
      },
    },

    // "& .MuiAutocomplete-option": {
    //   width: "100%",
    //   background:"red"
    // },
    // '& [class*="MuiAutocomplete-popper"]': {
    //   width: "100%",
    // },
  }));

  const history = useHistory();
  const [selectedValue, setSelectedValue] = React.useState("b");
  const errScroll = useRef();
  const scrollElement = () => errScroll.current.scrollIntoView();
  const [openToast, setOpenToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertClass, setAlertClass] = useState("success");
  const [color, setColor] = useState("green");
  const [progress, setProgress] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [officeNo, setofficeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [officeCity, setofficeCity] = useState("");
  const [insurer, setInsurer] = useState("");
  const [policy, setPolicy] = useState("");
  const [entitlement, setentitlement] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errhomeNo, seterrhomeNo] = useState("");
  const [errofficeNo, seterrofficeNo] = useState("");
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [errofficeAddress, seterrofficeAddress] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errofficeCity, seterrofficeCity] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");
  const [open, setOpen] = useState(false);
  const [userId, setuserId] = useState("");
  const [image, setImage] = useState("");

  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [officeDistrictId, setofficeDistrictId] = useState(0);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [officeDistrict, setofficeDistrict] = useState("");
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);
  const [allOCities, setallOCities] = useState([]);
  const [allODistricts, setallODistricts] = useState([]);

  const [homepostCode, sethomepostCode] = useState("");
  const [officepostCode, setofficepostCode] = useState("");
  const [errhomepostCode, seterrhomepostCode] = useState(false);

  const [isStudent, setisStudent] = useState(false);
  const [studentId, setstudentId] = useState("");
  const [errstudentId, seterrstudentId] = useState(false);
  let check1 = 0;

  const [lopen, setlopen] = React.useState(false);

  const [popen, setpopen] = React.useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const onChangeAvatar = (e) => {
    let file = e.target.files[0];

    if (e.target.files[0] !== null) {


      if (!((e.target.files[0]?.size / 1000) < 1024)) {
        setOpenToast(true);
        setColor(COLORS.Primary);
        setAlertClass("error");
        setMsg("Your file size  should be below 1 MB");
        return;
      }
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    let baseURL;
    reader.onload = () => {
      baseURL = reader.result;
      setImage(baseURL);
    };
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "b") {
      setidisable(true);
      setInsurer("");
      setPolicy("");
      setentitlement("");
    } else {
      setidisable(false);
    }
  };

  const handleClose = () => {
    setOpenToast(false);
  };

  const handleChangeOdist = (e, value) => {
    setofficeDistrict(value.name_en);
    setofficeDistrictId(value.id);
  };
  const handleChangeOcity = (e, value) => {
    setofficeCity(value.name_en);
    setofficepostCode(value.postcode);
    seterrofficeCity(false);
  };

  const handleChangeHdist = (e, value) => {
    console.log("change>>>>>")
    sethomeDistrict(value.name_en);
    seterrhomeDistrict(false);
    sethomeDistrictId(value.id);
  };
  const handleChangeHcity = (e, value) => {
    sethomeCity(value.name_en);
    sethomepostCode(value.postcode);
    seterrhomeCity(false);
    seterrhomepostCode(false);
  };

  const sendVerification = async (e) => {

    e.preventDefault();
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }
    // if (homeNo === "") {
    //   seterrhomeNo("This Field is Requied");
    // }
    if (mobile === "") {
      seterrmobile("This Field is Requied");
    }
    if (password === "") {
      seterrPass(true);
    }
    if (repassword === "") {
      seterrRePass("This Field is Requied");
    }
    if (password != "" && repassword != "" && !(password === repassword)) {
      seterrRePass("Password Mismatch");
    }
    if (password != "" && repassword != "" && password === repassword) {
      seterrRePass("");
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }

    let obj = {
      data: {
        firstName: upperFirst(lowerCase(firstName)),
        lastName: upperFirst(lowerCase(lastName)),
        mobileNumber: mobile,
        email: email,
        homeNumber: homeNo,
        officeNumber: officeNo,
        homeAddress: homeAddress,
        officeAddress: officeAddress,
        homeCity: homeCity,
        officeCity: officeCity,
        insurer: insurer,
        policyNo: policy,
        entitlement: entitlement,
        password: password,
        image: image,
        homeDistrict: homeDistrict,
        officeDistrict: officeDistrict,
        homepostCode: homepostCode,
        officepostCode: officepostCode,
        isStudent: isStudent === true ? 1 : 0,
      },
    };

    console.log("obj>>>", obj);
    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }
    // if (!phoneRegex.test(homeNo)) {
    //   if (homeNo === "") {
    //     seterrhomeNo("This Field is Requied");
    //   } else {
    //     seterrhomeNo("Please Enter Valid number");
    //   }
    // }
    // if (officeNo !== "") {
    //   if (!phoneRegex.test(officeNo)) {
    //     seterrofficeNo("Please Enter Valid number");
    //   }
    // }

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      homeAddress !== "" &&
      homeDistrict !== "" &&
      homeCity !== "" &&
      homepostCode !== "" &&
      // homeNo !== "" &&
      mobile !== "" &&
      password !== "" &&
      repassword !== "" &&
      password === repassword &&
      phoneRegex.test(mobile)
      // phoneRegex.test(homeNo) &&
      // (officeNo !== "" ? phoneRegex.test(officeNo) : true)
      // phoneRegex.test(officeNo)
    ) {
      setlopen(true);
      // signUpVerification(obj)
      //   .then((res) => {
      //     setlopen(false);
      //     // setuserId(res.data[0]);
      //     setOpenToast(true);
      //     setColor(COLORS.Primary);
      //     setAlertClass("success");
      //     setMsg(
      //       `The account activation link has been sent to ${email}. Please click on the confirmation link to activate your account. `
      //     );
      //     // setTimeout(() => {
      //     //   //  setOpen(true);
      //     //   history.push("/login");
      //     //   window.location.reload();
      //     // }, 2000);

      //     // history.push("/");
      //     // window.location.reload();
      //   })
      //   .catch((error) => {
      //     setlopen(false);
      //     console.log("Error", error.response);
      //     setOpenToast(true);
      //     setColor(COLORS.Orange);
      //     setAlertClass("error");
      //     setMsg(error.response.data.message);
      //   });

      RegisterUser(obj.data)
        .then((res) => {


          setuserId(res.data[0]);
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Register Successful");

          // setlopen(false);

          // if(matches.matches === true){

          //   history.push("/login");

          // }else{

          //   setpopen(true);
          // }
          // setpopen(true);

          setTimeout(() => {
            // setlopen(false);
            //  setOpen(true);

            setlopen(false);
            setProgress(false);
            window.scroll(0, 0);

            if (matches.matches === true) {
              history.push("/login");

            } else {
              history.push("/home?login=true");
            }




          }, 0);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          // setlopen(false);
          console.log("Error", error.response);
          setProgress(false);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });

    } else {
      scrollElement();
    }
  };



  const getVerification = () => {

    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setProgress(true);
      getSignUpVerification(token)
        .then((res) => {
          RegisterUser(JSON.parse(res.data[0].data))
            .then((res) => {

              setuserId(res.data[0]);
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Register Successful");

              setTimeout(() => {
                // setlopen(false);
                //  setOpen(true);

                history.push("/login");



                // window.location.reload();
                setProgress(false);
              }, 4000);

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              // setlopen(false);
              console.log("Error", error.response);
              setProgress(false);
              setOpenToast(true);
              setColor(COLORS.Orange);
              setAlertClass("error");
              setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setProgress(false);
          setOpenToast(true);
          setColor(COLORS.Orange);
          setAlertClass("error");
          setMsg(error.response.data.message);
        });
    }
  };

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts 222>>>", res.data[0].name_en);


        setallHDistricts(res.data);
        setallODistricts(res.data);

        sethomeDistrict(res.data[0].name_en);
        sethomeDistrictId(res.data[0].id);

      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listOfficecities = () => {
    getCities(officeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallOCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getVerification();
    listDistricts();
  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  React.useEffect(() => {
    if (officeDistrictId !== 0) {
      listOfficecities();
    }
  }, [officeDistrictId]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <LoadingSpinner open={lopen} />
      <LoginPopup open={popen} handleClose={() => setpopen(false)} />
      <Package open={open} handleClose={() => setOpen(false)} id={userId} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progress}
      // onClick={()=>setProgress(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorPopup
        openToast={openToast}
        // handleClose={
        //   openToast === true
        //     ? setTimeout(() => {
        //         setOpenToast(false);
        //       }, 5000)
        //     : false
        // }
        handleClose={() => setOpenToast(false)}
        alertClass={alertClass}
        msg={msg}
      />
      <Snackbar
        open={false}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          style={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
          onClose={handleClose}
          severity={alertClass}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 0px",
        }}
      >
        <Grid md={8} lg={6} xs={11} style={{ width: "100%" }}>
          <Typography
            style={{
              fontFamily: FONTS_INTER,
              fontSize: "12pt",
              textAlign: "left",
              color: "white",
              background: COLORS.Primary,
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              fontWeight: "600",
              padding: "10px 15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PersonIcon
                  style={{
                    width: 45,
                    height: 45,
                    padding: 2,
                    background: "white",
                    color: COLORS.Primary,
                    borderRadius: "50%",
                    marginRight: "15px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "12pt",
                    fontFamily: FONTS_INTER,
                    color: "white",
                  }}
                >
                  PERSONAL DETAILS
                </Typography>
              </div>
            </div>
          </Typography>
          <form autoCorrect="off" autoComplete="new-password">
            <Typography
              style={{
                display: "flex",
                padding: "17px 20px",
                flexDirection: "column",
                background: COLORS.GrayDark3,
              }}
            // sx={{
            //   background:{
            //     xs:"white",sm:COLORS.GrayDark3
            //   },
            //   padding:{
            //     xs:"17px 0px",
            //     sm:"17px 20px"
            //   }
            // }}
            >
              <Grid
                ref={errScroll}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: 0,
                  background: "white",
                }}
                container
              >
                {/* <Grid xs={12} container>
                  <Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: "10px 15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {image !== "" ? (
                          <>
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onChangeAvatar}
                            />
                            <label htmlFor="icon-button-file">
                              <Tooltip arrow title="Upload Avatar">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <img
                                    src={image}
                                    style={{
                                      width: 75,
                                      height: 75,
                                      padding: 8,
                                      background: COLORS.GrayDark5,
                                      color: "white",
                                      borderRadius: "50%",
                                      marginRight: "18px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onChangeAvatar}
                            />
                            <label htmlFor="icon-button-file">
                              <Tooltip arrow title="Upload Avatar">
                                <IconButton
                                  style={{
                                    width: 100,
                                    height: 100,
                                    padding: 8,
                                    borderRadius: "50%",
                                    marginRight: "18px",
                                  }}
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <PersonIcon
                                    style={{
                                      width: 75,
                                      height: 75,
                                      padding: 8,
                                      background: COLORS.GrayDark5,
                                      color: "white",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>
                          </>
                        )}
                        <Typography
                          style={{
                            fontSize: "12pt",
                            fontFamily: FONTS_INTER,
                            color: COLORS.GrayDark6,
                          }}
                        >
                          Upload your profile picture
                        </Typography>
                      </div>
                    </div>
                  </Typography>
                </Grid> */}
                {/* <Grid xs={12} container>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      // width: "90%",
                      // marginTop: 5,
                      fontSize: "7.5pt",
                      padding: "0px 10px",
                      color: COLORS.TextColor,
                    }}
                  >
                    Maximum upload file size : 1 MB
                  </Typography>

                </Grid> */}
              </Grid>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "white",
                }}
              >
                <Divider
                  style={{
                    width: "93%",
                  }}
                />
              </div> */}
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginTop: "25px",
                  padding: "15px 25px",
                  background: "white",
                }}
              >
                <Grid item md={12} xs={12} style={{ width: "100%", padding: matches.matches === true ? "0px" : "0px 10px" }}>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        // variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        First Name *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",

                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setfirstName(e.target.value);
                          seterrfirstName(false);
                        }}
                        value={firstName}
                      />
                      {errfirstName && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Last Name *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setlasttName(e.target.value);
                          seterrlasttName(false);
                        }}
                        value={lastName}
                      />
                      {errlastName && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>

                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Email *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setemail(e.target.value);
                          seterremail(false);
                        }}
                        value={email}
                      />
                      {erremail && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>

                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Mobile Number *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setmobile(e.target.value);
                          seterrmobile("");
                        }}
                        value={mobile}
                      />
                      {errmobile && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errmobile}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Address *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          sethomeAddress(e.target.value);
                          seterrhomeAddress(false);
                        }}
                        value={homeAddress}
                      />
                      {errhomeAddress && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    style={{ padding: "0px 0px 20px 0px", justifyContent: "space-between" }}
                    xs={12}
                  >
                    <Grid xs={12} md={4} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          State *
                        </Typography>

                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            value={{ name_en: homeDistrict }}
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            // onInputChange={(event, newInputValue, reason) => {
                            //   if (reason === 'reset') {
                            //     sethomeDistrict("")
                            //     return
                            //   }
                            // }}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                handleChangeHdist(event, newValue);
                              }
                            }}
                            // style={{
                            //   width:
                            //     matches.matches === true ? "100%" : "12.3vw",
                            // }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  // className="hideScrollBar"
                                  style={{
                                    fontSize: "9pt",
                                    padding: "5",
                                    margin: "0",
                                    color: COLORS.TextColor,
                                    // width:
                                    //   matches.matches === true
                                    //     ? "100%"
                                    //     : "9.9vw",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                                background: "white",
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>

                        {errhomeDistrict && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={4}
                      sx={{
                        padding: {
                          xs: "20px 0px 0px 0px",
                          md: "0px"
                        }
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Suburb *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            // defaultValue={{ name_en: "Gampaha"}}
                            options={allHCities}
                            getOptionLabel={(option) => option.name_en}
                            value={{ name_en: homeCity }}
                            onInputChange={(event, newInputValue, reason) => {
                              if (reason === 'reset') {
                                sethomeCity("");
                                sethomepostCode("");
                                return
                              }
                            }}
                            onChange={(event, newValue) => {
                              handleChangeHcity(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                    color: COLORS.TextColor,
                                    width:
                                      matches.matches === true
                                        ? "100%"
                                        : "132px",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errhomeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={3} sx={{
                      padding: {
                        xs: "20px 0px 0px 0px",
                        md: "0px"
                      }
                    }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          PostCode *
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              width: matches.matches === true ? "93%" : matches900.matches === true ? "97%" : "84%",
                            }}
                            onChange={(e) => {
                              sethomepostCode(e.target.value);
                              seterrhomepostCode(false);
                            }}
                            value={homepostCode}
                          />
                        </div>
                        {errhomepostCode && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid
                  item
                  md={5.5}
                  xs={12}
                  style={{ width: "100%", marginTop: "15px" }}
                >
                  <Grid md={12} xs={12} style={{ margin: "0px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Office Address (Optional)
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setofficeAddress(e.target.value);
                          seterrofficeAddress(false);
                        }}
                        value={officeAddress}
                      />
                      {errofficeAddress && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    style={{
                      // padding: "15px 0px 0px 0px",
                      justifyContent: "space-between",
                    }}
                    sx={{
                      padding: {
                        xs: "0px ",
                        sm: "15px 0px 0px 0px",
                      },
                      marginTop: {
                        xs: "15px",
                        lg: "0px",
                        md: "0px",
                      },
                    }}
                    xs={12}
                  >
                    <Grid xs={12} md={4} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          State
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            // defaultValue={{ name_en: "Gampaha"}}
                            options={allODistricts}
                            getOptionLabel={(option) => option.name_en}
                            value={{ name_en: officeDistrict }}
                            onInputChange={(event, newInputValue, reason) => {
                              if (reason === 'reset') {
                                setofficeDistrict("")
                                return
                              }
                            }}
                            onChange={(event, newValue) => {
                              handleChangeOdist(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errofficeAddress && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={4} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Suburb
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <Autocomplete
                            classes={classes}
                            // defaultValue={{ name_en: "Gampaha"}}
                            options={allOCities}
                            getOptionLabel={(option) => option.name_en}
                            value={{ name_en: officeCity }}
                            onInputChange={(event, newInputValue, reason) => {
                              if (reason === 'reset') {
                                setofficeCity("");
                                setofficepostCode("");
                                return
                              }
                            }}
                            onChange={(event, newValue) => {
                              handleChangeOcity(event, newValue);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <p
                                  style={{
                                    fontSize: "9pt",
                                    padding: "0",
                                    margin: "0",
                                  }}
                                >
                                  {option.name_en}
                                </p>
                              </React.Fragment>
                            )}
                            ListboxProps={{
                              style: {
                                fontSize: "10.5pt",
                                fontFamily: FONTS_INTER,
                                color: COLORS.TextColor,
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        {errofficeCity && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={3} style={{ padding: 0 }}>
                      <Typography
                        component="div"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: {
                              xs: "15px",
                              lg: "0px",
                              md: "0px",
                            },
                          }}
                          variant="body2"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            color: COLORS.TextColor,
                            fontSize: "8.5pt",
                            // letterSpacing: "1px",
                            fontWeight: "bold",
                          }}
                          gutterBottom
                        >
                          Postcode
                        </Typography>
                        <div
                          style={{
                            marginLeft: 0,
                            height: "2rem",
                            borderRadius: 3,
                          }}
                        >
                          <input
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              width: matches.matches === true ? "93%" : "85%",
                            }}
                            onChange={(e) => {
                              setofficepostCode(e.target.value);
                              // seterrhomeAddress(false);
                            }}
                            value={officepostCode}
                          />
                        </div>
                        {false && (
                          <Typography
                            variant="caption"
                            color="red"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              margin: "5px 0px",
                              fontSize: "8pt",
                            }}
                          >
                            This Field is Requied
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Mobile Number *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setmobile(e.target.value);
                          seterrmobile("");
                        }}
                        value={mobile}
                      />
                      {errmobile && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errmobile}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Home Number *
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          sethomeNo(e.target.value);
                          seterrhomeNo("");
                        }}
                        value={homeNo}
                      />
                      {errhomeNo && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errhomeNo}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Office Number
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        onChange={(e) => {
                          setofficeNo(e.target.value);
                          seterrofficeNo("");
                        }}
                        value={officeNo}
                      />
                      {errofficeNo && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errofficeNo}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
              {false ?

                <div
                  style={{
                    marginTop: 20,
                    background: "white",
                    padding: "15px 25px",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      marginTop: 30,
                    }}
                  >
                    <Grid lg={9} md={9} sm={10} xs={12} style={{ width: "100%" }}>
                      <Typography
                        style={{
                          color: COLORS.Primary,
                          textAlign: "left",
                          // letterSpacing: 1,
                          fontFamily: FONTS_INTER,
                          fontWeight: "400",
                          fontSize: "13pt",
                        }}
                      >
                        Do you have a life insurance policy ?
                        {/* with Union Assurance or HNB
                    Assurance ? */}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          textAlign: "left",
                          float: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontWeight: "400",
                          fontSize: "10pt",
                        }}
                      >
                        ( If <b>"YES"</b>, please fill the below section and
                        "check" your eligibility to get discounts. )
                      </Typography>
                    </Grid>
                    <Grid
                      md={3}
                      sm={2}
                      xs={12}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                      sx={{
                        marginTop: {
                          xs: "10px",
                          sm: "0px",
                        },
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          disabled
                          size="small"
                          checked={selectedValue === "a"}
                          onChange={handleChange}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,
                            fontFamily: FONTS_INTER,
                            marginRight: 5,
                          }}
                        >
                          Yes
                        </Typography>
                        <Radio
                          disabled
                          size="small"
                          checked={selectedValue === "b"}
                          onChange={handleChange}
                          value="b"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "B" }}
                          style={{
                            color: COLORS.Primary,
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: COLORS.Primary,

                            fontFamily: FONTS_INTER,
                          }}
                        >
                          No
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 30,
                    }}
                  >
                    <Grid item xs={12} md={3} lg={3} sm={5.5}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Select your insurer
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setInsurer(e.target.value)}
                            value={insurer}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} sm={5.5}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Enter Policy Number
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setPolicy(e.target.value)}
                            value={policy}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS_INTER,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      md={3}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    // sx={{
                    //   padding: {
                    //     sm: 0,
                    //     md: "35px 0px",
                    //   },
                    // }}
                    >
                      <Button
                        disabled={idisable}
                        size="large"
                        variant="contained"
                        // style={{
                        //   background: "#808182",
                        //   height: "34px",
                        //   marginBottom: 14,
                        // }}
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "12pt",
                          color: "white",
                          padding: "20px 15px",
                          outline: "none",
                          border: "1px solid ",
                          borderColor: COLORS.InputBorderColor,
                          borderRadius: "5px",
                          height: "2rem",
                          background: COLORS.Orange2,
                          opacity: idisable === true ? 0.5 : 1,
                          marginBottom: 14,
                          boxShadow: "none",
                          width: "fit-content",
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "80%",
                            md: "90%",
                          },
                        }}
                      >
                        CHECK ELIGIBLITY
                      </Button>
                    </Grid>
                    <Grid item xs={12} lg={3} md={3}>
                      <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "left",
                              fontFamily: FONTS_INTER,
                              color: COLORS.TextColor,
                              fontSize: "8.5pt",
                              // letterSpacing: "1px",
                              fontWeight: "bold",
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            gutterBottom
                          >
                            Your entitlement
                          </Typography>
                          <input
                            disabled={idisable}
                            style={{
                              fontFamily: FONTS_INTER,
                              fontSize: "10.5pt",
                              padding: "4px 10px",
                              outline: "none",
                              borderRadius: 3,
                              height: "2rem",
                              border: "none",
                              background: COLORS.GrayDark3,
                              color: COLORS.TextColor2,
                              opacity: idisable === true ? 0.5 : 1,
                            }}
                            onChange={(e) => setentitlement(e.target.value)}
                            value={entitlement}
                          />
                          {false && (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{
                                textAlign: "left",
                                fontFamily: FONTS,
                                margin: "5px 0px",
                              }}
                            >
                              This Field is Requied
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>

                : <></>}

              {/* <Grid
                container
                xs={12}
                style={{
                  flexDirection: "row",
                  background: "white",
                  padding: "15px 25px",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                  },
                }}
              >
                <Grid item xs={12} md={5.8} lg={5.8} sm={5.8}>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        style={{
                          height: "34px",
                          // marginBottom: 19,
                          padding: "20px 15px",
                          fontSize: "11pt",
                          background: COLORS.Primary,
                          boxShadow: "none",
                          color: "white",
                          // width: "146px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                        }}
                        // onClick={sendVerification}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "12%",
                            md: "18%",
                          },
                        }}
                      >
                        Check Discounts
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5.8} lg={5.8} sm={5.8}>
                  <Grid md={12} xs={12} style={{ margin: "10px 0px 15px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "13pt",

                          background: COLORS.PrimaryBackground,
                          padding: "20px",
                        }}
                        gutterBottom
                      >
                        Your are entitled for 10% discount on every order
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  flexDirection: "row",
                  background: "white",
                  padding: matches.matches === true ? "15px 25px" : "15px 35px",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
              >

                <Grid item xs={9} sm={8} style={{ margin: "15px 0px", display: "flex", justifyContent: "left" }}>

                  {/* <FormControl>
                    <FormControlLabel
                      control={

                        <GreenCheckbox
                          checked={isStudent}
                          size="medium"
                          onChange={(e) => {
                            check1 = e.target.checked;
                            console.log("check 01 ", e.target.checked);
                            setisStudent(e.target.checked)
                          }}
                        />
                      }
                      label={
                        <Typography

                          style={{
                            fontSize: matches900.matches === true ? "9.5pt" : "10pt",
                            fontFamily: FONTS_INTER,
                            // marginTop: "15px",
                            marginLeft: "5px",
                            color: COLORS.TextColor,
                            textAlign: "left",
                          }}
                        >
                          Are you an International Student ?
                        </Typography>
                      }
                    />
                  </FormControl> */}

                  <Typography

                    style={{
                      fontSize: matches900.matches === true ? "9.5pt" : "13pt",
                      fontFamily: FONTS_INTER,
                      // marginTop: "15px",
                      // marginLeft: "5px",
                      color: COLORS.Primary,
                      textAlign: "left",
                    }}
                  >
                    Are you an International Student ?
                  </Typography>


                </Grid>

                <Grid
                  // md={3}
                  sm={4}
                  xs={3}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    margin: "15px 0px",
                  }}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "0px",
                    },
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Radio
                      // disabled
                      size="small"
                      checked={isStudent === true}
                      onChange={(e) => {
                        check1 = true;
                        console.log("check 01 ", true);
                        setisStudent(true)
                      }}
                      value={true}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      style={{
                        color: COLORS.Primary,
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        color: COLORS.Primary,
                        fontFamily: FONTS_INTER,
                        marginRight: 5,
                      }}
                    >
                      Yes
                    </Typography>
                    <Radio
                      // disabled
                      size="small"
                      checked={isStudent === false}
                      onChange={(e) => {
                        check1 = false;
                        console.log("check 01 ", false);
                        setisStudent(false)
                      }}
                      value={false}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                      style={{
                        color: COLORS.Primary,
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        color: COLORS.Primary,

                        fontFamily: FONTS_INTER,
                      }}
                    >
                      No
                    </Typography>
                  </div>
                </Grid>


                <Grid item xs={12} md={12} style={{ margin: "15px 0px", }}>

                  {
                    isStudent &&

                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Enter Student Id
                      </Typography>
                      <input

                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          color: COLORS.TextColor2,
                          padding: "4px 10px",
                          outline: "none",
                          border: "1px solid ",
                          // borderColor: COLORS.InputBorderColor,
                          borderRadius: 6,
                          height: "2rem",
                          border: "none",

                          background: COLORS.GrayDark3,
                        }}
                        type="text"
                        onChange={(e) => {
                          setstudentId(e.target.value);
                          seterrstudentId(false);
                        }}
                        value={studentId}
                      />
                      {errstudentId && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>


                  }



                </Grid>
                <Grid item xs={12} md={5.8} lg={5.8} sm={5.8}>

                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Enter Password
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          seterrPass(false);
                        }}
                        value={password}
                      />
                      {errPass && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          This Field is Requied
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5.8} lg={5.8} sm={5.8}>
                  <Grid md={12} xs={12} style={{ margin: "15px 0px" }}>
                    <Typography
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontFamily: FONTS_INTER,
                          color: COLORS.TextColor,
                          fontSize: "8.5pt",
                          // letterSpacing: "1px",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        Re Enter Password
                      </Typography>
                      <input
                        style={{
                          fontFamily: FONTS_INTER,
                          fontSize: "10.5pt",
                          padding: "4px 10px",
                          outline: "none",
                          borderRadius: 3,
                          height: "2rem",
                          border: "none",
                          background: COLORS.GrayDark3,
                          color: COLORS.TextColor2,
                        }}
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setrePassword(e.target.value);
                          seterrRePass("");
                        }}
                        value={repassword}
                      />
                      {errRePass && (
                        <Typography
                          variant="caption"
                          color="red"
                          style={{
                            textAlign: "left",
                            fontFamily: FONTS_INTER,
                            margin: "5px 0px",
                            fontSize: "8pt",
                          }}
                        >
                          {errRePass}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // padding: "10px 27px",
                  background: "none",
                  borderTop: `20px solid ${COLORS.GrayDark3}`,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                sx={{
                  padding: {
                    sm: "10px 27px",
                    xs: "10px 25px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                    float: "right",
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    style={{
                      marginBottom: 19,
                      // padding: "20px 15px",
                      // fontSize: "11pt",
                      background: COLORS.Primary,
                      boxShadow: "none",
                      color: "white",
                      // width: "146px",
                      fontWeight: "bold",
                      borderRadius: "5px",
                      paddingTop: "2px",
                    }}
                    onClick={sendVerification}
                    sx={{
                      fontSize: {
                        xs: "12pt",
                        sm: "11pt",
                      },
                      height: {
                        xs: "34pt",
                        sm: "34px",
                      },
                      width: {
                        xs: "100%",
                        lg: "146px",
                        md: "146px",
                      },
                    }}
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SignUp;
